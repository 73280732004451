import React, { useCallback } from "react";
import useTranslations from "@hooks/useTranslations";
import Button from "@oriola-origo/core/lib/Button";
import Box from "@oriola-origo/core/lib/Box";
import Footer from "../../common/footer";
import { styled } from "@oriola-origo/core/lib/styles";
import useInviteUser from "../../../services/user_management/hooks/inviteUser";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import useTurbolinks from "@hooks/useTurbolinks";
import useSnackbar from "@hooks/useSnackbar";
import useRoutes from "@hooks/useRoutes";
import { resetInviteUser } from "../../../store/states/inviteUserSlice";

export interface UserFooterProps {
  userListPath: string;
}

export const FooterContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  height: "100%",
  gap: theme.spacing(4),
}));

export const isUserInformationValid = (user, errors) => {
  return (
    user.email_address && user.first_name && user.last_name && !errors.length
  );
};

export default function UserFooter({
  userListPath,
}: Readonly<UserFooterProps>) {
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const { setSnackMessage } = useSnackbar();
  const routes = useRoutes();
  const { sendInvitation, isLoading } = useInviteUser();
  const {
    user,
    organizations,
    userAccessCompanyMap,
    defaultOrganizationId,
    errors,
  } = useAppSelector((state) => state.inviteUser);

  const companySelection = organizations?.map((org) => ({
    id: org.id,
    role_ids: userAccessCompanyMap[org.id]?.roleIds || [],
    user_group_ids: userAccessCompanyMap[org.id]?.userGroupIds || [],
    professional_title_id: org.professionalTitle.id,
    profit_center_ids: org.profitCenters.map((pc) => pc.id),
    scc_profit_center_ids: org.sccProfitCenters.map((scc) => scc.id) || [],
    default: org.id === defaultOrganizationId,
  }));

  const combinedData = {
    user: {
      ...user,
      company_selection: companySelection,
      subscribe_weekly_newsletter: true,
      subscribe_important_announcement_notification: true,
      subscribe_emergency_announcement_notification: true,
      active: true,
    },
  };

  const handleSendInvitationClick = useCallback(async () => {
    try {
      const result = await sendInvitation(combinedData);
      if (result.errors) {
        setSnackMessage(
          result.errors["email_address"][0].message.replace(
            "E-mail",
            combinedData.user.email_address
          ),
          "error",
          false,
          5000,
          I18n.t("invite_user_failure")
        );
      } else {
        setSnackMessage(
          result.email_address,
          "success",
          false,
          5000,
          I18n.t("invite_user_success")
        );
        dispatch(resetInviteUser());
        navigate(routes.userShowPath(result.id));
      }
    } catch (error) {
      setSnackMessage(
        combinedData.user.email_address,
        "error",
        false,
        5000,
        I18n.t("invite_user_error")
      );
    }
  }, [sendInvitation, combinedData]);

  const { navigate } = useTurbolinks();

  const handleCancelClick = () => {
    navigate(userListPath);
  };

  return (
    <Footer>
      <FooterContent>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCancelClick}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSendInvitationClick}
          isLoading={isLoading}
          disabled={
            !isUserInformationValid(user, errors) ||
            !organizations.length ||
            isLoading
          }
        >
          {t("send_invitation")}
        </Button>
      </FooterContent>
    </Footer>
  );
}
