import React from 'react';
import DetailList from '../common/detail_list';
import { Table } from '../common/table';
import { ContentSection, ContentSectionHead } from '../common/content_section';
import Info from '../common/info';
import IconLink from '../common/icon_link';
import FormModal from './form_modal';
import RoleIcon from './role_icon';

export default class OrigoApplicationRoleShow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            role: props.role
        }
    }

    render() {
        return <div>
            <div className="row">
                <div className="col">
                    <DetailList>
                        {[
                            [I18n.t('name'), this.state.role.name],
                            [I18n.t('role_str_id'), this.state.role.str_id],
                            [I18n.t('description'), this.state.role.description ?
                                { __html: StringHelper.simpleFormat(this.state.role.description, 'mb-0') } :
                                '-'
                            ],
                            [I18n.t('icon'), this.state.role.icon !== null ?
                                <RoleIcon icon={this.state.role.icon} title={this.state.role.icon_title} /> :
                                '-'
                            ]
                        ]}
                    </DetailList>
                </div>
                <div className="col-auto">
                    <IconLink iconClassName="fa fa-pencil text-highlight" href="#" onClick={e => this.handleEditRoleClick(e)}>
                        {I18n.t('edit')}
                    </IconLink>
                </div>
            </div>
            <ContentSection className="mt-5">
                <ContentSectionHead>
                    <h2>{I18n.t('role_users')}</h2>
                </ContentSectionHead>
                <OrigoApplicationRoleMemberList roleId={this.state.role.id} />
            </ContentSection>
            { this.state.editMode && 
                <FormModal
                    title={I18n.t('edit_role')}
                    onClose={this.handleDialogClose}
                    action={Routes.role_update_path()}
                    onSuccess={this.handleFormSuccess} 
                    role={this.state.role}
                    iconOptions={this.props.iconOptions}
                />
            }
        </div>
    }

    handleEditRoleClick(event) {
        event.preventDefault();
        this.setState({
            editMode: true,
        });
    }

    handleDialogClose = () => {
        this.setState({
            editMode: false,
        });
    }

    handleFormSuccess = (role) => {
        this.handleDialogClose();
        this.setState({
            role: role
        });
    }
}

class OrigoApplicationRoleMemberList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        Info.initTooltips();
    }

    render() {
        let columns = [
            {
                dataField: 'last_name',
                text: I18n.t('name'),
                sort: true,
                formatter: (cell, row) => {
                    return <a href={Routes.user_show_path({person_id: row.id})}>
                        {row.last_name && row.first_name ? [row.last_name, row.first_name].join(' ') : 'N/A'}
                    </a>;
                }
            },
            {
                dataField: 'email_address',
                text: I18n.t('email_address'),
                sort: true,
                headerClasses: 'd-none d-md-table-cell',
                classes: 'd-none d-md-table-cell text-truncate',
                formatter: (cell, row) => {
                    return <a href={`mailto:${row.email_address}`}>{row.email_address}</a>;
                },
            },
            {
                dataField: 'company_ids',
                text: I18n.t('organizations'),
                headerClasses: 'd-none d-lg-table-cell',
                classes: 'd-none d-lg-table-cell',
                headerFormatter: () => {
                    return <span>
                        {I18n.t('organizations')}
                    </span>;
                },
                formatter: (cell, row) => {
                    return row.companies.map(c => c.name).join(', ');
                }
            },
            {
                dataField: 'status',
                text: I18n.t('status'),
                sort: true,
                formatter: (cell, row) => {
                    return I18n.t('user_status_' + row.status);
                }
            }
        ];

        return <Table
            columns={columns}
            remote={true}
            remoteUrl={Routes.role_member_list_json_path({ role_id: this.props.roleId })}
            pagination={{ sizePerPage: 500 }}
            defaultSorted={[{
                dataField: 'last_name',
                order: 'asc'
            }]}
            noDataIndication={I18n.t('no_role_users')}
        />;
    }

}