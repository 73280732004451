import { InheritanceSource, InheritedRole } from "./types";
import {
  UserAccessCompanyItem,
  UserAccessCompanyMap,
  UserAccessInformationCompany,
} from "./user_access_information";

export const uniqArray = (arr: number[]) => [...new Set(arr)];

export enum UserAccessItemType {
  Role = "role",
  UserGroup = "user_group",
}

const getPropForType = (
  type: UserAccessItemType
): keyof UserAccessCompanyItem =>
  type === UserAccessItemType.Role ? "roleIds" : "userGroupIds";

export interface BuildUserAccessMapForCompanyAndChildsOpts {
  companies: UserAccessInformationCompany[];
  activeCompanyId: number;
  currentAccessMap: UserAccessCompanyMap;
  ids: { newState: number[]; oldState: number[] };
  skipIdsForChildren?: number[];
  accessType: UserAccessItemType;
}

export const buildUserAccessMapForCompanyAndChilds = ({
  accessType,
  activeCompanyId,
  companies,
  currentAccessMap,
  ids,
  skipIdsForChildren = [],
}: BuildUserAccessMapForCompanyAndChildsOpts): UserAccessCompanyMap => {
  const added = ids.newState.filter((id) => !ids.oldState.includes(id));
  const removed = ids.oldState.filter((id) => !ids.newState.includes(id));

  const childCompanyIds = companies
    .filter(
      (company) =>
        activeCompanyId &&
        company.hierarchy_parent_company_ids?.includes(activeCompanyId)
    )
    .map((company) => company.id);

  const accessProperty = getPropForType(accessType);
  const activeCompanyUserAccess = currentAccessMap[activeCompanyId];
  const companyCurrentIds = activeCompanyUserAccess?.[accessProperty] ?? [];
  const companyFilteredIds = companyCurrentIds.filter(
    (id) => !removed.includes(id)
  );

  const newState: UserAccessCompanyMap = {
    ...currentAccessMap,
    [activeCompanyId]: {
      ...activeCompanyUserAccess,
      [accessProperty]: uniqArray([...companyFilteredIds, ...added]),
    },
  };

  const inheritIds = added.filter((id) => !skipIdsForChildren.includes(id));
  childCompanyIds.forEach((companyId) => {
    const currentCompanyUserAccess = currentAccessMap[companyId];
    const currentIds = currentCompanyUserAccess?.[accessProperty] ?? [];
    const filteredIds = currentIds.filter((id) => !removed.includes(id));

    const newCompanyState = {
      userGroupIds: [],
      roleIds: [],
      ...(currentCompanyUserAccess ?? {}),
      [accessProperty]: uniqArray([...filteredIds, ...inheritIds]),
    };

    newState[companyId] = newCompanyState;
  });

  return newState;
};

const buildInheritedRoleArray = (
  ids: number[],
  source: InheritanceSource
): InheritedRole[] => ids.map((id) => ({ id, source }));

export const refreshInheritedRoles = (
  oldRoles: InheritedRole[],
  newSource: InheritanceSource,
  newIds: number[]
) => {
  const nonSourceRoles = oldRoles.filter((role) => role.source !== newSource);
  const newRoles = buildInheritedRoleArray(newIds, newSource);
  return [...nonSourceRoles, ...newRoles];
};
