import React from "react";
import countries from "i18n-iso-countries";
import useTranslations from "@hooks/useTranslations";
import Box from "@oriola-origo/core/lib/Box";
import Grid from "@oriola-origo/core/lib/Grid";
import Typography from "@oriola-origo/core/lib/Typography";
import Divider from "@oriola-origo/core/lib/Divider";
import Chip from "@oriola-origo/core/lib/Chip";
import { styled } from "@oriola-origo/core/lib/styles";
import { UserCompany } from "@services/user_management/types/users";

export interface OrganizationProps {
  organization: UserCompany;
  isFirst?: boolean;
}

const PositionedChip = styled(Chip)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  marginLeft: theme.spacing(1),
}));

const renderLabelValue = (
  label: string,
  value: React.ReactNode,
  chipValue = "",
  disabled = false
) => (
  <Box>
    <Typography
      display="block"
      color="secondaryText"
      fontSize={12}
      position="relative"
    >
      {label}
      {chipValue && (
        <PositionedChip
          label={chipValue}
          color="primary"
          variant="filled"
          size="small"
        />
      )}
    </Typography>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography
        display="block"
        py={1}
        sx={(theme) => ({
          color: disabled
            ? theme.palette.text.disabled
            : theme.palette.text.primary,
        })}
      >
        {value}
      </Typography>
    </Box>
  </Box>
);

const renderAddress = (organization: UserCompany, language: string) => {
  const { street, house_number, business_location, post_code, city, country } =
    organization;

  const lines: React.ReactNode[] = [];
  const maybeAdd = (value: string, key: string) =>
    value && lines.push(<Box key={key}>{value}</Box>);

  maybeAdd(
    [street, house_number, business_location].filter(Boolean).join(" "),
    "street"
  );
  maybeAdd([post_code, city].filter(Boolean).join(" "), "city");
  maybeAdd(country && countries.getName(country, language), "country");
  return lines;
};

export default function Organization({
  organization,
  isFirst = false,
}: Readonly<OrganizationProps>) {
  const { t, language } = useTranslations();
  const addressLines = renderAddress(organization, language);

  return (
    <Box pl={10 * (organization.hierarchy_level ?? 0)}>
      {!isFirst && <Divider />}
      <Box p={4}>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 7, lg: 6 }}>
            {renderLabelValue(
              t("organization"),
              `${organization.api_id} - ${organization.name}`,
              organization.default ? t("default_organization") : ""
            )}
          </Grid>
          <Grid size={{ xs: 12, md: 5, lg: 6 }}>
            {renderLabelValue(
              t("professional_title"),
              t(
                `professional_title_${organization.professional_title_str_id}`
              ) ?? "-"
            )}
          </Grid>
          <Grid size={{ xs: 12, md: 7, lg: 6 }}>
            {renderLabelValue(
              t("address"),
              Array.isArray(addressLines) && addressLines.length > 0
                ? addressLines
                : "-"
            )}
          </Grid>
          <Grid size={{ xs: 12, md: 5, lg: 6 }}>
            {renderLabelValue(
              t("organization_type"),
              organization.type_name[language] ??
                organization.type_name["en"] ??
                "-"
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
