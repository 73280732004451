import React from 'react';
import { Table, TableRowActions } from '../common/table';
import ProfitCenterForm from './form';

export default class List extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tableKey: StringHelper.random(8),
            mode: 'list' // new | edit
        };
    }

    render() {
        let columns = [
            {
                dataField: 'code',
                text: I18n.t('profit_center_code'),
                sort: true,
                formatter: (cell, row) => {
                    return !this.props.permissions.may_edit ? row.code : <a href="#" onClick={e => { this.handleEditProfitCenterClick(row); e.preventDefault(); }}>
                        {row.code}
                    </a>;
                }
            },
            {
                dataField: 'name',
                text: I18n.t('name'),
                sort: true,
                formatter: (cell, row) => {
                    return !this.props.permissions.may_edit ? row.name : <a href="#" onClick={e => { this.handleEditProfitCenterClick(row); e.preventDefault(); }}>
                        {row.name}
                    </a>;
                }
            },
            {
                dataField: 'reclamation_translations',
                text: I18n.t('reclamation_translations'),
                sort: true,
                formatter: (cell, row) => {
                    return cell === true ? I18n.t('txt_yes') : '';
                }
            },
            {
                dataField: 'reclamation_spd_email_address',
                text: I18n.t('reclamation_spd_email_addresses'),
                sort: true,
                formatter: (cell, row) => {
                    return row.reclamation_spd_email_addresses.map((a, i) => {
                        return <React.Fragment key={i}>
                            <a href={`mailto:${a}`}>{a}</a>{i !== row.reclamation_spd_email_addresses.length - 1 && ', '}
                        </React.Fragment>;
                    });
                }
            },
            {
                dataField: 'reclamation_use_supplier',
                text: I18n.t('reclamation_use_supplier'),
                sort: false,
                formatter: (cell, _) => {
                    return cell === true ? I18n.t('txt_yes') : '';
                }
            },
            {
                dataField: 'compensation_manager_service',
                text: I18n.t('compensation_manager_service'),
                sort: false,
                formatter: (cell, _) => {
                    return cell === true ? I18n.t('txt_yes') : '';
                }
            },
            {
                dataField: 'id',
                text: '',
                classes: 'row-actions',
                headerClasses: 'row-actions',
                formatter: (cell, row) => {
                    let actions = [];

                    if (this.props.permissions.may_edit) {
                        actions.push(<a key="edit" href="#" onClick={e => { this.handleEditProfitCenterClick(row); e.preventDefault(); }}>
                            <i className="fa fa-pencil"></i> {I18n.t('edit')}
                        </a>);
                    }
                    if (this.props.permissions.may_delete) {
                        if (actions.length > 0) {
                            actions.push(<TableRowActions.Divider key="div" />)
                        }
                        actions.push(<a key="del" href="#" onClick={e => { this.deleteProfitCenter(row); e.preventDefault(); }}><i className="oricon-bin"></i> {I18n.t('delete')}</a>);
                    }
                    return actions.length > 0 ? <TableRowActions.Menu>{actions}</TableRowActions.Menu> : null
                }
            }
        ];

        let actions = [];

        if (this.props.permissions.may_add) {
            actions.push(<a key="add" href="#" onClick={e => { this.handleAddProfitCenterClick(); e.preventDefault(); }} className="btn btn-light mr-3">
                <i className="fa fa-plus text-highlight"></i> {I18n.t('add')}
            </a>);
        }

        return (
            <div ref="root">
                <Table
                    key={this.state.tableKey}
                    ref="table"
                    columns={columns}
                    actions={actions}
                    pagination={{ sizePerPage: 20 }}
                    defaultSorted={[{
                        dataField: 'name',
                        order: 'asc'
                    }]}
                    remote={true}
                    remoteUrl={Routes.profit_center_list_json_path()}
                    preserveState={true} />

                {this.state.mode == 'new' && <ProfitCenterForm
                    title={I18n.t('add_profit_center')}
                    onClose={this.handleDialogClose}
                    action={Routes.profit_center_create_path()}
                    onSuccess={this.handleFormSuccess} 
                />}
                {this.state.mode == 'edit' && <ProfitCenterForm
                    title={I18n.t('edit_profit_center')}
                    onClose={this.handleDialogClose}
                    action={Routes.profit_center_update_path({profit_center_id: this.state.selectedProfitCenter.id})}
                    onSuccess={this.handleFormSuccess} 
                    profitCenter={this.state.selectedProfitCenter}
                />}
            </div>
        );
    }

    handleAddProfitCenterClick = () => {
        this.setState({ mode: 'new' });
    }

    handleEditProfitCenterClick = (profitCenter) => {
        this.setState({
            mode: 'edit',
            selectedProfitCenter: profitCenter
        });
    }

    handleDialogClose = () => {
        this.setState({
            mode: 'list',
            selectedProfitCenter: null
        });
    }

    handleFormSuccess = () => {
        this.handleDialogClose();
        this.reload();
    }

    reload = () => {
        this.setState({ tableKey: StringHelper.random(8) });
    }

    deleteProfitCenter = (profitCenter) => {
        if (confirm(I18n.t('confirm_delete_profit_center', { name: [profitCenter.code, profitCenter.name].join(' - ') }))) {
            jQuery.ajax({
                url: Routes.profit_center_delete_path({ profit_center_id: profitCenter.id }),
                type: 'DELETE',
                success: this.reload
            });
        }
    }
}