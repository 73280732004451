import React, { useState } from "react";
import Header from "@oriola-origo/core/lib/Header";
import Page from "@oriola-origo/core/lib/Page";
import Box from "@oriola-origo/core/lib/Box";
import Grid from "@oriola-origo/core/lib/Grid";
import Button from "@oriola-origo/core/lib/Button";
import useTranslations from "@hooks/useTranslations";
import { Organization } from "@services/user_management/types/organizations";
import EditableField, {
  EditableFieldMode,
} from "@components/common/editable_field";
import { localizeCountryName } from "@utils/country_localization";
import { createDefaultReadValueTransform } from "../utils";

const INITIAL_DISPLAY_DELIVERY_ADDRESSES = 2;

interface DeliveryAddressProps {
  organization: Organization;
}

function OrganizationDeliveryAddress({
  organization,
}: Readonly<DeliveryAddressProps>) {
  const { t } = useTranslations();
  const [showAll, setShowAll] = useState(false);
  const delivery_addresses = organization.delivery_addresses ?? [];
  const delivery_address_backorders =
    organization.delivery_address_backorders ?? {};
  const inputs = delivery_addresses.map((delivery_address) => [
    {
      property: "customer_number",
      label: t("customer_number"),
      value: delivery_address.id,
    },
    {
      property: "address",
      label: t("address"),
      value: `${delivery_address.street} ${delivery_address.house_number},${delivery_address.post_code} ${delivery_address.city},${localizeCountryName(delivery_address.country, I18n.locale)}`,
      readValueTransform: (value: string) => {
        return (
          `${delivery_address.name}\n` +
          value.split(",").filter(Boolean).join("\n")
        );
      },
      wrapText: true,
    },
    {
      property: "accepts_backorders",
      label: t("accepts_backorders"),
      value: delivery_address_backorders[delivery_address.id] ?? false,
    },
  ]);

  const initialItems = inputs.splice(0, INITIAL_DISPLAY_DELIVERY_ADDRESSES);
  const defaultReadValueTransform = createDefaultReadValueTransform(t);

  return (
    <Page header={<Header title={t("delivery_addresses")} />} mb={2}>
      <Box>
        <Grid container spacing={2}>
          <Grid size={{ lg: 12 }}>
            <Grid container rowSpacing={2} columnSpacing={8}>
              {initialItems.map((delivery_address) =>
                delivery_address.map((field) => (
                  <Grid size={{ xs: 4 }} key={field.property}>
                    <EditableField
                      mode={EditableFieldMode.Read}
                      label={field.label}
                      value={field.value}
                      notEditable={true}
                      readValueTransform={
                        field.readValueTransform ?? defaultReadValueTransform
                      }
                      wrapText={field.wrapText}
                    />
                  </Grid>
                ))
              )}
              {showAll &&
                inputs.map((delivery_address) =>
                  delivery_address.map((field) => (
                    <Grid size={{ xs: 4 }} key={field.property}>
                      <EditableField
                        mode={EditableFieldMode.Read}
                        label={field.label}
                        value={field.value}
                        notEditable={true}
                        readValueTransform={
                          field.readValueTransform ?? defaultReadValueTransform
                        }
                        wrapText={field.wrapText}
                      />
                    </Grid>
                  ))
                )}
              {inputs.length > 0 && !showAll && (
                <Box
                  display="flex"
                  mt={2}
                  textAlign="center"
                  sx={{ justifyContent: "center", width: "400%" }}
                >
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => setShowAll(!showAll)}
                  >
                    {`${t("show_all")} (${inputs.length})`}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
}

export default OrganizationDeliveryAddress;
