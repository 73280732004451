import React from "react";
import useTranslations from "@hooks/useTranslations";
import { styled } from "@oriola-origo/core/lib/styles";
import Box from "@oriola-origo/core/lib/Box";
import Grid from "@oriola-origo/core/lib/Grid";
import Typography from "@oriola-origo/core/lib/Typography";
import Chip from "@oriola-origo/core/lib/Chip";
import Tooltip from "@oriola-origo/core/lib/Tooltip";
import {
  OrigoApplicationRole,
  UserGroup,
} from "@services/user_management/types";

export interface OrganizationAccessSummaryProps {
  userGroupsMapping: Record<string, Array<UserGroup>>;
  rolesMapping: Record<
    string,
    Array<OrigoApplicationRole & { isInherited: boolean }>
  >;
}

const DisabledStyledChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.text.disabled,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.info.main,
}));

const ChipContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(0.75),
  flexWrap: "wrap",
}));

export default function OrganizationAccessSummary({
  userGroupsMapping,
  rolesMapping,
}: Readonly<OrganizationAccessSummaryProps>) {
  const { t } = useTranslations();

  return (
    <Grid container spacing={3}>
      <Grid size={{ md: 12, lg: 5 }}>
        <Box>
          <Typography variant="h6" color="textSecondary">
            {t("user_groups")}
          </Typography>
          {Object.entries(userGroupsMapping).map(
            ([category, groups]) =>
              groups.length > 0 && (
                <Box display="flex" mt={1.5}>
                  <Typography
                    variant="subtitle1"
                    sx={{ marginRight: "8px", minWidth: "100px" }}
                  >
                    {category}
                  </Typography>
                  <ChipContainer>
                    {groups.map((group) => (
                      <StyledChip
                        key={group.id}
                        label={group.name}
                        color="secondary"
                        size="small"
                      />
                    ))}
                  </ChipContainer>
                </Box>
              )
          )}
        </Box>
      </Grid>

      <Grid size={{ md: 12, lg: 7 }}>
        <Box>
          <Typography variant="h6" color="textSecondary">
            {t("roles")}
          </Typography>
          {Object.entries(rolesMapping).map(
            ([app, roles]) =>
              roles.length > 0 && (
                <Box display="flex" mt={1.5}>
                  <Typography
                    variant="subtitle1"
                    sx={{ marginRight: "8px", minWidth: "270px" }}
                  >
                    {app}
                  </Typography>
                  <ChipContainer>
                    {roles.map((role) =>
                      role.isInherited ? (
                        <Tooltip
                          title={t(
                            "roles_inherited_from_organization_and_user_groups"
                          )}
                          placement="top-start"
                        >
                          <DisabledStyledChip
                            key={role.id}
                            label={role.name}
                            color="secondary"
                            size="small"
                          />
                        </Tooltip>
                      ) : (
                        <StyledChip
                          key={role.id}
                          label={role.name}
                          color="secondary"
                          size="small"
                        />
                      )
                    )}
                  </ChipContainer>
                </Box>
              )
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
