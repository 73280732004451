import React from "react";
import UserInformation from "./form_v2/user_information";
import OrganizationInformation from "./form_v2/organization_information/organization_information";
import UserAccessInformation, {
  UserAccessInformationProps,
} from "@components/user/components/user_access_information/user_access_information";
import Box from "@oriola-origo/core/lib/Box";
import BackButton from "@components/common/back_button";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import OriolaSnackbarProvider from "@oriola-origo/core/lib/Snackbar/SnackbarProvider";
import useTranslations from "@hooks/useTranslations";
import { FooterProvider } from "../../hooks/useFooter";
import UserFooter from "./form_v2/user_footer";
import { UserFormV2Props } from "./form_v2/types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setOrganizationUserAccessMap } from "@store/states/inviteUserSlice";

function UserFormV2(props: Readonly<UserFormV2Props>) {
  const {
    userListPath,
    apps,
    permissions,
    roles,
    user_groups,
    user_group_categories,
    noninheriting_role_ids,
    professional_titles,
    profit_centers,
    scc_profit_center_allowed_organization_ids,
    default_professional_title_id,
  } = props;
  const { t } = useTranslations();
  const dispatch = useAppDispatch();

  const { lastAddedOrganizationId, selectedOrgs, userAccessMap } =
    useAppSelector((state) => ({
      selectedOrgs: state.inviteUser.organizations,
      lastAddedOrganizationId: state.inviteUser.lastAddedOrganizationId,
      userAccessMap: state.inviteUser.userAccessCompanyMap,
    }));

  const handleUserAccessChange: UserAccessInformationProps["onUserAccessMapChange"] =
    (userAccessMap) => {
      dispatch(setOrganizationUserAccessMap(userAccessMap));
    };

  return (
    <OriolaThemeProvider>
      <OriolaSnackbarProvider>
        <Box minWidth="320px">
          <BackButton
            navigateTo={userListPath}
            text={t("back_to_user_list")}
            mt={0}
            mb={3}
          />
          <UserInformation permissions={permissions} />
          <OrganizationInformation
            defaultProfessionalTitleId={default_professional_title_id}
            professionalTitles={professional_titles}
            profitCenters={profit_centers}
            sccProfitCenterAllowedOrganizationIds={
              scc_profit_center_allowed_organization_ids
            }
          />
          {selectedOrgs.length > 0 && (
            <UserAccessInformation
              apps={apps}
              roles={roles}
              onUserAccessMapChange={handleUserAccessChange}
              userAccessMap={userAccessMap}
              userGroups={user_groups}
              selectedCompanies={selectedOrgs}
              prioritizeCompanyWithId={lastAddedOrganizationId}
              userGroupCategories={user_group_categories}
              permissions={permissions}
              nonInheritingRoleIds={noninheriting_role_ids}
            />
          )}
        </Box>
        <FooterProvider>
          <UserFooter userListPath={userListPath} />
        </FooterProvider>
      </OriolaSnackbarProvider>
    </OriolaThemeProvider>
  );
}

export default UserFormV2;
