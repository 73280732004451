import React from "react";
import useTranslations from "@hooks/useTranslations";
import Box from "@oriola-origo/core/lib/Box";
import Header from "@oriola-origo/core/lib/Header";

function OrganizationHeader({ onToggleOpen, title_key }) {
  const { t } = useTranslations();
  return (
    <Box>
      <Header
        buttons={[
          {
            onClick: () => onToggleOpen(true),
            text: t("add_organization"),
            variant: "contained",
            icon: "work",
          },
        ]}
        description=""
        title={t(title_key)}
      />
    </Box>
  );
}
export default OrganizationHeader;
