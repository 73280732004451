import React, { useState, useEffect, useMemo } from "react";
import TableVirtualized, {
  TableVirtualizedProps,
} from "@oriola-origo/core/lib/TableVirtualized";
import TableRow from "@oriola-origo/core/lib/TableRow";
import useMediaQuery from "@oriola-origo/core/lib/useMediaQuery";
import TableCell from "@oriola-origo/core/lib/TableCell";
import Box from "@oriola-origo/core/lib/Box";
import useScrollContainer from "@hooks/useScrollContainer";
import Typography from "@oriola-origo/core/lib/Typography";
import Tooltip from "@oriola-origo/core/lib/Tooltip";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Progress from "@oriola-origo/core/lib/Progress";
import { styled, useTheme } from "@oriola-origo/core/lib/styles";
import useRoutes from "@hooks/useRoutes";
import NoStyleAnchor from "@components/common/no_style_anchor";
import TableCellNoContent from "@oriola-origo/core/lib/TableCellNoContent/TableCellNoContent";
import useTranslations from "@hooks/useTranslations";
import { CompanyType } from "@services/user_management/types";
import Headers from "./components/header";
import StatusChip from "@components/common/user_management_base_table/status_chip";
import { BaseCell } from "@components/common/user_management_base_table/common_cells";
import { ListedOrganization } from "@services/user_management/hooks/useOrganizations";
import ActionsCell, {
  ActionsCellItem,
} from "@components/common/user_management_base_table/actions_cell";
import ForceChildTableToFixedLayoutWrapper from "@components/common/user_management_base_table/force_child_table_to_fixed_layout_wrapper";
import DetailsSummary from "@components/common/user_management_base_table/details/details_summary";
import HideableIconButton from "./components/hideable_icon_button";
import useWindow from "@hooks/useWindow";
import OrganizationLogo from "./components/organization_logo";
import NameCell from "./components/name_cell";
import { FiltersType } from "./components/filters";
import { TableColumnNonSortable } from "./columns";

export interface OrganizationTableActionItem extends ActionsCellItem {}

export interface OrganizationTableProps {
  organizations: ListedOrganization[];
  counts?: {
    total: number;
    active: number;
    passive: number;
  };
  companyTypes: CompanyType[];
  isRePopulating: boolean;
  isLoading: boolean;
  filters?: FiltersType;
  onRequestMore: () => void;
  onFiltersChange: (filter: FiltersType) => void;
  actionMenuItems?: OrganizationTableActionItem[];
}

const resolveCompanyTypeName = (
  companyTypes: CompanyType[],
  typeStrId: string,
  language: string
) => {
  const companyType = companyTypes.find(
    (companyType) => companyType.str_id === typeStrId
  );
  return companyType?.name?.[language] ?? companyType?.name?.["en"] ?? "";
};

enum OrganizationStatus {
  Active = "active",
  Passive = "passive",
}

const ORGANIZATION_STATUS_LOCALE_MAP = {
  [OrganizationStatus.Active]: "company_status_active",
  [OrganizationStatus.Passive]: "company_status_passive",
};

const resolveOrganizationStatus = (organization: any): OrganizationStatus => {
  const isActive = organization.active && !organization.name.startsWith("#");
  return isActive ? OrganizationStatus.Active : OrganizationStatus.Passive;
};

const Container = styled("div")({
  minHeight: 450,
});

const FlexFixedNoStyleAnchor = styled(NoStyleAnchor)({
  minWidth: 0,
  flex: 1,
});

const renderLoadingSpinnerCell = (columnCount: number) => (
  <TableCell colSpan={columnCount}>
    <Box display="flex" justifyContent="center">
      <Progress color="secondary" size={50} />
    </Box>
  </TableCell>
);

function OrganizationTable({
  actionMenuItems,
  companyTypes,
  organizations,
  counts,
  isLoading,
  isRePopulating,
  filters,
  onFiltersChange,
  onRequestMore,
}: Readonly<OrganizationTableProps>) {
  const { t, language } = useTranslations();
  const { scrollContainer } = useScrollContainer();
  const { companyShowPath } = useRoutes();
  const window = useWindow();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const [closedOrganizationItems, setClosedOrganizationItems] = useState<
    number[]
  >([]);
  const [actionOpenItemId, setActionOpenItemId] = useState<number | null>(null);
  const shouldShowNoContent = organizations.length === 0 && !isLoading;
  const shouldShowLoadingInitialContent =
    isLoading && organizations.length === 0;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallish = useMediaQuery(theme.breakpoints.down("md"));

  const columns = [
    TableColumnNonSortable.Name,
    isSmall ? null : TableColumnNonSortable.Type,
    isSmallish ? null : TableColumnNonSortable.Users,
    isSmallish ? null : TableColumnNonSortable.Details,
    TableColumnNonSortable.Status,
    TableColumnNonSortable.Actions,
  ].filter(Boolean);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef) {
        setContainerWidth(containerRef.offsetWidth);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef]);

  const rowRenderer: TableVirtualizedProps["onRowRender"] = (
    rowIndex: number,
    organization: ListedOrganization
  ) => {
    if (shouldShowNoContent) {
      return (
        <TableCellNoContent colSpan={columns.length} iconName="work_off">
          {t("table_empty_data_notification")}
        </TableCellNoContent>
      );
    } else if (shouldShowLoadingInitialContent) {
      return renderLoadingSpinnerCell(columns.length);
    }

    const nextOrganizationHierarchyLevel =
      organizations[rowIndex + 1]?.hierarchy_level;
    const hasAnythingToExpand =
      organization.hierarchy_child_company_api_ids?.length > 0;
    const isExpanded =
      hasAnythingToExpand && !closedOrganizationItems.includes(organization.id);

    const renderStatusChip = () => {
      const organizationStatus = resolveOrganizationStatus(organization);
      return (
        <StatusChip
          status={organizationStatus}
          localeMap={ORGANIZATION_STATUS_LOCALE_MAP}
        />
      );
    };

    return (
      <>
        <NameCell
          isDisabled={isRePopulating}
          hierarchyLevel={organization.hierarchy_level}
          leadingSpacingLevel={nextOrganizationHierarchyLevel}
        >
          <HideableIconButton
            invisible={!hasAnythingToExpand}
            onClick={() => {
              const newClosedOrganizationItems = [...closedOrganizationItems];
              if (newClosedOrganizationItems.includes(organization.id)) {
                newClosedOrganizationItems.splice(
                  newClosedOrganizationItems.indexOf(organization.id),
                  1
                );
              } else {
                newClosedOrganizationItems.push(organization.id);
              }
              setClosedOrganizationItems(newClosedOrganizationItems);
            }}
          >
            <FontIcon sx={{ color: (theme) => theme.palette.secondary.main }}>
              {isExpanded ? "remove" : "add"}
            </FontIcon>
          </HideableIconButton>

          <FlexFixedNoStyleAnchor href={companyShowPath(organization.id)}>
            <Tooltip title={`${organization.api_id} - ${organization.name}`}>
              <Typography variant="body2" color="textPrimary" noWrap>
                {organization.api_id} - {organization.name}
              </Typography>
            </Tooltip>
          </FlexFixedNoStyleAnchor>

          {organization.logo && (
            <OrganizationLogo organization={organization} />
          )}
        </NameCell>
        {columns.includes(TableColumnNonSortable.Type) && (
          <BaseCell isDisabled={isRePopulating}>
            <Typography variant="body2" color="textSecondary" noWrap>
              {resolveCompanyTypeName(
                companyTypes,
                organization.type_str_id,
                language
              )}
            </Typography>
          </BaseCell>
        )}
        {columns.includes(TableColumnNonSortable.Users) && (
          <BaseCell isDisabled={isRePopulating}>
            {organization.user_count}
          </BaseCell>
        )}
        {columns.includes(TableColumnNonSortable.Details) && (
          <BaseCell isDisabled={isRePopulating}>
            <DetailsSummary
              companyTypes={companyTypes}
              roles={organization.all_roles}
            />
          </BaseCell>
        )}
        <BaseCell isDisabled={isRePopulating}>{renderStatusChip()}</BaseCell>

        <ActionsCell
          resource={organization}
          items={actionMenuItems ?? []}
          isDisabled={isRePopulating}
          isMenuOpen={actionOpenItemId === organization.id}
          onMenuClose={() => setActionOpenItemId(null)}
          onMenuOpen={() => setActionOpenItemId(organization.id)}
        />
      </>
    );
  };

  const headerRenderer = () => (
    <Headers
      columns={columns}
      tableWidth={containerWidth ?? 0}
      companyTypes={companyTypes}
      filters={filters}
      onFiltersChange={onFiltersChange}
      counts={counts}
    />
  );

  const footerRenderer = () => {
    if (organizations.length >= (counts?.total ?? 0) || !isLoading) {
      return null;
    }

    return <TableRow>{renderLoadingSpinnerCell(columns.length)}</TableRow>;
  };

  const filteredOrganizations = useMemo(
    () =>
      organizations.filter((org) => {
        const isClosed = org.hierarchy_parent_company_ids?.some((parentId) =>
          closedOrganizationItems.includes(parentId)
        );

        return !isClosed;
      }),
    [organizations, closedOrganizationItems]
  );

  return (
    <Container ref={setContainerRef}>
      <ForceChildTableToFixedLayoutWrapper>
        <TableVirtualized
          data={
            shouldShowNoContent || shouldShowLoadingInitialContent
              ? [{ id: -1 }]
              : filteredOrganizations
          }
          scrollParent={scrollContainer}
          headerZIndex={999}
          idProperty="id"
          onRowRender={rowRenderer}
          onHeaderRender={headerRenderer}
          onFooterRender={footerRenderer}
          onEndReached={() => {
            onRequestMore();
          }}
          hideHoverEffect={true}
        />
      </ForceChildTableToFixedLayoutWrapper>
    </Container>
  );
}

export default OrganizationTable;
