export enum TableColumnNonSortable {
  Expand = "expand",
  Actions = "actions",
  ProfessionalTitle = "professional_title",
  Group = "group",
  Details = "details",
  Status = "status",
}

export enum TableColumnSortable {
  Name = "name",
  Email = "email",
}

export enum TableColumnSortDirection {
  Asc = "asc",
  Desc = "desc",
}

export interface SortType {
  column: TableColumnSortable;
  direction: TableColumnSortDirection;
}

const getColumnWidths = (
  showProfessionalTitleColumn: boolean
): Record<string, number | string> => {
  const columns = {
    [TableColumnSortable.Name]: "",
    [TableColumnNonSortable.Expand]: 60,
    [TableColumnNonSortable.Details]: 105,
    [TableColumnNonSortable.Status]: 120,
    [TableColumnNonSortable.Actions]: 100,
  };

  if (showProfessionalTitleColumn) {
    columns[TableColumnSortable.Email] = "20%";
    columns[TableColumnNonSortable.ProfessionalTitle] = "25%";
    columns[TableColumnNonSortable.Group] = "25%";
  } else {
    columns[TableColumnSortable.Email] = "25%";
    columns[TableColumnNonSortable.Group] = "30%";
  }

  return columns;
};
const percentageStringToDecimal = (percentageString: string) =>
  parseFloat(percentageString.replace("%", "")) / 100;

export const getColumnWidthFactory = (showProfessionalTitleColumn: boolean) => {
  const columnsMap = getColumnWidths(showProfessionalTitleColumn);
  const fixedWidthSum = Number(
    Object.values(columnsMap).reduce(
      (acc: number, width) => (typeof width === "number" ? acc + width : acc),
      0
    )
  );

  return (column: string, overallWidth: number) => {
    const columnWidth: number | string | undefined = columnsMap[column];
    if (typeof columnWidth === "string" && columnWidth.endsWith("%")) {
      const freeSpace = overallWidth - fixedWidthSum;
      return Math.floor(freeSpace * percentageStringToDecimal(columnWidth));
    }
    return columnWidth;
  };
};
