import React, { useCallback } from "react";
import { OrigoHeader } from "@oriola-origo/origo-app-shell";

export default function Header(props) {
  // Store header data into global origo cache to be re-used in new reports layout
  const headerDataHandler = useCallback((data) => {
    const cacheKey = `origo-user-data-${props.organizationId}-${props.accessToken}`;
    Origo.cache.set(cacheKey, data);
  }, []);

  return (
    <OrigoHeader
      origoAccessToken={props.accessToken}
      origoUrl={Origo.BASE_URL}
      queryParameters={window.location.search}
      activeOrganizationApiId={props.organizationId}
      onHeaderData={headerDataHandler}
    />
  );
}
