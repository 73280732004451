import useFetch from "@hooks/useFetch";
import useRoutes from "@hooks/useRoutes";
import { useCallback, useMemo } from "react";

interface OrganizationOptionsHookProps {
  excludeNoOrganization: boolean;
}

function useOrganizationOptions({
  excludeNoOrganization,
}: OrganizationOptionsHookProps) {
  const { userCompanyFilterOptionsPath } = useRoutes();
  const { doFetch, isFetching } = useFetch();

  const fetchOrganizationOptions = useCallback(async (): Promise<
    Array<{
      id: number;
      api_id: string;
      name: string;
      hierarchy_level?: number;
      hierarchy_parent_company_ids?: number[];
    }>
  > => {
    const res = await doFetch(
      userCompanyFilterOptionsPath({ excludeNoOrganization })
    );
    const jsonData = await res.json();
    return jsonData.list_companies;
  }, [doFetch]);

  return useMemo(
    () => ({ fetchOrganizationOptions, isFetching }),
    [fetchOrganizationOptions, isFetching]
  );
}

export default useOrganizationOptions;
