import { styled } from "@oriola-origo/core/lib/styles";
import { HTMLProps } from "react";

const NoStyleAnchor = styled("a")<
  HTMLProps<HTMLAnchorElement> & { disabled?: boolean }
>((props) => ({
  textDecoration: "none",
  color: "inherit",
  "&:hover": {
    textDecoration: "none",
    color: "inherit",
  },
  pointerEvents: props.disabled ? "none" : "auto",
  cursor: props.disabled ? "not-allowed" : "pointer",
}));

export default NoStyleAnchor;
