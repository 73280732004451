import React, { useMemo, useState } from "react";
import useTranslations from "@hooks/useTranslations";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import { InternalItemType, toInternalItemType } from "./internal_item_type";
import {
  ActionButton,
  ActionsContainer,
  AttentionBarContainer,
  CloseButton,
  Content,
  ContentBody,
  ContentTitle,
  Icon,
  ReadMore,
} from "./attention_bar_components";

export interface AttentionBarItem {
  id: number;
  type: string;
  title: string;
  body: string;
  announcement_url: string;
  is_confirmed?: boolean;
}

export interface AttentionBarProps {
  items: AttentionBarItem[];
  isEmergencyResponsiblePerson: boolean;
  onDismiss: (dismissedItems: AttentionBarItem[]) => void;
}

const canBeConfirmed = (
  item: AttentionBarItem,
  isEmergencyResponsiblePerson: boolean
) =>
  isEmergencyResponsiblePerson
    ? isEmergencyAnnouncement(item) && !item.is_confirmed
    : false;

const isEmergencyAnnouncement = ({ type }: { type: string }) =>
  toInternalItemType(type) === InternalItemType.EmergencyAnnouncement;

const ICONS = {
  other: {
    infoIcon: "oricon-yellow-alert",
    closeIcon: "oricon-yellow-close",
  },
  ImportantAnnouncement: {
    infoIcon: "oricon-red-alert",
    closeIcon: "oricon-red-close",
  },
  EmergencyAnnouncement: {
    infoIcon: "oricon-red-zap",
    closeIcon: "oricon-red-close",
  },
};

export default function AttentionBar({
  items: unsortedItems,
  isEmergencyResponsiblePerson,
  onDismiss,
}: Readonly<AttentionBarProps>) {
  const { t } = useTranslations();
  const [openIndex, setOpenIndex] = useState<number>(0);
  const items = useMemo(
    () => [...unsortedItems].sort((a) => (isEmergencyAnnouncement(a) ? -1 : 1)),
    [unsortedItems]
  );

  const activeItem = items[openIndex];
  if (!activeItem) {
    return null;
  }

  const isLast = openIndex === items.length - 1;
  const canDismissAll = isLast && items.length > 1;
  const hasPrevious = openIndex > 0;
  const hasNext = !isLast;
  const nextCount = items.length - openIndex - 1;
  const isConfirmable = canBeConfirmed(
    activeItem,
    isEmergencyResponsiblePerson
  );
  const isDismissable = !isConfirmable;

  const dismissActiveItem = () => {
    if (isDismissable) {
      onDismiss([activeItem]);
      setOpenIndex(0);
    }
  };

  const handleDismissAllClick = () => {
    const dismissableItems = items.filter(
      (item) => !canBeConfirmed(item, isEmergencyResponsiblePerson)
    );
    onDismiss(dismissableItems);
    setOpenIndex(0);
  };

  const internalItemType = toInternalItemType(activeItem.type);
  return (
    <OriolaThemeProvider>
      <AttentionBarContainer mb={4} schemaType={internalItemType}>
        {isDismissable && (
          <CloseButton
            className={ICONS[internalItemType].closeIcon}
            onClick={dismissActiveItem}
          />
        )}
        <Icon className={ICONS[internalItemType].infoIcon} />
        <Content>
          <ContentTitle>{activeItem.title}</ContentTitle>
          <ContentBody>
            {!!activeItem.body.length && (
              <p dangerouslySetInnerHTML={{ __html: activeItem.body }}></p>
            )}

            <ReadMore
              target="_blank"
              onClick={dismissActiveItem}
              href={`${activeItem.announcement_url}`}
            >
              {t("read_more")}
            </ReadMore>
          </ContentBody>
        </Content>
        <ActionsContainer>
          {items.length > 1 && (
            <>
              {hasPrevious && (
                <ActionButton
                  schemaType={internalItemType}
                  onClick={() => setOpenIndex(openIndex - 1)}
                >
                  {t("previous")}
                </ActionButton>
              )}
              {hasNext && (
                <ActionButton
                  schemaType={internalItemType}
                  onClick={() => setOpenIndex(openIndex + 1)}
                >
                  {t("next")} ({nextCount})
                </ActionButton>
              )}
              {canDismissAll && isDismissable && (
                <ActionButton
                  schemaType={internalItemType}
                  onClick={() => {
                    handleDismissAllClick();
                  }}
                >
                  {t("close_all")}
                </ActionButton>
              )}
            </>
          )}
          {isConfirmable && (
            <ActionButton
              schemaType={internalItemType}
              onClick={() => window.open(activeItem.announcement_url, "_blank")}
            >
              {t("confirmation_required")}
            </ActionButton>
          )}
        </ActionsContainer>
      </AttentionBarContainer>
    </OriolaThemeProvider>
  );
}
