import React, { useEffect, useState } from "react";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@oriola-origo/core/lib/Autocomplete/Autocomplete";
import TextField from "@oriola-origo/core/lib/TextField";
import useTranslations from "@hooks/useTranslations";
import useOrganizationOptions from "./useOrganizationOptions";
import useDebounce from "@hooks/useDebounce";
import { constructFilteredOptionsWithFamilies } from "./utils";

export type OrganizationFilterOption = {
  id: number;
  name: string;
  api_id: string;
  hierarchy_level?: number;
  hierarchy_parent_company_ids?: number[];
};

export interface OrganizationFilterProps {
  defaultValue?: number[];
  onSelectionChange: (selectedOptions: OrganizationFilterOption[]) => void;
  singleSelection?: boolean;
  moreText?: string;
  hasAutoFocus?: boolean;
  required?: boolean;
  getOptionDisabled?: (option: OrganizationFilterOption) => boolean;
  excludeNoOrganization?: boolean;
}

const MAX_ITEMS_SHOWN = 250;
const NON_BREAKING_SPACE = "\u00A0";

const getIndentationSpaces = (hierarchyLevel: number): string =>
  Array.from({
    length: (hierarchyLevel ?? 0) * 4,
  })
    .fill(NON_BREAKING_SPACE)
    .join("");

const generateLabel = (
  option: OrganizationFilterOption,
  includeHierarchySpacing = true
) => {
  if (!option) {
    return "";
  }

  const spacesForHierarchy = includeHierarchySpacing
    ? getIndentationSpaces(option.hierarchy_level)
    : "";

  return option.id > 0
    ? `${spacesForHierarchy}${option.api_id} - ${option.name}`
    : option.name ?? "";
};

function OrganizationFilter({
  defaultValue,
  onSelectionChange,
  singleSelection,
  moreText = "",
  hasAutoFocus = false,
  required = false,
  excludeNoOrganization = false,
  getOptionDisabled,
}: Readonly<OrganizationFilterProps>) {
  const { t } = useTranslations();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const { fetchOrganizationOptions } = useOrganizationOptions({
    excludeNoOrganization,
  });
  const [allOptions, setAllOptions] = useState<OrganizationFilterOption[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<
    OrganizationFilterOption[]
  >([]);
  const [selectedOptions, setSelectedOptions] = useState<
    OrganizationFilterOption[]
  >([]);

  useEffect(() => {
    const fetchOptions = async () => {
      const options = await fetchOrganizationOptions();
      setAllOptions(options);
      if (Array.isArray(defaultValue)) {
        setSelectedOptions(
          options.filter((option) => defaultValue.includes(option.id))
        );
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    let newFilteredOptions: OrganizationFilterOption[];
    let filteredBySearchValue = false;
    if (searchValue === "") {
      newFilteredOptions = [...allOptions];
    } else {
      filteredBySearchValue = true;
      newFilteredOptions = allOptions.filter((option) =>
        generateLabel(option, false)
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
      );
    }

    if (newFilteredOptions.length > MAX_ITEMS_SHOWN) {
      newFilteredOptions = newFilteredOptions.slice(0, MAX_ITEMS_SHOWN);
    }

    if (filteredBySearchValue) {
      // Build families for filtered options to keep hierarchy intact when searching
      newFilteredOptions = constructFilteredOptionsWithFamilies(
        newFilteredOptions,
        allOptions
      );
    }

    setFilteredOptions(newFilteredOptions);
  }, [debouncedSearchValue, allOptions]);

  const renderInputWithAutofocus = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        data-testid="organization-autofocus-input"
        {...params}
        label={t("organizations")}
        autoFocus
        required={required}
      />
    );
  };

  return (
    <Autocomplete
      required={required}
      label={t("organizations")}
      multiple={true}
      fullWidth={true}
      freeSolo={false}
      inputValue={searchValue}
      options={filteredOptions}
      value={selectedOptions}
      limitTags={singleSelection ? 1 : 2}
      getOptionDisabled={getOptionDisabled}
      disableCloseOnSelect={!singleSelection}
      size="small"
      renderInput={hasAutoFocus && renderInputWithAutofocus}
      getLimitTagsText={(more) =>
        `${NON_BREAKING_SPACE}${NON_BREAKING_SPACE}${moreText ?? "+" + more}`
      }
      getOptionLabel={(option) => generateLabel(option)}
      filterOptions={(options) => options}
      onChange={(_, newValue) => {
        if (singleSelection) {
          if (newValue.length === 1 && !generateLabel(newValue[0])) {
            newValue = [];
          } else if (newValue.length) {
            newValue = [
              newValue[newValue.length - 1],
              { id: -999, api_id: "", name: "" },
            ].filter(Boolean);
          }
        }

        setSelectedOptions(newValue);
        onSelectionChange(newValue.map((option) => ({ ...option })));
      }}
      onInputChange={(_, value, reason) => {
        setSearchValue(value);
      }}
    />
  );
}

export default OrganizationFilter;
