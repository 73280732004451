import { useState, useCallback, useMemo } from "react";
import useUser from "@services/user_management/hooks/useUser";
import useSnackbar from "@hooks/useSnackbar";
import useTranslations from "@hooks/useTranslations";
import useEditableUser from "@components/user/contexts/editable_user";

type PatchUpdateUserParameterUser = Parameters<
  ReturnType<typeof useUser>["patchUpdateUser"]
>[0]["user"];

export default function useEmailNotifications() {
  const { patchUpdate, isUpdating: isUpdatingUser, user } = useEditableUser();
  const { setSnackMessage } = useSnackbar();
  const { t } = useTranslations();
  const [togglingNotificationSwitch, setTogglingNotificationSwitch] =
    useState("");

  const updateUserEmailNotifications = useCallback(
    async (properties: PatchUpdateUserParameterUser) => {
      try {
        const res = await patchUpdate(properties);
        res.succeed
          ? setSnackMessage(
              t("email_notification_success_saving"),
              "success",
              false,
              2000
            )
          : setSnackMessage(t("email_notification_error_on_saving"), "error");
      } catch (error) {
        setSnackMessage(t("email_notification_error_on_saving"), "error");
      }
    },
    [patchUpdate, setSnackMessage]
  );

  return useMemo(
    () => ({
      user,
      isUpdatingUser,
      togglingNotificationSwitch,
      setTogglingNotificationSwitch,
      updateUserEmailNotifications,
    }),
    [
      user,
      isUpdatingUser,
      togglingNotificationSwitch,
      updateUserEmailNotifications,
      setTogglingNotificationSwitch,
    ]
  );
}
