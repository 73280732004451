import React from 'react';
import { Form, FormSubmit } from '../common/form';
import { CloseModal } from '../common/modal';
import SelectCheckboxes from '../common/select_checkboxes';
import { Layouts } from './layouts';
import _ from 'underscore';
import Select from '../common/select';

export default class DashboardForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dashboard: props.dashboard || {}
        }
    }

    render() {
        const contextOptions = this.props.contexts.map(o => ({ value: o, label: I18n.t(`dashboard_context_${o}`) }));
        const visibilityOptions = [
            {label: I18n.t('user_groups'), options: this.props.userGroups.map(g => ({value: `user_group-${g.id}`, label: g.name}))},
            {label: I18n.t('roles'), options: this.props.roles.map(r => ({value: `role-${r.id}`, label: r.name}))}
        ];
        const visibilitySelected = _.flatten([
            (this.state.dashboard.user_group_ids || []).map(g => visibilityOptions[0].options.filter(o => o.value == `user_group-${g}`)),
            (this.state.dashboard.role_ids || []).map(r => visibilityOptions[1].options.filter(o => o.value == `role-${r}`))
        ]);
        return <Form action={this.props.action} onSuccess={this.onSuccess}>
            <div className="form-group">
                <label htmlFor="dashboard_context">{I18n.t('dashboard_context')} *</label>
                <Select
                    defaultValue={this.state.dashboard.context ? contextOptions.find(o => o.value == this.state.dashboard.context) : null}
                    name="dashboard[context]"
                    options={contextOptions}
                    isClearable={true}
                    placeholder={I18n.t('select_blank_text')}
                />
            </div>
            <div className="form-group">
                <label htmlFor="dashboard_visibility">{I18n.t('visibility')} *</label>
                <SelectCheckboxes
                    placeholder={I18n.t('select_blank_text')}
                    isMulti={true}
                    defaultValue={visibilitySelected}
                    options={visibilityOptions}
                    name="dashboard[visibility][]"
                    hideSelectAll={true}
                    closeMenuOnSelect={true}
                />
            </div>
            <div className="form-group">
                <label htmlFor="dashboard_title">{I18n.t('title')} *</label>
                <input id="dashboard_title" name="dashboard[title]" type="text" defaultValue={this.state.dashboard.title} className="form-control" />
            </div>
            <div className="form-group">
                <label>{I18n.t('layout')} *</label>
                <div className="dashboard-layout-selector">
                    <div className="row">
                        {this.props.layouts.map(o => (
                            <div key={o[0]} className="col-4 mb-3">
                                <Layouts.Icon layout={o[0]} className={this.state.dashboard.layout == o[0] ? 'selected' : null} onClick={e => this.selectLayout(o[0])} />
                            </div>
                        ))}
                    </div>
                    <input type="hidden" name="dashboard[layout]" defaultValue={this.state.dashboard.layout} />
                </div>
            </div>
            <div className="form-group">
                <input id="dashboard_default" name="dashboard[default]" type="checkbox" value="true" defaultChecked={this.state.dashboard.default} />
                <label htmlFor="dashboard_default" className="pl-2">{I18n.t('set_as_default')}</label>
                <div className="text-muted">
                    <small>{I18n.t('dashboard_default_explanation')}</small>
                </div>
            </div>
            <div className="form-group">
                <input id="dashboard_published" name="dashboard[published]" type="checkbox" value="true" defaultChecked={this.state.dashboard.published} />
                <label htmlFor="dashboard_published" className="pl-2">{I18n.t('publish')}</label>
                <div className="text-muted">
                    <small>{I18n.t('dashboard_published_explanation')}</small>
                </div>
            </div>
            <div className="text-right">
                <CloseModal className="btn btn-light mr-3 px-4">{I18n.t('cancel')}</CloseModal>
                <FormSubmit />
            </div>
        </Form>;
    }

    selectLayout = (layout) => {
        this.setState(prevState => {
            let dashboard = prevState.dashboard;
            dashboard.layout = layout;
            return { dashboard: dashboard };
        });
    }

    onSuccess = (result) => {
        Turbolinks.visit(result.url);
    }

}
