import React from "react";
import Grid from "@oriola-origo/core/lib/Grid";
import { OrigoApplication, OrigoApplicationRole } from "../../common/types";
import RoleBlock from "./role_block";
import useRoutes from "@hooks/useRoutes";
import { APPLICATION_QUERY_PARAM } from "@components/user_group/form_v2/constants";

export type RolesProps = {
  userGroupId: number;
  apps: OrigoApplication[];
  roles: OrigoApplicationRole[];
  hasUserGroupManagePermissions: boolean;
};

function Roles({ apps, roles, userGroupId, hasUserGroupManagePermissions }: Readonly<RolesProps>) {
  const routes = useRoutes();

  return (
    <Grid container spacing={2}>
      {apps.map((app) => (
        <RoleBlock
          editUrl={routes.userGroupEditPath(userGroupId.toString(), {
            [APPLICATION_QUERY_PARAM]: app.id.toString(),
          })}
          key={app.id}
          app={app}
          roles={roles.filter((role) => role.origo_application_id === app.id)}
          hasUserGroupManagePermissions={hasUserGroupManagePermissions}
        />
      ))}
    </Grid>
  );
}

export default Roles;
