export const staticIconMapping = {
  Sales: "bar_chart",
  Stock: "stacked_line_chart",
  StockPrincipalActions: "stacked_line_chart",
  KPI: "speed",
  OpenSales: "pending_actions",
  FMD: "playlist_add_check",
  Promotional: "chrome_reader_mode",
  Samples: "local_pharmacy",
};

export const staticImageMapping = {
  Sales: "sales.png",
  Stock: "stock.png",
  StockPrincipalActions: "stock.png",
  KPI: "kpi.png",
  OpenSales: "open_sales.png",
  FMD: "fmd.png",
  Promotional: "promo_material_services.png",
  Samples: "pharma_sample_services.png",
};

export const staticDataTrackingTextMapping = {
  Sales: "Sales/Show report",
  Stock: "Stock/Show report",
  StockPrincipalActions: "Stock Principal Actions/Show report",
  KPI: "KPI/Show report",
  OpenSales: "Open sales/Show report",
  FMD: "FMD/Show report",
  Promotional: "Promotional Material services/Show report",
  Samples: "Pharmaceutical sample services/Show report",
};

export const AUTH_PATH = "origoauth";
