import React from "react";
import Box from "@oriola-origo/core/lib/Box";
import Dialog from "@oriola-origo/core/lib/Dialog";
import { DialogTitle } from "@oriola-origo/core/lib/DialogTitle";
import { DialogContent } from "@oriola-origo/core/lib/DialogContent";
import { FontIcon } from "@oriola-origo/core/lib/Icons";
import { DialogActions } from "@oriola-origo/core/lib/DialogActions";
import { Button } from "@oriola-origo/core/lib/Button";
import Typography from "@oriola-origo/core/lib/Typography";
import useTranslations from "@hooks/useTranslations";

export interface ResetConfirmationProps {
  open: boolean;
  userName: string;
  isSending: boolean;
  onSend: () => void;
  onCancel: () => void;
  shouldTriggerResetPassword: boolean;
}

function ResetPasswordOrResendInvitationConfirmation({
  open,
  userName,
  isSending,
  onSend,
  onCancel,
  shouldTriggerResetPassword,
}: Readonly<ResetConfirmationProps>) {
  const { t } = useTranslations();

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle onCloseButtonClick={onCancel}>
        {shouldTriggerResetPassword ? t("send_password_reset_link") : t("send_new_invitation")}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" mb={4}>
          <FontIcon
            sx={{
              fontSize: 150,
            }}
            baseClassName="material-icons-outlined"
            fontSize="medium"
            color="secondary"
          >
            {shouldTriggerResetPassword ? "password" : "email"}
          </FontIcon>
        </Box>
        <Typography color="text.secondary">
          {shouldTriggerResetPassword ? t("send_password_reset_link_message") : t("send_new_invitation_message")}
        </Typography>
        <br/>
        <Typography color="text.secondary">
          {t(shouldTriggerResetPassword ? "confirm_send_password_reset_link" : "confirm_new_invitation", { name: userName })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSending}
          variant="outlined"
          color="secondary"
          onClick={onCancel}
        >
          {t("cancel")}
        </Button>
        <Button
          disabled={isSending}
          isLoading={isSending}
          variant="contained"
          color="info"
          onClick={onSend}
        >
          {t("send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResetPasswordOrResendInvitationConfirmation;
