import React from 'react';
import { useEffect, useRef } from 'react';

export default function RoleIcon(props) {
    const iconRef = useRef();

    useEffect(() => {
        jQuery(iconRef.current).tooltip();
    });

    return <img
        ref={iconRef}
        src={require(`./icons/${props.icon}`)}
        alt={props.title ? props.title[I18n.locale] : props.icon}
        title={props.title ? props.title[I18n.locale] : null}
        className={props.className}
        style={{ height: props.height || "24px" }}
    />;
}
