import React from 'react';
import { Table, TableRowActions } from '../common/table';
import UsageContactForm from './form';

export default class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableKey: StringHelper.random(8),
      mode: 'list' // new | edit
    };
  }

  render() {
    let columns = [
      {
        dataField: 'content',
        text: I18n.t('title'),
        sort: true,
        formatter: (cell, row) => {
          return !this.props.permissions.may_edit ? row.content[I18n.locale] : <a href="#" onClick={e => { this.handleEditUsageContactClick(row); e.preventDefault(); }}>
            {row.content[I18n.locale].title}
          </a>;
        }
      },
      {
        dataField: 'id',
        text: '',
        classes: 'row-actions',
        headerClasses: 'row-actions',
        formatter: (cell, row) => {
          let actions = [];

          if (this.props.permissions.may_edit) {
            actions.push(<a key="edit" href="#" onClick={e => { this.handleEditUsageContactClick(row); e.preventDefault(); }}>
              <i className="icon icon-edit"></i> {I18n.t('edit')}
            </a>);
          }
          if (this.props.permissions.may_delete) {
            if (actions.length > 0) {
              actions.push(<TableRowActions.Divider key="div" />)
            }
            actions.push(<a key="del" href="#" onClick={e => { this.deleteUsageContact(row); e.preventDefault(); }}><i className="icon icon-trash text-danger"></i> {I18n.t('delete')}</a>);
          }
          return actions.length > 0 ? <TableRowActions.Menu>{actions}</TableRowActions.Menu> : null
        }
      }
    ];

    let actions = [];

    if (this.props.permissions.may_add) {
      actions.push(<a key="add" href="#" onClick={e => { this.handleAddUsageContactClick(); e.preventDefault(); }} className="btn btn-light mr-3">
        <i className="fa fa-plus text-highlight"></i> {I18n.t('add')}
      </a>);
    }

    return (
      <div ref="root">
        <Table
          key={this.state.tableKey}
          ref="table"
          columns={columns}
          actions={actions}
          pagination={{ sizePerPage: 20 }}
          defaultSorted={[{
            dataField: 'content',
            order: 'asc'
          }]}
          remote={true}
          remoteUrl={Routes.usage_contact_list_json_path()}
          preserveState={true} />

        {this.state.mode == 'new' && <UsageContactForm
          title={I18n.t('add_usage_contact')}
          onClose={this.handleDialogClose}
          action={Routes.usage_contact_create_path()}
          onSuccess={this.handleFormSuccess}
          languages={this.props.form_data.languages}
        />}
        {this.state.mode == 'edit' && <UsageContactForm
          title={I18n.t('edit_usage_contact')}
          onClose={this.handleDialogClose}
          action={Routes.usage_contact_update_path({ usage_contact_id: this.state.selectedUsageContact.id })}
          onSuccess={this.handleFormSuccess}
          languages={this.props.form_data.languages}
          usageContact={this.state.selectedUsageContact}
        />}
      </div>
    );
  }

  handleAddUsageContactClick = () => {
    this.setState({ mode: 'new' });
  }

  handleEditUsageContactClick = (UsageContact) => {
    this.setState({
      mode: 'edit',
      selectedUsageContact: UsageContact
    });
  }

  handleDialogClose = () => {
    this.setState({
      mode: 'list',
      selectedUsageContact: null
    });
  }

  handleFormSuccess = () => {
    this.handleDialogClose();
    this.reload();
  }

  reload = () => {
    this.setState({ tableKey: StringHelper.random(8) });
  }

  deleteUsageContact = (UsageContact) => {
    if (confirm(I18n.t('confirm_delete_usage_contact', { content: UsageContact.content }))) {
      jQuery.ajax({
        url: Routes.usage_contact_delete_path({ usage_contact_id: UsageContact.id }),
        type: 'DELETE',
        success: this.reload
      });
    }
  }
}