import { useCallback, useMemo, useState } from "react";
import useFetch from "@hooks/useFetch";
import useRoutes from "@hooks/useRoutes";
import useCSRFToken from "@hooks/useCSRFToken";

export interface ICompanySelection {
  id: number;
  role_ids: number[];
  user_group_ids: number[];
  professional_title_id: number;
  profit_center_ids: number[];
  scc_profit_center_ids?: number[];
  default: boolean;
}
export interface IInviteUserOpts {
  user: {
    first_name: string;
    last_name: string;
    email_address: string;
    mobile_phone?: string;
    work_title?: string;
    language?: string;
    company_selection: ICompanySelection[];
    subscribe_weekly_newsletter: boolean;
    subscribe_important_announcement_notification: boolean;
    subscribe_emergency_announcement_notification: boolean;
    active: boolean;
  };
}

export default function useInviteUser() {
  const routes = useRoutes();
  const { doFetch } = useFetch();
  const csrfToken = useCSRFToken();
  const [isLoading, setIsLoading] = useState(false);

  const triggerInviteUser = useCallback(
    async (params: IInviteUserOpts) => {
      setIsLoading(true);
      try {
        const response = await doFetch(routes.userInvitePath(), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          body: JSON.stringify(params),
        });

        const json = await response.json();
        return { data: json };
      } finally {
        setIsLoading(false);
      }
    },
    [routes, useFetch, csrfToken]
  );

  const sendInvitation = useCallback(
    async (params: IInviteUserOpts) => {
      setIsLoading(true);
      try {
        const result = await triggerInviteUser(params);
        return result.data;
      } finally {
        setIsLoading(false);
      }
    },
    [triggerInviteUser]
  );

  return useMemo(
    () => ({ triggerInviteUser, sendInvitation, isLoading }),
    [triggerInviteUser, sendInvitation, isLoading]
  );
}
