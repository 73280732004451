import React, { useEffect, useState } from "react";
import Autocomplete from "@oriola-origo/core/lib/Autocomplete/Autocomplete";
import { AutocompleteProps } from "@oriola-origo/core/lib/Autocomplete/Autocomplete.props";
import MenuItem from "@oriola-origo/core/lib/MenuItem/MenuItem";
import Box from "@oriola-origo/core/lib/Box/Box";
import useFetchCompanyOptions, {
  CompanyOption,
} from "./useFetchCompanyOptions";
import { staticReportsTranslationFactory } from "../utils";
import useDebounce from "../../../../../hooks/useDebounce";

export interface CustomerSelectionProps {
  language: string;
  selectedCompanies: CompanyOption[];
  onChange: (companies: CompanyOption[]) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyAutocompleteProps = AutocompleteProps<any, any, any, any, any>;

const renderOption: AnyAutocompleteProps["renderOption"] = (
  props,
  option: CompanyOption,
  state
) => {
  // remove className from props to ensure our own styling
  const newProps = { ...props };
  delete newProps.className;

  return (
    <MenuItem
      sx={{ pl: ((option.hierarchyLevel ?? 0) + 1) * 2 }}
      {...newProps}
      selected={state.selected}
    >
      {typeof option === "string" ? option : option.label}
    </MenuItem>
  );
};

const INPUT_VALUE_DEBOUNCE_MS = 500;

function CustomerSelection({
  language,
  selectedCompanies,
  onChange,
}: Readonly<CustomerSelectionProps>) {
  const { fetchCompanyOptions, isFetching } = useFetchCompanyOptions();
  const [inputValue, setInputValue] = useState<string>("");
  const debouncedInputValue = useDebounce(inputValue, INPUT_VALUE_DEBOUNCE_MS);
  const [options, setOptions] = useState<CompanyOption[]>([]);
  const getText = staticReportsTranslationFactory(language);

  useEffect(() => {
    if (debouncedInputValue === "") {
      setOptions([]);
      return;
    }

    let active = true;
    const fetchOptions = async () => {
      const newOptions = await fetchCompanyOptions(debouncedInputValue);
      if (active) {
        setOptions(newOptions);
      }
    };

    fetchOptions();

    return () => {
      active = false;
    };
  }, [debouncedInputValue]);

  return (
    <Box mt={2}>
      <Autocomplete
        label={getText("customers")}
        sx={{
          // Override globally defined legend margin
          "& fieldset legend": {
            margin: 0,
          },
        }}
        multiple={true}
        loading={isFetching}
        loadingText={I18n.t("loading")}
        renderOption={renderOption}
        options={options}
        value={selectedCompanies}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.label
        }
        inputValue={inputValue}
        onInputChange={(_, value) => {
          setInputValue(value);
        }}
        onChange={(_, newValue) => {
          onChange(newValue as CompanyOption[]);
        }}
      />
    </Box>
  );
}

export default CustomerSelection;
