import React, { useEffect, useState } from "react";
import Dialog from "@oriola-origo/core/lib/Dialog";
import DialogActions from "@oriola-origo/core/lib/DialogActions";
import DialogContent from "@oriola-origo/core/lib/DialogContent";
import DialogTitle from "@oriola-origo/core/lib/DialogTitle";
import Button from "@oriola-origo/core/lib/Button";
import Typography from "@oriola-origo/core/lib/Typography";
import TextField from "@oriola-origo/core/lib/TextField";
import Box from "@oriola-origo/core/lib/Box";
import useTranslations from "../../../../hooks/useTranslations";

export interface AddNewCategoryDialogProps {
  open: boolean;
  isSaving: boolean;
  onClose: (reason: string) => void;
  onSaveRequest: (categoryName: string) => void;
}

const isCategoryNameValid = (categoryName: string) =>
  categoryName.trim().length > 0 && categoryName.length < 255;

function AddNewCategoryDialog({
  open,
  isSaving,
  onClose,
  onSaveRequest,
}: Readonly<AddNewCategoryDialogProps>) {
  const { t } = useTranslations();
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    if (!open) {
      setCategoryName("");
    }
  }, [open]);

  const handleSaveButtonClick = () => {
    if (isSaving) {
      return;
    }

    onSaveRequest(categoryName);
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      PaperProps={{
        sx: (theme) => ({ backgroundColor: theme.palette.background.default }),
      }}
      onClose={(_, reason) => onClose(reason)}
    >
      <DialogTitle onCloseButtonClick={() => onClose("title-close")}>
        {t("add_new_user_group_category")}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" mb={3}>
          <img src="/user_groups/category_creation_icon.svg" alt="" />
        </Box>
        <Typography variant="body1" color="textSecondary" mb={3}>
          {t("add_new_user_group_category_description")}
        </Typography>
        <TextField
          label={t("user_group_category_name")}
          value={categoryName}
          onChange={(event) => setCategoryName(event.target.value)}
          fullWidth={true}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => onClose("cancel")}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSaveButtonClick}
          isLoading={isSaving}
          disabled={!isCategoryNameValid(categoryName)}
        >
          {t("add_new_user_group_category_create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddNewCategoryDialog;
