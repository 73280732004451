import React from 'react';
import Dropzone from 'react-dropzone';
import request from 'superagent';
import IconLink from './icon_link';

export default class FileUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            files: props.files || [],
            deletedFiles: []
        };
    }

    render() {
        return <div>
            {this.state.files.length > 0 && <ul className="list-group mb-2">
                {this.state.files.map(f => <li key={f.name} className="form-control h-auto" style={f.uploading ? {opacity: 0.5} : null}>
                    <div className="row">
                        <div className="col-6">
                            <div style={{ width: '256px', maxWidth: '100%' }}>
                                {f.previewUrl ? <img title={f.name}
                                    src={`${f.previewUrl}${f.previewUrl.match(/^blob:/) ? '' : (f.previewUrl.match(/\?/) ? '&' : '?') + Date.now()}`}
                                    className="w-100"
                                /> : f.name}
                            </div>
                        </div>
                        <div className="col-6 text-right">
                            {f.uploading ? <i className="fa fa-cog fa-spin text-primary"></i> : <IconLink href="#" iconClassName="oricon-bin text-primary" onClick={e => { this.deleteFile(f); e.preventDefault(); }}>
                                {I18n.t('delete')}
                            </IconLink>}
                        </div>
                    </div>
                    {f.newUpload && <input type="hidden" name={`${this.props.name}[new]${this.props.multiple ? '[]' : ''}`} value={f.name} />}
                </li>)}
            </ul>}
            {this.state.deletedFiles.map(f => (
                <input key={f.name} type="hidden" name={`${this.props.name}[deleted]${this.props.multiple ? '[]' : ''}`} value={f.name} />
            ))}
            <Dropzone accept={this.props.accept} multiple={this.props.multiple ? true : false} onDrop={acceptedFiles => this.uploadFiles(acceptedFiles)}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <div className={`alert ${isDragActive ? 'alert-success' : 'alert-light border'} text-center p-4 m-0`} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div>{I18n.t('file_upload_instruction')}</div>
                    </div>
                )}
            </Dropzone>
        </div>;
    }

    uploadFiles = (files) => {
        const uploadingFiles = files.map(f => ({
            name: f.name,
            uploading: true,
            newUpload: true,
            previewUrl: f.type.match(/(jpe?g|gif|png)/) ? URL.createObjectURL(f) : null
        }));

        this.setState(prevState => {
            return {
                files: this.props.multiple ? prevState.files.concat(uploadingFiles) : uploadingFiles
            };
        });
        files.forEach(file => {
            const req = request.post(this.props.url);
            req.set('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content'));
            req.attach('file', file);
            req.end((error, result) => {
                if (result.ok) {
                    this.setState(prevState => {
                        let files = prevState.files;
                        files.find(f => f.name == file.name && f.uploading).uploading = false;
                        return {
                            files: files
                        };
                    });
                } else {
                    this.setState(prevState => {
                        let files = prevState.files;
                        let file = files.find(f => f.name == file.name && f.uploading);
                        file.error = error;
                        file.uploadig = false;
                        return {
                            files: files
                        };
                    });
                }
            });
        });
    }

    deleteFile = (file) => {
        this.setState(prevState => {
            let files = [];
            let deletedFiles = prevState.deletedFiles;
            prevState.files.forEach(f => {
                if (f.name != file.name) {
                    files.push(f);
                } else if (!f.newUpload) {
                    deletedFiles.push(f);
                }
            });
            return {
                files: files,
                deletedFiles: deletedFiles
            };
        });
    }
}
