import { Organization } from "./organizations";
import { OrigoApplicationRole } from "./roles";

export interface UserCompany extends Organization {
  default: boolean;
  professional_title_id: number;
  professional_title_str_id: string;
  profit_center_ids: number[];
  scc_profit_center_ids?: number[];
}

export enum UserStatus {
  Active = "active",
  Inactive = "inactive",
  Invited = "invited",
}

export enum ResetAction {
  Invitation = "invitation",
  Password = "password",
}

export interface UserBasicInformation {
  id: number;
  active: boolean;
  status: UserStatus;
  first_name: string;
  last_name: string;
  language: string;
  email_address: string;
  mobile_phone: string;
  work_title: string;
  is_invitee: boolean;
  professional_title: string;
  user_groups: any[];
}

export interface UserNotificationSubscriptionInformation {
  subscribe_weekly_newsletter: boolean;
  subscribe_important_announcement_notification: boolean;
  subscribe_emergency_announcement_notification: boolean;
}

export interface UserSignupInformation {
  signup_company_claim: string;
  signup_professional_title_claim: string;
}

export interface UserAssociationInformation<TCompany> {
  companies: TCompany[];
  all_roles: OrigoApplicationRole[];
  user_group_ids: number[];
  company_ids: string[];
}

export interface User<TCompany = UserCompany>
  extends UserBasicInformation,
    UserAssociationInformation<TCompany>,
    UserNotificationSubscriptionInformation,
    UserSignupInformation {}

export interface UserWithCompactCompanies
  extends User<{ id: number; name: string }> {}
