import { useCallback, useMemo, useState } from "react";
import { Filters, formatFiltersForFetch } from "./utils";

export interface Report {
  fileId: string;
  fileName: string;
  mediaType: string;
  tags: {
    ReportName: string;
    ReportCreated: number;
    CustomerId: string;
    ProfitCenterID: string;
    PeriodType: string;
    DatePeriod: number;
    DatePeriodTimestamp: number;
  };
}

export type FetchLatestReportsInput = {
  kind: "latest";
};

export type FetchListReportsInput = {
  kind: "list";
  maySelectCustomers?: boolean;
  filters: Filters;
  page: number;
  pageSize: number;
};

export type FetchReportsInput = FetchLatestReportsInput | FetchListReportsInput;

export interface FetchReportsOutput {
  reports: Report[];
  pageCount: number;
  totalCount: number;
  filteredCount: number;
}

export interface UseFetchReportsOutput {
  isFetching: boolean;
  fetchReports: (opts: FetchReportsInput) => Promise<FetchReportsOutput>;
}

const getCommonWidgetDataOptions = (
  method: string,
  organizationId?: string
) => ({
  widget: "StaticReports",
  method,
  organization_id: organizationId,
});

const fetchLatest = async (opts: {
  reportNames: string[];
  organizationId?: string;
  abortController: AbortController;
}) => {
  const res = await fetch(
    Routes.dashboard_widget_data_path({
      ...getCommonWidgetDataOptions("latest", opts.organizationId),
      report_names: opts.reportNames,
    }),
    { signal: opts.abortController.signal }
  );
  return res.json();
};

const useFetchReports = (
  reportNames: string[],
  organizationId?: string
): UseFetchReportsOutput => {
  const [isFetching, setIsFetching] = useState(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const fetchReports = useCallback(
    async (opts: FetchReportsInput): Promise<FetchReportsOutput> => {
      setIsFetching(true);
      try {
        if (abortController) {
          abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        if (opts.kind === "latest") {
          const items = await fetchLatest({
            reportNames,
            organizationId,
            abortController: newAbortController,
          });
          return {
            reports: items,
            pageCount: 1,
            totalCount: items.length,
            filteredCount: items.length,
          };
        }

        const data = await fetch(
          Routes.dashboard_widget_data_path({
            ...getCommonWidgetDataOptions("list", organizationId),
            filters: formatFiltersForFetch(
              opts.filters,
              reportNames,
              opts.maySelectCustomers
            ),
            page: opts.page,
            page_size: opts.pageSize,
          }),
          { signal: newAbortController.signal }
        );

        const json = await data.json();
        return {
          reports: json.items,
          pageCount: Math.ceil(json.overallCount / opts.pageSize),
          totalCount: json.unfilteredCount,
          filteredCount: json.overallCount,
        };
      } finally {
        setAbortController(null);
        setIsFetching(false);
      }
    },
    [reportNames, organizationId, abortController]
  );

  return useMemo(
    () => ({
      fetchReports,
      isFetching,
    }),
    [fetchReports, isFetching]
  );
};

export default useFetchReports;
