import React from 'react';

export class Modal extends React.Component {

    static close(modalId) {
        jQuery(`#${modalId}`).modal('hide');
    }

    constructor(props) {
        super(props);

        this.root = React.createRef();
    }

    render() {
        return <div ref={this.root} id={this.props.id} className="modal fade" data-backdrop="static" tabIndex="-1" role="dialog">
            <div className={'modal-dialog' + (this.props.size ? ' modal-' + this.props.size : '')} role="document">
                <div className="modal-content">
                    <div className="modal-header d-block">
                        <div className="row">
                            <div className="col">
                                <h5 className="modal-title">{this.props.title}</h5>
                            </div>
                            <div className="col-auto modal-header-actions text-nowrap">
                                <button type="button" className="close d-inline-block float-none" data-dismiss="modal" aria-label={I18n.t('close')}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        {this.props.children}
                    </div>
                </div>
            </div>
        </div>;
    }

    componentDidMount() {
        jQuery(this.root.current).modal().on('hidden.bs.modal', (event) => {
            if (typeof this.props.onClose == 'function') {
                this.props.onClose();
            }
        });
    }

    componentWillUnmount() {
        jQuery('body > .modal-backdrop').last().remove();
    }
}

export class CloseModal extends React.Component {
    render() {
        return <a href="#" data-dismiss="modal" data-target={`#${this.props.id}`} className={this.props.className || 'btn'}>
            {this.props.label || I18n.t('close')}
        </a>;
    }
}