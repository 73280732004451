import React, { useState } from "react";
import Header from "@oriola-origo/core/lib/Header";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Page from "@oriola-origo/core/lib/Page";
import useTranslations from "@hooks/useTranslations";
import Organization from "./organization";
import useUser from "@services/user_management/hooks/useUser";
import SnackbarProvider from "@oriola-origo/core/lib/Snackbar/SnackbarProvider";
import useSnackbar from "@hooks/useSnackbar";
import EmailNotificationSubscriptions from "../components/email_notification_subscriptions/email_notification_subscriptions";
import useEditableUser, {
  EditableUserProvider,
} from "@components/user/contexts/editable_user";
import { User } from "@services/user_management/types/users";
import UserDetailFields from "../components/user_detail_fields";
import Box from "@oriola-origo/core/lib/Box";

export interface UserProfileV2Props {
  is_self: boolean;
  user: User;
  languages: Array<[string, string]>;
}

type PatchUpdateUserParameterUser = Parameters<
  ReturnType<typeof useUser>["patchUpdateUser"]
>[0]["user"];

function UserProfileInternal({
  user: initialUserFromProps,
  is_self: isUsersOwnProfile,
  languages,
}: Readonly<UserProfileV2Props>) {
  const { t } = useTranslations();
  const [user, setUser] = useState(initialUserFromProps);
  const { updateLanguage } = useUser();
  const { patchUpdate, isUpdating } = useEditableUser();
  const { setSnackMessage } = useSnackbar();

  const saveUserData = async (property: string, value: string) => {
    const properties: PatchUpdateUserParameterUser = {
      [property]: value,
    };
    const res = await patchUpdate(properties);
    if (res.succeed) {
      setUser((prev) => ({
        ...prev,
        ...properties,
      }));
      if (property === "language" && isUsersOwnProfile) {
        await updateLanguage(value);
        window.location.reload();
      }
    } else {
      setSnackMessage(t("profile_error_on_saving"), "error");
    }
  };

  return (
    <Box sx={{ minWidth: "320px" }}>
      <Page
        header={<Header title={`${user.first_name} ${user.last_name}`} />}
        mb={2}
      >
        <UserDetailFields
          user={user}
          isUpdating={isUpdating}
          languages={languages}
          onSave={saveUserData}
        />
      </Page>
      <EmailNotificationSubscriptions />
      <Page
        header={<Header title={t("organizations")} />}
        pt={1}
        pb={1}
        pr={0}
        pl={0}
      >
        {(user.companies ?? []).map((company, index) => (
          <Organization
            key={company.id}
            organization={company}
            isFirst={index === 0}
          />
        ))}
      </Page>
    </Box>
  );
}

function UserProfileV2(props: Readonly<UserProfileV2Props>) {
  return (
    <OriolaThemeProvider>
      <SnackbarProvider>
        <EditableUserProvider user={props.user}>
          <UserProfileInternal {...props} />
        </EditableUserProvider>
      </SnackbarProvider>
    </OriolaThemeProvider>
  );
}

export default UserProfileV2;
