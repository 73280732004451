import React from "react";
import { styled } from "@oriola-origo/core/lib/styles";
import Box from "@oriola-origo/core/lib/Box";
import {
  BaseCell,
  BaseCellProps,
} from "@components/common/user_management_base_table/common_cells";

const NameCellWrapper = styled(Box)<{ hierarchyLevel?: number }>(
  ({ hierarchyLevel, theme }) => ({
    paddingLeft: hierarchyLevel
      ? theme.spacing((hierarchyLevel ?? 0) * 2 + 1)
      : undefined,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  })
);

const StyledBaseCell = styled(BaseCell)<{ leadingSpacingLevel?: number }>(
  ({ theme, leadingSpacingLevel }) => ({
    position: "relative",
    ["&::after"]: leadingSpacingLevel
      ? {
          content: '""',
          position: "absolute",
          left: 0,
          bottom: -2,
          height: 5,
          width: theme.spacing((leadingSpacingLevel ?? 0) * 2 + 1),
          backgroundColor: theme.palette.background.default,
        }
      : undefined,
  })
);

export interface NameCellProps extends BaseCellProps {
  hierarchyLevel?: number;
  leadingSpacingLevel?: number;
}

export default function NameCell({
  children,
  hierarchyLevel,
  leadingSpacingLevel,
  ...rest
}: Readonly<NameCellProps>) {
  return (
    <StyledBaseCell leadingSpacingLevel={leadingSpacingLevel} {...rest}>
      <NameCellWrapper hierarchyLevel={hierarchyLevel}>
        {children}
      </NameCellWrapper>
    </StyledBaseCell>
  );
}
