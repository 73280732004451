import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Table } from '../common/table';
import moment from 'moment-timezone';

export default class List extends React.Component {
    constructor(props) {
        super(props);

        moment.tz.setDefault(Application.config.timeZone);
    }

    render() {
        const { target_type, target_id } = this.props;

        let columns = [
            {
                dataField: 'created_by',
                text: I18n.t('user'),
                sort: true,
                headerClasses: 'd-none d-sm-table-cell',
                classes: 'd-none d-sm-table-cell',
                formatter: (cell, row) => {
                    return [row.creator_last_name, row.creator_first_name].join(' ')
                }
            },
            {
                dataField: 'created_at',
                text: I18n.t('time'),
                sort: true,
                formatter: (val) => {
                    return moment(val).format(Formats.DATETIME);
                },
            },
            {
                dataField: 'action',
                text: I18n.t('activity'),
                sort: false,
                formatter: (cell, row) => {
                    let summary = [
                        I18n.t('activity_target_' + row.target_type.replace(/([A-Z])/g, '_$1').replace(/^_/, '').toLowerCase()),
                        I18n.t('activity_action_' + row.action)
                    ].join(' ') + (row.visible_identifier ? ` (${row.visible_identifier})` : '')

                    return (
                        <a href="#" className="view-content" data-title={summary} data-entry={JSON.stringify(row)}>
                            {summary}
                        </a>
                    );
                }
            }
        ];

        return (
            <div ref="root">
                <Table
                    ref="table"
                    columns={columns}
                    pagination={{ sizePerPage: 20 }}
                    defaultSorted= {[{
                        dataField: 'created_at',
                        order: 'desc'
                    }]}
                    searchFilter={target_type && target_id ? `${target_type}:${decodeURIComponent(target_id)}` : undefined}
                    remote={true}
                    remoteUrl={Routes.activity_log_list_json_path()} />
            </div>
        );
    }

    componentDidMount() {
        // Open activity details in a dialog
        jQuery(this.refs.root).on('click', 'a.view-content', (event) => {
            let link = jQuery(event.currentTarget);

            Dialog.load({ size: 'lg', title: link.data('title') }, (callback) => {
                callback(this.formatActivityContent(link.data('entry')));
            });

            return false;
        });
    }

    formatActivityContent(entry) {
        const { header, rows } = this.resolveHeaderAndRows(entry);

        return ReactDOMServer.renderToStaticMarkup(
            <div>
                {this.formatUserInfo(entry)}
                <table className="table table-bordered mb-0">
                    <thead>
                        {header}
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    }

    resolveHeaderAndRows(entry) {
        if (entry.version === 'v2') {
            return this.resolveHeaderAndRowsForV2Entry(entry);
        } else if (entry.action === 'update') {
            return this.resolveHeaderAndRowsForUpdateEntry(entry);
        }

        return {
            rows: Object.keys(entry.content).map((attr) => (
                <tr key={attr}>
                    <th>{I18n.t(attr)}</th>
                    <td className="dont-break-out">{entry.content[attr]}</td>
                </tr>
            )),
            header: null
        }
    }

    resolveHeaderAndRowsForUpdateEntry(entry) {
        const header = (
            <tr>
                <th></th>
                <th>{I18n.t('old_value')}</th>
                <th>{I18n.t('new_value')}</th>
            </tr>
        );
        
        const rows = Object.keys(entry.content).map((attr) => {
            const { before, after } = entry.content[attr] ?? {};
            return (
                <tr key={attr}>
                    <th>{I18n.t(attr)}</th>
                    <td className="dont-break-out">
                        {this.formatUpdateValue(before)}
                    </td>
                    <td className="dont-break-out">
                        {this.formatUpdateValue(after)}
                    </td>
                </tr>
            )
        });

        return { header, rows };
    }

    resolveHeaderAndRowsForV2Entry(entry) {
        const header = (
            <tr>
                <th>{I18n.t('content')}</th>
            </tr>
        )
        const { content } = entry;
        const contentAsArray = Array.isArray(content) ? content : [content];
        const rows = contentAsArray.map((content) => (
            <tr key={content}>
                <td className="dont-break-out">{content}</td>
            </tr>
        ));

        return { header, rows }
    }

    formatUserInfo(entry) {
        return (
            <p>
                {I18n.t('activity_user_info', {
                    name: [entry.creator_last_name, entry.creator_first_name].join(' '),
                    time: moment(entry.created_at).format(Formats.DATETIME)
                })}
            </p>
        );
    }

    formatUpdateValue(value) {
        if (value && typeof value === 'object') {
            return JSON.stringify(value);
        }
        if (typeof value === 'boolean') {
            return I18n.t(value ? 'yes' : 'no');
        }
        return value;
    }
}
