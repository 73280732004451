import React, { useMemo } from "react";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Typography from "@oriola-origo/core/lib/Typography";
import useTurbolinks from "../../hooks/useTurbolinks";
import { useTheme, styled } from "@oriola-origo/core/lib/styles";

export interface BackButtonProps {
  navigateTo: string;
  text: string;
  mb?: number;
  mt?: number;
}

const NoStyleButton = styled("button")({
  border: "none",
  background: "none",
  display: "flex",
  alignItems: "center",
});

const Circle = styled("div")(({ theme }) => ({
  borderRadius: "50%",
  backgroundColor: theme.palette.secondary.main,
  width: theme.spacing(3),
  height: theme.spacing(3),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

function BackButton({ navigateTo, text, mt, mb }: Readonly<BackButtonProps>) {
  const { navigate } = useTurbolinks();
  const theme = useTheme();

  const handleClick = () => {
    navigate(navigateTo);
  };

  const styleObject = useMemo(
    () => ({
      marginTop: mt ? theme.spacing(mt) : undefined,
      marginBottom: mb ? theme.spacing(mb) : undefined,
    }),
    [mt, mb]
  );

  return (
    <NoStyleButton style={styleObject} onClick={handleClick}>
      <Circle>
        <FontIcon
          sx={{ fontSize: 24, color: (theme) => theme.palette.common.white }}
        >
          keyboard_arrow_left
        </FontIcon>
      </Circle>
      <Typography variant="body1" color="textPrimary" ml={1} lineHeight="100%">
        {text}
      </Typography>
    </NoStyleButton>
  );
}

export default BackButton;
