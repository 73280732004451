import React, { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import Savebar from "@oriola-origo/core/lib/Savebar";
import { styled } from "@oriola-origo/core/lib/styles";
import Box from "@oriola-origo/core/lib/Box";
import useFooter from "../../hooks/useFooter";

const FullHeightContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
}));

function Footer({ children }: Readonly<PropsWithChildren<{}>>) {
  const { container } = useFooter();

  return container ? createPortal((
    <Savebar>
    <FullHeightContainer>{children}</FullHeightContainer>
  </Savebar>
  ), container) : null;
}

export default Footer;
