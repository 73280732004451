import React, { useEffect, useMemo, useRef, useState } from "react";
import Box from "@oriola-origo/core/lib/Box";
import ScopedSearch from "@oriola-origo/core/lib/ScopedSearch";
import ToggleButtonGroup from "@oriola-origo/core/lib/ToggleButtonGroup/ToggleButtonGroup";
import Button from "@oriola-origo/core/lib/Button";
import { styled } from "@oriola-origo/core/lib/styles";
import useTranslations from "@hooks/useTranslations";
import useDebounce from "@hooks/useDebounce";
import { OrganizationTableProps } from "../organization_table";
import { CompanyType } from "@services/user_management/types";
import OrganizationTypeAutocomplete, {
  OrganizationTypeAutocompleteProps,
} from "./organization_type_autocomplete";

export interface FiltersType {
  status?: string;
  typeStrIds?: string[];
  searchScope?: string;
  search?: string;
}

export interface FiltersProps {
  companyTypes: CompanyType[];
  filters: FiltersType;
  counts?: OrganizationTableProps["counts"];
  onFiltersChange: (filters: FiltersType) => void;
}

const ALL_VALUE = "_all";

const StyledButton = styled(Button)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  whiteSpace: "nowrap",
}));

const SCOPES: Array<{ id: string; labelKey: string }> = [
  {
    id: "all",
    labelKey: "all",
  },
  {
    id: "sap",
    labelKey: "origin_scope_sap",
  },
  {
    id: "origo",
    labelKey: "origin_scope_origo",
  },
];

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1),
  gap: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  maxWidth: 450,
  width: "100%",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));

function Filters({
  filters,
  counts,
  companyTypes,
  onFiltersChange,
}: Readonly<FiltersProps>) {
  const { t, language } = useTranslations();
  const [searchValue, setSearchValue] = useState(filters.search);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const isInitialMount = useRef(true);

  useEffect(() => {
    setSearchValue(filters.search);
  }, [filters.search]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (filters.search !== debouncedSearchValue) {
      onFiltersChange({ ...filters, search: debouncedSearchValue });
    }
  }, [debouncedSearchValue]);

  const companyTypesValue = useMemo(
    () =>
      companyTypes.filter((companyType) =>
        filters.typeStrIds?.includes(companyType.str_id)
      ),
    [companyTypes, filters.typeStrIds]
  );

  const scopes = SCOPES.map((scope) => ({
    id: scope.id,
    text: t(scope.labelKey),
  }));

  const handleStatusChange = (status: string) => {
    onFiltersChange({
      ...filters,
      status: status === ALL_VALUE ? undefined : status,
    });
  };

  const handleSearchScopeChange = (scope: string) => {
    onFiltersChange({
      ...filters,
      searchScope: scope,
    });
  };

  const handleTypeStrIdsChange: OrganizationTypeAutocompleteProps["onChange"] =
    (_, selectedOptions) => {
      onFiltersChange({
        ...filters,
        typeStrIds: selectedOptions.map((option) => option.str_id),
      });
    };

  const renderLabel = (label: string, count: number = 0) =>
    `${label} (${count})`;

  return (
    <Container>
      <Box>
        <ToggleButtonGroup
          onChange={handleStatusChange}
          value={filters.status ?? ALL_VALUE}
        >
          <StyledButton value={ALL_VALUE}>
            {renderLabel(t("all"), counts?.total)}
          </StyledButton>
          <StyledButton value="active">
            {renderLabel(t("company_status_active"), counts?.active)}
          </StyledButton>
          <StyledButton value="passive">
            {renderLabel(t("company_status_passive"), counts?.passive)}
          </StyledButton>
        </ToggleButtonGroup>
      </Box>
      <Box width="100%">
        <OrganizationTypeAutocomplete
          label={t("organization_type")}
          language={language}
          onChange={handleTypeStrIdsChange}
          options={companyTypes}
          value={companyTypesValue}
        />
      </Box>
      <SearchContainer>
        <ScopedSearch
          label={t("search")}
          textValue={searchValue}
          hasClearButton={true}
          onTextChange={setSearchValue}
          scopes={scopes}
          selectedScope={filters.searchScope ?? SCOPES[0].id}
          onScopeChange={handleSearchScopeChange}
        />
      </SearchContainer>
    </Container>
  );
}

export default Filters;
