import { Report } from "./useFetchReports";
import { CompanyOption } from "./customer_selection/useFetchCompanyOptions";
import { Dayjs } from "dayjs";

export type FetchFormattedFilters = {
  report_names: string[];
  start_date: string;
  end_date: string;
  customer_numbers?: string[];
};

export interface Filters {
  reportNames?: Array<{ value: string; label: string }>;
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  customers?: Array<CompanyOption>;
}

export const staticReportsTranslationFactory = (language: string) => {
  return (key: string, defaultValue?: string) =>
    I18n.t(key, {
      scope: "static_reports",
      language,
      default: defaultValue,
    });
};

export const formatFiltersForFetch = (
  filters: Filters,
  defaultReportNames: string[],
  userMaySelectCustomers: boolean = false
): FetchFormattedFilters => {
  const reportNameValues = (filters.reportNames || []).map((r) => r.value);
  const formattedFilters: FetchFormattedFilters = {
    report_names:
      reportNameValues.length > 0 ? reportNameValues : defaultReportNames,
    start_date: filters.startDate?.format(Formats.DATE) || "",
    end_date: filters.endDate?.format(Formats.DATE) || "",
  };

  if (userMaySelectCustomers && filters?.customers?.length) {
    formattedFilters.customer_numbers = filters.customers.map((c) => c.value);
  }

  return formattedFilters;
};

const FORMAT_DATE_PERIOD_FUNCTIONS = {
  Year: (str: string) => str.slice(0, 4),
  Month: (str: string) => `${str.slice(0, 4)}-${str.slice(4, 6)}`,
  Day: (str: string) =>
    `${str.slice(0, 4)}-${str.slice(4, 6)}-${str.slice(6, 8)}`,
};

export const formatDatePeriod = (
  datePeriod: number,
  periodType: string
): string => {
  const formatFunction = FORMAT_DATE_PERIOD_FUNCTIONS[periodType];
  return formatFunction ? formatFunction(datePeriod.toString()) : "";
};

const isArrayAndHasItems = (value: Array<unknown> | undefined): boolean =>
  Array.isArray(value) && value.length > 0;

export const buildDownloadUrl = (
  report: Report,
  opts: { organizationId?: string; customerIds?: string[] } = {}
) => {
  const urlArgs: { [key: string]: any } = {
    widget: "StaticReports",
    method: "download",
    report_created: report.tags.ReportCreated,
    organization_id: opts.organizationId,
    file_id: report.fileId,
  };

  if (isArrayAndHasItems(opts.customerIds)) {
    urlArgs.filters = {
      customer_numbers: opts.customerIds,
    };
  }

  return Routes.dashboard_widget_data_path(urlArgs);
};
