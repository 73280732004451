import React from "react";
import Chip from "@oriola-origo/core/lib/Chip";
import FormControlLabel from "@oriola-origo/core/lib/FormControlLabel";
import FormGroup from "@oriola-origo/core/lib/FormGroup";
import Switch from "@oriola-origo/core/lib/Switch";
import { Organization } from "@services/user_management/types";
import { isNonSystemOrigoOrganization } from "@components/organization/utils";
import { SUCCESS_LIGHT_COLOR } from "@components/common/user_management_base_table/status_chip";
import useTranslations from "@hooks/useTranslations";

export interface OrganizationStatusIndicatorProps {
  organizationOrigin: Organization["origin"];
  isOrganizationActive: Organization["active"];
  onOrganizationActiveChange: (active: boolean) => void;
  isUpdating: boolean;
}

export default function OrganizationStatusIndicator({
  organizationOrigin,
  isOrganizationActive,
  onOrganizationActiveChange,
  isUpdating,
}: Readonly<OrganizationStatusIndicatorProps>) {
  const { t } = useTranslations();

  const activeText = isOrganizationActive
    ? t("company_status_active")
    : t("company_status_passive");

  if (!isNonSystemOrigoOrganization({ origin: organizationOrigin })) {
    return (
      <Chip
        size="small"
        label={activeText}
        sx={(theme) => ({
          backgroundColor: isOrganizationActive
            ? SUCCESS_LIGHT_COLOR
            : theme.palette.secondary.lightest,
        })}
      />
    );
  }

  const handleActiveChange = () => {
    onOrganizationActiveChange(!isOrganizationActive);
  };

  return (
    <FormGroup>
      <FormControlLabel
        sx={(theme) => ({
          color: isOrganizationActive
            ? theme.palette.text.primary
            : theme.palette.text.disabled,
        })}
        label={activeText}
        control={
          <Switch
            size="small"
            disabled={isUpdating}
            checked={isOrganizationActive}
            onChange={handleActiveChange}
          />
        }
      />
    </FormGroup>
  );
}
