import React from 'react';
import Select from '../../common/select';

export class ReportLink extends React.Component {

    static get ICONS() {
        return [
            ['grey-sample', I18n.t('sample')],
            ['grey-euro', I18n.t('currency_euro')],
            ['grey-bar-chart', I18n.t('bar_chart')],
            ['grey-bar-chart-up', I18n.t('bar_chart_arrow_up')],
            ['grey-fmd', I18n.t('fmd')],
            ['grey-drug', I18n.t('drug')],
            ['grey-promo', I18n.t('promo_material')]
        ];
    }

    static get AUTH_PATH() {
        return 'origoauth';
    }

    constructor(props) {
        super(props);

        this.state = {
            settings: props.settings,
            icons: []
        }
    }

    render() {
        if (this.props.mode == Origo.Widget.Mode.SETTINGS) {
            return this.renderSettings();
        } else {
            return this.renderView();
        }
    }

    renderView() {
        const title = this.getTitle(this.props.language);

        if (this.state.settings.report_url) {
            return <div className="d-flex flex-column justify-content-between position-relative h-100" style={{minHeight: '18rem'}}>
                <div className="h-100 w-100 d-flex flex-wrap align-content-center position-absolute" style={{ fontSize: '500%' }}>
                    {this.state.settings.icon && <i className={`oricon-${this.state.settings.icon} w-100`}></i>}
                </div>
                {title && <h1 className="text-highlight font-weight-bold p-3" style={{fontSize: '250%', overflow: 'hidden'}}>{title}</h1>}
                <div className="text-right position-absolute w-100 p-3" style={{bottom: 0}}>
                    <a href={this.state.settings.report_url} target="_blank" onClick={this.openLink}>
                        {I18n.t('show_report')}
                    </a>
                </div>
            </div>;
        } else {
            return <h2 className="text-muted text-center p-3">
                {I18n.t('report_is_not_configured')}
            </h2>;
        }
    }

    renderSettings() {
        return <form className="settings p-3">
            <div className="form-group">
                <label>{I18n.t('title')}</label>
                {Origo.AVAILABLE_LANGUAGES.map(lang => <div key={lang} className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" style={{width: '2.7rem'}}>{lang.toUpperCase()}</span>
                    </div>
                    <input type="text" name={`title_${lang}`} className="form-control" value={this.getTitle(lang)} onChange={e => this.updateSettings(`title_${lang}`, e.currentTarget.value)} />
                </div>)}
            </div>
            <div className="form-group">
                <label>{I18n.t('report_url')} *</label>
                <input type="text" name="report_url" placeholder="https://..." className="form-control" value={this.state.settings.report_url || ''} onChange={e => this.updateSettings('report_url', e.currentTarget.value)} />
                <div className="text-muted">
                    <small>{I18n.t('report_url_description', {param: 'access_token=<token>'})}</small>
                </div>
            </div>
            <div className="form-group">
                <label>{I18n.t('icon')}</label>
                <Select
                    defaultValue={this.state.settings.icon ? this.formatIconOptions((this.constructor.ICONS || []).filter(i => i[0] == this.state.settings.icon)) : null}
                    options={this.formatIconOptions(this.constructor.ICONS)}
                    isClearable={true}
                    placeholder={I18n.t('select_blank_text')}
                    formatOptionLabel={option => (
                        <span><i className={`oricon-${option.value}`}></i> {option.label}</span>
                    )}
                    onChange={option => this.updateSettings('icon', (option || {}).value)}
                />
            </div>
        </form>;
    }

    updateSettings(attr, value) {
        this.setState(prevState => {
            let settings = prevState.settings;
            settings[attr] = value;
            return {settings: settings};
        }, () => {
            this.props.updateSettings(prevSettings => {
                return this.state.settings;
            });
        });
    }

    openLink = (event) => {
        const url = new URL(this.state.settings.report_url);
        const authUrl = `${url.protocol}//${url.hostname}/${this.constructor.AUTH_PATH}`;

        let reportWindow = window.open('', '_blank');

        fetch(authUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {
                accessToken: this.props.accessToken
            }
        }).then(response => {
            reportWindow.location = this.state.settings.report_url;
        }).catch(error => {
            reportWindow.document.write('Authentication failed: ', error.message);
        });

        event.preventDefault();
    }

    getTitle = (lang) => {
        return typeof this.state.settings[`title_${lang}`] == 'string' ? this.state.settings[`title_${lang}`] : this.state.settings[`title_${I18n.defaultLocale}`] || '';
    }

    formatIconOptions = (icons) => {
        return icons.map(i => ({ value: i[0], label: i[1] })).sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            } else if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
    }
}