import moment from "moment";
import React, { useEffect, useState } from "react";
import { styled } from "@oriola-origo/core/lib/styles";
import Box from "@oriola-origo/core/lib/Box";
import Typography from "@oriola-origo/core/lib/Typography";
import Table from "@oriola-origo/core/lib/Table";
import TableHead from "@oriola-origo/core/lib/TableHead";
import TableBody from "@oriola-origo/core/lib/TableBody";
import TableRow from "@oriola-origo/core/lib/TableRow";
import TableCell from "@oriola-origo/core/lib/TableCell";
import TableFooter from "@oriola-origo/core/lib/TableFooter";
import TablePagination from "@oriola-origo/core/lib/TablePagination/TablePagination";
import TableCellNoContent from "@oriola-origo/core/lib/TableCellNoContent/TableCellNoContent";
import Button from "@oriola-origo/core/lib/Button";
import ToggleButtonGroup from "@oriola-origo/core/lib/ToggleButtonGroup/ToggleButtonGroup";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";

import Loading from "../../../common/loading";
import { IWidgetProps } from "../base_widget";
import { getTitle } from "../report_link_v2/utils";
import useFetchReports, { FetchReportsOutput, Report } from "./useFetchReports";
import useFetchFilterOptions, {
  FetchFilterOptionsOutput,
} from "./useFetchFilterOptions";
import {
  Filters,
  buildDownloadUrl,
  formatDatePeriod,
  staticReportsTranslationFactory,
} from "./utils";
import StaticReportsFilters from "./static_reports_filters";

type StaticReportsWidgetSettings = {
  title_en: string;
  title_fi: string;
  title_sv: string;
  reportNames?: string[];
};

type View = "latest" | "history";

export interface IStaticReportsWidgetV2Props
  extends IWidgetProps<StaticReportsWidgetSettings> {}

const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 4),
  backgroundColor: theme.palette.background.default,
}));

const Title = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));

const DownloadLink = styled("a")(() => ({
  position: "relative",
  top: 2,
}));

const PAGE_SIZE = 10;

function StaticReportsWidgetV2({
  organizationId,
  language,
  settings,
}: Readonly<IStaticReportsWidgetV2Props>) {
  const [filters, setFilters] = useState<Filters>({});
  const [selectedPage, setSelectedPage] = useState(0);
  const [filterOptions, setFilterOptions] =
    useState<FetchFilterOptionsOutput>();
  const [selectedView, setSelectedView] = useState<View>("latest");
  const [fetchReportsResult, setFetchReportsResult] =
    useState<FetchReportsOutput>(null);

  const { fetchReports, isFetching: isFetchingReports } = useFetchReports(
    settings.reportNames ?? [],
    organizationId
  );
  const { fetchFilterOptions, isFetching: isFetchingFilterOptions } =
    useFetchFilterOptions(language, organizationId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedView === "latest") {
          const res = await fetchReports({ kind: "latest" });
          setFetchReportsResult(res);
          return;
        }

        let options = filterOptions;
        if (!options) {
          options = await fetchFilterOptions();
          setFilterOptions(options);
        }

        const res = await fetchReports({
          kind: "list",
          page: selectedPage,
          pageSize: PAGE_SIZE,
          filters,
          maySelectCustomers: options.permissions.maySelectCustomers,
        });
        setFetchReportsResult(res);
      } catch (err) {
        if (err?.name !== "AbortError") {
          console.error("Error fetching reports", err);
          throw err;
        }
      }
    };

    fetchData();
  }, [selectedView, selectedPage, filters]);

  const handleFiltersChange = (newFilters: Filters) => {
    setFilters(newFilters);
    setSelectedPage(0);
  };

  const getText = staticReportsTranslationFactory(language);

  const getReportName = (report: Report) => {
    const {
      CustomerId: customerId,
      ProfitCenterID: profitCenterId,
      DatePeriod: datePeriod,
      PeriodType: periodType,
      ReportName: reportName,
    } = report.tags;

    return [
      getText(`report_name_${reportName}`, reportName),
      customerId || profitCenterId,
      formatDatePeriod(datePeriod, periodType),
    ]
      .filter(Boolean)
      .join(" ");
  };

  const isHistoryView = selectedView === "history";
  const isHistoryViewReady = isHistoryView
    ? filterOptions && !isFetchingFilterOptions
    : true;
  const isLoading =
    !fetchReportsResult || isFetchingReports || !isHistoryViewReady;

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={3}>
            <Loading />
          </TableCell>
        </TableRow>
      );
    }

    if (!fetchReportsResult?.reports.length) {
      return (
        <TableRow>
          <TableCellNoContent
            colSpan={3}
            height={100}
            iconName="live_help"
            iconSize={40}
          >
            {getText("no_reports_message")}
          </TableCellNoContent>
        </TableRow>
      );
    }

    return (fetchReportsResult?.reports ?? []).map((report) => {
      const customerIds = filters.customers?.map((c) => c.value) || [];
      const downloadUrl = buildDownloadUrl(report, {
        organizationId,
        customerIds,
      });
      const reportName = getReportName(report);
      const { mediaType, fileId, fileName, tags } = report;
      return (
        <TableRow key={fileId}>
          <TableCell>
            <Typography variant="body1">
              <a
                data-tracking-link="staticreports"
                data-tracking-text={reportName}
                href={downloadUrl}
                download={fileName}
                type={mediaType}
              >
                {reportName}
              </a>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1" color="text.secondary">
              {moment.unix(tags.ReportCreated).format(Formats.DATETIME)}
            </Typography>
          </TableCell>
          <TableCell>
            <DownloadLink
              data-tracking-link="staticreports"
              data-tracking-text={reportName}
              href={downloadUrl}
              download={fileName}
              type={mediaType}
            >
              <FontIcon sx={{ fontSize: 20 }}>download</FontIcon>
            </DownloadLink>
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderTable = () => (
    <Table size="small" hideHoverEffect={true}>
      <TableHead>
        <TableRow>
          <TableCell>{getText("report")}</TableCell>
          <TableCell width={300}>{getText("report_created")}</TableCell>
          <TableCell width={150}>{getText("download")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{renderTableBody()}</TableBody>
      {isHistoryView && !isLoading && (
        <TableFooter>
          <TablePagination
            sx={{
              // Override the default margin-bottom coming from application css
              "& p": {
                marginBottom: 0,
              },
            }}
            count={fetchReportsResult?.filteredCount}
            page={selectedPage}
            rowsPerPage={PAGE_SIZE}
            onPageChange={(_, newPage) => setSelectedPage(newPage)}
            rowsPerPageOptions={[]}
          />
        </TableFooter>
      )}
    </Table>
  );

  const title = getTitle(language, settings);
  return (
    <Root>
      {!!title && (
        <Title>
          <Typography variant="h5">{title}</Typography>
        </Title>
      )}
      <Box mb={2}>
        <ToggleButtonGroup
          value={selectedView}
          onChange={(view) => setSelectedView(view as View)}
        >
          <Button value="latest">{getText("latest")}</Button>
          <Button value="history">{getText("history")}</Button>
        </ToggleButtonGroup>
      </Box>
      {isHistoryView && filterOptions && (
        <Box mb={2}>
          <StaticReportsFilters
            language={language}
            filterOptions={filterOptions.filterOptions}
            permissions={filterOptions.permissions}
            allowedReportNames={settings.reportNames ?? []}
            filters={filters}
            onChange={handleFiltersChange}
          />
        </Box>
      )}
      <Box>{renderTable()}</Box>
    </Root>
  );
}

export default StaticReportsWidgetV2;
