import React from "react";
import Card from "@oriola-origo/core/lib/Card";
import Button from "@oriola-origo/core/lib/Button";
import CardMedia from "@oriola-origo/core/lib/CardMedia";
import CardHeader from "@oriola-origo/core/lib/CardHeader";
import CardActions from "@oriola-origo/core/lib/CardActions";
import CardContent from "@oriola-origo/core/lib/CardContent";
import { styled } from "@oriola-origo/core/lib/styles";
import Typography from "@oriola-origo/core/lib/Typography";
import { ReportAccessRequestStatus } from "../types";

export interface IReportAccessCardProps {
  id: string;
  status: ReportAccessRequestStatus;
  title: string;
  description: string;
  imageUrl: string;
  onClickRequestAccess: (id: string) => void;
}

const Root = styled(Card)(({ theme }) => ({
  minWidth: theme.spacing(35),
  maxWidth: theme.spacing(50),
  height: theme.spacing(47.5),
  position: "relative",
}));

const Media = styled(CardMedia)(({ theme }) => ({
  height: theme.spacing(22),
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

const Content = styled(CardContent)(({ theme }) => ({
  maxWidth: theme.spacing(44.5),
}));

const Action = styled(CardActions)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(3),
  right: theme.spacing(4),
  padding: 0,
}));

function ReportAccessCard({
  imageUrl,
  status,
  id,
  onClickRequestAccess,
  title,
  description,
}: Readonly<IReportAccessCardProps>) {
  return (
    <Root>
      <Media component="img" image={imageUrl} />
      <CardHeader
        title={I18n.t(title)}
        titleTypographyProps={{ variant: "h6" }}
      />
      <Content>
        <Typography variant="body1"> {I18n.t(description)}</Typography>
      </Content>
      <Action>
        <Button
          color="secondary"
          size="medium"
          variant="outlined"
          disabled={status === ReportAccessRequestStatus.PENDING}
          onClick={() => onClickRequestAccess(id)}
        >
          {status === ReportAccessRequestStatus.PENDING
            ? I18n.t("report_access_pending")
            : I18n.t("report_access_available")}
        </Button>
      </Action>
    </Root>
  );
}

export default ReportAccessCard;
