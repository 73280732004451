import React from 'react';
import { Modal, CloseModal } from '../common/modal';
import { Form, FormSubmit } from '../common/form';
import Switch from '../common/switch';

export default class ProfitCenterForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reclamationTranslations: props.profitCenter ? props.profitCenter.reclamation_translations : false,
            reclamationUseSupplier: props.profitCenter ? props.profitCenter.reclamation_use_supplier : false,
            compensationManagerService: props.profitCenter ? props.profitCenter.compensation_manager_service : false,
        };
    }

    render() {
        const profitCenter = this.props.profitCenter;

        return <Modal title={this.props.title} onClose={this.props.onClose}>
            <Form action={this.props.action} onSuccess={this.props.onSuccess}>
                <div className="form-group">
                    <label htmlFor="profit_center_code">{I18n.t('profit_center_code')} *</label>
                    <input id="profit_center_code" name="profit_center[code]" type="text" defaultValue={profitCenter ? profitCenter.code : ''} className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="profit_center_name">{I18n.t('name')} *</label>
                    <input id="profit_center_name" name="profit_center[name]" type="text" defaultValue={profitCenter ? profitCenter.name : ''}
                        className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="profit_center_reclamation_spd_email_address">{I18n.t('reclamation_spd_email_addresses')}</label>
                    <input id="profit_center_reclamation_spd_email_address" name="profit_center[reclamation_spd_email_address]"
                        type="text" defaultValue={profitCenter ? profitCenter.reclamation_spd_email_address : ''}
                        placeholder={I18n.t('reclamation_spd_email_addresses_placeholder')} className="form-control" />
                </div>

                <div className="form-group">
                    <label htmlFor="profit_center_reclamation_translations">{I18n.t('reclamation_translations')}</label>
                    <Switch
                        id="profit_center_reclamation_translations"
                        name="profit_center[reclamation_translations]"
                        value="1"
                        onChange={checked => this.setState({ reclamationTranslations: checked })}
                        checked={this.state.reclamationTranslations}
                        className="d-block"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="profit_center_reclamation_use_supplier">{I18n.t('reclamation_use_supplier')}</label>
                    <Switch
                        id="profit_center_reclamation_use_supplier"
                        name="profit_center[reclamation_use_supplier]"
                        value="1"
                        onChange={checked => this.setState({ reclamationUseSupplier: checked })}
                        checked={this.state.reclamationUseSupplier}
                        className="d-block"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="profit_center_compensation_manager_service">{I18n.t('compensation_manager_service')}</label>
                    <Switch
                        id="profit_center_compensation_manager_service"
                        name="profit_center[compensation_manager_service]"
                        value="1"
                        onChange={checked => this.setState({ compensationManagerService: checked })}
                        checked={this.state.compensationManagerService}
                        className="d-block"
                    />
                </div>
                <div style={{ display: this.state.compensationManagerService ? 'block' : 'none' }}>
                    <div className="form-group">
                        <label htmlFor="profit_center_material_number">{I18n.t('profit_center_material_number')}</label>
                        <input id="profit_center_material_number" name="profit_center[material_number]" type="text" defaultValue={profitCenter ? profitCenter.material_number : ''} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="profit_center_material_description">{I18n.t('profit_center_material_description')}</label>
                        <input id="profit_center_material_description" name="profit_center[material_description]" type="text" defaultValue={profitCenter ? profitCenter.material_description : ''} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="profit_center_material_type">{I18n.t('profit_center_material_type')}</label>
                        <input id="profit_center_material_type" name="profit_center[material_type]" type="text" defaultValue={profitCenter ? profitCenter.material_type : ''} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="profit_center_customer_number">{I18n.t('profit_center_customer_number')}</label>
                        <input id="profit_center_customer_number" name="profit_center[customer_number]" type="text" defaultValue={profitCenter ? profitCenter.customer_number : ''} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="profit_center_po_number">{I18n.t('profit_center_po_number')}</label>
                        <input id="profit_center_po_number" name="profit_center[po_number]" type="text" defaultValue={profitCenter ? profitCenter.po_number : ''} className="form-control" />
                    </div>
                </div>
                <div className="text-right">
                    <CloseModal className="btn btn-light mr-3 px-4">{I18n.t('cancel')}</CloseModal>
                    <FormSubmit />
                </div>
            </Form>
        </Modal>
    }

}
