import React, { useEffect } from "react";
import SnackbarProvider from "@oriola-origo/core/lib/Snackbar/SnackbarProvider";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Box from "@oriola-origo/core/lib/Box";
import { UserGroups } from "@services/user_management/hooks/useUserGroupCategories";
import {
  CompanyType,
  Organization,
  OrigoApplication,
  OrigoApplicationRole,
  ProfitCenter,
} from "@services/user_management/types";
import UsersSection from "./users_section/users_section";
import BackButton from "@components/common/back_button";
import useTranslations from "@hooks/useTranslations";
import OrganizationBasicInfo from "./basic_info_section/organization_basic_info";
import { styled } from "@oriola-origo/core/lib/styles";
import useScrollContainer from "@hooks/useScrollContainer";
import OrganizationContactInformation from "./contact_section/organization_contact_information";
import OrganizationDeliveryAddress from "./delivery_address_section/organization_delivery_address";
import useOrganization, {
  PatchUpdateOrganizationOpts,
} from "@services/user_management/hooks/useOrganization";
import useSnackbar from "@hooks/useSnackbar";
import useTurbolinks from "@hooks/useTurbolinks";
import useOrganizations from "@services/user_management/hooks/useOrganizations";
import { isSapOrganization } from "../utils";

export interface CompanyPageV2Props {
  company: Organization;
  companyTypes: CompanyType[];
  userGroups: UserGroups[];
  permissions: {
    may_delete_organization: boolean;
    may_edit_users: boolean;
    may_view_organization_activity_log: boolean;
    may_view_users_activity_log: boolean;
    may_assign_roles: boolean;
  };
  companyListUrl: string;
  apps: OrigoApplication[];
  roles: { [origoApplicationId: number]: OrigoApplicationRole[] };
  profitCenters: ProfitCenter[];
}

const StickyContainer = styled("div")({
  top: 0,
  position: "sticky",
  zIndex: 10,
});

function CompanyPageBase({
  userGroups,
  company,
  companyTypes,
  permissions,
  companyListUrl,
  apps,
  roles,
  profitCenters,
}: Readonly<CompanyPageV2Props>) {
  const { t } = useTranslations();
  const { scrollContainer } = useScrollContainer();
  const [stickyContainerRef, setStickyContainerRef] =
    React.useState<HTMLDivElement | null>(null);
  const { patchUpdateOrganization, isUpdating } = useOrganization();
  const { deleteOrganization } = useOrganizations();
  const { setSnackMessage } = useSnackbar();
  const [organization, setOrganization] = React.useState<Organization>(company);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { navigate } = useTurbolinks();

  useEffect(() => {
    setOrganization(company);
  }, [company]);

  const saveOrganizationData = async (
    property: string,
    value: string | object
  ) => {
    const properties: PatchUpdateOrganizationOpts = {
      organizationId: organization.id,
      organization: {
        [property]: value,
      },
    };
    const res = await patchUpdateOrganization(properties);
    if (res.succeed) {
      setOrganization({ ...organization, [property]: value });
      setSnackMessage(t("organization_info_updated"), "success");
    } else {
      setSnackMessage(t("organization_info_update_failed"), "error");
    }
  };

  const handleDeleteOrganization = async () => {
    setIsDeleting(true);
    try {
      await deleteOrganization(organization.id);
      navigate(`${companyListUrl}?deleted=true`);
    } catch (e) {
      setSnackMessage(t("organization_delete_failed"), "error");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Box minWidth="320px">
      <BackButton
        navigateTo={companyListUrl}
        text={t("back_to_organization_list")}
        mt={0}
        mb={3}
      />
      <StickyContainer ref={(ref) => setStickyContainerRef(ref)} />

      <OrganizationBasicInfo
        apps={apps}
        roles={roles}
        profitCenters={profitCenters}
        organizationTypes={companyTypes}
        organization={organization}
        permissions={{
          may_assign_roles: permissions.may_assign_roles,
          may_delete: permissions.may_delete_organization,
          may_show_activity_log: permissions.may_view_organization_activity_log,
        }}
        stickyProps={{
          stickyContainerElement: stickyContainerRef,
          viewportElement: scrollContainer,
        }}
        onSave={saveOrganizationData}
        onDelete={handleDeleteOrganization}
        isUpdating={isUpdating}
        isDeleting={isDeleting}
      />
      {isSapOrganization(organization) && (
        <OrganizationContactInformation
          organization={organization}
          isUpdating={isUpdating}
          onSave={saveOrganizationData}
        />
      )}
      {isSapOrganization(organization) && (
        <OrganizationDeliveryAddress organization={organization} />
      )}
      <UsersSection
        organizationId={company.id}
        companyTypes={companyTypes}
        userGroups={userGroups}
        mayEditUsers={permissions.may_edit_users}
        mayViewUsersActivityLog={permissions.may_view_users_activity_log}
      />
    </Box>
  );
}

function CompanyPageV2(props: Readonly<CompanyPageV2Props>) {
  return (
    <OriolaThemeProvider>
      <SnackbarProvider autoHideDuration={2000}>
        <CompanyPageBase {...props} />
      </SnackbarProvider>
    </OriolaThemeProvider>
  );
}

export default CompanyPageV2;
