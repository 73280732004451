import React, { useCallback } from "react";
import UserTable, { UsersTableProps } from "../user_table/user_table";
import OrganizationFilter, {
  OrganizationFilterOption,
} from "../organization_filter/organization_filter";
import useTranslations from "@hooks/useTranslations";
import OrganizationCellContent from "./components/organization_cell_content";
export {
  strIdsToOrganizationIds,
  idsToStrOrganizationIds,
} from "../organization_filter/utils";

type UserTableWithOrganizationsRawProps = UsersTableProps<{
  organizationIds: number[];
}>;

export type UserTableWithOrganizationsProps = Omit<
  UserTableWithOrganizationsRawProps,
  | "customFilterRender"
  | "groupColumnHeaderText"
  | "groupCellHasAnythingToExpand"
  | "groupCellRender"
>;

export function UserTableWithOrganizations(
  props: Readonly<UserTableWithOrganizationsProps>
) {
  const { t } = useTranslations();
  const groupFilterRender: UserTableWithOrganizationsRawProps["groupFilterRender"] =
    useCallback((filtersValue, onFiltersChange) => {
      const handleOrganizationsChange = (
        selectedOrganizationOptions: OrganizationFilterOption[]
      ) => {
        const selectedOrganizationIds = selectedOrganizationOptions.map(
          (option) => option.id
        );
        onFiltersChange({
          ...filtersValue,
          organizationIds:
            Array.isArray(selectedOrganizationIds) &&
            selectedOrganizationIds.length > 0
              ? selectedOrganizationIds
              : undefined,
        });
      };

      return (
        <OrganizationFilter
          defaultValue={filtersValue.organizationIds}
          onSelectionChange={handleOrganizationsChange}
        />
      );
    }, []);

  const renderOrganizationCellContanent: UserTableWithOrganizationsRawProps["groupCellRender"] =
    ({ companies }, isExpanded) => (
      <OrganizationCellContent
        organizations={companies}
        isExpanded={isExpanded}
      />
    );

  return (
    <UserTable
      {...props}
      groupFilterRender={groupFilterRender}
      groupColumnHeaderText={t("organizations")}
      groupCellHasAnythingToExpand={({ companies }) => companies.length > 1}
      groupCellRender={renderOrganizationCellContanent}
    />
  );
}

export default UserTableWithOrganizations;
