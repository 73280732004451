import { useMemo } from "react";

function useScrollContainer() {
  return useMemo(() => {
    const scrollContainer = document.getElementById("content-scroll");
    return {
      scrollContainer,
      scrollToTop: () => {
        scrollContainer?.scrollTo(0, 0);
      },
    };
  }, []);
}

export default useScrollContainer;
