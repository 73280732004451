import { Dashboard, ReportDashboardNavItem } from "./new_reports_page_sidebar";

export const fetchCacheData = (accessToken: string) => {
  const baseCacheKey = "origo-user-data";
  const userOrg = window.location.href.split("/")[3];
  const finalKey = `${baseCacheKey}-${userOrg}-${accessToken}`;
  const cache = Origo.cache.get(finalKey);
  return cache;
};

export const getReportUrlsList = (cache: any) => {
  return cache.apps?.find((item) => item.id === "reports");
};

export const reportDashboardMapping = {
  "All Reports": {
    iconName: "bar_chart",
    id: "report_sidebar_home",
    text: "Home",
    iconStyle: "outlined",
  },
  "ALV -ja Hankintaennakkoraportit": {
    iconName: "payments",
    id: "report_sidebar_vat",
    text: "VAT and prepayment",
    iconStyle: "outlined",
  },
  "Invoice Attachments": {
    iconName: "description",
    id: "report_sidebar_invoice",
    text: "Invoice attachments",
    iconStyle: "outlined",
  },
};

export const prepareReportDashboardLinks = (
  reportDashboardLinks: Dashboard[]
): ReportDashboardNavItem[] => {
  return reportDashboardLinks.reduce((acc, item) => {
    if (!reportDashboardMapping[item.label]) {
      acc.push({
        iconName: "dashboard",
        id: item.label,
        text: item.label,
        iconStyle: "outlined",
        path: item.url,
      });
    }

    if (reportDashboardMapping[item.label]) {
      acc.push({
        ...reportDashboardMapping[item.label],
        path: item.url,
      });
    }

    return acc;
  }, []);
};

export const redirectToPath = (path: string) => {
  window.location.href = path;
};

export const getSelectedDashboardId = (
  reportDashboards: ReportDashboardNavItem[]
) => {
  const currentPath = window.location.href;
  const selectedDashboard = reportDashboards.find((item) =>
    currentPath.includes(item.path)
  );
  return selectedDashboard ? selectedDashboard.id : null;
};
