import React, { useEffect } from "react";
import TableVirtualized, {
  TableVirtualizedProps,
} from "@oriola-origo/core/lib/TableVirtualized";
import TableRow from "@oriola-origo/core/lib/TableRow";
import TableCell from "@oriola-origo/core/lib/TableCell";
import Box from "@oriola-origo/core/lib/Box";
import useScrollContainer from "@hooks/useScrollContainer";
import Typography from "@oriola-origo/core/lib/Typography";
import Tooltip from "@oriola-origo/core/lib/Tooltip";
import IconButton from "@oriola-origo/core/lib/IconButton";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Progress from "@oriola-origo/core/lib/Progress";
import { styled } from "@oriola-origo/core/lib/styles";
import useRoutes from "@hooks/useRoutes";
import useWindow from "@hooks/useWindow";
import NoStyleAnchor from "@components/common/no_style_anchor";
import { FiltersProps, FiltersType } from "./filters";
import { UserTableCell } from "./cells/common_cells";
import DetailsCell from "./cells/details_cell";
import ProfessionalTitlesCell from "./cells/professional_titles_cell";
import Headers from "./headers";
import { type SortType } from "./columns";
import { User, UserStatus, CompanyType } from "@services/user_management/types";
import TableCellNoContent from "@oriola-origo/core/lib/TableCellNoContent/TableCellNoContent";
import useTranslations from "@hooks/useTranslations";
import StatusChip from "../user_management_base_table/status_chip";
import ForceChildTableToFixedLayoutWrapper from "../user_management_base_table/force_child_table_to_fixed_layout_wrapper";
import ActionsCell, {
  ActionsCellItem,
} from "../user_management_base_table/actions_cell";
import DetailsSummary from "../user_management_base_table/details/details_summary";

const STATUS_LOCALE_MAP = {
  [UserStatus.Active]: "user_status_active",
  [UserStatus.Inactive]: "user_status_inactive",
  [UserStatus.Invited]: "user_status_invited",
};

export type UserTableUser = Pick<
  User,
  | "id"
  | "active"
  | "first_name"
  | "last_name"
  | "email_address"
  | "companies"
  | "status"
  | "all_roles"
  | "professional_title"
  | "user_groups"
>;

export interface UsersTableActionMenuItem extends ActionsCellItem {}

export type UsersTableProps<TFilterExtras extends object = {}> = {
  users: UserTableUser[];
  counts?: {
    total: number;
    active: number;
    inactive: number;
    invited: number;
  };
  companyTypes: CompanyType[];
  isRePopulating: boolean;
  showProfessionalTitleColumn?: boolean;
  groupCellHasAnythingToExpand: (user: UserTableUser) => boolean;
  groupColumnHeaderText: string;
  groupCellRender: (
    user: UserTableUser,
    isExpanded: boolean
  ) => React.ReactNode;
  groupFilterRender?: FiltersProps<TFilterExtras>["groupFilterRender"];
  isLoading: boolean;
  filters?: FiltersType<TFilterExtras>;
  sort?: SortType;
  onRequestMore: () => void;
  onFiltersChange: (filter: FiltersType<TFilterExtras>) => void;
  onSortChange: (sortBy: SortType) => void;
  actionMenuItems?: UsersTableActionMenuItem[];
};

const StyledIconButton = styled(IconButton)({
  padding: 0,
});

const Container = styled("div")({
  minHeight: 450,
});

function UserTable<TFilterExtras = {}>({
  actionMenuItems,
  users,
  sort,
  counts,
  isLoading,
  isRePopulating,
  companyTypes,
  filters,
  groupCellHasAnythingToExpand,
  showProfessionalTitleColumn,
  groupCellRender,
  groupColumnHeaderText,
  onFiltersChange,
  groupFilterRender,
  onRequestMore,
  onSortChange,
}: Readonly<
  UsersTableProps<TFilterExtras extends object ? TFilterExtras : {}>
>) {
  const { t } = useTranslations();
  const { scrollContainer } = useScrollContainer();
  const { userPath } = useRoutes();
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(
    null
  );
  const [containerWidth, setContainerWidth] = React.useState(0);
  const window = useWindow();
  const [extendedItemId, setExtendedItemId] = React.useState<number | null>(
    null
  );
  const [actionOpenItemId, setActionOpenItemId] = React.useState<number | null>(
    null
  );
  const shouldShowNoContent = users.length === 0 && !isLoading;
  const columnCount = showProfessionalTitleColumn ? 8 : 7;

  useEffect(() => {
    const handleResize = () => {
      if (containerRef) {
        setContainerWidth(containerRef.offsetWidth);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef]);

  const rowRenderer: TableVirtualizedProps["onRowRender"] = (
    _: number,
    user: UserTableUser
  ) => {
    if (shouldShowNoContent) {
      return (
        <TableCellNoContent colSpan={columnCount} iconName="emoji_people">
          {t("table_empty_data_notification")}
        </TableCellNoContent>
      );
    }

    const hasAnythingToExpand = groupCellHasAnythingToExpand(user);
    const isExpanded = extendedItemId === user.id;

    const renderStatusChip = () => {
      if (user.status === UserStatus.Invited) {
        const tooltipText = [
          t(user.active ? "user_status_active" : "user_status_inactive"),
          `(${t("user_status_invited")})`,
        ].join(" ");

        return (
          <Tooltip title={tooltipText}>
            <StatusChip status={user.status} localeMap={STATUS_LOCALE_MAP} />
          </Tooltip>
        );
      }

      return <StatusChip status={user.status} localeMap={STATUS_LOCALE_MAP} />;
    };

    return (
      <>
        <UserTableCell isExpanded={isExpanded} isDisabled={isRePopulating}>
          {hasAnythingToExpand && (
            <StyledIconButton
              onClick={() => setExtendedItemId(isExpanded ? null : user.id)}
            >
              <FontIcon sx={{ color: (theme) => theme.palette.secondary.main }}>
                {isExpanded ? "remove" : "add"}
              </FontIcon>
            </StyledIconButton>
          )}
        </UserTableCell>
        <UserTableCell isExpanded={isExpanded} isDisabled={isRePopulating}>
          <NoStyleAnchor href={userPath(user.id)}>
            <Tooltip title={user.first_name + " " + user.last_name}>
              <Typography variant="body2" color="textPrimary" noWrap>
                {user.last_name} {user.first_name}
              </Typography>
            </Tooltip>
          </NoStyleAnchor>
        </UserTableCell>
        <UserTableCell isExpanded={isExpanded} isDisabled={isRePopulating}>
          <Tooltip title={user.email_address}>
            <Typography variant="body2" color="textSecondary" noWrap>
              {user.email_address}
            </Typography>
          </Tooltip>
        </UserTableCell>
        {showProfessionalTitleColumn &&
          (user.companies ? (
            <ProfessionalTitlesCell
              isDisabled={isRePopulating}
              isExpanded={isExpanded}
              organizations={user.companies}
            />
          ) : (
            <UserTableCell isExpanded={isExpanded} isDisabled={isRePopulating}>
              <Tooltip title={user.professional_title}>
                <Typography noWrap variant="body2" color="textSecondary">
                  {user.professional_title}
                </Typography>
              </Tooltip>
            </UserTableCell>
          ))}
        <UserTableCell isExpanded={isExpanded} isDisabled={isRePopulating}>
          {groupCellRender(user, isExpanded)}
        </UserTableCell>
        {user.companies ? (
          <DetailsCell
            user={user}
            isExpanded={isExpanded}
            isDisabled={isRePopulating}
            companyTypes={companyTypes}
          />
        ) : (
          <UserTableCell isExpanded={isExpanded} isDisabled={isRePopulating}>
            <DetailsSummary
              roles={user.all_roles}
              companyTypes={companyTypes}
            />
          </UserTableCell>
        )}
        <UserTableCell isExpanded={isExpanded} isDisabled={isRePopulating}>
          {renderStatusChip()}
        </UserTableCell>
        <ActionsCell
          resource={user}
          items={actionMenuItems ?? []}
          verticalAlign={isExpanded ? "top" : "middle"}
          isDisabled={isRePopulating}
          isMenuOpen={actionOpenItemId === user.id}
          onMenuClose={() => setActionOpenItemId(null)}
          onMenuOpen={() => setActionOpenItemId(user.id)}
        />
      </>
    );
  };

  const headerRenderer = () => (
    <Headers
      tableWidth={containerWidth}
      showProfessionalTitleColumn={showProfessionalTitleColumn}
      groupColumnHeaderText={groupColumnHeaderText}
      filters={filters}
      onFiltersChange={onFiltersChange}
      sort={sort}
      onSortChange={onSortChange}
      counts={counts}
      groupFilterRender={groupFilterRender}
    />
  );

  const footerRenderer = () => {
    if (users.length >= (counts?.total ?? 0) || !isLoading) {
      return null;
    }

    return (
      <TableRow>
        <TableCell colSpan={columnCount}>
          <Box display="flex" justifyContent="center">
            <Progress color="secondary" size={50} />;
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Container ref={setContainerRef}>
      <ForceChildTableToFixedLayoutWrapper>
        <TableVirtualized
          data={shouldShowNoContent ? [{ id: -1 }] : users}
          scrollParent={scrollContainer}
          headerZIndex={999}
          idProperty="id"
          onRowRender={rowRenderer}
          onHeaderRender={headerRenderer}
          onFooterRender={footerRenderer}
          onEndReached={() => onRequestMore()}
          hideHoverEffect={true}
        />
      </ForceChildTableToFixedLayoutWrapper>
    </Container>
  );
}

export default UserTable;
