/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import * as Routes from "routes.js.erb";
import packageJson from "../../../package.json";
import { sentry } from "@oriola-origo/origo-common-client-lib";
import { Provider } from "react-redux";
import { store } from "../store";

sentry.init({
  release: `${packageJson.name}@${packageJson.version}`,
});

window.Routes = Routes;

window.mountedReactComponentIds = [];

window.mountReactComponent = function (componentId, componentName, props) {
  ReactDOM.render(
    <Provider store={store}>
      {React.createElement(Webpack[componentName], props)}
    </Provider>,
    document.getElementById(componentId)
  );
  window.mountedReactComponentIds.push(componentId);
};

window.unmountReactComponent = function (componentId) {
  try {
    ReactDOM.unmountComponentAtNode(document.getElementById(componentId));
    const newArr = window.mountedReactComponentIds.filter(
      (id) => id !== componentId
    );
    window.mountedReactComponentIds = newArr;
  } catch (e) {
    // No need to do anything
  }
};

window.unmountAllReactComponents = function () {
  const componentsIds = [...window.mountedReactComponentIds];
  for (const componentId of componentsIds) {
    window.unmountReactComponent(componentId);
  }
};

export { default as UserListV2 } from "../components/user/list_v2";
export { default as ActivityLogList } from "../components/activity_log/list";
export { default as OrigoHeader } from "../components/header/header";
export { default as Dashboard } from "../components/dashboard/dashboard";
export { default as DashboardList } from "../components/dashboard/list";
export { default as DashboardForm } from "../components/dashboard/form";
export { default as DashboardWidgetList } from "../components/dashboard_widget/list";
export { default as OrigoApplicationRoleList } from "../components/origo_application_role/list";
export { default as OrigoApplicationRoleShow } from "../components/origo_application_role/show";
export { default as CompanyForm } from "../components/organization/form";
export { default as CompanyTypeList } from "../components/company_type/list";
export { default as CompanyTypeRoleAssigner } from "../components/company_type/role_assigner";
export { default as UserGroupFormV2 } from "../components/user_group/form_v2";
export { default as UserGroupListV2 } from "../components/user_group/list_v2";
export { default as UserGroupShowV2 } from "../components/user_group/show_v2";
export { default as UserFormV2 } from "../components/user/form_v2";
export { default as UserPageV2 } from "../components/user/user_view/user_page_v2";
export { default as UserProfileV2 } from "../components/user/profile_v2";
export { default as ProfitCenterList } from "../components/profit_center/list";
export { default as UsageTipList } from "../components/usage_tip/list";
export { default as UsageContactList } from "../components/usage_contact/list";
export { default as SystemSettingList } from "../components/system_setting/list";
export { default as AccessManagementNav } from "../components/user/access_management_nav";
export { default as OrganizationListV2 } from "../components/organization/list_v2";
export { default as CompanyShowV2 } from "../components/organization/organization_view";
export { default as NotFound } from "../components/not_found/not_found";
