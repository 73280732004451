import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header, { HeaderButton } from "@oriola-origo/core/lib/Header";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Page from "@oriola-origo/core/lib/Page";
import Box from "@oriola-origo/core/lib/Box";
import useTranslations, { TranslateFunction } from "@hooks/useTranslations";
import useRoutes from "@hooks/useRoutes";
import useTurbolinks from "@hooks/useTurbolinks";
import useUsers from "@services/user_management/hooks/useUsers";
import { CompanyType } from "@services/user_management/types";

import useSearchParams from "@hooks/useSearchParams";
import {
  idsToStrOrganizationIds,
  strIdsToOrganizationIds,
} from "@components/common/organization_filter/utils";
import { UserStatus, User } from "@services/user_management/types/users";
import DeleteConfirmation from "@components/common/delete_confirmation";
import {
  SortType,
  TableColumnSortDirection,
  TableColumnSortable,
  UsersTableActionMenuItem,
} from "@components/common/user_table";
import UserTableWithOrganizations, {
  UserTableWithOrganizationsProps,
} from "@components/common/user_table_with_organizations/user_table_with_organizations";

export interface UserListV2Props {
  company_types: CompanyType[];
  permissions: {
    may_add: boolean;
    may_edit: boolean;
    may_delete: boolean;
    may_passivate: boolean;
  };
}

const SORT_FIELD_TO_COLUMN_MAP: Record<TableColumnSortable, string> = {
  [TableColumnSortable.Name]: "last_name",
  [TableColumnSortable.Email]: "email_address",
};

const USERS_PER_FETCH = 50;

const searchParamsToFilters = (
  searchParams: URLSearchParams
): UserTableWithOrganizationsProps["filters"] => {
  const filtersType: UserTableWithOrganizationsProps["filters"] = {
    search: searchParams.get("search") ?? "",
  };

  if (searchParams.has("status")) {
    filtersType.status = searchParams.get("status") ?? "";
  }
  if (searchParams.has("organization_ids")) {
    filtersType.organizationIds = strIdsToOrganizationIds(
      (searchParams.get("organization_ids") ?? "").split(",")
    );
  }

  return filtersType;
};

const confirmationFactory =
  (t: TranslateFunction) => (localeKey: string, user: User) =>
    confirm(
      t(localeKey, {
        name: [user.last_name, user.first_name].join(" "),
      })
    );

export const handleDelete = async ({
  selectedUser,
  setIsDeleting,
  setUsers,
  setIsDeleteConfirmationOpen,
  setSelectedUser,
  deleteUser,
}) => {
  setIsDeleting(true);
  if (await deleteUser(selectedUser.id)) {
    setUsers((prevUsers) => prevUsers.filter((u) => u.id !== selectedUser.id));
  }
  setIsDeleting(false);
  setIsDeleteConfirmationOpen(false);
  setSelectedUser(null);
};

function UserListInternal({
  company_types: companyTypes,
  permissions,
}: Readonly<UserListV2Props>) {
  const { t } = useTranslations();
  const routes = useRoutes();
  const { navigate } = useTurbolinks();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isRePopulatingUsers, setIsRePopulatingUsers] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [userCounts, setUserCounts] = useState<
    null | UserTableWithOrganizationsProps["counts"]
  >(null);
  const { fetchUsers, isFetching, deleteUser, passivateUser } = useUsers();
  const [activePageIndex, setActivePageIndex] = useState<null | number>(null);
  const [activeSort, setActiveSort] = useState<SortType>({
    column: TableColumnSortable.Name,
    direction: TableColumnSortDirection.Asc,
  });
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const filters = useMemo(
    () => searchParamsToFilters(searchParams),
    [searchParams.toString()]
  );

  const fetchMoreUsers = useCallback(
    async (page = 1) => {
      const count = userCounts?.[filters.status] ?? userCounts?.total ?? 0;
      if (page > 1 && typeof count === "number" && count <= users.length) {
        return;
      }

      let organizationIds: string[] | undefined;
      if (Array.isArray(filters.organizationIds)) {
        organizationIds = idsToStrOrganizationIds(filters.organizationIds);
      }

      const res = await fetchUsers({
        page,
        perPage: USERS_PER_FETCH,
        sortField: SORT_FIELD_TO_COLUMN_MAP[activeSort.column],
        sortOrder: activeSort.direction,
        search: filters.search || undefined,
        statusFilter: filters.status || undefined,
        organizationIds,
      });

      if (res.succeed) {
        if (page === 1) {
          setUsers(res.users ?? []);
        } else {
          setUsers((prev) => [...prev, ...(res.users ?? [])]);
        }
        setActivePageIndex(page);
        if (page === 1) {
          setUserCounts(res.counts);
        }
        setIsRePopulatingUsers(false);
      }
    },
    [activePageIndex, activeSort, userCounts, fetchUsers, filters]
  );

  useEffect(() => {
    if (isFetching) {
      return;
    }
    setIsRePopulatingUsers(true);
    fetchMoreUsers(1);
  }, [activeSort, filters]);

  const confirmFromUser = confirmationFactory(t);

  const actionMenuItems: UsersTableActionMenuItem[] = useMemo(() => {
    const menuItems: UsersTableActionMenuItem[] = [];

    if (permissions.may_edit) {
      menuItems.push({
        label: t("edit_user"),
        icon: "edit",
        href: (user: User) => routes.userPath(user.id),
      });
    }

    if (permissions.may_passivate) {
      menuItems.push({
        label: t("passivate_user"),
        icon: "do_not_disturb",
        disabled: (user: User) => !user.active,
        onClick: async (user: User) => {
          if (confirmFromUser("confirm_passivate_user", user)) {
            await passivateUser(user.id);
            const newUsers = [...users];
            const userIndex = newUsers.findIndex((u) => u.id === user.id);
            if (userIndex >= 0) {
              newUsers[userIndex] = {
                ...newUsers[userIndex],
                active: false,
                status: UserStatus.Inactive,
              };
              setUsers(newUsers);
            }
            return true;
          }
          return false;
        },
      });
    }

    if (permissions.may_delete) {
      menuItems.push({
        label: t("delete_user"),
        icon: "delete",
        onClick: (user: User) => {
          setSelectedUser(user);
          setIsDeleteConfirmationOpen(true);
        },
      });
    }

    return menuItems;
  }, [users, permissions]);

  const headerButtons: HeaderButton[] = [];
  if (permissions.may_add) {
    headerButtons.push({
      text: t("invite_user"),
      icon: "person_add",
      variant: "contained",
      onClick: () => {
        navigate(routes.usersNewPath());
      },
    });
  }

  const handleFiltersChange = (
    newFilters: UserTableWithOrganizationsProps["filters"]
  ) => {
    const newSearchParams = new URLSearchParams();
    if (newFilters.search) {
      newSearchParams.set("search", newFilters.search);
    }
    if (newFilters.status) {
      newSearchParams.set("status", newFilters.status);
    }
    if (
      Array.isArray(newFilters.organizationIds) &&
      newFilters.organizationIds.length > 0
    ) {
      newSearchParams.set(
        "organization_ids",
        newFilters.organizationIds.join(",")
      );
    }

    setSearchParams(newSearchParams);
  };

  return (
    <Page
      header={<Header title={t("users")} buttons={headerButtons} />}
      mb={5}
      pt={1}
    >
      <Box mx={-4}>
        <UserTableWithOrganizations
          users={users}
          filters={filters}
          onFiltersChange={handleFiltersChange}
          onRequestMore={() => {
            if (activePageIndex !== null) {
              fetchMoreUsers(activePageIndex + 1);
            }
          }}
          counts={userCounts}
          companyTypes={companyTypes}
          isRePopulating={isRePopulatingUsers}
          isLoading={isFetching}
          sort={activeSort}
          onSortChange={setActiveSort}
          actionMenuItems={actionMenuItems}
        />
      </Box>
      {selectedUser && (
        <DeleteConfirmation
          title={t("delete_user")}
          content={t("confirm_delete_user", {
            name: `${selectedUser.last_name} ${selectedUser.first_name}`,
          })}
          open={isDeleteConfirmationOpen}
          isDeleting={isDeleting}
          onCancel={() => setIsDeleteConfirmationOpen(false)}
          onDelete={() =>
            handleDelete({
              selectedUser,
              setIsDeleting,
              setUsers,
              setIsDeleteConfirmationOpen,
              setSelectedUser,
              deleteUser,
            })
          }
        />
      )}
    </Page>
  );
}

function UserListV2(props: Readonly<UserListV2Props>) {
  return (
    <OriolaThemeProvider>
      <UserListInternal {...props} />
    </OriolaThemeProvider>
  );
}

export default UserListV2;
