import React from "react";
import Grid from "@oriola-origo/core/lib/Grid";
import { OrigoApplication } from "@services/user_management/types/application";
import { OrigoApplicationRole } from "@services/user_management/types/roles";
import { styled } from "@oriola-origo/core/lib/styles";
import Typography from "@oriola-origo/core/lib/Typography";
import Card from "@oriola-origo/core/lib/Card";
import CardHeader from "@oriola-origo/core/lib/CardHeader";
import useTranslations from "@hooks/useTranslations";
import CardContent from "@oriola-origo/core/lib/CardContent";
import CardActions from "@oriola-origo/core/lib/CardActions";
import Button from "@oriola-origo/core/lib/Button";

export interface RoleBlockProps {
  app: OrigoApplication;
  roles: OrigoApplicationRole[];
  editUrl: string;
  hasUserGroupManagePermissions: boolean;
}

const StyledCard = styled(Card)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  paddingBottom: 0,
});

const StyledCardContent = styled(CardContent)({ flexGrow: 1 });

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  paddingRight: theme.spacing(2),
}));

function RoleBlock({
  app,
  roles,
  editUrl,
  hasUserGroupManagePermissions,
}: Readonly<RoleBlockProps>) {
  const { language, t } = useTranslations();
  return (
    <Grid size={{ xs: 12, md: 6, lg: 3 }}>
      <StyledCard>
        <CardHeader title={app.title[language] ?? app.title.en} />
        <StyledCardContent>
          <ul>
            {roles.map((role) => (
              <Typography
                component="li"
                variant="body1"
                color="textSecondary"
                key={role.id}
              >
                {role.name}
              </Typography>
            ))}
          </ul>
        </StyledCardContent>
        {hasUserGroupManagePermissions && (
          <StyledCardActions>
            <Button color="secondary" href={editUrl} variant="text">
              {t("edit")}
            </Button>
          </StyledCardActions>
        )}
      </StyledCard>
    </Grid>
  );
}

export default RoleBlock;
