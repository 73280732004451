export enum TableColumnSortDirection {
  Asc = "asc",
  Desc = "desc",
}

export interface SortType<ColumnType> {
  column: ColumnType;
  direction: TableColumnSortDirection;
}

const percentageStringToDecimal = (percentageString: string) =>
  parseFloat(percentageString.replace("%", "")) / 100;

export const getColumnWidthFactory = <
  T extends Record<string, number | string>,
>(
  columnsMap: T
) => {
  const fixedWidthSum = Number(
    Object.values(columnsMap).reduce(
      (acc: number, width) => (typeof width === "number" ? acc + width : acc),
      0
    )
  );

  return (column: string, overallWidth: number) => {
    const columnWidth: number | string | undefined = columnsMap[column];
    if (typeof columnWidth === "string" && columnWidth.endsWith("%")) {
      const freeSpace = overallWidth - fixedWidthSum;
      return Math.floor(freeSpace * percentageStringToDecimal(columnWidth));
    }
    return columnWidth;
  };
};
