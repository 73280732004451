import React from 'react';
import { Table, TableRowActions } from '../common/table';

export default class List extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dashboards: props.dashboards
        };

        this.filter = this.filter.bind(this);
        this.deleteDashboard = this.deleteDashboard.bind(this);
    }

    render() {
        let columns = [
            {
                dataField: 'title',
                text: I18n.t('title'),
                sort: true,
                formatter: (val, row) => {
                    return <a href={Routes.dashboard_path({dashboard_id: row.id, from: 'admin'})}>{val}</a>
                }
            },
            {
                dataField: 'context',
                text: I18n.t('dashboard_context'),
                sort: true,
                formatter: (val, row) => {
                    return I18n.t(`dashboard_context_${row.context}`);
                }
            },
            {
                dataField: 'user_group_names',
                text: I18n.t('visibility'),
                sort: false,
                formatter: (val, row) => {
                    return <div>
                        {row.role_names.map(r => <div key={`r-${r}`}>
                            {r}<small className="text-muted ml-2"> | {I18n.t('role')}</small>
                        </div>).concat(row.user_group_names.map(g => <div key={`g-${g}`}>
                            {g}<small className="text-muted ml-2"> | {I18n.t('user_group')}</small>
                        </div>))}
                    </div>;
                }
            },
            {
                dataField: 'default',
                text: I18n.t('default'),
                sort: true,
                formatter: (val, row) => val ? <i className="fa fa-check"></i> : null
            },
            {
                dataField: 'published',
                text: I18n.t('published'),
                sort: true,
                formatter: (val, row) => val ? <i className="fa fa-check"></i> : null
            },
            {
                dataField: 'id',
                text: '',
                classes: 'row-actions',
                headerClasses: 'row-actions',
                formatter: (cell, row) => {
                    let actions = []

                    if (this.props.permissions.may_edit) {
                        actions.push(<a key="edit" href={Routes.dashboard_edit_path({ dashboard_id: row.id, from: 'list' })} data-dialog="true" data-title={I18n.t('edit_dashboard')}>
                            <i className="fa fa-pencil"></i> {I18n.t('edit')}
                        </a>)
                    }
                    if (this.props.permissions.may_delete) {
                        if (actions.length > 0) {
                            actions.push(<TableRowActions.Divider key="div" />)
                        }
                        actions.push(<a key="del" href={Routes.dashboard_delete_path({ dashboard_id: row.id })} onClick={this.deleteDashboard} data-id={row.id}><i className="oricon-bin"></i> {I18n.t('delete')}</a>)
                    }
                    return actions.length > 0 ? <TableRowActions.Menu>{actions}</TableRowActions.Menu> : null
                }
            }
        ];

        return <Table
            key={this.state.dashboards.length} // Forces update on delete
            actions={this.renderActions()}
            data={this.state.dashboards}
            columns={columns}
            pagination={false}
            defaultSorted={[{
                dataField: 'title',
                order: 'asc'
            }]}
            filterCallback={this.filter}
            preserveState={true} />;
    }

    renderActions() {
        let actions = [];

        if (this.props.permissions.may_add) {
            actions.push(<a key="add" href={Routes.dashboard_new_path()} data-dialog="true" data-title={I18n.t('add_dashboard')} className="btn btn-light">
                <i className="fa fa-plus text-highlight"></i> {I18n.t('add')}
            </a>);
        }
        return actions;
    }

    filter(row, filter, filterRexExp) {
        return [
            row.title,
            row.role_name,
            row.user_group_name
        ].join(' ').match(filterRexExp);
    }

    deleteDashboard(event) {
        let a = jQuery(event.currentTarget);
        let dashboard = this.state.dashboards.filter(d => d.id == a.data('id'))[0];

        if (dashboard && confirm(I18n.t('confirm_delete_dashboard', { title: dashboard.title }))) {
            jQuery.ajax({
                url: a.attr('href'),
                type: 'DELETE',
                success: () => {
                    this.setState({
                        dashboards: this.state.dashboards.filter(d => d.id != dashboard.id)
                    });
                }
            });
        }
        event.preventDefault();
    }
}