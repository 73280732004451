import React, { useContext, useEffect, useState } from "react";
import Dialog from "@oriola-origo/core/lib/Dialog";
import DialogTitle from "@oriola-origo/core/lib/DialogTitle";
import DialogContent from "@oriola-origo/core/lib/DialogContent";
import DialogActions from "@oriola-origo/core/lib/DialogActions";
import Typography from "@oriola-origo/core/lib/Typography";
import Button from "@oriola-origo/core/lib/Button";
import Box from "@oriola-origo/core/lib/Box";
import { styled } from "@oriola-origo/core/lib/styles";
import { SnackbarContext } from "@oriola-origo/core/lib/Snackbar/SnackbarProvider";
import useCreateAccessRequest from "./useCreateAccessRequest";
import SelectReportsPage from "./select_reports_dialog_page";
import { GetAccessCard } from "../types";

export interface ReportAccessDialogProps {
  open: boolean;
  getAccessCardsSettings: GetAccessCard[];
  selectedReportAccessId: string;
  onClose: (newPendingReportIds: string[]) => void;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: 500,
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

const BannerImage = styled("img")({ maxWidth: "100%" });

const BANNER_IMAGE_MAP = {
  1: "/additional_report_cards/getaccessdialog_banner_1.png",
  2: "/additional_report_cards/getaccessdialog_banner_2.png",
};

const PRIMARY_BUTTON_LOCALE_KEY = {
  0: "report_access_dialog_primary_button_continue",
  1: "report_access_dialog_primary_button_send_request",
  2: "report_access_dialog_primary_button_close",
};

enum DialogPage {
  LANDING = 0,
  SELECT_REPORTS = 1,
  FINISH = 2,
}

function ReportAccessDialog({
  open,
  getAccessCardsSettings,
  selectedReportAccessId,
  onClose,
}: Readonly<ReportAccessDialogProps>) {
  const [page, setPage] = useState<DialogPage>(DialogPage.LANDING);
  const snackbarContext = useContext(SnackbarContext);
  const [selectedReportIds, setSelectedReportIds] = useState<string[]>([]);
  const { createAccessRequest, isRequesting: isRequestingAccess } =
    useCreateAccessRequest();

  useEffect(() => {
    setSelectedReportIds([selectedReportAccessId]);
    setPage(0);
  }, [selectedReportAccessId]);

  if (!selectedReportAccessId) {
    return null;
  }

  const selectedAccessCardSettings = getAccessCardsSettings.find(
    (cardSettings) => cardSettings.id === selectedReportAccessId
  );

  const renderBannerImage = () => {
    const bannerImageSrc =
      page > DialogPage.LANDING
        ? BANNER_IMAGE_MAP[page]
        : selectedAccessCardSettings.bannerImageUrl;

    return <BannerImage src={bannerImageSrc} />;
  };

  const renderDialogContent = () => {
    if (page === DialogPage.LANDING) {
      return (
        <>
          <Typography variant="subtitle1" color="textPrimary">
            {I18n.t(selectedAccessCardSettings.title)}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {I18n.t(selectedAccessCardSettings.descriptionLong)}
          </Typography>
        </>
      );
    } else if (page === DialogPage.SELECT_REPORTS) {
      return (
        <SelectReportsPage
          getAccessCardsSettings={getAccessCardsSettings}
          selectedReportIds={selectedReportIds}
          onChangeSelectedReportIds={setSelectedReportIds}
        />
      );
    }

    return (
      <Box display="flex" justifyContent="center">
        <Box sx={{ maxWidth: 300 }}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            textAlign="center"
          >
            {I18n.t("report_access_dialog_finish_title")}
          </Typography>
          <Typography variant="body1" color="textSecondary" textAlign="center">
            {I18n.t("report_access_dialog_finish_description")}
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleClose = () => {
    onClose(page === DialogPage.FINISH ? [...selectedReportIds] : []);
    setSelectedReportIds([]);
  };

  const handlePrimaryButtonClick = async () => {
    if (page === DialogPage.SELECT_REPORTS) {
      if (isRequestingAccess) {
        return;
      }

      try {
        await createAccessRequest(selectedReportIds);
      } catch (e) {
        snackbarContext.setSnackMessage(
          I18n.t("report_access_dialog_generic_error"),
          "error",
          true
        );
        return handleClose();
      }
    } else if (page === DialogPage.FINISH) {
      return handleClose();
    }

    setPage(page + 1);
  };

  const isSecondaryButtonVisible = page < DialogPage.FINISH;
  const isPrimaryButtonDisabled =
    page === DialogPage.SELECT_REPORTS && selectedReportIds.length === 0;
  const isSecondaryButtonDisabled = isRequestingAccess;

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle onCloseButtonClick={handleClose}>
        {I18n.t("report_access_dialog_title")}
      </DialogTitle>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: 242 }}
      >
        {renderBannerImage()}
      </Box>
      <StyledDialogContent sx={{ height: 200 }}>
        {renderDialogContent()}
      </StyledDialogContent>
      <DialogActions>
        {isSecondaryButtonVisible && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            disabled={isSecondaryButtonDisabled}
          >
            {I18n.t("report_access_dialog_secondary_button")}
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          disabled={isPrimaryButtonDisabled}
          isLoading={isRequestingAccess}
          onClick={handlePrimaryButtonClick}
        >
          {I18n.t(PRIMARY_BUTTON_LOCALE_KEY[page])}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default ReportAccessDialog;
