import React, { useState } from "react";
import Dialog from "@oriola-origo/core/lib/Dialog";
import DialogTitle from "@oriola-origo/core/lib/DialogTitle";
import DialogActions from "@oriola-origo/core/lib/DialogActions";
import DialogContent from "@oriola-origo/core/lib/DialogContent";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Typography from "@oriola-origo/core/lib/Typography";
import Button from "@oriola-origo/core/lib/Button";
import Box from "@oriola-origo/core/lib/Box";
import useTranslations from "../../../hooks/useTranslations";
import { confirmDeleteClick } from "./deleteUserGroup";

function DeleteConfirmationDialog({
  openDialog,
  selectedUserGroup,
  csrfToken,
  deletedUserGroupIds,
  setOpenDialog,
  setDeletedUserGroupIds
}) {
  const { t } = useTranslations();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Box>
      <Dialog
        open={openDialog}
        disableEscapeKeyDown={false}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle onCloseButtonClick={() => setOpenDialog(false)}>
          {t("delete_user_group")}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" mb={3}>
            <FontIcon sx={{ fontSize: 150 }}>group_remove</FontIcon>
          </Box>
          <Typography color="text.secondary">
            {t("delete_user_group_confirmation_1")}<b> {selectedUserGroup.name} </b>{t("delete_user_group_confirmation_2")}
            <ul style={{ marginBottom: 0 }}>
              <br/>
              <li>{t("delete_user_group_hint_1")}</li>
              <li>{t("delete_user_group_hint_2")}</li>
            </ul>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpenDialog(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              confirmDeleteClick(
                selectedUserGroup.id,
                csrfToken,
                setOpenDialog,
                deletedUserGroupIds,
                setDeletedUserGroupIds,
                setIsLoading,
              );
            }}
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DeleteConfirmationDialog;
