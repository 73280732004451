import React, { useState } from "react";
import useTranslations from "@hooks/useTranslations";
import Box from "@oriola-origo/core/lib/Box";
import Typography from "@oriola-origo/core/lib/Typography";
import Chip from "@oriola-origo/core/lib/Chip";
import IconButton from "@oriola-origo/core/lib/IconButton";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Menu from "@oriola-origo/core/lib/Menu";
import MenuList from "@oriola-origo/core/lib/MenuList";
import MenuItem from "@oriola-origo/core/lib/MenuItem";
import ListItemIcon from "@oriola-origo/core/lib/ListItemIcon";
import ListItemText from "@oriola-origo/core/lib/ListItemText";
import Autocomplete from "@oriola-origo/core/lib/Autocomplete/Autocomplete";
import Divider from "@oriola-origo/core/lib/Divider";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  OrganizationInformationProps,
  OrganizationDetailsProps,
} from "../../types";
import { useSetDefaultOrganization } from "../../utils";
import {
  removeOrganization,
  removeSubOrganizations,
  selectOrganizationsSortedByHierarchy,
  updateProfessionalTitle,
  updateProfitCenters,
  updateSccProfitCenters,
} from "@store/states/inviteUserSlice";
import ProfitCenterAutocomplete from "./profit_center_autocomplete";
import { ProfessionalTitle } from "@services/user_management/types/professional_titles";
import { ProfitCenter } from "@services/user_management/types/profit_centers";
import { IOrganizationSelection } from "@components/user/types";
import RemoveConfirmationDialog from "../../organisation_information/components/remove_confirmation_dialog";

export const onRemoveOrganization = ({
  dispatch,
  isChecked,
  organizationId,
  setOpenConfirmationDialog,
  setAnchorEl,
}) => {
  dispatch(
    removeOrganization({
      organizationId,
    })
  );
  isChecked &&
    dispatch(
      removeSubOrganizations({
        parentOrganizationId: organizationId,
      })
    );
  setOpenConfirmationDialog(false);
  setAnchorEl(null);
};

function OrganizationDetails(props: Readonly<OrganizationDetailsProps>) {
  const {
    organization,
    allSelectedOrganizationIds,
    defaultOrganizationId,
    professional_titles,
    profit_centers,
    scc_profit_center_allowed_organization_ids,
    hasChildren,
  } = props;
  const setDefaultOrganization = useSetDefaultOrganization();
  const dispatch = useAppDispatch();
  const { t } = useTranslations();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const shouldPadHierarchyLevel = (
    organization.hierarchy_parent_company_ids ?? []
  ).every((id) => allSelectedOrganizationIds.includes(id));
  const hierarchyMultiplier = organization.hierarchy_level ?? 0;
  const padFromLeft = shouldPadHierarchyLevel ? hierarchyMultiplier : 0;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  return (
    <Box pl={5 * padFromLeft}>
      <Box display="flex" justifyContent="space-between" gap={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h6" color="secondary">
            {organization.api_id} - {organization.name}
          </Typography>
          {organization.id === defaultOrganizationId && (
            <Chip
              color="primary"
              label={t("default_organization")}
              size="small"
            />
          )}
        </Box>
        <IconButton
          data-testid="iconButtonMenu"
          size="large"
          onClick={handleClick}
          color="secondary"
        >
          <FontIcon>more_vert</FontIcon>
        </IconButton>
        <Menu
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <Box>
            <MenuList>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setDefaultOrganization(organization.id);
                }}
              >
                <ListItemIcon>
                  <FontIcon>star</FontIcon>
                </ListItemIcon>
                <ListItemText>{t("set_as_default_organization")}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenConfirmationDialog(true);
                }}
              >
                <ListItemIcon>
                  <FontIcon>delete</FontIcon>
                </ListItemIcon>
                <ListItemText>{t("remove_organization")}</ListItemText>
              </MenuItem>
            </MenuList>
            {openConfirmationDialog && (
              <RemoveConfirmationDialog
                organization={organization as any}
                onClose={() => {
                  setAnchorEl(null);
                  setOpenConfirmationDialog(false);
                }}
                onSave={(organizationId, isChecked) =>
                  onRemoveOrganization({
                    dispatch,
                    isChecked,
                    organizationId,
                    setOpenConfirmationDialog,
                    setAnchorEl,
                  })
                }
                hasChildren={hasChildren}
              />
            )}
          </Box>
        </Menu>
      </Box>
      <Box maxWidth="700px" mt={2} display="grid" gap={2}>
        <ProfitCenterAutocomplete
          label={t("profit_centers")}
          options={profit_centers}
          defaultValue={organization["profitCenters"]}
          onChange={(_, newValue) => {
            dispatch(
              updateProfitCenters({
                organizationId: organization.id,
                profitCenters: newValue,
              })
            );
          }}
        />
        {scc_profit_center_allowed_organization_ids.includes(
          organization["value"] || organization["id"]
        ) && (
          <ProfitCenterAutocomplete
            label={t("scc_profit_centers")}
            options={profit_centers}
            defaultValue={organization["sccProfitCenters"]}
            onChange={(_, newValue) => {
              dispatch(
                updateSccProfitCenters({
                  organizationId: organization.id,
                  sccProfitCenters: newValue,
                })
              );
            }}
          />
        )}
        <Autocomplete
          autoComplete="cc-name"
          id="title-select"
          required={true}
          options={professional_titles}
          label={t("professional_title")}
          autoHighlight
          onChange={(_, newValue: ProfessionalTitle) =>
            dispatch(
              updateProfessionalTitle({
                organizationId: organization.id,
                professionalTitle: newValue,
              })
            )
          }
          defaultValue={organization["professionalTitle"]}
          getOptionLabel={(option: ProfessionalTitle) => option.name}
        />
      </Box>
    </Box>
  );
}

export function NoOrganizationDetails() {
  const { t } = useTranslations();
  return (
    <Box
      minHeight={200}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="body1" color="secondaryDarkGray">
        {t("no_organization_added_to_user")}.
      </Typography>
    </Box>
  );
}
function renderOrganizationDetails(
  organizations: IOrganizationSelection[],
  allSelectedOrganizationIds: number[],
  defaultOrganizationId: number,
  professional_titles: ProfessionalTitle[],
  profit_centers: ProfitCenter[],
  scc_profit_center_allowed_organization_ids: number[]
) {
  return (
    <Box>
      {organizations.map((organization, index) => (
        <Box key={organization.id}>
          <OrganizationDetails
            organization={organization}
            allSelectedOrganizationIds={allSelectedOrganizationIds}
            defaultOrganizationId={defaultOrganizationId}
            professional_titles={professional_titles}
            profit_centers={profit_centers}
            scc_profit_center_allowed_organization_ids={
              scc_profit_center_allowed_organization_ids
            }
            hasChildren={
              !!organizations.filter((org) =>
                org.hierarchy_parent_company_ids?.includes(organization.id)
              ).length
            }
          />
          <Divider sx={{ my: 3 }} />
        </Box>
      ))}
    </Box>
  );
}

export interface CardContentProps
  extends Omit<OrganizationInformationProps, "defaultProfessionalTitleId"> {}

function CardContent(props: Readonly<CardContentProps>) {
  const {
    professionalTitles,
    profitCenters,
    sccProfitCenterAllowedOrganizationIds,
  } = props;
  const { defaultOrganizationId } = useAppSelector((state) => state.inviteUser);
  const organizations = useAppSelector(selectOrganizationsSortedByHierarchy);
  const allSelectedOrganizationIds = organizations.map((org) => org.id);

  return (
    <>
      {organizations.length ? (
        renderOrganizationDetails(
          organizations,
          allSelectedOrganizationIds,
          defaultOrganizationId,
          professionalTitles,
          profitCenters,
          sccProfitCenterAllowedOrganizationIds
        )
      ) : (
        <NoOrganizationDetails />
      )}
    </>
  );
}
export default CardContent;
