import React from 'react';
import _ from 'underscore';
import Loading from '../../common/loading';
import Select from '../../common/select';
import SelectCheckboxes from '../../common/select_checkboxes';
import moment from 'moment-timezone';
import { ThemeProvider } from '@material-ui/core';
import OriolaTheme from '@oriola-origo/origo-ui-theme';
import { MuiStartEndDatePicker } from '@oriola-origo/origo-ui-mui-datepicker';

const styles = {
    header: {
        margin: 0,
        color: '#585353',
        fontWeight: 'bold',
        fontSize: '115%'
    },
    tab: {
        color: 'inherit',
        textDecoration: 'none',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    tabActive: {
        color: '#2B3E5B',
        textDecoration: 'none',
        borderBottom: '2px solid #ffa000',
        padding: '0.25rem 0',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    }
};

export class StaticReports extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            settings: props.settings,
            activeTab: 'latest',
            reports: [],
            totalCount: 0,
            filteredCount: 0,
            loading: true,
            fetchingReports: false,
            permissions: {},
            filterOptions: null,
            filters: {
                startDate: null,
                endDate: null
            },
            page: 0,
            pageSize: 10,
            pageCount: 1,
            isNarrowView: false
        };

        this.rootRef = React.createRef();
    }

    render() {
        if (this.props.mode == Origo.Widget.Mode.SETTINGS) {
            return this.renderSettings();
        } else {
            return this.renderView();
        }
    }

    renderView() {
        const title = this.getTitle(this.props.language);

        return <div ref={this.rootRef} className="p-3">
            {title && <h1 style={styles.header} className="mb-4">{title}</h1>}
            <div className="row mb-4">
                {['latest', 'history'].map(tab => <div key={tab} className="col-auto">
                    <a href="#" onClick={e => { this.selectTab(tab); e.preventDefault(); }} style={tab == this.state.activeTab ? styles.tabActive : styles.tab}>
                        {this.getText(tab)}
                    </a>
                </div>)}
            </div>
            {this.state.loading ? <Loading /> : <div>
                {this.state.activeTab == 'history' && this.renderHistoryFilters()}
                {this.state.reports.length == 0 ? (
                    <div className="alert alert-info">
                        {this.getText('no_reports_message')}
                    </div>
                ) : (
                    this.state.fetchingReports ?
                        <Loading /> :
                        <table className="table">
                            <thead>
                                <tr>
                                    <th><small className="text-muted">{this.getText('report')}</small></th>
                                    <th><small className="text-muted">{this.getText('report_created')}</small></th>
                                    <th><small className="text-muted">{this.getText('download')}</small></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.reports.map((report, idx) => {
                                    const datePeriod = String(report.tags.DatePeriod || '');
                                    let formattedPeriod = '';
                                    const url = this.buildReportDownloadUrl(report);

                                    if (report.tags.PeriodType == 'Year') {
                                        formattedPeriod = datePeriod.slice(0, 4);
                                    } else if (report.tags.PeriodType == 'Month') {
                                        formattedPeriod = `${datePeriod.slice(0, 4)}-${datePeriod.slice(4, 6)}`;
                                    } else if (report.tags.PeriodType == 'Day') {
                                        formattedPeriod = `${datePeriod.slice(0, 4)}-${datePeriod.slice(4, 6)}-${datePeriod.slice(6, 8)}`;
                                    }

                                    return <tr key={`${report.fileId}-${idx}`}>
                                        <td>
                                            <a href={url} download={report.fileName} type={report.mediaType}>
                                                {this.getText(`report_name_${report.tags.ReportName}`, { default: report.tags.ReportName })}&nbsp;
                                                {report.tags.CustomerId || report.tags.ProfitCenterID}&nbsp;
                                                {formattedPeriod}
                                            </a>
                                        </td>
                                        <td>{moment.unix(report.tags.ReportCreated).format('YYYY-MM-DD HH:mm')}</td>
                                        <td>
                                            <a href={url} download={report.fileName} type={report.mediaType}>
                                                <i className="oricon-export"></i>
                                            </a>
                                        </td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                )}
                {this.state.activeTab == 'history' && this.state.pageCount > 1 && <nav className="clearfix">
                    <ul className="pagination float-right">
                        <li className={`page-item${this.state.page > 0 ? '' : ' disabled'}`}>
                            <a className="page-link" href="#" onClick={e => { this.updatePage(this.state.page - 1); e.preventDefault(); }}>
                                {this.getText('previous_page')}
                            </a>
                        </li>
                        <li className="page-item disabled">
                            <span className="page-link">{this.state.page + 1} / {this.state.pageCount}</span>
                        </li>
                        <li className={`page-item${this.state.page < this.state.pageCount - 1 ? '' : ' disabled'}`}>
                            <a className="page-link" href="#" onClick={e => { this.updatePage(this.state.page + 1); e.preventDefault(); }}>
                                {this.getText('next_page')}
                            </a>
                        </li>
                    </ul>
                </nav>}
            </div>}
        </div>;
    }

    renderSettings() {
        const reportNameOptions = (this.state.filterOptions || {}).report_names || [];

        return <form  ref={this.rootRef}className="settings p-3">
            <div className="form-group">
                <label>{I18n.t('title')}</label>
                {Origo.AVAILABLE_LANGUAGES.map(lang => <div key={lang} className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" style={{width: '2.7rem'}}>{lang.toUpperCase()}</span>
                    </div>
                    <input type="text" name={`title_${lang}`} className="form-control" value={this.getTitle(lang)} onChange={e => this.updateSettings(`title_${lang}`, e.currentTarget.value)} />
                </div>)}
            </div>
            <div className="form-group" key={this.state.filterOptions ? 'loaded' : 'loading'}>
                <label>{this.getText('report_types')}</label>
                <SelectCheckboxes
                    placeholder={this.getText('select_placeholder')}
                    isMulti={true}
                    defaultValue={reportNameOptions.filter(o => (this.state.settings.reportNames || []).indexOf(o.value) !== -1)}
                    options={this.formatReportNameOptions(reportNameOptions)}
                    onChange={value => this.updateSettings('reportNames', (value || []).map(v => v.value))}
                />
            </div>
        </form>;
    }

    renderHistoryFilters() {
        const reportNameOptions = ((this.state.filterOptions || {}).report_names || []).filter(o => (this.state.settings.reportNames || []).indexOf(o.value) !== -1);

        return <div className="mb-4">
            <div className="row">
                <div className={`mb-3 col-12${this.state.isNarrowView ? '' : ' col-lg-4'}`}>
                    <SelectCheckboxes
                        placeholder={this.getText('select_placeholder_show_all')}
                        isMulti={true}
                        defaultValue={this.state.filters.reportNames || []}
                        options={this.formatReportNameOptions(reportNameOptions)}
                        onChange={value => this.updateFilters({ reportNames: value || [] })}
                    />
                </div>
                <div className={`mb-3 col-12${this.state.isNarrowView ? '' : ' col-md-6 col-lg-auto'}`}>
                    <ThemeProvider theme={OriolaTheme}>
                        <MuiStartEndDatePicker
                            maxWidth="24.5rem"
                            startDateValue={this.state.filters.startDate}
                            endDateValue={this.state.filters.endDate}
                            onDatesSelected={this.updateDateRange}
                            startDateProps={{
                                locale: I18n.locale,
                                placeholder: I18n.t('date_format_placeholder')
                            }}
                            endDateProps={{
                                locale: I18n.locale,
                                placeholder: I18n.t('date_format_placeholder')
                            }}
                        />
                    </ThemeProvider>
                </div>
                <div className={`mb-3 col-12 pt-0${this.state.isNarrowView ? '' : ' col-md-6 col-lg-auto pt-md-2'}`}>
                    {this.getText('showing_count', {count: this.state.filteredCount, total: this.state.totalCount})}
                </div>
            </div>
            {this.state.permissions.maySelectCustomers &&
                <div className="row">
                    <div className={`col-12${this.state.isNarrowView ? '' : ' col-lg-4'}`}>
                        <Select
                            placeholder={this.getText('select_customers_placeholder')}
                            isMulti={true}
                            defaultValue={this.state.filters.customers || []}
                            loadOptions={this.loadCompanyOptions}
                            onChange={value => this.updateFilters({ customers: value || [] })}
                            formatOptionLabel={(option, context) => {
                                if (context === 'value') {
                                    return option.label;
                                }
                                return <div style={{ paddingLeft: ((option.hierarchy_level || 0) * 2) + 'rem' }}>
                                    {option.label}
                                </div>;
                            }}
                        />
                    </div>
                </div>
            }
        </div>
    }

    componentDidMount() {
        if (jQuery(this.rootRef.current).outerWidth() < 820) {
            this.setState({isNarrowView: true});
        }

        if (this.props.mode == Origo.Widget.Mode.SETTINGS) {
            this.fetchFilterOptions();
        } else {
            this.selectTab('latest');
        }
    }

    selectTab = (tab) => {
        this.setState({activeTab: tab, loading: true}, () => {
            if (tab == 'latest') {
                this.fetchLatestReports();
            } else {
                this.fetchFilterOptions(this.fetchReports);
            }
        });
    }

    updateDateRange = (startDate, endDate) => {
        this.updateFilters({
            startDate: startDate,
            endDate: endDate,
        });
    }

    updateFilters = (filters) => {
        this.setState(prevState => {
            let newState = Object.assign({}, prevState);
            newState.filters = Object.assign(newState.filters || {}, filters);
            newState.page = 0;
            return newState;
        }, this.fetchReports);
    }

    updatePage = (page) => {
        if (page >= 0 && page < this.state.pageCount) {
            this.setState({ page: page }, this.fetchReports);
        }
    }

    formatReportNameOptions(reportNames) {
        return [
            {
                label: this.getText('reports'),
                options: reportNames || []
            }
        ];
    }

    fetchLatestReports() {
        jQuery.getJSON(Routes.dashboard_widget_data_path({
            widget: 'StaticReports',
            method: 'latest',
            organization_id: this.props.organizationId,
            report_names: this.state.settings.reportNames
        }), (data) => {
            this.setState({
                reports: data,
                loading: false
            });
        });
    }

    fetchReports = () => {
        this.setState({ fetchingReports: true }, () => {
            jQuery.getJSON(Routes.dashboard_widget_data_path({
                widget: 'StaticReports',
                method: 'list',
                organization_id: this.props.organizationId,
                filters: this.formatFiltersForFetch(),
                page: this.state.page,
                page_size: this.state.pageSize
            }), (data) => {
                this.setState({
                    reports: data.items,
                    pageCount: Math.ceil(data.overallCount / this.state.pageSize),
                    filteredCount: data.overallCount,
                    totalCount: data.unfilteredCount,
                    loading: false
                });
            }).always(() => {
                this.setState({ fetchingReports: false });
            });
        });
    }

    fetchFilterOptions(callback) {
        if (!this.state.filterOptions) {
            jQuery.getJSON(Routes.dashboard_widget_data_path({
                widget: 'StaticReports',
                method: 'filter_options',
                organization_id: this.props.organizationId
            }), (data) => {
                data.filterOptions.report_names = (data.filterOptions.report_names || []).map(o => (
                    Object.assign({...o}, { label: this.getText(`report_name_${o.label}`, { default: o.label }) })
                ));

                this.setState({
                    permissions: data.permissions,
                    filterOptions: data.filterOptions
                }, callback);
            });
        } else {
            callback && callback();
        }
    }

    updateSettings(attr, value) {
        this.setState(prevState => {
            let settings = prevState.settings;
            settings[attr] = value;
            return {settings: settings};
        }, () => {
            this.props.updateSettings(prevSettings => {
                return this.state.settings;
            });
        });
    }

    formatFiltersForFetch() {
        const filters = {
            report_names: (this.state.filters.reportNames || []).length > 0 ?
                this.state.filters.reportNames.map(r => r.value) :
                this.state.settings.reportNames,
            start_date: this.state.filters.startDate ? this.state.filters.startDate.format(Formats.DATE) : '',
            end_date: this.state.filters.endDate ? this.state.filters.endDate.format(Formats.DATE) : ''
        };

        if (this.state.permissions.maySelectCustomers && (this.state.filters.customers || []).length > 0) {
            filters.customer_numbers = this.state.filters.customers.map(c => c.value);
        }

        return filters;
    }

    getText = (key, vars) => {
        return I18n.t(key, Object.assign({scope: 'static_reports', locale: this.props.language}, vars || {}));
    }

    getTitle = (lang) => {
        return typeof this.state.settings[`title_${lang}`] == 'string' ? this.state.settings[`title_${lang}`] : this.state.settings[`title_${I18n.defaultLocale}`] || I18n.t(`internal_widget_static_reports`);
    }

    formatCompanyOptions = (companies) => {
        return companies.map(c => ({ value: c.api_id, label: [c.api_id, c.name].join(' - '), hierarchy_level: c.hierarchy_level }));
    }

    loadCompanyOptions = _.throttle((inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        } else {
            jQuery.get(Routes.dashboard_widget_data_path({
                widget: 'StaticReports',
                method: 'search_customers',
                query: inputValue
            }), (results) => {
                callback(this.formatCompanyOptions(results));
            });
        }
    }, 500)

    buildReportDownloadUrl = (report) => {
        const urlArgs = {
            widget: 'StaticReports',
            method: 'download',
            report_created: report.tags.ReportCreated,
            organization_id: this.props.organizationId,
            file_id: report.fileId
        };

        const filters =  _.pick(this.formatFiltersForFetch(), ['customer_numbers']);
        if (!_.isEmpty(filters)) {
            urlArgs.filters = filters;
        }

        return Routes.dashboard_widget_data_path(urlArgs);
    }
}
