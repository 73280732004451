import { useCallback, useMemo } from "react";
import useFetch from "@hooks/useFetch";
import useRoutes from "@hooks/useRoutes";
import {
  buildUserListResult,
  FetchUsersResult,
  FetchUserApiOpts,
  mapFetchUserApiOptsToRouteOpts,
} from "./utils/users";

function useUserGroups() {
  const { doFetch, isFetching } = useFetch();
  const routes = useRoutes();
  const fetchUsers = useCallback(
    async (
      userGroupId: number,
      opts: FetchUserApiOpts = {}
    ): Promise<FetchUsersResult> => {
      const response = await doFetch(
        routes.userGroupMembers(
          userGroupId,
          mapFetchUserApiOptsToRouteOpts(opts)
        )
      );

      if (!response.ok) {
        return {
          succeed: false,
          users: [],
          counts: {
            active: 0,
            inactive: 0,
            invited: 0,
            total: 0,
          },
        };
      }

      const { rows, status_counts } = await response.json();
      return {
        succeed: true,
        ...buildUserListResult(rows, status_counts),
      };
    },
    [routes]
  );

  return useMemo(() => ({ fetchUsers, isFetching }), [fetchUsers, isFetching]);
}

export default useUserGroups;
