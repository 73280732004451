import React, { useState } from "react";
import Box, { BoxProps } from "@oriola-origo/core/lib/Box";
import { styled } from "@oriola-origo/core/lib/styles";
import Typography from "@oriola-origo/core/lib/Typography";

import { DashboardWidget } from "./dashboard";
import { reportDashboardMapping } from "./utils";
import { IWidgetProps } from "./widgets/base_widget";
import NewStaticReports from "./widgets/static_reports_v2/new_static_reports";
import NewReportLinkWidgets from "./widgets/report_link_v2/new_report_link_container";
import ReportAccessCard from "./request_report_access/report_access_card/report_access_card";
import ReportAccessDialog from "./request_report_access/report_access_dialog/report_access_dialog";
import {
  GetAccessCard,
  ReportAccessRequestStatus,
} from "./request_report_access/types";

const NAVIGATION_PANE_HEIGHT_MOBILE = 126;
// For now a temp. fix to make navigation pane visible on mobile
const PADDING_BOTTOM_MOBILE = 100;

interface RootProps extends BoxProps {
  isMobileScreen?: boolean;
}

export interface INewReportPageContentProps {
  dashboard: {
    title: string;
    layout: string;
  };
  get_access_cards?: GetAccessCard[];
  widgets: IWidgetProps<any>[];
  dynamicWidgetProps: {
    update: (id: string, settings: any) => void;
  };
  isMobileScreen?: boolean;
}

const Root = styled(Box)<RootProps>(({ theme, isMobileScreen }) => ({
  flexGrow: 2,
  width: "95%",
  margin: `${theme.spacing(2)} auto`,
  maxWidth: "1920px",
  padding: `0 ${theme.spacing(2)}`,
  height: `calc(100vh - ${NAVIGATION_PANE_HEIGHT_MOBILE}px)`,
  overflow: "auto",
  paddingBottom: isMobileScreen ? PADDING_BOTTOM_MOBILE : 0,
}));

const MainTitle = styled(Box)(({ theme }) => ({
  height: theme.spacing(9),
  padding: theme.spacing(2, 4),
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.secondary.lightest}`,
}));

const DynamicWidgetContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3, 4),
  marginBottom: theme.spacing(4),
}));

const AccessCardsWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 4),
  "& > h5": { marginBottom: theme.spacing(2) },
}));

const AccessCardsMainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: theme.spacing(3),
}));

function NewReportPageContent(props: Readonly<INewReportPageContentProps>) {
  const { dashboard, get_access_cards, isMobileScreen } = props;

  const initialReportAccessStatuses: Record<string, ReportAccessRequestStatus> =
    {};
  get_access_cards?.forEach((card) => {
    initialReportAccessStatuses[card.id] = card.status;
  });

  const [reportAccessStatuses, setReportAccessStatuses] = useState<
    Record<string, ReportAccessRequestStatus>
  >(initialReportAccessStatuses);
  const [selectedReportAccessId, setSelectedReportAccessId] = useState(null);

  // Get Report link widgets
  const reportLinkWidgets = props.widgets.filter(
    (widget) => widget.widget_identifier === "ReportLink"
  );
  const staticTitleId = reportDashboardMapping[dashboard.title]?.id;

  // Get static report widgets
  const staticReportWidgets = props.widgets.filter(
    (widget) => widget.widget_identifier === "StaticReports"
  );
  const dynamicWidgets = props.widgets.filter(
    (widget) =>
      !["ReportLink", "StaticReports"].includes(widget.widget_identifier)
  );

  const accessCardsToRender = (get_access_cards || [])
    .filter((card) => card.status !== ReportAccessRequestStatus.COMPLETED)
    .map((card) => ({
      ...card,
      status: reportAccessStatuses[card.id],
    }));

  const handleDialogClose = (newPendingReportIds: string[]) => {
    if (newPendingReportIds.length > 0) {
      const statuses = { ...reportAccessStatuses };
      newPendingReportIds.forEach((id) => {
        statuses[id] = ReportAccessRequestStatus.PENDING;
      });
      setReportAccessStatuses(statuses);
    }
    setSelectedReportAccessId(null);
  };

  return (
    <Root isMobileScreen={isMobileScreen}>
      <Box>
        <MainTitle>
          <Typography variant="h4">
            {staticTitleId ? I18n.t(staticTitleId) : dashboard.title}
          </Typography>
        </MainTitle>
        {reportLinkWidgets.length !== 0 && (
          <NewReportLinkWidgets reportLinkWidgets={reportLinkWidgets} />
        )}

        {staticReportWidgets.length !== 0 && (
          <NewStaticReports staticReportsWidgets={staticReportWidgets} />
        )}

        {dynamicWidgets.map((widget) => (
          <DynamicWidgetContainer key={widget.id}>
            <DashboardWidget
              identifier={widget.widget_identifier}
              instanceId={widget.id}
              mode={widget.mode || Origo.Widget.Mode.VIEW}
              viewSize={Origo.Widget.Size.DEFAULT}
              language={I18n.locale}
              organizationId={widget.organizationId}
              settings={widget.settings || {}}
              updateSettings={(settings) =>
                props.dynamicWidgetProps.update(widget.id, settings)
              }
              accessToken={widget.accessToken}
              idToken={widget.idToken}
              isAutoHeight={true}
            />
          </DynamicWidgetContainer>
        ))}
        {dashboard.layout === "sidebar_one_column_primary" &&
          accessCardsToRender.length > 0 && (
            <AccessCardsWrapper>
              <Typography variant="h5">
                {I18n.t("report_access_main_title")}
              </Typography>
              <AccessCardsMainContainer>
                {accessCardsToRender.map((card) => (
                  <ReportAccessCard
                    key={card.id}
                    id={card.id}
                    title={card.title}
                    description={card.descriptionShort}
                    imageUrl={card.imageUrl}
                    status={card.status}
                    onClickRequestAccess={(id) => setSelectedReportAccessId(id)}
                  />
                ))}
              </AccessCardsMainContainer>
              <ReportAccessDialog
                selectedReportAccessId={selectedReportAccessId}
                getAccessCardsSettings={accessCardsToRender}
                open={!!selectedReportAccessId}
                onClose={handleDialogClose}
              />
            </AccessCardsWrapper>
          )}
      </Box>
    </Root>
  );
}

export default NewReportPageContent;
