import React, { useEffect, useState } from "react";
import Box from "@oriola-origo/core/lib/Box";
import FileUpload, {
  FileUploadStatus,
  FileUploadTexts,
} from "@oriola-origo/core/lib/FileUpload";

import useTranslations from "@hooks/useTranslations";
import { Organization } from "@services/user_management/types";
import useOrganization from "@services/user_management/hooks/useOrganization";
import useSnackbar from "@hooks/useSnackbar";
import { FileInputTexts } from "@oriola-origo/core/lib/FileInput";
import useRoutes from "@hooks/useRoutes";

export interface LogoManagerProps {
  organization: Organization;
  onSave: (property: string, value: string | object) => void | Promise<void>;
  isUpdating: boolean;
}

const MAX_FILE_SIZE = 3072000;

type statusUpdateEvent = {
  id: string;
  status: "complete" | "failed";
};

function LogoManager({ organization, onSave }: Readonly<LogoManagerProps>) {
  const { t, language } = useTranslations();
  const [uploadFiles, setUploadFiles] = useState<FileUploadStatus[]>([]);
  const { uploadLogo } = useOrganization();
  const { setSnackMessage } = useSnackbar();
  const [statusUpdate, setStatusUpdate] = useState<statusUpdateEvent | null>();
  const routes = useRoutes();

  const updateItemStatus = (id: string, status: "complete" | "failed") => {
    const item = uploadFiles.filter((file) => file.id == id);
    if (item && item.length === 1) {
      const newItem = { ...item[0], status: status };
      const otherItems = uploadFiles.filter((item) => item.id !== id);
      const updated: FileUploadStatus[] = [...otherItems, newItem];
      setUploadFiles(updated);
    }
  };

  useEffect(() => {
    if (statusUpdate) {
      const { id, status } = statusUpdate;
      updateItemStatus(id, status);
    }
  }, [statusUpdate]);

  const renderLogo = () => {
    if (organization?.logo) {
      // name may be in name or file_name - depending if its just uploaded or not
      const name = organization?.logo?.name || organization?.logo?.file_name;
      return (
        <Box
          display="flex"
          sx={{
            justifyContent: "center",
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          <img
            alt="logo"
            title={name}
            style={{ width: "95%" }}
            src={`${routes.companyLogoPath(organization.api_id, "thumb")}?${new Date().getTime()}`}
          />
        </Box>
      );
    }
  };

  const handleUploadLogo = async (id: string, file: File) => {
    const res = await uploadLogo(id, file);
    if (res.succeed) {
      setStatusUpdate({ id: id, status: "complete" });
      onSave("logo", {
        upload_id: id,
        file_name: file.name,
      });
    } else {
      setSnackMessage(t("upload_failed"), "error");
      setStatusUpdate({ id: id, status: "failed" });
    }
  };

  const handleFileAdded = (files: FileUploadStatus[]) => {
    const newFiles: FileUploadStatus[] = [...uploadFiles, ...files];
    setUploadFiles(newFiles);
    if (files.length === 1 && files[0].status === "loading") {
      handleUploadLogo(files[0].id, files[0].file);
    }
  };

  const handleFileRemoved = (id: string) => {
    const newFiles = uploadFiles.filter((file) => file.id !== id);
    setUploadFiles(newFiles);
  };

  const getLocalizedTexts = () => {
    if (language === "sv" || language === "fi") {
      const translatedUploadStatusTexts = {
        titleFailed: t("title_upload_failed"),
        msgFileInvalidType: t("msg_file_invalid_type"),
        msgFileTooLarge: t("msg_file_too_large"),
        msgFileTooSmall: t("msg_file_too_small"),
        msgTooManyFiles: t("msg_too_many_files"),
        textComplete: t("text_complete"),
        textLoading: t("text_loading"),
        textFailed: t("text_failed"),
        textPending: t("text_pending"),
      };

      const translatedInputTexts: FileInputTexts = {
        titleBold: t("title_bold"),
        titleNormal: t("title_normal"),
        textAnyFileType: t("text_any_file_type"),
        textAcceptedFileTypes: t("text_accepted_file_types"),
        textMaxSize: t("text_max_size"),
      };

      const translatedUploadTexts: FileUploadTexts = {
        fileInputTexts: translatedInputTexts,
        uploadStatusTexts: translatedUploadStatusTexts,
      };

      return translatedUploadTexts;
    }
    // English texts come from components
    return undefined;
  };

  return (
    <Box sx={{ width: "inherit" }}>
      {organization?.logo && renderLogo()}
      <FileUpload
        fileTypes={{
          "image/png": [".png"],
          "image/svg": [".svg"],
          "image/jpeg": [".jpg", ".jpeg"],
          "image/gif": [".gif"],
        }}
        maxFileCount={1}
        maxFileSize={MAX_FILE_SIZE}
        onFileRemoved={handleFileRemoved}
        onFilesAdded={handleFileAdded}
        uploadFiles={uploadFiles}
        localizedTexts={getLocalizedTexts()}
      />
    </Box>
  );
}

export default LogoManager;
