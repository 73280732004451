import React from "react";
import useTranslations from "@hooks/useTranslations";
import Header from "@oriola-origo/core/lib/Header";
import Select from "@oriola-origo/core/lib/Select";
import MenuItem from "@oriola-origo/core/lib/MenuItem";
import { UserAccessInformationCompany } from "../user_access_information";
import Typography from "@oriola-origo/core/lib/Typography";

export interface UserAccessHeaderProps {
  companies: UserAccessInformationCompany[];
  selectedCompanyId: number | null;
  onCompanyChange: (companyId: number) => void;
}

export default function UserAccessHeader({
  companies,
  onCompanyChange,
  selectedCompanyId,
}: Readonly<UserAccessHeaderProps>) {
  const { t } = useTranslations();

  const renderValue = (value: unknown) => {
    const companyId = Number(value);
    const company = companies.find((company) => company.id === companyId);
    const text = company ? `${company.api_id} - ${company.name}` : "";
    return (
      <Typography noWrap sx={{ width: "95%" }}>
        {text}
      </Typography>
    );
  };

  return (
    <Header title={t("user_access")} description="" breakpoint="md">
      <Select
        helperText=""
        label={t("organization")}
        onChange={(event) => {
          onCompanyChange(Number(event.target.value));
        }}
        value={selectedCompanyId?.toString() ?? ""}
        renderValue={renderValue}
      >
        {companies.map((company) => {
          const marginLeft = `${(company.hierarchy_level ?? 0) * 8}px`;
          return (
            <MenuItem key={company.id} value={company.id.toString()}>
              <span style={{ marginLeft }}>
                {company.api_id} - {company.name}
              </span>
            </MenuItem>
          );
        })}
      </Select>
    </Header>
  );
}
