import React, { useState } from "react";
import Box from "@oriola-origo/core/lib/Box";
import Header from "@oriola-origo/core/lib/Header";
import useTranslations from "@hooks/useTranslations";
import Grid from "@oriola-origo/core/lib/Grid";
import TextField from "@oriola-origo/core/lib/TextField";
import Select from "@oriola-origo/core/lib/Select";
import MenuItem from "@oriola-origo/core/lib/MenuItem/MenuItem";
import Page from "@oriola-origo/core/lib/Page";
import {
  validateEmailAddress,
  isInvalidNameOrCompanyName,
  validateMobilePhone,
} from "../form_validation";
import { useAppDispatch } from "../../../store/hooks";
import {
  setUser,
  addError,
  removeError,
} from "../../../store/states/inviteUserSlice";

interface UserTextFieldProps {
  iconName: string;
  label: string;
  name?: string;
  validateFunc: any;
  onChange: (e: any) => any;
}

type Permissions = Record<string, boolean>;
interface UserInformationProps {
  permissions: Permissions;
}

export function UserTextField(
  props: Readonly<UserTextFieldProps & { required?: boolean }>
) {
  const {
    iconName,
    label,
    name = "",
    validateFunc,
    required = true,
    onChange,
  } = props;
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const dispatch = useAppDispatch();

  const handleChange = (e) => {
    const value = e.target.value;
    const validationResult = validateFunc(label, value);
    if (validationResult.error) {
      setError(true);
      setHelperText(validationResult.error);
      dispatch(addError(validationResult));
    } else {
      setError(false);
      setHelperText("");
      dispatch(removeError(validationResult));
    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <Grid size={{ sm: 12, md: required ? 6 : 5, lg: required ? 6 : 4 }}>
      <Box>
        <TextField
          name={name || label}
          fullWidth={true}
          required={required}
          iconName={iconName}
          iconBaseClassName="material-icons-outlined"
          iconPosition="end"
          label={label}
          maxRows={5}
          minRows={1}
          error={error}
          helperText={helperText}
          onChange={handleChange}
        />
      </Box>
    </Grid>
  );
}

export function handleFormChange(dispatch) {
  return (event) => {
    const { name, value } = event.target;
    dispatch(setUser({ [name]: value }));
  };
}

export function UserLanguageSelect({ t, dispatch }) {
  const handleChange = (event) => {
    dispatch(setUser({ language: event.target.value }));
  };
  return (
    <Grid size={{ sm: 12, md: 5, lg: 4 }}>
      <Select
        name="language"
        fullWidth={true}
        label="Language"
        onChange={handleChange}
        defaultValue={I18n.locale}
      >
        <MenuItem value="en">{t("language_en")}</MenuItem>
        <MenuItem value="fi">{t("language_fi")}</MenuItem>
        <MenuItem value="sv">{t("language_sv")}</MenuItem>
      </Select>
    </Grid>
  );
}

function UserInformation({ permissions }: Readonly<UserInformationProps>) {
  const { t } = useTranslations();
  const handleEmailValidation = (_, value) => {
    return validateEmailAddress("email_address", value);
  };
  const handleNameValidation = (_, value) => {
    return isInvalidNameOrCompanyName("first_name", value);
  };
  const handleMobileValidation = (_, value) => {
    if (value === "") {
      return { key: "mobile_phone", error: "" };
    }
    return validateMobilePhone("mobile_phone", value);
  };

  const dispatch = useAppDispatch();

  return (
    <Page
      pt={3}
      header={<Header buttons={[]} description="" title={t("invite_user")} />}
    >
      <Grid container spacing={4}>
        <UserTextField
          name="first_name"
          iconName={"edit"}
          label={t("first_name")}
          validateFunc={handleNameValidation}
          required={true}
          onChange={handleFormChange(dispatch)}
        ></UserTextField>
        <UserTextField
          name="mobile_phone"
          iconName={"phone_iphone"}
          label={t("mobile_phone")}
          validateFunc={handleMobileValidation}
          required={!permissions["manage_all_users"]}
          onChange={handleFormChange(dispatch)}
        ></UserTextField>
        <UserTextField
          name="last_name"
          iconName={"edit"}
          label={t("last_name")}
          validateFunc={handleNameValidation}
          required={true}
          onChange={handleFormChange(dispatch)}
        ></UserTextField>
        <UserTextField
          name="work_title"
          iconName={"edit"}
          label={t("work_title")}
          validateFunc={handleNameValidation}
          required={false}
          onChange={handleFormChange(dispatch)}
        ></UserTextField>
        <UserTextField
          name="email_address"
          iconName={"email"}
          label={t("email")}
          validateFunc={handleEmailValidation}
          required={true}
          onChange={handleFormChange(dispatch)}
        ></UserTextField>
        {UserLanguageSelect({
          t,
          dispatch,
        })}
      </Grid>
    </Page>
  );
}

export default UserInformation;
