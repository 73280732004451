export type SortableOrganization = {
  id: number;
  api_id: string;
  name: string;
  hierarchy_parent_company_ids?: number[];
};

const createOrganizationSortOrder = (
  organization: SortableOrganization,
  organizations: SortableOrganization[]
) => {
  const parentNames = (organization.hierarchy_parent_company_ids ?? [])
    .map((parentId) => organizations.find((s) => s.id === parentId)?.name)
    .filter(Boolean);

  return [...parentNames, organization.name, ""].join("|").replace(/\s/g, "");
};

export const sortOrganizationsByHierarchy = <
  T extends SortableOrganization = SortableOrganization,
>(
  organizations: T[]
): T[] =>
  [...organizations].sort((a, b) => {
    const aOrder = createOrganizationSortOrder(a, organizations);
    const bOrder = createOrganizationSortOrder(b, organizations);
    return aOrder.localeCompare(bOrder);
  });
