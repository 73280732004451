import { OriolaTheme, styled } from "@oriola-origo/core/lib/styles";
import { InternalItemType } from "./internal_item_type";
import Box from "@oriola-origo/core/lib/Box";

const schemeTypeStyles = (
  theme: typeof OriolaTheme,
  schemaType: InternalItemType,
  overrideBorderColor?: string
) => {
  const maybeOverride = (color: string) => overrideBorderColor ?? color;

  if (schemaType === InternalItemType.EmergencyAnnouncement) {
    return {
      backgroundColor: theme.palette.error.light,
      borderColor: maybeOverride(theme.palette.error.main),
      color: theme.palette.error.dark,
    };
  } else if (schemaType === InternalItemType.ImportantAnnouncement) {
    return {
      backgroundColor: theme.palette.background.default,
      borderColor: maybeOverride(theme.palette.error.main),
      color: theme.palette.error.dark,
    };
  }
  return {
    backgroundColor: theme.palette.warning.light,
    borderColor: maybeOverride(theme.palette.warning.dark),
    color: theme.palette.warning.dark,
  };
};

interface AttentionBarContainerProps {
  schemaType: InternalItemType;
}

export const AttentionBarContainer = styled(Box, {
  shouldForwardProp: (propName) => propName !== "schemaType",
})<AttentionBarContainerProps>(({ theme, schemaType }) => ({
  padding: theme.spacing(3),
  border: "1px solid",
  borderRadius: theme.spacing(0.5),
  display: "flex",
  alignItems: "center",
  position: "relative",
  gap: theme.spacing(4),
  ...schemeTypeStyles(theme, schemaType),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

export const CloseButton = styled("button")(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  fontSize: 30,
  transform: "translate(50%, -50%)",
  cursor: "pointer",
  transition: "transform 0.2s",
  border: "none",
  backgroundColor: "transparent",
  "&:hover": {
    transform: "translate(50%, -50%) rotateZ(45deg)",
  },
}));

export const Icon = styled("i")(({ theme }) => ({
  top: "initial",
  fontSize: 55,
  "&.oricon-red-zap": {
    animation: "fadeinout 1.3s infinite",
  },
}));

export const Content = styled("div")(({ theme }) => ({
  flex: 2,
  minWidth: 0,
  maxWidth: 600,

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    maxWidth: "100%",
  },
}));

export const ContentTitle = styled("b")(({ theme }) => ({
  display: "block",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

export const ContentBody = styled("div")(({ theme }) => ({
  display: "flex",
  "& > p": {
    height: 25,
    marginBottom: 0,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

export const ReadMore = styled("a")(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: "inherit",
  fontWeight: "bold",
  textDecoration: "underline",
  whiteSpace: "nowrap",
}));

export const ActionsContainer = styled("div")(({ theme }) => ({
  display: "inline-flex",
  gap: theme.spacing(1),
  marginLeft: "auto",

  [theme.breakpoints.down("md")]: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "initial",
  },
}));

interface ActionButtonProps {
  schemaType: InternalItemType;
}

export const ActionButton = styled("button")<ActionButtonProps>(({
  theme,
  schemaType,
}) => {
  const isEmergencyOrImportantAnnouncement = [
    InternalItemType.EmergencyAnnouncement,
    InternalItemType.ImportantAnnouncement,
  ].includes(schemaType);
  const typeSpecificHoverStyles = () =>
    isEmergencyOrImportantAnnouncement
      ? {
          backgroundColor: theme.palette.error.dark,
          color: theme.palette.error.light,
        }
      : {
          backgroundColor: theme.palette.warning.dark,
          color: theme.palette.warning.light,
        };

  const overrideButtonBorderColor = isEmergencyOrImportantAnnouncement
    ? theme.palette.error.dark
    : undefined;

  return {
    width: "100%",
    border: "1px solid",
    padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
    borderRadius: 4,
    transition: "background-color 0.25s, color 0.25s",
    whiteSpace: "nowrap",
    ...schemeTypeStyles(theme, schemaType, overrideButtonBorderColor),
    "&:hover": {
      ...typeSpecificHoverStyles(),
    },
  };
});
