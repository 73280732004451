import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header, { HeaderButton } from "@oriola-origo/core/lib/Header";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Page from "@oriola-origo/core/lib/Page";
import Box from "@oriola-origo/core/lib/Box";
import useTranslations from "@hooks/useTranslations";
import useRoutes from "@hooks/useRoutes";
import { CompanyType } from "@services/user_management/types";
import useSearchParams from "@hooks/useSearchParams";
import DeleteConfirmation from "@components/common/delete_confirmation";
import OrganizationTable, {
  OrganizationTableProps,
  OrganizationTableActionItem,
} from "./components/organization_table/organization_table";
import useOrganizations, {
  ListedOrganization,
} from "@services/user_management/hooks/useOrganizations";
import { FiltersType } from "./components/organization_table/components/filters";
import SnackbarProvider from "@oriola-origo/core/lib/Snackbar/SnackbarProvider";
import useSnackbar from "@hooks/useSnackbar";

export interface OrganizationListV2Props {
  company_types: CompanyType[];
  permissions: {
    may_add: boolean;
    may_edit: boolean;
    may_delete: boolean;
    may_manage_types: boolean;
  };
}

const ORGANIZATIONS_PER_FETCH = 50;

export const searchParamsToFilters = (
  searchParams: URLSearchParams
): FiltersType => {
  const filtersType: FiltersType = {
    search: searchParams.get("search") ?? "",
  };

  if (searchParams.has("status")) {
    filtersType.status = searchParams.get("status") ?? "";
  }

  if (searchParams.has("origin")) {
    filtersType.searchScope = searchParams.get("origin") ?? "";
  }

  if (searchParams.has("type_str_ids")) {
    filtersType.typeStrIds = (searchParams.get("type_str_ids") ?? "").split(
      ","
    );
  }

  return filtersType;
};

const shouldSkipOrganizationFetching = (
  page: number,
  activeStatus: string,
  currentOrganizationsCount: number,
  organizationCounts: OrganizationTableProps["counts"]
) => {
  const currentMaxCount =
    organizationCounts?.[activeStatus] ?? organizationCounts?.total ?? 0;
  return page > 1 && currentMaxCount <= currentOrganizationsCount;
};

function OrganizationListInternal({
  company_types: companyTypes,
  permissions,
}: Readonly<OrganizationListV2Props>) {
  const { t } = useTranslations();
  const routes = useRoutes();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setSnackMessage } = useSnackbar();
  const [isFetchingOrganizations, setIsFetchingOrganizations] = useState(false);
  const [isRePopulatingOrgs, setIsRePopulatingOrgs] = useState(false);
  const [isInitialDataFetched, setIsInitialDataFetched] = useState(false);
  const [organizations, setOrganizations] = useState<ListedOrganization[]>([]);
  const [organizationCounts, setOrganizationCounts] = useState<
    null | OrganizationTableProps["counts"]
  >(null);
  const { fetchOrganizations, deleteOrganization, isDeleting } =
    useOrganizations();
  const [activePageIndex, setActivePageIndex] = useState<null | number>(null);
  const [deleteConfirmationOpenFor, setDeleteConfirmationOpenFor] =
    useState<ListedOrganization | null>(null);
  const shouldShowDeletedSuccessfully = searchParams.has("deleted");

  const notifySuccessfullyDeleted = () => {
    setSnackMessage(t("organization_deleted"), "success");
  };

  const filters = useMemo(
    () => searchParamsToFilters(searchParams),
    [searchParams.toString()]
  );

  const fetchMoreOrganizations = useCallback(
    async (page = 1) => {
      if (
        shouldSkipOrganizationFetching(
          page,
          filters.status,
          organizations.length,
          organizationCounts
        )
      ) {
        return;
      }

      setIsFetchingOrganizations(true);
      try {
        const origin =
          filters.searchScope === "all" ? undefined : filters.searchScope;

        const res = await fetchOrganizations({
          page,
          perPage: ORGANIZATIONS_PER_FETCH,
          search: filters.search || undefined,
          origin: origin || undefined,
          statusFilter: filters.status || undefined,
          organizationTypesFilter: filters.typeStrIds,
        });

        if (res.succeed) {
          if (page === 1) {
            setOrganizations(res.data.rows ?? []);
            setOrganizationCounts(res.data.counts);
          } else {
            setOrganizations((prev) => [...prev, ...(res.data.rows ?? [])]);
          }
          setActivePageIndex(page);
          setIsRePopulatingOrgs(false);
          setIsInitialDataFetched(true);
        }
      } finally {
        setIsFetchingOrganizations(false);
      }
    },
    [fetchOrganizations, organizationCounts, filters]
  );

  useEffect(() => {
    if (shouldShowDeletedSuccessfully) {
      notifySuccessfullyDeleted();
      searchParams.delete("deleted");
      setSearchParams(searchParams);
    }
  }, [shouldShowDeletedSuccessfully]);

  useEffect(() => {
    setIsInitialDataFetched(false);
    setIsRePopulatingOrgs(true);
    fetchMoreOrganizations(1);
  }, [filters]);

  const actionMenuItems: OrganizationTableActionItem[] = useMemo(() => {
    const menuItems: OrganizationTableActionItem[] = [];

    if (permissions.may_edit) {
      menuItems.push({
        label: t("edit_organization"),
        icon: "edit",
        href: (res) => routes.companyShowPath(res.id),
      });
    }

    if (permissions.may_delete) {
      menuItems.push({
        label: t("delete_organization"),
        icon: "delete",
        hidden: (res) => {
          const org = organizations.find((o) => o.id === res.id);
          const hasOrigoOrigin = ["origo", "origo-system"].includes(
            org?.origin
          );
          return !hasOrigoOrigin;
        },
        onClick: (res) => {
          const org = organizations.find((o) => o.id === res.id);
          if (org) {
            setDeleteConfirmationOpenFor(org);
          }
        },
      });
    }

    return menuItems;
  }, [organizations, permissions]);

  const headerButtons: HeaderButton[] = [];
  if (permissions.may_manage_types) {
    headerButtons.push({
      variant: "outlined",
      text: t("manage_organization_types"),
      icon: "edit",
      path: routes.companyTypeListPath(),
    });
  }
  if (permissions.may_add) {
    headerButtons.push({
      variant: "contained",
      text: t("add_organization"),
      icon: "cases",
      path: routes.companyNewPath("list"),
    });
  }

  const handleFiltersChange = (newFilters: FiltersType) => {
    const newSearchParams = new URLSearchParams();
    if (newFilters.search) {
      newSearchParams.set("search", newFilters.search);
    }
    if (newFilters.status) {
      newSearchParams.set("status", newFilters.status);
    }
    if (newFilters.searchScope && newFilters.searchScope !== "all") {
      newSearchParams.set("origin", newFilters.searchScope);
    }

    if (
      Array.isArray(newFilters.typeStrIds) &&
      newFilters.typeStrIds.length > 0
    ) {
      newSearchParams.set("type_str_ids", newFilters.typeStrIds.join(","));
    }

    setSearchParams(newSearchParams);
  };

  const handleDeleteOrganization = async () => {
    if (!deleteConfirmationOpenFor) {
      return;
    }

    const res = await deleteOrganization(deleteConfirmationOpenFor.id);
    if (res.succeed) {
      setOrganizations((prev) =>
        prev.filter((o) => o.id !== deleteConfirmationOpenFor.id)
      );

      notifySuccessfullyDeleted();
    }

    setDeleteConfirmationOpenFor(null);
  };

  return (
    <Page
      header={<Header title={t("organizations")} buttons={headerButtons} />}
      mb={5}
      pt={1}
    >
      <Box mx={-4}>
        <OrganizationTable
          organizations={organizations}
          filters={filters}
          onFiltersChange={handleFiltersChange}
          onRequestMore={() => {
            if (isInitialDataFetched) {
              fetchMoreOrganizations(activePageIndex + 1);
            }
          }}
          counts={organizationCounts}
          companyTypes={companyTypes}
          isRePopulating={isRePopulatingOrgs}
          isLoading={isFetchingOrganizations}
          actionMenuItems={actionMenuItems}
        />
      </Box>
      {deleteConfirmationOpenFor && (
        <DeleteConfirmation
          title={t("delete_organization")}
          content={t("confirm_delete_organization", {
            name: deleteConfirmationOpenFor.name,
          })}
          open={true}
          isDeleting={isDeleting}
          onCancel={() => setDeleteConfirmationOpenFor(null)}
          onDelete={handleDeleteOrganization}
        />
      )}
    </Page>
  );
}

function OrganizationListV2(props: Readonly<OrganizationListV2Props>) {
  return (
    <SnackbarProvider>
      <OriolaThemeProvider>
        <OrganizationListInternal {...props} />
      </OriolaThemeProvider>
    </SnackbarProvider>
  );
}

export default OrganizationListV2;
