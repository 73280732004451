import React, { useEffect, useState } from "react";
import Box from "@oriola-origo/core/lib/Box";
import Select from "@oriola-origo/core/lib/Select";
import MenuItem from "@oriola-origo/core/lib/MenuItem";
import FontIcon from "@oriola-origo/core/lib/Icons";
import Timeline from "@oriola-origo/core/lib/Timeline";
import Button from "@oriola-origo/core/lib/Button";
import useUser from "@services/user_management/hooks/useUser";
import Progress from "@oriola-origo/core/lib/Progress";
import useOrganization from "../../services/user_management/hooks/useOrganization";
const PER_PAGE = 6;

export const onSortChange = (e, setPage, setSortBy) => {
  setPage(1);
  setSortBy(e.target.value);
};

export const renderSortValue = (value) => {
  return (
    <Box style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
      <FontIcon>filter_list</FontIcon> {value}
    </Box>
  );
};

export function renderActivityLogs(props) {
  const {
    sortBy,
    activities,
    totalCount,
    page,
    setPage,
    isFetching,
    setSortBy,
  } = props;
  if (page === 1 && isFetching) {
    return <Progress color="primary" size={50} text="" />;
  }
  if (page === 1 && !isFetching && activities.length === 0) {
    return (
      <Box textAlign="center" my={5}>
        {I18n.t("no_activity_logs")}
      </Box>
    );
  }

  return (
    <Box pt={2}>
      <Select
        label="Sort by"
        value={sortBy}
        renderValue={renderSortValue}
        onChange={(e) => onSortChange(e, setPage, setSortBy)}
      >
        <MenuItem value={I18n.t("latest_events_first")}>
          {I18n.t("latest_events_first")}
        </MenuItem>
        <MenuItem value={I18n.t("oldest_events_first")}>
          {I18n.t("oldest_events_first")}
        </MenuItem>
      </Select>

      <Timeline items={activities} position="right" />
      {activities.length < totalCount && (
        <Box mt={2} textAlign="center">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setPage(page + 1)}
            isLoading={isFetching}
          >
            {I18n.t("show_more")}
          </Button>
        </Box>
      )}
    </Box>
  );
}
function ActivityTimeline() {
  const [sortBy, setSortBy] = useState(I18n.t("latest_events_first"));
  const [activities, setActivities] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const { fetchUserActivityLogs, isUpdating: isUserFetching } = useUser();
  const { fetchOrganizationActivityLogs, isUpdating: isOrganizationFetching } =
    useOrganization();

  // target can be user or organization
  const targetId = window.location.pathname.split("/").pop();
  const targetType = window.location.pathname.split("/").slice(-2, -1)[0];

  useEffect(() => {
    fetchActivityLogsFn();
  }, [sortBy, page]);

  async function fetchActivityLogsFn() {
    const res =
      targetType === "user"
        ? await fetchUserActivityLogs(
            targetId,
            page,
            PER_PAGE,
            sortBy === I18n.t("latest_events_first") ? "desc" : "asc"
          )
        : await fetchOrganizationActivityLogs(
            targetId,
            page,
            PER_PAGE,
            sortBy === I18n.t("latest_events_first") ? "desc" : "asc"
          );

    if (res.succeed) {
      const modifiedData = res.data.data.map((activity) => {
        return {
          oppositeContent: `${activity.createdDateTime} \n ${activity.createdBy}`,
          content: activity.value.join("\n"),
        };
      });
      const previousData = page === 1 ? [] : activities;
      setActivities([...previousData, ...modifiedData]);
      setTotalCount(res.data.totalCount);
    }
  }

  return renderActivityLogs({
    sortBy,
    activities,
    totalCount,
    page,
    setPage,
    isFetching: isUserFetching || isOrganizationFetching,
    setSortBy,
  });
}

export default ActivityTimeline;
