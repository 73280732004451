import React from 'react';
import { Table, TableRowActions } from '../common/table';
import DropdownMenu from '../common/dropdown_menu';
import FormModal from './form_modal';

export default class List extends React.Component {

    constructor(props) {
        super(props);

        this.dialogMode = {
            new: 'new',
            edit: 'edit'
        }

        this.state = {
            tableKey: Date.now(),
            selectedApplication: props.applications ? props.applications[0] : null,
            dialogMode: null,
            selectedRole: null
        }

        this.handleSelectApplicationClick = this.handleSelectApplicationClick.bind(this);
        this.handleAddRoleClick = this.handleAddRoleClick.bind(this);
        this.handleEditRoleClick = this.handleEditRoleClick.bind(this);
        this.handleDeleteRoleClick = this.handleDeleteRoleClick.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleFormSuccess = this.handleFormSuccess.bind(this);
        this.reload = this.reload.bind(this);

        this.rootRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.selectedApplication.id != this.state.selectedApplication.id) {
            this.reload();
        }
    }

    render() {
        let columns = [
            {
                dataField: 'name',
                text: I18n.t('name'),
                sort: true,
                formatter: (cell, row) => {
                    return <a href={Routes.role_show_path({role_id: row.id})}>
                        {cell}
                    </a>;
                }
            },
            {
                dataField: 'description',
                text: I18n.t('description'),
                sort: true,
                formatter: (cell, row) => {
                    return <span title={cell}>{cell}</span>;
                },
                headerClasses: 'd-none d-sm-table-cell',
                classes: 'd-none d-sm-table-cell text-truncate'
            },
            {
                dataField: 'str_id',
                text: I18n.t('role_str_id'),
                sort: true,
                headerClasses: 'd-none d-xl-table-cell',
                classes: 'd-none d-xl-table-cell'
            },
            {
                dataField: 'id',
                text: '',
                formatter: (cell, row) => {
                    let actions = [];
                    
                    if (this.props.permissions.mayEdit) {
                        actions.push(
                            <a key="edit" href="#" onClick={(e) => this.handleEditRoleClick(e, row)}>
                                <i className="fa fa-pencil"></i> {I18n.t('edit')}
                            </a>
                        );
                    }

                    if (this.props.permissions.mayDelete) {
                        if (actions.length > 0) {
                            actions.push(<TableRowActions.Divider key="div" />)
                        }

                        actions.push(
                            <a key="del" href="#" onClick={(e) => this.handleDeleteRoleClick(e, row)}>
                                <i className="oricon-bin"></i> {I18n.t('delete')}
                            </a>
                        );
                    }

                    return actions.length > 0 ? <TableRowActions.Menu>{actions}</TableRowActions.Menu> : null
                },
                classes: 'row-actions',
                headerClasses: 'row-actions'
            }
        ];

        return <div ref={this.rootRef} className="origo-application-role-list">
            <Table
                key={this.state.tableKey}
                columns={columns}
                actions={this.renderActions()}
                remote={true}
                remoteUrl={Routes.role_list_json_path({ origo_application_id: this.state.selectedApplication.id })}
                pagination={{ sizePerPage: 20 }}
                defaultSorted={[{
                    dataField: 'name',
                    order: 'asc'
                }]}
            />
            { this.state.dialogMode == this.dialogMode.new && 
                <FormModal
                    title={I18n.t('add_role')}
                    onClose={this.handleDialogClose}
                    action={Routes.role_create_path()}
                    onSuccess={this.handleFormSuccess} 
                    origoApplicationId={this.state.selectedApplication.id}
                    iconOptions={this.props.iconOptions}
                />
            }
            { this.state.dialogMode == this.dialogMode.edit && 
                <FormModal
                    title={I18n.t('edit_role')}
                    onClose={this.handleDialogClose}
                    action={Routes.role_update_path()}
                    onSuccess={this.handleFormSuccess} 
                    role={this.state.selectedRole}
                    iconOptions={this.props.iconOptions}
                />
            }
        </div>;
    }

    renderActions() {
        let buttons = [];
        if (this.props.permissions.mayAdd) {
            buttons.push(
                <button key="add" href="#" onClick={this.handleAddRoleClick} className="btn btn-light">
                    <i className="fa fa-plus text-highlight"></i> {I18n.t('add')}
                </button>
            );
        }

        const actions = <div className="row no-gutters align-items-center">
            <div className="col-auto">
                <h2 className="mr-3 mb-0">
                    {this.props.applications.length > 1 ? <DropdownMenu.Menu label={this.state.selectedApplication.title[I18n.locale] || this.state.selectedApplication.title.en}
                        btnClass="dropdown-toggle btn-text text-dark text-truncate">
                        <DropdownMenu.Header>{I18n.t('select_application')}</DropdownMenu.Header>
                        {this.props.applications.filter(o => o.id != this.state.selectedApplication.id).map(a => (
                            <a key={a.id} href="#" onClick={(e) => this.handleSelectApplicationClick(e, a.id)}>{a.title[I18n.locale] || a.title.en}</a>
                        ))}
                    </DropdownMenu.Menu> : this.state.selectedApplication.title[I18n.locale] || this.state.selectedApplication.title.en}
                </h2>
            </div>
            {buttons.length > 0 &&
                <div className="col">
                    {buttons}
                </div>
            }
        </div>;

        return actions;
    }

    handleSelectApplicationClick(event, applicationId) {
        event.preventDefault();
        this.setState({
            selectedApplication: this.props.applications.find((a) => a.id == applicationId)
        });
    }

    handleAddRoleClick(event) {
        event.preventDefault();
        this.setState({ dialogMode: this.dialogMode.new });
    }

    handleEditRoleClick(event, role) {
        event.preventDefault();
        this.setState({
            dialogMode: this.dialogMode.edit,
            selectedRole: role
        });
    }

    handleDialogClose() {
        this.setState({
            dialogMode: null,
            selectedRole: null
        });
    }

    handleFormSuccess() {
        this.handleDialogClose();
        this.reload();
    }

    handleDeleteRoleClick(event, role) {
        event.preventDefault();

        if (role && confirm(I18n.t('confirm_delete_origo_application_role', { role_name: role.name,
            application_name: this.state.selectedApplication.title[I18n.locale] || this.state.selectedApplication.title.en }))) {
            jQuery.ajax({
                url: Routes.role_delete_path({ role_id: role.id }),
                type: 'DELETE',
                success: this.reload
            });
        }
        
    }

    reload() {
        this.setState({
            tableKey: Date.now()
        });
    }
}
