import React, { useCallback, useMemo } from "react";
import UserTable, {
  UsersTableProps,
  UserTableUser,
} from "@components/common/user_table/user_table";
import { UserGroups } from "@services/user_management/hooks/useUserGroupCategories";
import UserGroupAutocomplete, {
  UserGroupOption,
} from "./components/user_group_autocomplete";
import useTranslations from "@hooks/useTranslations";
import UserGroupCellContent from "./components/user_group_cell_content";

export type UserTableUserWithUserGroups = UserTableUser;

type UserTableWithUserGroupsRawProps = UsersTableProps<{
  userGroupIds: number[];
}>;

export type UserTableWithUserGroupsProps = Omit<
  UserTableWithUserGroupsRawProps,
  | "customFilterRender"
  | "groupColumnHeaderText"
  | "groupCellRender"
  | "groupCellHasAnythingToExpand"
  | "users"
> & {
  users: UserTableUserWithUserGroups[];
  activeOrganizationId: number;
  userGroups: UserGroups[];
};

export function UserTableWithUserGroups({
  userGroups,
  activeOrganizationId,
  users,
  ...rest
}: Readonly<UserTableWithUserGroupsProps>) {
  const { t } = useTranslations();

  const userGroupOptions: UserGroupOption[] = useMemo(
    () =>
      userGroups.flatMap((item) =>
        item.groups.map((group) => ({
          id: group.id,
          name: group.name,
          categoryId: item.category.id,
          categoryName: item.category.name,
        }))
      ),
    [userGroups]
  );

  const groupFilterRender: UserTableWithUserGroupsRawProps["groupFilterRender"] =
    useCallback((filtersValue, onFiltersChange) => {
      const handleUserGroupsChange = (options: UserGroupOption[]) => {
        const userGroupIds = options.map((option) => option.id) ?? [];
        onFiltersChange({
          ...filtersValue,
          userGroupIds,
        });
      };
      return (
        <UserGroupAutocomplete
          userGroupOptions={userGroupOptions}
          onChange={handleUserGroupsChange}
          defaultValue={(filtersValue?.userGroupIds ?? []).map((id) =>
            userGroupOptions.find((option) => option.id === id)
          )}
        />
      );
    }, []);

  const groupCellRender: UserTableWithUserGroupsRawProps["groupCellRender"] =
    useCallback(
      (user: UserTableUserWithUserGroups, isExpanded) => {
        const userGroups = user.user_groups;

        return (
          <UserGroupCellContent
            userGroups={userGroups}
            isExpanded={isExpanded}
          />
        );
      },
      [activeOrganizationId, userGroupOptions]
    );

  return (
    <UserTable
      {...rest}
      users={users}
      groupFilterRender={groupFilterRender}
      groupCellHasAnythingToExpand={(user: UserTableUserWithUserGroups) =>
        user.user_groups.length > 1
      }
      groupColumnHeaderText={t("user_groups")}
      groupCellRender={groupCellRender}
    />
  );
}

export default UserTableWithUserGroups;
