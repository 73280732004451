import React, { useState } from "react";
import useTranslations from "@hooks/useTranslations";
import ToggleItemsList, {
  ALL_CATEGORY_ID,
  ItemsActiveCategoryId,
  ToggleItemsListItem,
} from "@components/common/toggle_items_list";
import { UserFormV2Props } from "@components/user/form_v2/types";
import { InheritedRole } from "../types";

export interface AssignRolesProps {
  apps: UserFormV2Props["apps"];
  permissions: UserFormV2Props["permissions"];
  roles: UserFormV2Props["roles"];
  selectedRoleIds: number[];
  onSelectedRoleIdsChange: (roleIds: number[], oldState: number[]) => void;
  inheritedRoles: InheritedRole[];
  disabled?: boolean;
}

export function AssignRoles({
  apps,
  permissions,
  roles,
  selectedRoleIds,
  inheritedRoles,
  onSelectedRoleIdsChange,
  disabled,
}: Readonly<AssignRolesProps>) {
  const { t } = useTranslations();
  const [activeCategoryId, setActiveCategoryId] =
    useState<ItemsActiveCategoryId>(ALL_CATEGORY_ID);
  const handleActiveCategoryChange = (categoryId: ItemsActiveCategoryId) => {
    setActiveCategoryId(categoryId);
  };

  const items: { [categoryId: number]: ToggleItemsListItem[] } = {};
  Object.keys(roles).forEach((key) => {
    items[key] = roles[key].map((role) => {
      const inheritanceItem = inheritedRoles.find(
        (item) => item.id === role.id
      );

      let disabledTooltip;
      if (inheritanceItem) {
        disabledTooltip = t(
          "roles_inherited_from_organization_and_user_groups"
        );
      }

      return {
        id: role.id,
        name: role.name,
        description: role.description,
        disabled:
          !permissions.assign_roles ||
          (role.is_superuser && !permissions.assign_superuser_roles) ||
          !!inheritanceItem,
        disabledTooltip,
      } as ToggleItemsListItem;
    });
  });

  return (
    <ToggleItemsList
      categories={apps}
      items={items}
      noItemsText={t("no_roles")}
      selectedItemsIds={selectedRoleIds}
      readonlySelectedItemIds={inheritedRoles.map((item) => item.id)}
      onSelectedItemIdsChange={onSelectedRoleIdsChange}
      activeCategoryId={activeCategoryId}
      onActiveCategoryChange={handleActiveCategoryChange}
      disabled={disabled}
    />
  );
}
