import { getColumnWidthFactory } from "@components/common/user_management_base_table/columns";

export enum TableColumnNonSortable {
  Type = "type",
  Details = "details",
  Actions = "actions",
  Name = "name",
  Users = "users",
  Status = "status",
}

export const COLUMN_FIXED_WIDTHS: Record<
  TableColumnNonSortable,
  number | string
> = {
  [TableColumnNonSortable.Name]: "",
  [TableColumnNonSortable.Type]: "30%",
  [TableColumnNonSortable.Users]: 85,
  [TableColumnNonSortable.Details]: 105,
  [TableColumnNonSortable.Status]: 110,
  [TableColumnNonSortable.Actions]: 100,
};

export const getColumnWidth = getColumnWidthFactory(COLUMN_FIXED_WIDTHS);
