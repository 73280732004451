import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IError, IUser } from "@components/user/form_v2/types";
import { IOrganizationSelection } from "@components/user/types";
import { sortOrganizationsByHierarchy } from "@utils/organization_sorting";
interface InviteUserState {
  defaultOrganizationId?: number;
  lastAddedOrganizationId?: number;
  organizations: IOrganizationSelection[];
  userAccessCompanyMap: Record<
    number,
    { roleIds: number[]; userGroupIds: number[] }
  >;
  user: IUser;
  errors: IError[];
}

const initialState: InviteUserState = {
  defaultOrganizationId: null,
  lastAddedOrganizationId: null,
  organizations: [],
  userAccessCompanyMap: {},
  user: {
    first_name: "",
    last_name: "",
    mobile_phone: "",
    work_title: "",
    email_address: "",
    language: I18n.locale as "en" | "fi" | "sv",
  },
  errors: [],
};

export const InviteUserSlice = createSlice({
  name: "inviteUser",
  initialState,
  reducers: {
    addOrganization: (state, action) => {
      state.lastAddedOrganizationId = action.payload.id;
      state.organizations = [...state.organizations, action.payload];
    },
    addOrganizations: (state, action) => {
      state.lastAddedOrganizationId = action.payload[0]?.id;
      state.organizations = [...state.organizations, ...action.payload];
    },
    setOrganizationUserAccessMap: (state, action) => {
      state.userAccessCompanyMap = action.payload;
    },
    setDefaultOrganizationById: (state, action) => {
      state.defaultOrganizationId = action.payload;
    },
    removeOrganization: (state, action) => {
      const removedIsDefault =
        state.defaultOrganizationId === action.payload.organizationId;
      state.organizations = state.organizations.filter(
        (organization) => organization.id !== action.payload.organizationId
      );
      if (removedIsDefault) {
        state.defaultOrganizationId = state.organizations[0]?.id;
      }
    },
    removeSubOrganizations: (state, action) => {
      const { parentOrganizationId } = action.payload;
      state.organizations = state.organizations.filter(
        (org) =>
          !org.hierarchy_parent_company_ids?.includes(parentOrganizationId)
      );
    },
    updateProfessionalTitle: (state, action) => {
      const organization = state.organizations.find(
        (org) => org.id === action.payload.organizationId
      );
      if (organization) {
        organization.professionalTitle = action.payload.professionalTitle;
      }
    },
    updateProfitCenters: (state, action) => {
      const organization = state.organizations.find(
        (org) => org.id === action.payload.organizationId
      );
      if (organization) {
        organization.profitCenters = action.payload.profitCenters;
      }
    },
    updateSccProfitCenters: (state, action) => {
      const organization = state.organizations.find(
        (org) => org.id === action.payload.organizationId
      );
      if (organization) {
        organization.sccProfitCenters = action.payload.sccProfitCenters;
      }
    },

    setUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    addError: (state, action) => {
      state.errors = [...state.errors, action.payload];
    },
    removeError: (state, action) => {
      state.errors = state.errors.filter(
        (error) => error.key !== action.payload.key
      );
    },
    resetInviteUser: () => {
      return initialState;
    },
  },
  selectors: {
    selectOrganizationsSortedByHierarchy: (state) =>
      sortOrganizationsByHierarchy(state.organizations),
  },
});

export const SelectInviteUser = (state: RootState) => state.inviteUser;
export const {
  addOrganization,
  addOrganizations,
  setOrganizationUserAccessMap,
  setDefaultOrganizationById,
  removeOrganization,
  removeSubOrganizations,
  updateProfessionalTitle,
  updateProfitCenters,
  updateSccProfitCenters,
  setUser,
  addError,
  removeError,
  resetInviteUser,
} = InviteUserSlice.actions;
export const { selectOrganizationsSortedByHierarchy } =
  InviteUserSlice.selectors;

export default InviteUserSlice.reducer;
