import React, { useEffect, useMemo, useState } from "react";
import Grid from "@oriola-origo/core/lib/Grid";
import EditableField, {
  EditableFieldMode,
  EditableFieldProps,
  EditableFieldType,
} from "@components/common/editable_field";
import useTranslations from "@hooks/useTranslations";
import {
  CompanyType,
  Organization,
  ProfitCenter,
} from "@services/user_management/types";
import {
  isNonSystemOrigoOrganization,
  isOrigoOrganization,
} from "@components/organization/utils";
import { createDefaultReadValueTransform } from "../utils";
import {
  parentCompanyCustomEditRenderer,
  parentCompanyReadValueTransformRenderer,
  profitCentersCustomEditRenderer,
  profitCentersReadValueTransformRenderer,
} from "./organization_detail_field_renderers";

export type ParentOrganizationDetails = Pick<
  Organization,
  "id" | "api_id" | "name"
>;

export interface OrganizationDetailProps {
  organization: Organization;
  parentOrganization?: ParentOrganizationDetails;
  isFetchingParentOrganization?: boolean;
  organizationTypes: CompanyType[];
  profitCenters: ProfitCenter[];
  isUpdating: boolean;
  onSave: (property: string, value: string) => void | Promise<void>;
}

const ORIGO_ORGANIZATION_FIELDS = [
  "name",
  "type_name",
  "type_str_id",
  "api_id",
  "profit_center_ids",
  "parent_company_id",
];

const strIdsToNumeric = (strIds: string): number[] =>
  strIds.split(",").filter(Boolean).map(Number);

function OrganizationDetailFields({
  organization,
  parentOrganization,
  isFetchingParentOrganization,
  organizationTypes,
  profitCenters,
  isUpdating,
  onSave,
}: Readonly<OrganizationDetailProps>) {
  const [inEditMode, setInEditMode] = useState("");
  const { t, language } = useTranslations();
  const [editableOrganization, setEditableOrganization] =
    useState(organization);

  useEffect(() => {
    setEditableOrganization(organization);
  }, [organization]);

  const isVisible = (property: string) => {
    if (isOrigoOrganization(editableOrganization)) {
      return ORIGO_ORGANIZATION_FIELDS.includes(property);
    }
    return true;
  };

  const organizationTypeItems = useMemo(
    () =>
      organizationTypes
        .map((type) => ({
          value: type.str_id,
          label: type.name[language] ?? type.name["en"] ?? "",
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [organizationTypes, language]
  );

  const inputs = [
    {
      property: "name",
      label: t("name"),
      value: editableOrganization.name,
      notEditable: !isOrigoOrganization(editableOrganization),
      checkSaveDisabled: (value) => !value.trim(),
    },
    {
      property: "api_id",
      label: t("customer_number"),
      value: editableOrganization.api_id,
      notEditable: true,
    },
    {
      property: "type_str_id",
      label: t("type"),
      value: editableOrganization.type_str_id,
      notEditable: !isNonSystemOrigoOrganization(editableOrganization),
      checkSaveDisabled: (value) => !value.trim(),
      type: EditableFieldType.Select,
      options: organizationTypeItems,
      readValueTransform: (value) =>
        organizationTypeItems.find((item) => item.value === value)?.label,
    },
    {
      property: "chain",
      label: t("chain"),
      value: editableOrganization.chain,
      notEditable: true,
    },
    {
      property: "owner",
      label: t("owner"),
      value: editableOrganization.owner,
      notEditable: true,
    },
    {
      property: "hospital_district",
      label: t("hospital_district"),
      value: editableOrganization.hospital_district,
      notEditable: true,
    },
    {
      property: "foundation_date",
      label: t("foundation_date"),
      value: editableOrganization.foundation_date,
      notEditable: true,
    },
    {
      property: "liquidation_date",
      label: t("liquidation_date"),
      value: editableOrganization.liquidation_date,
      notEditable: true,
    },
    {
      property: "delivery_block",
      label: t("delivery_block"),
      value: editableOrganization.delivery_block,
      notEditable: true,
    },
    {
      property: "accepts_backorders",
      label: t("accepts_backorders"),
      value: editableOrganization.allow_backorders,
      notEditable: true,
    },
    {
      property: "kela_number",
      label: t("kela_number"),
      value: editableOrganization.kela_number,
      notEditable: true,
    },
    {
      type: EditableFieldType.Custom,
      property: "parent_company_id",
      label: t("parent_company_id"),
      value: editableOrganization.parent_company_id?.toString() ?? "",
      customFieldTypeEditRender: (props) =>
        parentCompanyCustomEditRenderer(props, organization),
      readValueTransform: (value) =>
        parentCompanyReadValueTransformRenderer(
          value,
          parentOrganization,
          isFetchingParentOrganization
        ),
    },
    {
      type: EditableFieldType.Custom,
      property: "profit_center_ids",
      label: t("profit_centers"),
      value: editableOrganization.profit_center_ids.join(","),
      customFieldTypeEditRender: (props) =>
        profitCentersCustomEditRenderer(props, profitCenters),
      readValueTransform: (value) =>
        profitCentersReadValueTransformRenderer(value, profitCenters),
      onSaveTransform: strIdsToNumeric,
      checkSaveDisabled: (value) => {
        const ids = strIdsToNumeric(value);
        return (
          ids.length === organization.profit_center_ids.length &&
          ids.every((id) => organization.profit_center_ids.includes(id))
        );
      },
    },
  ];

  const renderField = (
    input: Partial<
      EditableFieldProps & { onSaveTransform: (value: string) => any }
    >
  ) => {
    const defaultReadValueTransform = createDefaultReadValueTransform(t);

    return (
      <Grid size={{ xs: 12, md: 6 }} key={input.property}>
        <EditableField
          label={input.label}
          value={input.value}
          inactive={inEditMode && inEditMode !== input.property}
          disabled={input.disabled || isUpdating}
          disabledInfo={input.disabledInfo}
          editIcon={input.editIcon}
          options={input.options ?? []}
          checkSaveDisabled={input.checkSaveDisabled}
          customFieldTypeEditRender={input.customFieldTypeEditRender}
          readValueTransform={
            input.readValueTransform ?? defaultReadValueTransform
          }
          type={input.type}
          notEditable={input.notEditable}
          mode={
            inEditMode === input.property
              ? EditableFieldMode.Edit
              : EditableFieldMode.Read
          }
          onModeChange={(newMode) =>
            setInEditMode(
              newMode === EditableFieldMode.Edit ? input.property : ""
            )
          }
          onSave={async (newValue) => {
            let value = newValue;
            if (input.onSaveTransform) {
              value = input.onSaveTransform(newValue);
            }
            await onSave(input.property, value);
            setInEditMode("");
          }}
          onCancel={() => {
            setInEditMode("");
          }}
        />
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid size={{ lg: 10 }}>
        <Grid container rowSpacing={2} columnSpacing={8}>
          {inputs.map((input) =>
            isVisible(input.property) ? renderField(input) : null
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrganizationDetailFields;
