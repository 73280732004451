import useUser, {
  PatchUpdateUserOpts,
} from "@services/user_management/hooks/useUser";
import {
  UserBasicInformation,
  UserCompany,
  UserNotificationSubscriptionInformation,
} from "@services/user_management/types/users";
import { ICompanySelection } from "@services/user_management/hooks/inviteUser";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export type UserAccessInformation = {
  company_selection?: ICompanySelection[];
};

export type EditableUserProperties = UserBasicInformation &
  UserNotificationSubscriptionInformation &
  UserAccessInformation;

export type EditableUser = {
  id: number;
  companies: UserCompany[];
  is_emergency_responsible_person: boolean;
  is_invitee: boolean;
} & EditableUserProperties;

export interface EditableUserContextType {
  user: EditableUser;
  patchUpdate: (
    userProperties: Partial<EditableUserProperties>
  ) => Promise<{succeed: boolean; error: any | null}>;
  isUpdating: boolean;
}

const EditableUserContext = createContext<EditableUserContextType>({
  patchUpdate: async () => ({succeed: false, error: null}),
  isUpdating: false,
  user: null,
});

export interface EditableUserProviderProps extends PropsWithChildren {
  user: EditableUser;
}

const mapEditableUserToApiUser = (
  user: Partial<EditableUserProperties>
): PatchUpdateUserOpts["user"] => user;

export function EditableUserProvider({
  user: initialUser,
  children,
}: Readonly<EditableUserProviderProps>) {
  const { patchUpdateUser, isUpdating } = useUser();
  const [user, setUser] = React.useState<EditableUser>(initialUser);

  const patchUpdate = useCallback(
    async (userProperties: Partial<EditableUserProperties>) => {
      const res = await patchUpdateUser({
        userId: user.id,
        user: mapEditableUserToApiUser(userProperties),
      });

      if (res.succeed) {
        setUser(() => ({
          ...res.data
        }));
      }

      return {
        succeed: res.succeed,
        error: res.error ?? null,
      };
    },
    [patchUpdateUser]
  );
  const contextValue = useMemo(
    () => ({
      user,
      patchUpdate,
      isUpdating,
    }),
    [user, patchUpdate, isUpdating]
  );

  return (
    <EditableUserContext.Provider value={contextValue}>
      {children}
    </EditableUserContext.Provider>
  );
}

export default function useEditableUser() {
  const [isUpdating, setIsUpdating] = useState(false);
  const editableUserContext = useContext(EditableUserContext);

  /**
   * Make sure that the `isUpdating` state is shown only user for this instance
   * and not globally like the `isUpdating` from the context.
   */
  const patchUpdate = useCallback(
    async (...args: Parameters<EditableUserContextType["patchUpdate"]>) => {
      try {
        setIsUpdating(true);
        return await editableUserContext.patchUpdate(...args);
      } finally {
        setIsUpdating(false);
      }
    },
    [editableUserContext.patchUpdate]
  );

  return {
    ...editableUserContext,
    patchUpdate,
    isUpdating,
  };
}
