import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header, { HeaderButton } from "@oriola-origo/core/lib/Header";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Page from "@oriola-origo/core/lib/Page";
import Typography from "@oriola-origo/core/lib/Typography";
import Box from "@oriola-origo/core/lib/Box";
import useTranslations from "@hooks/useTranslations";
import useRoutes from "@hooks/useRoutes";
import useTurbolinks from "@hooks/useTurbolinks";
import useUserGroups from "@services/user_management/hooks/useUserGroups";
import {
  OrigoApplication,
  OrigoApplicationRole,
  UserGroup,
  CompanyType,
  User,
} from "@services/user_management/types";
import Roles from "./roles/roles";
import BackButton from "@components/common/back_button";
import {
  SortType,
  TableColumnSortDirection,
  TableColumnSortable,
} from "@components/common/user_table";
import UserTableWithOrganizations, {
  UserTableWithOrganizationsProps,
  idsToStrOrganizationIds,
} from "@components/common/user_table_with_organizations/user_table_with_organizations";

export interface UserGroupShowV2Props {
  origin: string;
  user_group: UserGroup;
  apps: OrigoApplication[];
  roles: OrigoApplicationRole[];
  company_types: CompanyType[];
  permissions?: {
    mayManageUserGroups: boolean;
  };
}

const SORT_FIELD_TO_COLUMN_MAP: Partial<Record<TableColumnSortable, string>> = {
  [TableColumnSortable.Name]: "last_name",
  [TableColumnSortable.Email]: "email_address",
  [TableColumnSortable.Status]: "status",
};

const USERS_PER_FETCH = 50;

function UserGroupShowInternal({
  origin,
  user_group: userGroup,
  company_types: companyTypes,
  apps,
  roles,
  permissions,
}: Readonly<UserGroupShowV2Props>) {
  const { t } = useTranslations();
  const routes = useRoutes();
  const { navigate } = useTurbolinks();
  const [isRePopulatingUsers, setIsRePopulatingUsers] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [userCounts, setUserCounts] = useState<
    null | UserTableWithOrganizationsProps["counts"]
  >(null);
  const { fetchUsers, isFetching } = useUserGroups();
  const [activePageIndex, setActivePageIndex] = useState<null | number>(null);
  const [filters, setFilters] = useState<
    UserTableWithOrganizationsProps["filters"]
  >({ search: "" });
  const [activeSort, setActiveSort] = useState<SortType>({
    column: TableColumnSortable.Name,
    direction: TableColumnSortDirection.Asc,
  });

  const hasUserGroupManagePermissions = permissions?.mayManageUserGroups;

  const fetchMoreUsers = useCallback(
    async (page = 1) => {
      const count = userCounts?.[filters.status] ?? userCounts?.total ?? 0;
      if (page > 1 && typeof count === "number" && count <= users.length) {
        return;
      }

      const res = await fetchUsers(userGroup.id, {
        page,
        perPage: USERS_PER_FETCH,
        sortField: SORT_FIELD_TO_COLUMN_MAP[activeSort.column],
        sortOrder: activeSort.direction,
        search: filters.search || undefined,
        statusFilter: filters.status || undefined,
        organizationIds: idsToStrOrganizationIds(filters.organizationIds ?? []),
      });

      if (res.succeed) {
        if (page === 1) {
          setUsers(res.users ?? []);
        } else {
          setUsers((prev) => [...prev, ...(res.users ?? [])]);
        }
        setActivePageIndex(page);
        if (page === 1) {
          setUserCounts(res.counts);
        }
        setIsRePopulatingUsers(false);
      }
    },
    [userGroup.id, activePageIndex, activeSort, userCounts, fetchUsers, filters]
  );

  const actionMenuItems: UserTableWithOrganizationsProps["actionMenuItems"] =
    useMemo(
      () => [
        {
          label: t("edit_user"),
          icon: "edit",
          href: (user: User) => routes.userPath(user.id.toString()),
        },
      ],
      []
    );

  useEffect(() => {
    if (isFetching) {
      return;
    }
    setIsRePopulatingUsers(true);
    fetchMoreUsers(1);
  }, [activeSort, filters]);

  const headerButtons: HeaderButton[] = hasUserGroupManagePermissions
    ? [
        {
          text: t("edit_user_group"),
          variant: "contained",
          onClick: () => {
            navigate(routes.userGroupEditPath(userGroup.id.toString()));
          },
        },
      ]
    : [];

  return (
    <>
      <BackButton text={t("back_to_user_groups")} navigateTo={origin} mb={3} />

      <Page
        header={
          <Header
            title={userGroup.name}
            description={userGroup.description}
            buttons={headerButtons}
          />
        }
        mb={3}
        pt={2}
      >
        <Typography variant="h5">{t("roles")}</Typography>
        <Typography variant="body1" color="textSecondary">
          {t("user_group_consists")}
        </Typography>
        <Box mt={3}>
          <Roles
            apps={apps}
            roles={roles}
            userGroupId={userGroup.id}
            hasUserGroupManagePermissions={hasUserGroupManagePermissions}
          />
        </Box>
      </Page>

      <Page header={<Header title={t("users")} />} mb={5} pt={1}>
        <Box mx={-4}>
          <UserTableWithOrganizations
            users={users}
            filters={filters}
            onFiltersChange={setFilters}
            onRequestMore={() => fetchMoreUsers(activePageIndex + 1)}
            counts={userCounts}
            companyTypes={companyTypes}
            isRePopulating={isRePopulatingUsers}
            isLoading={isFetching}
            sort={activeSort}
            onSortChange={setActiveSort}
            actionMenuItems={actionMenuItems}
          />
        </Box>
      </Page>
    </>
  );
}

function UserGroupShowV2(props: Readonly<UserGroupShowV2Props>) {
  return (
    <OriolaThemeProvider>
      <UserGroupShowInternal {...props} />
    </OriolaThemeProvider>
  );
}

export default UserGroupShowV2;
