import React, { ReactNode } from "react";
import Box from "@oriola-origo/core/lib/Box";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Typography from "@oriola-origo/core/lib/Typography";
import { styled } from "@oriola-origo/core/lib/styles";
import Tooltip from "@oriola-origo/core/lib/Tooltip";

export interface DetailsIconProps {
  icon: string;
  endAdornmentText?: string;
  tooltipContent?: ReactNode;
}

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: "50%",
  backgroundColor: theme.palette.secondary.lightest,
}));

/**
 * Map legacy icons to new icons
 */
const resolveFontIconName = (icon: string) => {
  if (icon === "fingerprint.svg") {
    return "fingerprint";
  } else if (icon === "research.png") {
    return "biotech";
  }
  return icon;
};

const ICON_FONT_SIZE = 16;

function DetailsIcon({
  icon,
  endAdornmentText,
  tooltipContent,
}: Readonly<DetailsIconProps>) {
  return (
    <Tooltip title={tooltipContent ?? ""}>
      <Box display="flex" alignItems="center">
        <IconWrapper display="flex" justifyContent="center" alignItems="center">
          <FontIcon
            sx={(theme) => ({
              color: theme.palette.secondary.light,
              fontSize: ICON_FONT_SIZE,
            })}
          >
            {resolveFontIconName(icon)}
          </FontIcon>
        </IconWrapper>
        {!!endAdornmentText && (
          <Typography variant="body2" display="inline" color="textPrimary">
            {endAdornmentText}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
}

export default DetailsIcon;
