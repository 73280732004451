import React, { useState } from "react";
import ToggleItemsList, {
  ALL_CATEGORY_ID,
  ItemsActiveCategoryId,
  ToggleItemsListItem,
} from "@components/common/toggle_items_list";
import { UserFormV2Props } from "@components/user/form_v2/types";

// User group category name is same for all languages, hence we use this adhoc name
// for category that marks the user groups that dont have any category attached to them.
const UNCATEGORIZED_CATEGORY_TITLE = "Uncategorized";
const UNCATEGORIZED_CATEGORY_FAKE_ID = -999999;

const createUnlocalizedCategoryTitle = (name: string) => ({
  fi: name,
  en: name,
  sv: name,
});

export interface AssignUserGroupsProps {
  userGroups: UserFormV2Props["user_groups"];
  userGroupCategories: UserFormV2Props["user_group_categories"];
  permissions: UserFormV2Props["permissions"];
  selectedUserGroupIds: number[];
  onSelectedUserGroupIdsChange: (
    newUserGroupIds: number[],
    oldState: number[]
  ) => void;
  disabled?: boolean;
}

export function AssignUserGroups({
  userGroupCategories,
  userGroups,
  permissions,
  selectedUserGroupIds,
  onSelectedUserGroupIdsChange,
  disabled
}: Readonly<AssignUserGroupsProps>) {
  const [activeCategoryId, setActiveCategoryId] =
    useState<ItemsActiveCategoryId>(ALL_CATEGORY_ID);

  const sortedCategories = [...userGroupCategories]
    .sort((a, b) => a.position - b.position)
    .map((category) => ({
      id: category.id,
      title: createUnlocalizedCategoryTitle(category.name),
    }));

  const categories = [
    {
      id: UNCATEGORIZED_CATEGORY_FAKE_ID,
      title: createUnlocalizedCategoryTitle(UNCATEGORIZED_CATEGORY_TITLE),
    },
    ...sortedCategories,
  ];

  const items: { [categoryId: number]: ToggleItemsListItem[] } =
    userGroups.reduce((acc, userGroup) => {
      const categoryId =
        userGroup.user_group_category_id ?? UNCATEGORIZED_CATEGORY_FAKE_ID;
      if (!acc[categoryId]) {
        acc[categoryId] = [];
      }

      acc[categoryId].push({
        id: userGroup.id,
        name: userGroup.name,
        description: userGroup.description,
        disabled: userGroup.is_superuser && !permissions.assign_superuser_roles,
      } as ToggleItemsListItem);

      return acc;
    }, {});

  // Remove categories that don't have any user groups
  const categoriesWithUserGroups = categories.filter(
    (category) => items[category.id]?.length > 0
  );

  return (
    <ToggleItemsList
      categories={categoriesWithUserGroups}
      items={items}
      selectedItemsIds={selectedUserGroupIds}
      onSelectedItemIdsChange={onSelectedUserGroupIdsChange}
      activeCategoryId={activeCategoryId}
      onActiveCategoryChange={setActiveCategoryId}
      disabled={disabled}
    />
  );
}
