import React from "react";
import IconButton from "@oriola-origo/core/lib/IconButton";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Menu from "@oriola-origo/core/lib/Menu";
import MenuItem from "@oriola-origo/core/lib/MenuItem";
import { styled } from "@oriola-origo/core/lib/styles";
import NoStyleAnchor from "@components/common/no_style_anchor";
import { BaseCell, BaseCellProps } from "./common_cells";

export type ActionsCellResource = {
  id: number;
};

export interface ActionsCellItem {
  label: string;
  icon?: string;
  href?: string | ((resource: ActionsCellResource) => string);
  disabled?: boolean | ((resource: ActionsCellResource) => boolean);
  hidden?: boolean | ((resource: ActionsCellResource) => boolean);
  onClick?: (
    resource: ActionsCellResource
  ) => void | boolean | Promise<void> | Promise<boolean>;
  render?: (resource: ActionsCellResource) => React.ReactNode;
}

export interface ActionsCellProps {
  resource: ActionsCellResource;
  items: ActionsCellItem[];
  verticalAlign?: BaseCellProps["verticalAlign"];
  isDisabled: boolean;
  isMenuOpen: boolean;
  onMenuOpen: () => void;
  onMenuClose: () => void;
}

const StyledIconButton = styled(IconButton)({
  padding: 0,
});

function ActionsCell({
  resource,
  items,
  verticalAlign,
  isDisabled,
  isMenuOpen,
  onMenuOpen,
  onMenuClose,
}: Readonly<ActionsCellProps>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const renderMenuItems = () => {
    return items.map((item, index) => {
      const { label, icon, href, onClick, render, disabled, hidden } = item;
      const key = `${label}-${index}`;

      const isDisabled =
        typeof disabled === "function" ? disabled(resource) : disabled;

      const isHidden = typeof hidden === "function" ? hidden(resource) : hidden;
      if (isHidden) {
        return null;
      }

      if (typeof render === "function") {
        return render(resource);
      }

      const handleClick = async () => {
        if (typeof onClick === "function") {
          const res = await Promise.resolve(onClick(resource));
          if (res) {
            onMenuClose();
          }
        }
      };

      return (
        <NoStyleAnchor
          key={key}
          disabled={isDisabled}
          href={typeof href === "function" ? href(resource) : href}
          role="<menuitem>"
        >
          <MenuItem onClick={handleClick} disabled={isDisabled}>
            {icon && <FontIcon sx={{ mr: 1 }}>{icon}</FontIcon>}
            {label}
          </MenuItem>
        </NoStyleAnchor>
      );
    });
  };

  return (
    <BaseCell verticalAlign={verticalAlign} isDisabled={isDisabled}>
      {items.length > 0 && (
        <>
          <StyledIconButton
            ref={(node) => setAnchorEl(node)}
            disabled={isDisabled}
            onClick={onMenuOpen}
            color="secondary"
          >
            <FontIcon>more_vert</FontIcon>
          </StyledIconButton>
          <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={onMenuClose}>
            {renderMenuItems()}
          </Menu>
        </>
      )}
    </BaseCell>
  );
}

export default ActionsCell;
