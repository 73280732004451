import { useCallback, useMemo, useRef } from "react";
import useFetch from "@hooks/useFetch";
import useRoutes, { OrganizationListOpts } from "@hooks/useRoutes";
import { Organization, OrigoApplicationRole } from "../types";
import useCSRFToken from "@hooks/useCSRFToken";

type OrganizationResult<T> = {
  succeed: boolean;
  data?: T;
};

export type ListedOrganization = Organization & {
  user_count: number;
  hierarchy_child_company_api_ids?: string[];
  all_roles: OrigoApplicationRole[];
  origin: string;
  logo?: {
    name: string;
    content_type: string;
    scaled_content_type: string;
  };
};
export type FetchOrganizationsOpts = {
  page?: number;
  perPage?: number;
  statusFilter?: string;
  organizationTypesFilter?: string[];
  search?: string;
  origin?: string;
};

export type FetchOrganizationsResult = OrganizationResult<{
  rows: ListedOrganization[];
  counts: {
    total: number;
    active: number;
    passive: number;
  };
}>;

export type FetchOrganizationRolesResult = OrganizationResult<{
  [origoApplicationId: number]: number[];
}>;

export type fetchOrganizationAncestorsResult = OrganizationResult<
  Array<{
    api_id: string;
    hierarchy_level: number;
    hierarchy_parent_company_ids: number[];
    id: number;
    name: string;
  }>
>;

const fetchOrganizationOptsToRouteOpts = (
  opts: FetchOrganizationsOpts
): OrganizationListOpts => {
  const columnFilters = {};
  if (opts.statusFilter) {
    columnFilters["status"] = opts.statusFilter;
  }

  if (opts.origin && opts.origin !== "all") {
    columnFilters["origin"] = opts.origin;
  }

  if (opts.organizationTypesFilter?.length > 0) {
    columnFilters["type_str_id"] = opts.organizationTypesFilter;
  }

  return {
    page: opts.page,
    sizePerPage: opts.perPage,
    globalFilter: opts.search?.trim(),
    columnFilters:
      Object.keys(columnFilters).length > 0 ? columnFilters : undefined,
  };
};

export default function useOrganizations() {
  const { doFetch, isFetching } = useFetch();
  const { doFetch: doDelete, isFetching: isDeleting } = useFetch();
  const fetchOrganizationsAbortControllerRef = useRef<AbortController | null>(
    null
  );
  const csrfToken = useCSRFToken();
  const routes = useRoutes();

  const abortIfFetching = () => {
    if (fetchOrganizationsAbortControllerRef.current) {
      fetchOrganizationsAbortControllerRef.current.abort();
    }
  };

  const fetchOrganizations = useCallback(
    async (opts: FetchOrganizationsOpts): Promise<FetchOrganizationsResult> => {
      abortIfFetching();

      const abortController = new AbortController();
      fetchOrganizationsAbortControllerRef.current = abortController;
      try {
        const response = await doFetch(
          routes.companyListPath(fetchOrganizationOptsToRouteOpts(opts)),
          { signal: abortController.signal }
        );
        if (!response.ok) {
          return { succeed: false };
        }

        const data = await response.json();
        return {
          succeed: true,
          data: {
            rows: data.rows,
            counts: {
              total: data.total_count,
              active: data.total_active_count,
              passive: data.total_passive_count,
            },
          },
        };
      } catch (e) {
        return { succeed: false };
      } finally {
        fetchOrganizationsAbortControllerRef.current = null;
      }
    },
    [fetchOrganizationsAbortControllerRef.current]
  );

  const fetchOrganizationRoles = useCallback(
    async (organizationId: number): Promise<FetchOrganizationRolesResult> => {
      const response = await doFetch(
        routes.companyListRoleIdsPath({ company_id: organizationId })
      );
      if (!response.ok) {
        return { succeed: false };
      }

      const data = await response.json();
      return {
        succeed: true,
        data,
      };
    },
    [routes]
  );

  const fetchOrganizationAncestors = useCallback(
    async (
      organizationId: number
    ): Promise<fetchOrganizationAncestorsResult> => {
      const response = await fetch(
        routes.companySearchPath({ ancestor_company_id: organizationId })
      );
      if (!response.ok) {
        return { succeed: false };
      }

      const data = await response.json();
      return {
        succeed: true,
        data,
      };
    },
    [routes]
  );

  const deleteOrganization = useCallback(
    async (organizationId: number): Promise<{ succeed: boolean }> => {
      const response = await doDelete(
        routes.companyDeletePath(organizationId),
        {
          method: "DELETE",
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      );
      return { succeed: response.ok };
    },
    [routes, doDelete]
  );

  return useMemo(
    () => ({
      fetchOrganizationRoles,
      fetchOrganizationAncestors,
      fetchOrganizations,
      deleteOrganization,
      isDeleting,
      isFetching,
    }),
    [
      fetchOrganizationRoles,
      fetchOrganizationAncestors,
      fetchOrganizations,
      deleteOrganization,
      isDeleting,
      isFetching,
    ]
  );
}
