import React from 'react';

const styles = {
    tipIcon: {
        fontSize: '200%',
        width: '5rem',
        height: '5rem',
        lineHeight: '5rem',
        backgroundColor: '#e7ebf3'
    },
    navButton: {
        backgroundColor: '#e7ebf3',
        width: '2rem'
    },
    nav: {
        position: 'relative',
        left: '-3.5rem',
        whiteSpace: 'nowrap'
    }
};

export class Tips extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            tipIds: (this.props.settings.tip_ids || []).map(i => parseInt(i, 10)),
            tips: [],
            tipOptions: null,
            currentIndex: 0
        }
    }

    render() {
        if (this.props.mode == Origo.Widget.Mode.SETTINGS) {
            return this.renderSettings();
        } else {
            return this.renderView();
        }
    }

    renderView() {
        let tips = null;

        if (this.state.loading) {
            tips = <h2 className="text-muted text-center">
                {I18n.t('loading')}...
            </h2>;
        } else if (this.state.tips.length > 0) {
            const tip = this.state.tips[this.state.currentIndex];

            tips = <div className="row flex-nowrap">
                <div className="col-auto">
                    <div style={styles.tipIcon} className="text-center rounded-circle m-1">
                        {tip.icon ? <i className={`oricon-${tip.icon}`}></i> : <i className="fa fa-exclamation text-primary"></i>}
                    </div>
                </div>
                <div className="col">
                    <div className="text-center mb-3" style={styles.nav}>
                        <button style={styles.navButton} className="btn btn-sm rounded-circle mr-3" type="button" onClick={e => this.navigateTips(-1)}>
                            <i className="fa fa-chevron-left"></i>
                        </button>
                        <span>
                            {I18n.t('usage_tip')} {this.state.currentIndex + 1}/{this.state.tips.length}
                        </span>
                        <button style={styles.navButton} className="btn btn-sm rounded-circle ml-3" type="button" onClick={e => this.navigateTips(1)}>
                            <i className="fa fa-chevron-right"></i>
                        </button>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: StringHelper.simpleFormat(tip.content[I18n.locale]) }}></div>
                </div>
            </div>
        } else {
            tips = <h2 className="text-muted text-center mt-3">
                {I18n.t('widget_no_tips_to_show_message')}
            </h2>;
        }
        return <div className="px-3 pt-3">
            {tips}
        </div>
    }

    renderSettings() {
        return <form className="settings p-3">
            <h3 className="mb-3">{I18n.t('usage_tips')}</h3>
            {this.state.tipIds.map((tipId, index) => <div key={index} className="form-group">
                <div className="row">
                    <div className="col-auto pt-2">
                        <div style={{width: '1rem'}}>{index + 1}.</div>
                    </div>
                    <div className="col">
                        <select className="form-control custom-select" name="tip_ids[]" value={tipId} onChange={e => this.udpateTip(index, e.currentTarget.options[e.currentTarget.selectedIndex].value)}>
                            {this.renderTipOptions()}
                        </select>
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-light" onClick={e => this.deleteTip(index)}>
                            <i className="oricon-bin"></i>
                        </button>
                    </div>
                </div>
            </div>)}
            <div className="form-group">
                <div className="row">
                    <div className="col-auto pt-2">
                        <div style={{ width: '1rem' }}><i className="fa fa-plus text-highlight"></i></div>
                    </div>
                    <div className="col">
                        <select className="form-control custom-select" value="" onChange={e => this.addTip(e.currentTarget.options[e.currentTarget.selectedIndex].value)}>
                            <option value="">{I18n.t('select_blank_text')}</option>
                            {this.renderTipOptions()}
                        </select>
                    </div>
                </div>
            </div>
        </form>;
    }

    renderTipOptions() {
        return (this.state.tipOptions || []).map(o => <option key={o.id} value={o.id}>{o.content[I18n.locale]}</option>);
    }

    componentDidMount() {
        this.fetchTips(() => {
            if (this.props.mode == Origo.Widget.Mode.VIEW) {
                // On next viewing the tip shall be different
                jQuery.post(Routes.dashboard_widget_update_data_path({ widget: 'Tips', method: 'update_current_index' }), {
                    index: this.getNextIndex(this.state.currentIndex, 1), instance_id: this.props.instanceId
                });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.mode == Origo.Widget.Mode.SETTINGS && this.state.tipOptions == null) {
            this.fetchTipOptions();
        }
    }

    addTip = (tipId) => {
        if (tipId) {
            this.setState(prevState => {
                let ids = prevState.tipIds;
                ids.push(parseInt(tipId, 10));
                return { tipIds: ids };
            }, this.updateSettings);
        }
    }

    udpateTip = (index, tipId) => {
        this.setState(prevState => {
            let ids = prevState.tipIds;
            ids[index] = parseInt(tipId, 10);
            return { tipIds: ids };
        }, this.updateSettings);
    }

    deleteTip = (index) => {
        this.setState(prevState => {
            let ids = prevState.tipIds;
            ids.splice(index, 1);
            return { tipIds: ids };
        }, this.updateSettings);
    }

    navigateTips = (step) => {
        let nextIndex = this.getNextIndex(this.state.currentIndex, step);
        jQuery.post(Routes.dashboard_widget_update_data_path({ widget: 'Tips', method: 'update_current_index' }), { index: this.getNextIndex(nextIndex, 1), instance_id: this.props.instanceId }, () => {
            this.setState({ currentIndex: nextIndex });
        });
    }

    updateSettings() {
        this.props.updateSettings({tip_ids: this.state.tipIds});
    }

    fetchTips(callback) {
        if (this.state.tipIds.length > 0) {
            jQuery.getJSON(Routes.dashboard_widget_data_path({ widget: 'Tips', method: 'tips', tip_ids: this.state.tipIds, instance_id: this.props.instanceId }), (data) => {
                this.setState(prevState => {
                    const idx = data.current_index;
                    return {
                        tips: data.tips,
                        loading: false,
                        currentIndex: idx >= data.tips.length ? data.tips.length > 0 ? data.tips.length - 1 : 0 : idx
                    };
                }, callback);
            });
        } else {
            this.setState({
                tips: [],
                loading: false,
                currentIndex: 0
            }, callback);
        }
    }

    fetchTipOptions() {
        jQuery.getJSON(Routes.dashboard_widget_data_path({ widget: 'Tips', method: 'tip_options' }), (data) => {
            this.setState({
                tipOptions: data,
                loading: false
            });
        });
    }

    getNextIndex(index, step) {
        let nextIndex = index + step;

        if (nextIndex >= this.state.tipIds.length) {
            nextIndex = 0;
        } else if (nextIndex < 0) {
            nextIndex = this.state.tipIds.length - 1;
        }
        return nextIndex;
    }
}