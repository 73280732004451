import React from 'react';

export function ContentSection(props) {
    return <div className={[props.className, 'content-section'].filter(c => c).join(' ')}>
        {props.children}
    </div>;
}

export function ContentSectionHead(props) {
    return <div className={[props.className, 'content-section-head'].filter(c => c).join(' ')}>
        {props.children}
    </div>;
}