import React, { useState, useEffect } from "react";
import Box, { BoxProps } from "@oriola-origo/core/lib/Box";
import { styled } from "@oriola-origo/core/lib/styles";
import useMediaQuery from "@oriola-origo/core/lib/useMediaQuery";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import OriolaSnackbarProvider from "@oriola-origo/core/lib/Snackbar/SnackbarProvider";

import Loading from "../common/loading";
import { fetchCacheData, getReportUrlsList } from "./utils";
import NewReportsPageSidebar from "./new_reports_page_sidebar";
import NewReportPageContent, {
  INewReportPageContentProps,
} from "./new_reports_page_content";

const NAVIGATION_PANE_HEIGHT_MOBILE = 126;

interface INewReportPageLayoutProps extends INewReportPageContentProps {
  id: string;
  access_token: string;
}

interface RootProps extends BoxProps {
  isMobileScreen?: boolean;
}

const Root = styled(Box)<RootProps>(({ isMobileScreen }) => ({
  display: "flex",
  flexDirection: isMobileScreen ? "column" : "row",
  width: "100%",
  height: "auto",
}));

export function NewReportPageLayout(
  props: Readonly<INewReportPageLayoutProps>
) {
  const [loading, setLoading] = useState(true);
  const [reportUrls, setReportUrls] = useState(null);

  const isMobileScreen = useMediaQuery("(max-width:600px)"); // ODS breakpoint sm - 600px
  const mainContentHeight = !isMobileScreen ? "100vh" : `calc(100vh - ${NAVIGATION_PANE_HEIGHT_MOBILE}px)`;

  const getReportDashboards = (key: string, cache: any) => {
    if (key.includes("origo-user-data")) {
      storeReportUrls(cache);
    }
  };

  const storeReportUrls = (cache: any) => {
    const reportUrlsList = getReportUrlsList(cache);
    setReportUrls(reportUrlsList);
    setLoading(false);
  };

  useEffect(() => {
    const cache = fetchCacheData(props.access_token);
    if (cache) {
      storeReportUrls(cache);
    } else {
      Origo.cache.event.on("cache-set", getReportDashboards);
      return () => {
        Origo.cache.event.off("cache-set", getReportDashboards);
      };
    }
  }, []);

  return (
    <OriolaThemeProvider>
      <OriolaSnackbarProvider>
        {loading ? (
          <Loading />
        ) : (
          <Root data-testid="reports-page-root" isMobileScreen={isMobileScreen}>
            {reportUrls.items.length > 1 && (
              <Box order={isMobileScreen ? 2 : 1} zIndex={100} bottom={0} position={isMobileScreen ? "sticky" : "relative"}>
                <NewReportsPageSidebar
                  dashboards={reportUrls.items}
                  isMobileScreen={isMobileScreen}
                ></NewReportsPageSidebar>
              </Box>
            )}
            <Box order={isMobileScreen ? 1 : 2} width="100%" height={mainContentHeight}>
              <NewReportPageContent isMobileScreen={isMobileScreen} {...props} />
            </Box>
          </Root>
        )}
      </OriolaSnackbarProvider>
    </OriolaThemeProvider>
  );
}

export default NewReportPageLayout;
