import Box from "@oriola-origo/core/lib/Box";
import { styled } from "@oriola-origo/core/lib/styles";

const ForceChildTableToFixedLayoutWrapper = styled(Box)({
  "& table": {
    tableLayout: "fixed",
  },
});

export default ForceChildTableToFixedLayoutWrapper;
