import React from "react";
import TableRow from "@oriola-origo/core/lib/TableRow";
import TableCell from "@oriola-origo/core/lib/TableCell";
import useTranslations from "@hooks/useTranslations";
import Filters, { FiltersType } from "./filters";
import {
  HeaderPlaceholderCell,
  NoBottomBorderTableCell,
} from "@components/common/user_table/cells/common_cells";
import { OrganizationTableProps } from "../organization_table";
import { COLUMN_FIXED_WIDTHS, TableColumnNonSortable } from "../columns";
import HideableIconButton from "./hideable_icon_button";
import FontIcon from "@oriola-origo/core/lib/Icons";
import { CompanyType } from "@services/user_management/types";
import NameCell from "./name_cell";
import { getColumnWidthFactory } from "@components/common/user_management_base_table/columns";

export interface HeadersProps {
  columns: Array<TableColumnNonSortable>;
  tableWidth: number;
  companyTypes: CompanyType[];
  counts?: OrganizationTableProps["counts"];
  filters?: FiltersType;
  onFiltersChange: (filters: FiltersType) => void;
}

function Headers({
  columns,
  tableWidth,
  companyTypes,
  filters,
  counts,
  onFiltersChange,
}: Readonly<HeadersProps>) {
  const { t } = useTranslations();

  const renderWidthReservingCells = () => {
    const columnWidths = {};

    columns.forEach((column) => {
      columnWidths[column] = COLUMN_FIXED_WIDTHS[column];
    });

    const getColumnWidth = getColumnWidthFactory(columnWidths);

    return columns.map((column) => (
      <HeaderPlaceholderCell
        key={column}
        width={getColumnWidth(column, tableWidth)}
      />
    ));
  };

  return (
    <>
      <TableRow>{renderWidthReservingCells()}</TableRow>
      <TableRow>
        <NoBottomBorderTableCell colSpan={columns.length}>
          <Filters
            companyTypes={companyTypes}
            counts={counts}
            filters={filters}
            onFiltersChange={onFiltersChange}
          />
        </NoBottomBorderTableCell>
      </TableRow>
      <TableRow>
        <NameCell>
          <HideableIconButton invisible={true}>
            <FontIcon>add</FontIcon>
          </HideableIconButton>
          {t("name")}
        </NameCell>
        {columns.includes(TableColumnNonSortable.Type) && (
          <TableCell>{t("type")}</TableCell>
        )}
        {columns.includes(TableColumnNonSortable.Users) && (
          <TableCell>{t("users")}</TableCell>
        )}
        {columns.includes(TableColumnNonSortable.Details) && (
          <TableCell>{t("user_table_details")}</TableCell>
        )}
        <TableCell>{t("status")}</TableCell>
        <TableCell>{t("user_table_actions")}</TableCell>
      </TableRow>
    </>
  );
}

export default Headers;
