import React, { useEffect, useState } from "react";
import QuickFilter from "@oriola-origo/core/lib/QuickFilter";
import useUserGroupCategories, {
  UserGroupCategory,
} from "../../../services/user_management/hooks/useUserGroupCategories";
import useTranslations from "../../../hooks/useTranslations";
import useSnackbar from "../../../hooks/useSnackbar";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Button from "@oriola-origo/core/lib/Button";
import Grid from "@oriola-origo/core/lib/Grid";
import EditCategory from "./editCategory";

interface UserGroupCategoryListProps {
  onCategoryClick: (id: string) => void;
  setShouldFetchCategories: (value: boolean) => void;
  hasUserGroupCategoriesManagePermissions?: boolean;
}

const createBaseFilter = (categories: UserGroupCategory[]) => {
  const baseFilter: { id: string | number; name: string }[] = [];

  // Filter for all
  baseFilter.push({
    id: "all",
    name: "All",
  });

  categories.forEach((category) => {
    baseFilter.push({
      id: category.id,
      name: category.name,
    });
  });

  return baseFilter;
};

function UserGroupCategoryList({
  onCategoryClick,
  setShouldFetchCategories,
  hasUserGroupCategoriesManagePermissions,
}: Readonly<UserGroupCategoryListProps>) {
  const [categories, setCategories] = useState<UserGroupCategory[]>([]);
  const { fetchUserGroupCategories } = useUserGroupCategories();
  const { setSnackMessage } = useSnackbar();
  const { t } = useTranslations();

  const baseFilters = createBaseFilter(categories);
  const [active, setActive] = useState<string>("all");
  const handleFilterChange = (id: string) => {
    setActive(id);
    onCategoryClick(id);
  };

  const handleFetchCategories = async () => {
    const res = await fetchUserGroupCategories();
    if (res.succeed) {
      setCategories(res.categories);
    } else {
      setSnackMessage(t("error_occurred"), "error");
    }
  };

  const fetchCategoriesAfterUpdate = async () => {
    setShouldFetchCategories(true);
    await handleFetchCategories();
  };

  useEffect(() => {
    const fetchCategories = async () => {
      handleFetchCategories();
    };
    fetchCategories();
  }, []);

  if (categories.length === 0) {
    return (
      <OriolaThemeProvider>
        <Button color="secondary" isLoading variant="contained">
          {t("loading")}
        </Button>
      </OriolaThemeProvider>
    );
  }

  return (
    <>
      <Grid>
        <QuickFilter
          filters={baseFilters}
          onFilterClicked={handleFilterChange}
          activeId={active}
        />
      </Grid>
      {hasUserGroupCategoriesManagePermissions && (
        <Grid>
          <EditCategory
            categories={categories}
            fetchCategoriesAfterUpdate={fetchCategoriesAfterUpdate}
          />
        </Grid>
      )}
    </>
  );
}

export default UserGroupCategoryList;
