import { useCallback, useMemo } from "react";
import useSnackbar from "@hooks/useSnackbar";
import useTranslations, { TranslateFunction } from "@hooks/useTranslations";
import useEditableUser from "@components/user/contexts/editable_user";
import { UserAccessCompanyMap } from "@components/user/components/user_access_information/user_access_information";
import { UserCompany } from "@services/user_management/types/users";
import { ICompanySelection } from "@services/user_management/hooks/inviteUser";

export default function useUserAccessAndOrg() {
  const { patchUpdate, isUpdating: isUpdatingUser, user } = useEditableUser();
  const { setSnackMessage } = useSnackbar();
  const { t } = useTranslations();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resolveErrorMessage = (error: any, t: TranslateFunction) => {
    const errorMessage = Object.values(error.errors)[0][0].message ?? null;
    return errorMessage || t("user_access_and_org_error_on_saving");
  };

  const updateUserAccessMap = useCallback(
    async (
      properties: UserAccessCompanyMap,
      userCompaniesDetails: UserCompany[]
    ) => {
      try {
        const companySelection = mapUserAccessToPayload(
          properties,
          userCompaniesDetails
        );
        const res = await patchUpdate({ company_selection: companySelection });
        res.succeed
          ? setSnackMessage(
              t("user_access_and_org_success_saving"),
              "success",
              false,
              2000
            )
          : setSnackMessage(resolveErrorMessage(res.error, t), "error", true);
      } catch (error) {
        setSnackMessage(t("user_access_and_org_error_on_saving"), "error");
      }
    },
    [patchUpdate, setSnackMessage]
  );

  const createUserAccessMapFromCompaniesData = (companies: UserCompany[]) => {
    const userAccessMap = {};
    for (const company of companies) {
      userAccessMap[company.id] = {
        roleIds: company.role_ids,
        userGroupIds: company.user_group_ids,
      };
    }

    return userAccessMap;
  };

  const mapUserAccessToPayload = (
    userAccessMap: UserAccessCompanyMap,
    userCompaniesDetails: UserCompany[]
  ): ICompanySelection[] => {
    const companySelection = [];
    for (const org of userCompaniesDetails) {
      const key = String(org.id);

      companySelection.push({
        id: key,
        role_ids: userAccessMap[key].roleIds || [],
        user_group_ids: userAccessMap[key].userGroupIds || [],
        professional_title_id: org?.professional_title_id,
        profit_center_ids: org?.profit_center_ids || [],
        scc_profit_center_ids: org?.scc_profit_center_ids || [],
        default: org?.default,
      });
    }
    return companySelection;
  };

  return useMemo(
    () => ({
      user,
      isUpdatingUser,
      updateUserAccessMap,
      createUserAccessMapFromCompaniesData,
      mapUserAccessToPayload,
    }),
    [
      user,
      isUpdatingUser,
      updateUserAccessMap,
      createUserAccessMapFromCompaniesData,
      mapUserAccessToPayload,
    ]
  );
}
