import TableCell, { TableCellProps } from "@oriola-origo/core/lib/TableCell";
import { styled } from "@oriola-origo/core/lib/styles";

export const NoBottomBorderTableCell = styled(TableCell)({
  borderBottomWidth: "0 !important",
});

export const HeaderPlaceholderCell = styled(NoBottomBorderTableCell)<{
  width: number | string;
}>(({ width }) => ({
  padding: 0,
  width,
}));

export interface BaseCellProps extends TableCellProps {
  verticalAlign?: "top" | "middle";
  isDisabled?: boolean;
}

export const BaseCell = styled(TableCell, {
  shouldForwardProp: (prop) =>
    !["verticalAlign", "isDisabled"].includes(prop as string),
})<BaseCellProps>(({ verticalAlign, isDisabled }) => ({
  verticalAlign: verticalAlign ?? "middle",
  opacity: isDisabled ? 0.1 : undefined,
}));
