import React, { PropsWithChildren, useEffect, useState } from "react";
import Sticky, { StickyProps } from "@components/common/sticky";
import { Organization } from "@services/user_management/types";
import useTranslations from "@hooks/useTranslations";
import { isNonSystemOrigoOrganization } from "@components/organization/utils";
import MenuItem from "@oriola-origo/core/lib/MenuItem";
import Link from "@oriola-origo/core/lib/Link";
import Box from "@oriola-origo/core/lib/Box";
import ListItemIcon from "@oriola-origo/core/lib/ListItemIcon";
import FontIcon from "@oriola-origo/core/lib/Icons";
import ListItemText from "@oriola-origo/core/lib/ListItemText";
import Menu from "@oriola-origo/core/lib/Menu";
import IconButton from "@oriola-origo/core/lib/IconButton";
import Header from "@oriola-origo/core/lib/Header";
import Page from "@oriola-origo/core/lib/Page";
import DeleteConfirmation from "@components/common/delete_confirmation";
import OrganizationStatusIndicator from "./organization_status_indicator";

export interface OrganizationPageWithHeaderProps extends PropsWithChildren {
  organization: Organization;
  permissions: {
    may_delete: boolean;
    may_show_activity_log: boolean;
  };
  isUpdating: boolean;
  isDeleting: boolean;
  onSave: (property: string, value: any) => void | Promise<void>;
  onDelete?: () => void;
  stickyProps?: StickyProps;
}

export default function OrganizationPageWithHeader({
  children,
  organization,
  permissions,
  onSave,
  isUpdating,
  onDelete,
  isDeleting,
  stickyProps,
}: Readonly<OrganizationPageWithHeaderProps>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isActive, setIsActive] = useState(organization.active);
  const open = Boolean(anchorEl);
  const { t } = useTranslations();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  useEffect(() => {
    if (!isDeleting) {
      setDeleteConfirmationOpen(false);
    }
  }, [isDeleting]);

  const isDeleteOrganizationButtonVisible =
    permissions.may_delete && isNonSystemOrigoOrganization(organization);

  const shouldShowHeaderActionMenu =
    isDeleteOrganizationButtonVisible || permissions.may_show_activity_log;

  const handleDelete = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = () => (
    <Menu
      anchorEl={anchorEl}
      aria-label="Header menu"
      open={open}
      onClick={() => handleClose()}
    >
      {permissions.may_show_activity_log && (
        <MenuItem>
          <Link
            href={Routes.activity_log_list_path({
              target_type: "organization",
              target_id: organization.api_id,
            })}
          >
            <Box display="flex" sx={{ alignItems: "center" }}>
              <ListItemIcon>
                <FontIcon>published_with_changes</FontIcon>
              </ListItemIcon>
              <ListItemText>{t("organization_activity")}</ListItemText>
            </Box>
          </Link>
        </MenuItem>
      )}
      {isDeleteOrganizationButtonVisible && (
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <FontIcon>delete</FontIcon>
          </ListItemIcon>
          <ListItemText aria-label={t("delete_organization")}>
            {t("delete_organization")}
          </ListItemText>
        </MenuItem>
      )}
    </Menu>
  );

  const renderHeaderDescription = () => {
    return (
      <OrganizationStatusIndicator
        organizationOrigin={organization.origin}
        isOrganizationActive={isActive}
        isUpdating={isUpdating}
        onOrganizationActiveChange={async (active) => {
          setIsActive(active);
          await onSave("active", active);
        }}
      />
    );
  };

  const renderHeader = () => {
    return (
      <Header
        title={`${organization.api_id} ${organization.name} `}
        description={renderHeaderDescription()}
        titleSx={{
          color: (theme) =>
            isActive ? theme.palette.text.primary : theme.palette.text.disabled,
        }}
      >
        {shouldShowHeaderActionMenu && (
          <>
            <IconButton onClick={handleClick} aria-label="Open menu">
              <FontIcon>more_vert</FontIcon>
            </IconButton>
            {renderMenu()}
          </>
        )}
      </Header>
    );
  };

  const renderStickyHeader = () => (
    <Sticky
      viewportElement={stickyProps?.viewportElement}
      stickyContainerElement={stickyProps?.stickyContainerElement}
    >
      <Page header={renderHeader()} pb={0} pt={0} pr={0} pl={0} />
    </Sticky>
  );

  return (
    <>
      {stickyProps && renderStickyHeader()}
      <Page header={renderHeader()} mb={2} pt={3}>
        {children}
      </Page>
      <DeleteConfirmation
        open={deleteConfirmationOpen}
        title={t("delete_organization")}
        content={t("confirm_delete_organization", { name: organization.name })}
        isDeleting={isDeleting}
        onCancel={() => setDeleteConfirmationOpen(false)}
        onDelete={onDelete}
      />
    </>
  );
}
