import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

export default class Info extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <i
            title={this.props.text || renderToStaticMarkup(this.props.children)}
            className="fa fa-info-circle text-primary ml-1 info-tooltip"
            data-toggle="tooltip"
            data-html="true"
            data-placement={this.props.placement || "top"}
        ></i>;
    }

    static initTooltips() {
        jQuery('.info-tooltip').tooltip();
    }

}