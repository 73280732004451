import React, { useEffect, useState } from "react";
import DialogTitle from "@oriola-origo/core/lib/DialogTitle";
import DialogContent from "@oriola-origo/core/lib/DialogContent";
import DialogActions from "@oriola-origo/core/lib/DialogActions";
import Button from "@oriola-origo/core/lib/Button";
import { styled } from "@oriola-origo/core/lib/styles";
import useTranslations from "@hooks/useTranslations";
import OrganizationFilter, {
  OrganizationFilterOption,
} from "../../common/organization_filter/organization_filter";
import Autocomplete from "@oriola-origo/core/lib/Autocomplete/Autocomplete";
import Dialog from "@oriola-origo/core/lib/Dialog";
import Box from "@oriola-origo/core/lib/Box";
import Checkbox from "@oriola-origo/core/lib/Checkbox";
import ProfitCenterAutocomplete from "../form_v2/organization_information/components/profit_center_autocomplete";
import useOrganizations from "@services/user_management/hooks/useOrganizations";
import {
  ProfessionalTitle,
  ProfitCenter,
} from "@services/user_management/types";
import { IOrganizationSelection } from "../types";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: 650,
    width: "100%",
    backgroundColor: theme.palette.background.default,
    "& .MuiDialogContent-root": {
      paddingTop: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  },
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  padding: "5px 9px",
}));

function renderMoreText(ancestorOrgCount, t) {
  if (!ancestorOrgCount) {
    return " ";
  }
  if (ancestorOrgCount === 1) {
    return `+${ancestorOrgCount} ${t("sub_organization")}`;
  }
  return `+${ancestorOrgCount} ${t("sub_organizations")}`;
}

interface OrganizationWithChildren extends IOrganizationSelection {
  children: IOrganizationSelection[];
}

export interface AddOrganizationDialogProps {
  open: boolean;
  selectedOrganizationIds: number[];
  onToggleOpen: (value: boolean) => void;
  onAddOrganizations: (
    newOrganizations: IOrganizationSelection[],
    newDefaultOrganizationId?: number
  ) => void;
  defaultProfessionalTitleId: number;
  professionalTitles: ProfessionalTitle[];
  profitCenters: ProfitCenter[];
  sccProfitCenterAllowedOrganizationIds: number[];
}

function AddOrganizationDialog(props: Readonly<AddOrganizationDialogProps>) {
  const {
    open,
    onToggleOpen,
    professionalTitles,
    profitCenters,
    defaultProfessionalTitleId,
    sccProfitCenterAllowedOrganizationIds,
    selectedOrganizationIds,
    onAddOrganizations,
  } = props;

  const resolveDefaultProfessionalTitle = () =>
    professionalTitles.find(
      (title) => title.id === defaultProfessionalTitleId
    ) ?? null;

  const DEFAULT_FORM_DATA = {
    id: null,
    name: "",
    api_id: "",
    children: [],
    professionalTitle: resolveDefaultProfessionalTitle(),
    profitCenters: [],
    sccProfitCenters: [],
  };

  const { t } = useTranslations();
  const [addOrganizationFormData, setAddOrganizationFormData] =
    useState<OrganizationWithChildren>(DEFAULT_FORM_DATA);
  const [setAsDefaultOrg, setSetAsDefaultOrg] = useState(false);
  const [copyProfessionalTitle, setCopyProfessionalTitle] = useState(false);
  const [copyProfitCenters, setCopyProfitCenters] = useState(false);
  const { fetchOrganizationAncestors } = useOrganizations();

  const resetFields = () => {
    setAddOrganizationFormData(DEFAULT_FORM_DATA);
    setCopyProfessionalTitle(true);
    setCopyProfitCenters(false);
    setSetAsDefaultOrg(!selectedOrganizationIds.length);
  };

  useEffect(() => {
    if (open) {
      resetFields();
    }
  }, [open]);

  const fetchOrgAncestors = async (orgId: number) => {
    const res = await fetchOrganizationAncestors(orgId);
    if (!res.succeed) {
      return;
    }

    const data: IOrganizationSelection[] = (res.data ?? []).map((item) => ({
      ...item,
      professionalTitle: null,
      profitCenters: [],
      sccProfitCenters: [],
    }));

    setAddOrganizationFormData((prev) => ({
      ...prev,
      children: data,
    }));
  };

  const handleAddOrganizationToUser = () => {
    const defaultProfessionalTitle = resolveDefaultProfessionalTitle();
    const childProfessionalTitle = copyProfessionalTitle
      ? addOrganizationFormData.professionalTitle
      : defaultProfessionalTitle;

    addOrganizationFormData.children = addOrganizationFormData.children.map(
      (child) => ({
        ...child,
        professionalTitle: childProfessionalTitle,
      })
    );

    if (copyProfitCenters) {
      addOrganizationFormData.children = addOrganizationFormData.children.map(
        (child) => ({
          ...child,
          profitCenters: addOrganizationFormData.profitCenters,
        })
      );
    }

    const organizations: IOrganizationSelection[] = [];
    const { children, ...rest } = addOrganizationFormData;
    // Filter away children that has already been added into the organizations list
    const filteredChildren = children.filter(
      (child) => !selectedOrganizationIds.includes(child.id)
    );
    organizations.push({ ...rest }, ...(filteredChildren ?? []));

    onAddOrganizations(
      organizations,
      setAsDefaultOrg ? addOrganizationFormData.id : undefined
    );

    onToggleOpen(false);
    setAddOrganizationFormData(DEFAULT_FORM_DATA);
  };

  const hasChildren = addOrganizationFormData?.children?.length > 0;
  return (
    <StyledDialog open={open} onClose={() => onToggleOpen(false)}>
      <DialogTitle onCloseButtonClick={() => onToggleOpen(false)}>
        {t("add_organization_to_user")}
      </DialogTitle>
      <DialogContent>
        <OrganizationFilter
          required={true}
          onSelectionChange={(orgs: OrganizationFilterOption[]) => {
            const { api_id, id, name } = orgs[0];

            setAddOrganizationFormData((prev) => ({
              ...prev,
              id,
              name,
              api_id,
              children: [],
              sccProfitCenters: [],
            }));

            fetchOrgAncestors(id);
          }}
          getOptionDisabled={(option) =>
            selectedOrganizationIds.includes(option.id)
          }
          singleSelection={true}
          moreText={renderMoreText(
            addOrganizationFormData?.children?.length || "",
            t
          )}
          hasAutoFocus
          excludeNoOrganization={true}
        />
        <Autocomplete
          autoComplete="off"
          defaultValue={professionalTitles.find(
            (item) => item.id === defaultProfessionalTitleId
          )}
          id="title-select"
          required={true}
          options={professionalTitles}
          label={t("professional_title")}
          autoHighlight
          onChange={(_, newValue: ProfessionalTitle) =>
            setAddOrganizationFormData((prev) => ({
              ...prev,
              professionalTitle: newValue,
            }))
          }
          getOptionLabel={(option: ProfessionalTitle) => option.name}
        />
        <ProfitCenterAutocomplete
          label={t("profit_centers")}
          options={profitCenters}
          onChange={(_, newValue) => {
            setAddOrganizationFormData((prev) => ({
              ...prev,
              profitCenters: newValue,
            }));
          }}
        />
        {sccProfitCenterAllowedOrganizationIds.includes(
          addOrganizationFormData?.id
        ) && (
          <ProfitCenterAutocomplete
            label={t("scc_profit_centers")}
            options={profitCenters}
            onChange={(_, newValue) => {
              setAddOrganizationFormData((prev) => ({
                ...prev,
                sccProfitCenters: newValue,
              }));
            }}
          />
        )}
        <Box px={1}>
          {!!selectedOrganizationIds.length && (
            <StyledCheckbox
              label={t("set_as_default_organization")}
              checked={setAsDefaultOrg}
              onChange={() => setSetAsDefaultOrg(!setAsDefaultOrg)}
            />
          )}
          <br />
          {hasChildren && (
            <>
              <StyledCheckbox
                label={t("copy_professional_title_to_sub_organizations")}
                checked={copyProfessionalTitle}
                onChange={() =>
                  setCopyProfessionalTitle(!copyProfessionalTitle)
                }
              />
              <br />
              <StyledCheckbox
                label={t("copy_profit_centers_to_sub_organizations")}
                checked={copyProfitCenters}
                onChange={() => setCopyProfitCenters(!copyProfitCenters)}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ mt: 3 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => onToggleOpen(false)}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={
            !(
              addOrganizationFormData.id &&
              addOrganizationFormData.professionalTitle
            )
          }
          onClick={handleAddOrganizationToUser}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default AddOrganizationDialog;
