import { useCallback, useMemo } from "react";
import useFetch from "@hooks/useFetch";
import useRoutes from "@hooks/useRoutes";

export type FetchOrganizationTypeRolesResult = {
  succeed: boolean;
  data?: {
    [origoApplicationId: number]: number[];
  };
};

export default function useOrganizationTypes() {
  const { doFetch, isFetching } = useFetch();
  const routes = useRoutes();

  const fetchOrganizationTypeRoles = useCallback(
    async (typeStrId: string): Promise<FetchOrganizationTypeRolesResult> => {
      const response = await doFetch(
        routes.companyListRoleIdsPath({ type: typeStrId })
      );
      if (!response.ok) {
        return { succeed: false };
      }

      const data = await response.json();
      return {
        succeed: true,
        data,
      };
    },
    [routes]
  );

  return useMemo(
    () => ({ fetchOrganizationTypeRoles, isFetching }),
    [fetchOrganizationTypeRoles, isFetching]
  );
}
