import React, { useEffect, useState } from "react";
import Box from "@oriola-origo/core/lib/Box";
import ToggleButtonGroup from "@oriola-origo/core/lib/ToggleButtonGroup/ToggleButtonGroup";
import { Button } from "@oriola-origo/core/lib/Button";
import useTranslations from "@hooks/useTranslations";
import { StickyProps } from "@components/common/sticky";
import {
  CompanyType,
  Organization,
  OrigoApplication,
  OrigoApplicationRole,
  ProfitCenter,
} from "@services/user_management/types";
import OrganizationDetailFields, {
  ParentOrganizationDetails,
} from "./organization_detail_fields";
import LogoManager from "./logo_manager";
import Grid from "@oriola-origo/core/lib/Grid";
import { useTheme } from "@oriola-origo/core/lib/styles";
import OrganizationRoles from "./organization_roles";
import useOrganizationTypes from "@services/user_management/hooks/useOrganizationTypes";
import Divider from "@oriola-origo/core/lib/Divider";
import useMediaQuery from "@oriola-origo/core/lib/useMediaQuery";
import useOrganization from "@services/user_management/hooks/useOrganization";
import OrganizationPageWithHeader from "./organization_page_with_header";
import useSnackbar from "@hooks/useSnackbar";
import ActivityTimeline from "../../../common/activity_timeline";

enum ContentType {
  Details = "details",
  Roles = "roles",
  Activity = "activity",
}

export interface OrganizationBasicInfoProps {
  apps: OrigoApplication[];
  roles: { [origoApplicationId: number]: OrigoApplicationRole[] };
  profitCenters: ProfitCenter[];
  organizationTypes: CompanyType[];
  organization: Organization;
  permissions: {
    may_assign_roles: boolean;
    may_delete: boolean;
    may_show_activity_log: boolean;
  };
  isUpdating: boolean;
  isDeleting: boolean;
  onSave: (property: string, value: any) => void | Promise<void>;
  onDelete?: () => void;
  parentPath?: string;
  stickyProps?: StickyProps;
}

function OrganizationBasicInfo({
  apps,
  roles,
  profitCenters,
  organizationTypes,
  organization,
  permissions,
  isUpdating,
  isDeleting,
  onSave,
  onDelete,
  stickyProps,
}: Readonly<OrganizationBasicInfoProps>) {
  const { t } = useTranslations();
  const [content, setContent] = useState<ContentType>(ContentType.Details);
  const theme = useTheme();
  const { setSnackMessage } = useSnackbar();
  const [
    organizationTypeInheritedRoleIds,
    setOrganizationTypeInheritedRoleIds,
  ] = React.useState<number[]>([]);
  const { fetchOrganizationTypeRoles } = useOrganizationTypes();
  const [parentOrganizationDetails, setParentOrganizationDetails] =
    useState<ParentOrganizationDetails | null>(null);
  const { fetchOrganization } = useOrganization();
  const [isFetchingParentOrganization, setIsFetchingParentOrganization] =
    useState(false);

  useEffect(() => {
    setParentOrganizationDetails(null);
    if (!organization.parent_company_id) {
      return;
    }

    const fetchParentOrganizationDetails = async () => {
      setIsFetchingParentOrganization(true);
      try {
        const res = await fetchOrganization(organization.parent_company_id);
        if (res.succeed) {
          setParentOrganizationDetails(res.data ?? null);
        }
      } catch (e) {
        setSnackMessage(t("parent_organization_fetch_failed"), "error");
      } finally {
        setIsFetchingParentOrganization(false);
      }
    };
    fetchParentOrganizationDetails();
  }, [organization?.parent_company_id]);

  useEffect(() => {
    const fetchTypeRoles = async () => {
      const rolesForOrgType = await fetchOrganizationTypeRoles(
        organization.type_str_id
      );
      if (rolesForOrgType.succeed) {
        setOrganizationTypeInheritedRoleIds([
          ...new Set(Object.values(rolesForOrgType.data).flat()),
        ]);
      }
    };

    fetchTypeRoles();
  }, [organization.type_str_id]);

  const handleSave = async (property: string, value: any) => {
    await onSave(property, value);
  };

  const isMdOrSmaller = useMediaQuery(theme.breakpoints.down("md"));

  const selectedRoleCount =
    organizationTypeInheritedRoleIds.length + organization.role_ids.length;
  const rolesTabLabel =
    selectedRoleCount > 0 ? `${t("roles")} (${selectedRoleCount})` : t("roles");
  return (
    <OrganizationPageWithHeader
      isDeleting={isDeleting}
      onDelete={onDelete}
      organization={organization}
      stickyProps={stickyProps}
      isUpdating={isUpdating}
      onSave={onSave}
      permissions={permissions}
    >
      <Box>
        <ToggleButtonGroup
          onChange={(value) => setContent(value as ContentType)}
          value={content}
          sx={{ mb: 2 }}
        >
          <Button value={ContentType.Details}>{t("information")}</Button>
          <Button value={ContentType.Roles}>{rolesTabLabel}</Button>
          <Button value={ContentType.Activity}>{t("activity")}</Button>
        </ToggleButtonGroup>
      </Box>

      {content === ContentType.Details && (
        <Box>
          <Grid container rowSpacing={2} columnSpacing={8}>
            <Grid size={{ xs: 12, md: 8 }} key="organization">
              <OrganizationDetailFields
                organization={organization}
                parentOrganization={parentOrganizationDetails}
                isFetchingParentOrganization={isFetchingParentOrganization}
                profitCenters={profitCenters}
                organizationTypes={organizationTypes}
                isUpdating={isUpdating}
                onSave={handleSave}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} key="logo">
              <Box
                display="flex"
                sx={{
                  flexDirection: isMdOrSmaller ? "column" : "row",
                  height: "100%",
                }}
              >
                <Divider
                  orientation={isMdOrSmaller ? "horizontal" : "vertical"}
                  flexItem
                />
                <Box
                  display="flex"
                  sx={{
                    width: "100%",
                    justifyContent: "center",
                    paddingLeft: "16px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                  }}
                >
                  <LogoManager
                    organization={organization}
                    onSave={onSave}
                    isUpdating={isUpdating}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {content === ContentType.Roles && (
        <Box>
          <OrganizationRoles
            apps={apps}
            roles={roles}
            selectedCompanyTypeStrId={organization.type_str_id}
            onSelectedRoleIdsChange={(roleIds) => onSave("role_ids", roleIds)}
            selectedRoleIds={organization.role_ids}
            isReadonly={!permissions.may_assign_roles}
            inheritedRoleIds={organizationTypeInheritedRoleIds}
          />
        </Box>
      )}
      {content === ContentType.Activity && <ActivityTimeline />}
    </OrganizationPageWithHeader>
  );
}

export default OrganizationBasicInfo;
