import React from "react";
import { UserData } from "../user_page_v2";
import BasicContainer from "./basic_container";
import useSnackbar from "@hooks/useSnackbar";
import useTurbolinks from "@hooks/useTurbolinks";
import { StickyProps } from "@components/common/sticky";
import useUser from "@services/user_management/hooks/useUser";
import { ResetAction } from "@services/user_management/types/users";
import useEditableUser from "@components/user/contexts/editable_user";
import useUsers from "@services/user_management/hooks/useUsers";
import useTranslations, { TranslateFunction } from "@hooks/useTranslations";

export interface UserBasicInfoProps {
  user_data: UserData;
  user_last_login?: string;
  userListPath: string;
  stickyProps?: StickyProps;
  is_emergency_responsible_person: boolean;
  resetAction: {
    action_url: string;
    button_text: string;
    type: ResetAction;
  };
}

type PatchUpdateUserParameterUser = Parameters<
  ReturnType<typeof useUser>["patchUpdateUser"]
>[0]["user"];

function UserBasicInfo(props: Readonly<UserBasicInfoProps>) {
  const { setSnackMessage } = useSnackbar();
  const { navigate } = useTurbolinks();
  const { user_data, user_last_login, userListPath, resetAction } = props;
  const { patchUpdate, isUpdating, user } = useEditableUser();
  const { deleteUser, isDeleting, sendResetLink, isPosting } = useUsers();
  const [isDeleted, setIsDeleted] = React.useState(false);
  const { t } = useTranslations();

  const saveUserData = async (property: string, value: string) => {
    const properties: PatchUpdateUserParameterUser = {
      [property]: value,
    };
    const res = await patchUpdate(properties);
    res.succeed
      ? setSnackMessage(t("user_info_updated"), "success")
      : setSnackMessage(t("user_info_update_failed"), "error");
  };

  const handleDeleteUser = async () => {
    const res = await deleteUser(user.id);
    if (res) {
      setIsDeleted(true);
      setSnackMessage(t("user_deleted"), "success");
      // Wait for 2 seconds (to be able to display the message) before redirecting to user list
      await new Promise((resolve) => setTimeout(resolve, 2000));
      navigate(props.userListPath);
    } else {
      setSnackMessage(t("user_delete_failed"), "error");
    }
  };

  const resolveResetPasswordLinkOrInvitationSuccessMessage = (
    type: ResetAction,
    t: TranslateFunction
  ) => {
    return type === ResetAction.Invitation
      ? t("send_new_invitation_success")
      : t("send_password_reset_link_success");
  };

  const resolveResetPasswordLinkOrInvitationErrorMessage = (
    type: ResetAction,
    t: TranslateFunction
  ) => {
    return type === ResetAction.Invitation
      ? t("send_new_invitation_error")
      : t("send_password_reset_link_error");
  };

  const sendResetPasswordLinkOrInvitation = async () => {
    const res = await sendResetLink(resetAction.action_url, user.id);
    res.succeed
      ? setSnackMessage(
          resolveResetPasswordLinkOrInvitationSuccessMessage(
            resetAction.type,
            t
          ),
          "success"
        )
      : setSnackMessage(
          resolveResetPasswordLinkOrInvitationErrorMessage(resetAction.type, t),
          "error"
        );
  };

  return (
    <BasicContainer
      user={user}
      userLastLogin={user_last_login}
      permissions={user_data.permissions}
      languages={user_data.languages}
      isUpdating={isUpdating || isDeleting || isDeleted || isPosting}
      isDeleting={isDeleting}
      onSave={saveUserData}
      onDelete={handleDeleteUser}
      onResetPasswordOrInvitationResend={sendResetPasswordLinkOrInvitation}
      parentPath={userListPath}
      stickyProps={props?.stickyProps}
    />
  );
}

export default UserBasicInfo;
