import React from "react";
import Box from "@oriola-origo/core/lib/Box";
import BackButton from "@components/common/back_button";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import OriolaSnackbarProvider from "@oriola-origo/core/lib/Snackbar/SnackbarProvider";
import useTranslations from "@hooks/useTranslations";
import { styled } from "@oriola-origo/core/lib/styles";
import UserBasicInfo from "./basic_info_section/user_basic_info";
import useScrollContainer from "@hooks/useScrollContainer";
import { EditableUserProvider } from "../contexts/editable_user";
import CombinedOrganizationAndUserAccessSections from "./organization_and_user_access_section/combined_section";
import EmailNotificationSubscriptions from "../components/email_notification_subscriptions/email_notification_subscriptions";
import { User, ResetAction } from "@services/user_management/types/users";
import { OrigoApplication } from "@services/user_management/types/application";
import { UserGroup } from "@services/user_management/types/user_groups";
import { OrigoApplicationRole } from "@services/user_management/types/roles";
import { UserGroupCategory } from "@services/user_management/hooks/useUserGroupCategories";
import {
  ProfessionalTitle,
  ProfitCenter,
} from "../../../services/user_management/types";

export interface UserPermissions {
  assign_roles: boolean;
  assign_superuser_roles: boolean;
  assign_user_to_company: boolean;
  manage_all_users: boolean;
}

export interface UserData {
  user: User;
  languages: [string, string][];
  permissions: UserPermissions;
  apps: OrigoApplication[];
  roles: Record<string, OrigoApplicationRole[]>;
  user_groups: UserGroup[];
  user_group_categories: UserGroupCategory[];
  noninheriting_role_ids: number[];
  professional_titles: ProfessionalTitle[];
  profit_centers: ProfitCenter[];
  scc_profit_center_allowed_organization_ids: number[];
  default_professional_title_id: number;
}

export interface UserPageV2Props {
  user_data: UserData;
  user_last_login: string;
  userListPath: string;
  is_emergency_responsible_person: boolean;
  resetAction: {
    action_url: string;
    button_text: string;
    type: ResetAction;
  };
}

const StickyContainer = styled("div")({
  top: 0,
  position: "sticky",
  zIndex: 10,
});

function UserBase(props: Readonly<UserPageV2Props>) {
  const { userListPath } = props;
  const { t } = useTranslations();
  const { scrollContainer } = useScrollContainer();
  const [stickyContainerRef, setStickyContainerRef] =
    React.useState<HTMLDivElement | null>(null);

  return (
    <Box minWidth="320px">
      <BackButton
        navigateTo={userListPath}
        text={t("back_to_user_list")}
        mt={0}
        mb={3}
      />
      <StickyContainer ref={(ref) => setStickyContainerRef(ref)} />
      <UserBasicInfo
        {...props}
        stickyProps={{
          stickyContainerElement: stickyContainerRef,
          viewportElement: scrollContainer,
        }}
      />
      <EmailNotificationSubscriptions />
      <CombinedOrganizationAndUserAccessSections {...props} />
    </Box>
  );
}

function UserPageV2(props: Readonly<UserPageV2Props>) {
  return (
    <OriolaThemeProvider>
      <OriolaSnackbarProvider autoHideDuration={2000}>
        <EditableUserProvider
          user={{
            ...props.user_data.user,
            is_emergency_responsible_person:
              props.is_emergency_responsible_person,
          }}
        >
          <UserBase {...props} />
        </EditableUserProvider>
      </OriolaSnackbarProvider>
    </OriolaThemeProvider>
  );
}

export default UserPageV2;
