import { useCallback, useMemo, useState } from "react";

interface RawCompany {
  id: number;
  api_id: string;
  name: string;
  hierarchy_level: number | null;
  hierarchy_parent_company_ids: number[] | null;
}

export type CompanyOption = {
  value: string;
  label: string;
  hierarchyLevel: number;
};

export interface UseFetchCompanyOptionsOutput {
  isFetching: boolean;
  fetchCompanyOptions: (query: string) => Promise<CompanyOption[]>;
}

const formatCompanyOptions = (companies: RawCompany[]): CompanyOption[] =>
  companies.map((c) => ({
    value: c.api_id,
    label: [c.api_id, c.name].join(" - "),
    hierarchyLevel: c.hierarchy_level,
  }));

const useFetchCompanyOptions = (): UseFetchCompanyOptionsOutput => {
  const [isFetching, setIsFetching] = useState(false);

  const fetchCompanyOptions = useCallback(
    async (query: string): Promise<CompanyOption[]> => {
      setIsFetching(true);
      try {
        const data = await fetch(
          Routes.dashboard_widget_data_path({
            widget: "StaticReports",
            method: "search_customers",
            query,
          })
        );

        const json = await data.json();
        return formatCompanyOptions(json);
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  return useMemo(
    () => ({
      isFetching,
      fetchCompanyOptions,
    }),
    [fetchCompanyOptions, isFetching]
  );
};

export default useFetchCompanyOptions;
