import React, { useState } from "react";
import Page from "@oriola-origo/core/lib/Page";
import OrganizationHeader from "../../components/organization_header";
import AddOrganizationDialog, {
  AddOrganizationDialogProps,
} from "../../components/add_organization_dialog";
import CardContent from "./components/card_content";
import { OrganizationInformationProps } from "../types";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { addOrganizations } from "@store/states/inviteUserSlice";
import { useSetDefaultOrganization } from "../utils";

function OrganizationInformation(
  props: Readonly<OrganizationInformationProps>
) {
  const {
    defaultProfessionalTitleId,
    professionalTitles,
    profitCenters,
    sccProfitCenterAllowedOrganizationIds,
  } = props;
  const [showAddOrganizationDialog, setShowAddOrganizationDialog] =
    useState(false);
  const organizationsIds = useAppSelector((state) =>
    state.inviteUser.organizations.map((org) => org.id)
  );
  const dispatch = useAppDispatch();
  const setDefaultOrganization = useSetDefaultOrganization();

  const handleAddOrganizations: AddOrganizationDialogProps["onAddOrganizations"] =
    (newOrganizations, newDefaultOrganizationId) => {
      dispatch(addOrganizations(newOrganizations));
      if (newDefaultOrganizationId) {
        setDefaultOrganization(newDefaultOrganizationId);
      }
    };

  return (
    <Page
      header={
        <OrganizationHeader
          title_key="add_organization_to_user"
          onToggleOpen={(value) => setShowAddOrganizationDialog(value)}
        />
      }
      mt={2}
    >
      <CardContent
        professionalTitles={professionalTitles}
        profitCenters={profitCenters}
        sccProfitCenterAllowedOrganizationIds={
          sccProfitCenterAllowedOrganizationIds
        }
      />
      <AddOrganizationDialog
        selectedOrganizationIds={organizationsIds}
        open={showAddOrganizationDialog}
        onToggleOpen={(value) => setShowAddOrganizationDialog(value)}
        onAddOrganizations={handleAddOrganizations}
        professionalTitles={professionalTitles}
        profitCenters={profitCenters}
        defaultProfessionalTitleId={defaultProfessionalTitleId}
        sccProfitCenterAllowedOrganizationIds={
          sccProfitCenterAllowedOrganizationIds
        }
      />
    </Page>
  );
}

export default OrganizationInformation;
