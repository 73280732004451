import React from 'react';
import { Table, TableRowActions } from '../common/table';
import UsageTipForm from './form';

export default class List extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tableKey: StringHelper.random(8),
            mode: 'list' // new | edit
        };
    }

    render() {
        let columns = [
            {
                dataField: 'icon',
                text: I18n.t('icon'),
                headerStyle: { width: '5rem' },
                sort: false,
                formatter: (cell, row) => {
                    return row.icon ? <i className={`oricon-${row.icon}`}></i> : null;
                }
            },
            {
                dataField: 'content',
                text: I18n.t('usage_tip'),
                sort: true,
                formatter: (cell, row) => {
                    return !this.props.permissions.may_edit ? row.content[I18n.locale] : <a href="#" onClick={e => { this.handleEditUsageTipClick(row); e.preventDefault(); }}>
                        {row.content[I18n.locale]}
                    </a>;
                }
            },
            {
                dataField: 'id',
                text: '',
                classes: 'row-actions',
                headerClasses: 'row-actions',
                formatter: (cell, row) => {
                    let actions = [];

                    if (this.props.permissions.may_edit) {
                        actions.push(<a key="edit" href="#" onClick={e => { this.handleEditUsageTipClick(row); e.preventDefault(); }}>
                            <i className="fa fa-pencil"></i> {I18n.t('edit')}
                        </a>);
                    }
                    if (this.props.permissions.may_delete) {
                        if (actions.length > 0) {
                            actions.push(<TableRowActions.Divider key="div" />)
                        }
                        actions.push(<a key="del" href="#" onClick={e => { this.deleteUsageTip(row); e.preventDefault(); }}><i className="oricon-bin"></i> {I18n.t('delete')}</a>);
                    }
                    return actions.length > 0 ? <TableRowActions.Menu>{actions}</TableRowActions.Menu> : null
                }
            }
        ];

        let actions = [];

        if (this.props.permissions.may_add) {
            actions.push(<a key="add" href="#" onClick={e => { this.handleAddUsageTipClick(); e.preventDefault(); }} className="btn btn-light mr-3">
                <i className="fa fa-plus text-highlight"></i> {I18n.t('add')}
            </a>);
        }

        return (
            <div ref="root">
                <Table
                    key={this.state.tableKey}
                    ref="table"
                    columns={columns}
                    actions={actions}
                    pagination={{ sizePerPage: 20 }}
                    defaultSorted={[{
                        dataField: 'content',
                        order: 'asc'
                    }]}
                    remote={true}
                    remoteUrl={Routes.usage_tip_list_json_path()}
                    preserveState={true} />

                {this.state.mode == 'new' && <UsageTipForm
                    title={I18n.t('add_usage_tip')}
                    onClose={this.handleDialogClose}
                    action={Routes.usage_tip_create_path()}
                    onSuccess={this.handleFormSuccess}
                    icons={this.props.form_data.icons}
                    languages={this.props.form_data.languages}
                />}
                {this.state.mode == 'edit' && <UsageTipForm
                    title={I18n.t('edit_usage_tip')}
                    onClose={this.handleDialogClose}
                    action={Routes.usage_tip_update_path({ usage_tip_id: this.state.selectedUsageTip.id })}
                    onSuccess={this.handleFormSuccess}
                    icons={this.props.form_data.icons}
                    languages={this.props.form_data.languages}
                    usageTip={this.state.selectedUsageTip}
                />}
            </div>
        );
    }

    handleAddUsageTipClick = () => {
        this.setState({ mode: 'new' });
    }

    handleEditUsageTipClick = (usageTip) => {
        this.setState({
            mode: 'edit',
            selectedUsageTip: usageTip
        });
    }

    handleDialogClose = () => {
        this.setState({
            mode: 'list',
            selectedUsageTip: null
        });
    }

    handleFormSuccess = () => {
        this.handleDialogClose();
        this.reload();
    }

    reload = () => {
        this.setState({ tableKey: StringHelper.random(8) });
    }

    deleteUsageTip = (usageTip) => {
        if (confirm(I18n.t('confirm_delete_usage_tip', { content: usageTip.content }))) {
            jQuery.ajax({
                url: Routes.usage_tip_delete_path({ usage_tip_id: usageTip.id }),
                type: 'DELETE',
                success: this.reload
            });
        }
    }
}