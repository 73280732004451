import React, { useEffect, useState } from "react";
import Header from "@oriola-origo/core/lib/Header";
import Page from "@oriola-origo/core/lib/Page";
import Box from "@oriola-origo/core/lib/Box";
import IconButton from "@oriola-origo/core/lib/IconButton";
import Link from "@oriola-origo/core/lib/Link";
import FontIcon from "@oriola-origo/core/lib/Icons/";
import Menu from "@oriola-origo/core/lib/Menu/Menu";
import MenuItem from "@oriola-origo/core/lib/MenuItem/MenuItem";
import ListItemText from "@oriola-origo/core/lib/ListItemText";
import ToggleButtonGroup from "@oriola-origo/core/lib/ToggleButtonGroup/ToggleButtonGroup";
import { Button } from "@oriola-origo/core/lib/Button";
import useTranslations from "@hooks/useTranslations";
import ListItemIcon from "@oriola-origo/core/lib/ListItemIcon";
import ActivityTimeline from "../../../common/activity_timeline";
import ResetPasswordOrResendInvitationConfirmation from "./reset_password_or_invitation_resend_confirmation";
import Switch from "@oriola-origo/core/lib/Switch";
import Sticky, { StickyProps } from "@components/common/sticky";
import { UserPermissions } from "../user_page_v2";
import useRoutes from "@hooks/useRoutes";
import moment from "moment";
import { EditableUser } from "@components/user/contexts/editable_user";
import UserDetailFields from "@components/user/components/user_detail_fields";
import DeleteConfirmation from "@components/common/delete_confirmation";

enum ContentType {
  Details = "details",
  Activity = "activity",
}

export interface BasicContainerProps {
  user: EditableUser;
  userLastLogin?: string;
  permissions: UserPermissions;
  languages: [string, string][];
  isUpdating: boolean;
  isDeleting: boolean;
  onSave: (property: string, value: any) => void | Promise<void>;
  onDelete?: () => void;
  onResetPasswordOrInvitationResend?: () => void;
  parentPath?: string;
  stickyProps?: StickyProps;
}

const dateStrToDateParams = (dateStr: string) => {
  const dateObj = moment(dateStr);
  return {
    date: dateObj.format("DD.MM.YYYY"),
    time: dateObj.format("HH:mm"),
  };
};

function BasicContainer({
  user,
  userLastLogin,
  languages,
  permissions,
  isUpdating,
  isDeleting,
  onSave,
  onDelete,
  onResetPasswordOrInvitationResend,
  stickyProps,
}: Readonly<BasicContainerProps>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslations();
  const [content, setContent] = useState<ContentType>(ContentType.Details);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [resetConfirmationOpen, setResetConfirmationOpen] = useState(false);
  const [isActive, setIsActive] = useState(user.active);
  const hasAdminRight =
    permissions.manage_all_users && permissions.assign_roles;
  const hasCustomerServiceRight =
    permissions.manage_all_users && !permissions.assign_roles;

  const shouldTriggerResetPassword = !user.is_invitee;
  const routes = useRoutes();

  useEffect(() => {
    if (!isDeleting) {
      setDeleteConfirmationOpen(false);
    }
  }, [isDeleting]);

  useEffect(() => {
    if (!isUpdating) {
      setResetConfirmationOpen(false);
    }
  }, [isUpdating]);

  useEffect(() => {
    setIsActive(user.active);
  }, [user.active]);

  useEffect(() => {
    if (!isUpdating && user.active !== isActive) {
      setIsActive(user.active);
    }
  }, [isUpdating]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = () => (
    <Menu
      anchorEl={anchorEl}
      aria-label="Header menu"
      open={open}
      onClick={() => handleClose()}
    >
      {hasAdminRight && (
        <MenuItem>
          <Link href={routes.userLogListPath(user.email_address)}>
            <Box display="flex" sx={{ alignItems: "center" }}>
              <ListItemIcon>
                <FontIcon>published_with_changes</FontIcon>
              </ListItemIcon>
              <ListItemText>{t("activity")}</ListItemText>
            </Box>
          </Link>
        </MenuItem>
      )}
      <MenuItem onClick={() => setResetConfirmationOpen(true)}>
        <ListItemIcon>
          <FontIcon>
            {shouldTriggerResetPassword ? "password" : "email"}
          </FontIcon>
        </ListItemIcon>
        <ListItemText>
          {shouldTriggerResetPassword
            ? t("send_password_reset_link")
            : t("send_new_invitation")}
        </ListItemText>
      </MenuItem>
      {hasAdminRight && (
        <MenuItem onClick={() => setDeleteConfirmationOpen(true)}>
          <ListItemIcon>
            <FontIcon>delete</FontIcon>
          </ListItemIcon>
          <ListItemText aria-label="delete">{t("delete")}</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );

  const toggleContent = () => {
    setContent(
      content === ContentType.Details
        ? ContentType.Activity
        : ContentType.Details
    );
    handleClose();
  };

  const handleActiveChange = () => {
    onSave("active", !isActive);
    setIsActive(!isActive);
  };

  const getLabelForActiveSwitch = () => {
    if (!isActive) {
      return `${t("user_status_inactive")} ${t("user_not_able_to_sign_in")}`;
    }

    let loginInfo: string = "";
    if (userLastLogin) {
      loginInfo = `(${t("last_login", dateStrToDateParams(userLastLogin))})`;
    }
    return [t("user_status_active"), loginInfo].filter(Boolean).join(" ");
  };

  const renderHeader = () => {
    return (
      <Header
        title={`${user.first_name} ${user.last_name}`}
        titleSx={{
          color: (theme) =>
            isActive ? theme.palette.text.primary : theme.palette.text.disabled,
        }}
        description={
          <Switch
            data-testid="user-activity-switch"
            size="small"
            disabled={isUpdating}
            checked={isActive}
            label={getLabelForActiveSwitch()}
            onChange={handleActiveChange}
          />
        }
      >
        {(hasAdminRight || hasCustomerServiceRight) && (
          <IconButton onClick={handleClick} aria-label="Open menu">
            <FontIcon>more_vert</FontIcon>
          </IconButton>
        )}
        {(hasAdminRight || hasCustomerServiceRight) && renderMenu()}
      </Header>
    );
  };

  const renderStickyHeader = () => (
    <Sticky
      viewportElement={stickyProps?.viewportElement}
      stickyContainerElement={stickyProps?.stickyContainerElement}
    >
      <Page header={renderHeader()} pb={0} pt={0} pr={0} pl={0} />
    </Sticky>
  );

  const handleSave = async (property: string, value: any) => {
    await onSave(property, value);
  };

  return (
    <>
      {stickyProps && renderStickyHeader()}
      <Page header={renderHeader()} mb={2} pt={3}>
        <Box>
          <ToggleButtonGroup
            onChange={() => toggleContent()}
            value={content}
            sx={{ mb: 2 }}
          >
            <Button value={ContentType.Details}>{t("information")}</Button>
            <Button value={ContentType.Activity}>{t("activity")}</Button>
          </ToggleButtonGroup>
        </Box>

        {content === ContentType.Details && (
          <UserDetailFields
            user={user}
            isUpdating={isUpdating}
            languages={languages}
            onSave={handleSave}
            profileMode={false}
          />
        )}
        {content === ContentType.Activity && <ActivityTimeline />}
        <DeleteConfirmation
          title={t("delete_user")}
          open={deleteConfirmationOpen}
          content={t("confirm_delete_user", {
            name: `${user.first_name} ${user.last_name}`,
          })}
          isDeleting={isUpdating}
          onCancel={() => setDeleteConfirmationOpen(false)}
          onDelete={onDelete}
        />
        <ResetPasswordOrResendInvitationConfirmation
          open={resetConfirmationOpen}
          userName={`${user.first_name} ${user.last_name}`}
          isSending={isUpdating}
          onCancel={() => setResetConfirmationOpen(false)}
          onSend={onResetPasswordOrInvitationResend}
          shouldTriggerResetPassword={shouldTriggerResetPassword}
        />
      </Page>
    </>
  );
}

export default BasicContainer;
