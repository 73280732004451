import { TranslateFunction } from "@hooks/useTranslations";

export interface ValidationErrorMap {
  [property: string]: Array<{ message: string }>;
}

const createPresenceErrorFactory =
  (t: TranslateFunction) => (param: string) => [
    {
      message: t("error_param_presence", { param }),
    },
  ];

const createLengthErrorFactory =
  (t: TranslateFunction) => (param: string, maxLength: number) => [
    {
      message: t("error_param_length", { param, length: maxLength }),
    },
  ];

export const validateUserGroup = (
  obj: {
    userGroupName: string;
    description: string;
    userGroupCategoryId: number;
  },
  t: TranslateFunction
): ValidationErrorMap => {
  const validationErrors: ValidationErrorMap = {};
  const createPresenceError = createPresenceErrorFactory(t);
  const createLengthError = createLengthErrorFactory(t);

  if (!obj.userGroupName) {
    validationErrors.name = createPresenceError(t("user_group_name"));
  } else if (obj.userGroupName.length > 200) {
    validationErrors.name = createLengthError(t("user_group_name"), 200);
  }

  if (!obj.userGroupCategoryId) {
    validationErrors.userGroupCategoryId = createPresenceError(
      t("user_group_category")
    );
  }

  if (!obj.description) {
    validationErrors.description = createPresenceError(t("description"));
  } else if (obj.description.length > 250) {
    validationErrors.description = createLengthError(t("description"), 250);
  }

  return validationErrors;
};
