import React from 'react';
import { Modal, CloseModal } from '../common/modal';
import { Form, FormSubmit } from '../common/form';
import Select from '../common/select';

export default class UsageTipForm extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const tip = this.props.usageTip;

        return <Modal title={this.props.title} size="lg" onClose={this.props.onClose}>
            <Form action={this.props.action} onSuccess={this.props.onSuccess}>
                <div className="row">
                    <div className="col col-md-6">
                        <div className="form-group">
                            <label>{I18n.t('icon')}</label>
                            <Select
                                defaultValue={tip ? this.formatIconOptions((this.props.icons || []).filter(i => i == tip.icon)) : null}
                                name="usage_tip[icon]"
                                options={this.formatIconOptions(this.props.icons)}
                                isClearable={true}
                                placeholder={I18n.t('select_blank_text')}
                                formatOptionLabel={option => (
                                    <span><i className={`oricon-${option.value}`}></i> {option.label}</span>
                                )}
                            />
                        </div>
                    </div>
                </div>
                {this.props.languages.map(locale => <div key={locale} className="form-group">
                    <label htmlFor="usage_tip_content">{I18n.t('usage_tip')} ({I18n.t(`language_${locale}`)}) *</label>
                    <textarea id="usage_tip_content" name={`usage_tip[content][${locale}]`} defaultValue={tip ? (tip.content || {})[locale] : ''} className="form-control" rows="3">
                    </textarea>
                </div>)}
                <div className="text-right">
                    <CloseModal className="btn btn-light mr-3 px-4">{I18n.t('cancel')}</CloseModal>
                    <FormSubmit />
                </div>
            </Form>
        </Modal>
    }

    formatIconOptions = (icons) => {
        return icons.map(i => ({ value: i, label: I18n.t(i, { scope: 'oricon' }) })).sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            } else if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
    }
}
