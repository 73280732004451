import React, { useCallback, useEffect, useState } from "react";
import { UserGroups } from "@services/user_management/hooks/useUserGroupCategories";
import useOrganizationUsers from "@services/user_management/hooks/useOrganizationUsers";
import UserTableWithUserGroups, {
  UserTableWithUserGroupsProps,
} from "./components/user_table_with_user_groups";
import { CompanyType, User } from "@services/user_management/types";
import {
  SortType,
  TableColumnSortable,
  TableColumnSortDirection,
  UsersTableActionMenuItem,
} from "@components/common/user_table";
import useSearchParams from "@hooks/useSearchParams";
import Page from "@oriola-origo/core/lib/Page";
import Header from "@oriola-origo/core/lib/Header";
import Box from "@oriola-origo/core/lib/Box";
import useTranslations from "@hooks/useTranslations";
import useRoutes from "@hooks/useRoutes";

export interface UsersSectionProps {
  organizationId: number;
  companyTypes: CompanyType[];
  userGroups: UserGroups[];
  mayEditUsers: boolean;
  mayViewUsersActivityLog: boolean;
}

const SORT_FIELD_TO_COLUMN_MAP: Partial<Record<TableColumnSortable, string>> = {
  [TableColumnSortable.Name]: "last_name",
  [TableColumnSortable.Email]: "email_address",
  [TableColumnSortable.Status]: "status",
};

export const QUERY_PARAMETER_SEARCH = "users-search";
export const QUERY_PARAMETER_STATUS = "users-status";
export const QUERY_PARAMETER_USER_GROUP_IDS = "users-user_group_ids";

const searchParamsToFilters = (
  searchParams: URLSearchParams
): UserTableWithUserGroupsProps["filters"] => {
  const filtersType: UserTableWithUserGroupsProps["filters"] = {
    search: searchParams.get(QUERY_PARAMETER_SEARCH) ?? "",
  };

  if (searchParams.has(QUERY_PARAMETER_STATUS)) {
    filtersType.status = searchParams.get(QUERY_PARAMETER_STATUS) ?? "";
  }

  filtersType.userGroupIds = (
    searchParams.get(QUERY_PARAMETER_USER_GROUP_IDS) ?? ""
  )
    .split(",")
    .map((id) => parseInt(id, 10))
    .filter((id) => !isNaN(id));

  return filtersType;
};

const USERS_PER_FETCH = 50;

export default function UsersSection({
  organizationId,
  companyTypes,
  userGroups,
  mayEditUsers,
  mayViewUsersActivityLog,
}: Readonly<UsersSectionProps>) {
  const { t } = useTranslations();
  const { fetchUsers, isFetching } = useOrganizationUsers();
  const [searchParams, setSearchParams] = useSearchParams();
  const routes = useRoutes();
  const [isRePopulatingUsers, setIsRePopulatingUsers] = useState(false);
  const [users, setUsers] = useState<UserTableWithUserGroupsProps["users"]>([]);
  const [userCounts, setUserCounts] = useState<
    null | UserTableWithUserGroupsProps["counts"]
  >(null);
  const [activePageIndex, setActivePageIndex] = useState<null | number>(null);
  const [filters, setFilters] = useState<
    UserTableWithUserGroupsProps["filters"]
  >(searchParamsToFilters(searchParams));
  const [activeSort, setActiveSort] = useState<SortType>({
    column: TableColumnSortable.Name,
    direction: TableColumnSortDirection.Asc,
  });

  const fetchMoreUsers = useCallback(
    async (page = 1) => {
      const count = userCounts?.[filters.status] ?? userCounts?.total ?? 0;
      if (page > 1 && count <= users.length) {
        return;
      }

      const res = await fetchUsers({
        id: organizationId,
        page,
        sizePerPage: USERS_PER_FETCH,
        orderBy: SORT_FIELD_TO_COLUMN_MAP[activeSort.column],
        order: activeSort.direction,
        search: filters.search || undefined,
        filter: filters.status || undefined,
        userGroupIds: filters.userGroupIds,
      });

      if (res.succeed) {
        if (page === 1) {
          setUsers(res.users ?? []);
          setUserCounts(res.counts);
        } else {
          setUsers((prev) => [...prev, ...(res.users ?? [])]);
        }

        setActivePageIndex(page);
        setIsRePopulatingUsers(false);
      }
    },
    [
      organizationId,
      activePageIndex,
      activeSort,
      userCounts,
      fetchUsers,
      filters,
    ]
  );

  useEffect(() => {
    if (isFetching) {
      return;
    }
    setIsRePopulatingUsers(true);
    fetchMoreUsers(1);
  }, [activeSort, filters]);

  useEffect(() => {
    fetchUsers({
      organizationIds: [],
    });
  }, []);

  const handleFiltersChange = (
    newFilters: UserTableWithUserGroupsProps["filters"]
  ) => {
    setFilters(newFilters);

    const newSearchParams = new URLSearchParams();
    if (newFilters.search) {
      newSearchParams.set(QUERY_PARAMETER_SEARCH, newFilters.search);
    }
    if (newFilters.status) {
      newSearchParams.set(QUERY_PARAMETER_STATUS, newFilters.status);
    }
    if (
      Array.isArray(newFilters.userGroupIds) &&
      newFilters.userGroupIds.length > 0
    ) {
      newSearchParams.set(
        QUERY_PARAMETER_USER_GROUP_IDS,
        newFilters.userGroupIds.join(",")
      );
    }

    setSearchParams(newSearchParams);
  };

  const actionMenuItems: UsersTableActionMenuItem[] = [];
  if (mayEditUsers) {
    actionMenuItems.push({
      label: t("edit_user"),
      icon: "edit",
      href: ({ id }) => routes.userPath(id),
    });
  }

  if (mayViewUsersActivityLog) {
    actionMenuItems.push({
      label: t("user_activity"),
      icon: "published_with_changes",
      href: ({ email_address }: User) => routes.userLogListPath(email_address),
    });
  }

  return (
    <Page header={<Header title={t("users")} />} mb={5} pt={1}>
      <Box mx={-4}>
        <UserTableWithUserGroups
          activeOrganizationId={organizationId}
          isLoading={isFetching}
          isRePopulating={isRePopulatingUsers}
          filters={filters}
          showProfessionalTitleColumn={true}
          onFiltersChange={handleFiltersChange}
          onRequestMore={() => fetchMoreUsers(activePageIndex + 1)}
          sort={activeSort}
          onSortChange={setActiveSort}
          counts={userCounts}
          users={users}
          companyTypes={companyTypes}
          userGroups={userGroups}
          actionMenuItems={actionMenuItems}
        />
      </Box>
    </Page>
  );
}
