import React from "react";
import NavigationPane from "@oriola-origo/core/lib/NavigationPane";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import useTranslations, { TranslateFunction } from "@hooks/useTranslations";
import useTurbolinks, { NavigateFunction } from "@hooks/useTurbolinks";

const TOP_MENU_HEIGHT = 69;

export type AccessManagementContentMenu = [
  string,
  Array<[string, string, string, string]>,
];

export interface AccessManagementNavProps {
  contentMenu: AccessManagementContentMenu;
}

const resolvePathForId = (
  id: string,
  contentMenu: AccessManagementContentMenu
) => contentMenu[1].find((item: any[]) => item[0] === id)?.[2] ?? null;

export const renderContentNavigationPane = (
  contentMenu: AccessManagementNavProps["contentMenu"],
  navigate: NavigateFunction,
  t: TranslateFunction
) => {
  const handleSelectedChange = (id: string) => {
    const path = resolvePathForId(id, contentMenu);
    if (path) {
      navigate(path);
    }
  };

  return (
    <NavigationPane
      active={contentMenu[0]}
      links={(contentMenu[1] || []).map((item) => ({
        iconStyle: "outlined",
        id: item[0],
        text: item[1],
        path: item[2],
        iconName: item[3],
      }))}
      onSelected={handleSelectedChange}
      textMore={t("more")}
      customStyles={{
        height: `calc(100vh - ${TOP_MENU_HEIGHT}px)`,
      }}
    />
  );
};

function AccessManagementNav({
  contentMenu,
}: Readonly<AccessManagementNavProps>) {
  const { t } = useTranslations();
  const { navigate } = useTurbolinks();
  return (
    <OriolaThemeProvider>
      <div className="AccessManagementNavPan">
        {renderContentNavigationPane(contentMenu, navigate, t)}
      </div>
    </OriolaThemeProvider>
  );
}

export default AccessManagementNav;
