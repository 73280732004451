export const confirmDeleteClick = async (
  selectedUserGroupId: string,
  csrfToken: string,
  setOpenDialog: (value: boolean) => void,
  deletedUserGroupIds: string[],
  setDeletedUserGroupIds: (value: string[]) => void,
  setIsLoading: (value: boolean) => void
) => {
  const deleteUrl = Routes.user_group_delete_path({
    user_group_id: selectedUserGroupId,
  });

  const response = await fetch(deleteUrl, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  });
  if (response.ok) {
    setIsLoading(false);
    setOpenDialog(false);
    setDeletedUserGroupIds([...deletedUserGroupIds, selectedUserGroupId]);
  }
};
