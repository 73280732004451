import React from "react";
import OrganizationFilter from "@components/common/organization_filter";
import { Organization, ProfitCenter } from "@services/user_management/types";
import { CustomRendererProps } from "@components/common/editable_field";
import { ParentOrganizationDetails } from "./organization_detail_fields";
import Progress from "@oriola-origo/core/lib/Progress";
import ProfitCenterAutocomplete from "@components/user/form_v2/organization_information/components/profit_center_autocomplete";
import Typography from "@oriola-origo/core/lib/Typography";
import Box from "@oriola-origo/core/lib/Box";

const strIdsToNumeric = (strIds: string): number[] =>
  strIds.split(",").filter(Boolean).map(Number);

export function profitCentersCustomEditRenderer(
  { internalValue, label, setInternalValue }: CustomRendererProps,
  profitCenters: ProfitCenter[]
) {
  const internalValueIds = strIdsToNumeric(internalValue);
  const defaultValue = profitCenters.filter((profitCenter) =>
    internalValueIds.includes(profitCenter.id)
  );
  return (
    <ProfitCenterAutocomplete
      onChange={(_, value) =>
        setInternalValue(value.map((v) => v.id.toString()).join(","))
      }
      options={profitCenters}
      defaultValue={defaultValue}
      label={label}
      limitTags={1}
    />
  );
}

export function profitCentersReadValueTransformRenderer(
  value: string,
  profitCenters: ProfitCenter[]
) {
  return value ? (
    <Box mt={1.5}>
      {strIdsToNumeric(value).map((id) => {
        const profitCenter = profitCenters.find(
          (profitCenter) => profitCenter.id === id
        );
        return profitCenter ? (
          <Typography display="block" variant="body1" key={id} mb={1}>
            {profitCenter.code} - {profitCenter.name}
          </Typography>
        ) : null;
      })}
    </Box>
  ) : (
    "-"
  );
}

export function parentCompanyCustomEditRenderer(
  { setInternalValue }: CustomRendererProps,
  organization: Pick<Organization, "id" | "parent_company_id">
) {
  return (
    <OrganizationFilter
      excludeNoOrganization={true}
      singleSelection={true}
      onSelectionChange={(values) => {
        setInternalValue(values[0]?.id.toString() ?? "");
      }}
      getOptionDisabled={(option) => option.id === organization.id}
      defaultValue={
        organization.parent_company_id ? [organization.parent_company_id] : []
      }
    />
  );
}

export function parentCompanyReadValueTransformRenderer(
  value: string,
  parentOrganization: Pick<ParentOrganizationDetails, "id" | "api_id" | "name">,
  isFetchingParentOrganization: boolean
) {
  if (value && value === parentOrganization?.id.toString()) {
    return `${parentOrganization.api_id} - ${parentOrganization.name}`;
  }
  if (isFetchingParentOrganization) {
    return <Progress size={20} color="secondary" />;
  }
  return "-";
}
