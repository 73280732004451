import React from "react";
import Typography from "@oriola-origo/core/lib/Typography";
import { styled } from "@oriola-origo/core/lib/styles";
import ItemListItem from "./item_list_item";

import useTranslations from "@hooks/useTranslations";
import {
  ToggleItemListItemCategory,
  ToggleItemsListItem,
} from "./toggle_items_list";

export interface ItemListProps {
  category: ToggleItemListItemCategory;
  items: ToggleItemsListItem[];
  selectedItemIds: number[];
  noItemsText?: string;
  readonlySelectedItemIds?: number[];
  onItemClick: (item: {
    id: number;
    name: string;
    description: string;
  }) => void;
  disabled?: boolean;
}

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
});

function ItemList({
  category,
  items,
  onItemClick,
  noItemsText,
  selectedItemIds,
  readonlySelectedItemIds,
  disabled
}: Readonly<ItemListProps>) {
  const { language } = useTranslations();
  return (
    <>
      <Typography variant="h6" color="textSecondary" mb={2}>
        {category.title[language] ?? category.title.en}
      </Typography>

      <List>
        {items.length === 0 && !!noItemsText && (
          <Typography variant="body1" color="textSecondary">
            {noItemsText}
          </Typography>
        )}
        {items.map((item) => (
          <ItemListItem
            key={item.id}
            item={item}
            disabled={
              item.disabled || readonlySelectedItemIds?.includes(item.id) || disabled
            }
            tooltipText={item.disabledTooltip}
            onClick={onItemClick}
            selected={
              selectedItemIds.includes(item.id) ||
              readonlySelectedItemIds?.includes(item.id)
            }
          />
        ))}
      </List>
    </>
  );
}

export default ItemList;
