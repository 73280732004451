import React from 'react';
import { Form, FormSubmit } from '../common/form';
import Select from '../common/select';
import Assigner from '../role/assigner';
import _ from 'underscore';
import FileUpload from '../common/file_upload';
import countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fi.json'));
countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));

export default class CompanyForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            company: props.company || {},
            selectedRoleIds: props.selectedRoleIds
        };

        const countryNames = countries.getNames(I18n.locale) || {};
        this.countryOptions = Object.keys(countryNames).map(c => ({value: c, label: countryNames[c]}));
    }

    render() {
        const company = this.state.company;
        const action = company.id ? Routes.company_update_path({company_id: company.id}) : Routes.company_create_path();
        const isOrigoOrigin = this.props.isOrigoOrigin;
        const typeEditable = isOrigoOrigin && company.origin != 'origo-system';
        const typeOptions = this.props.types.map(t => ({ value: t[0], label: t[1] }));
        const parentEditable = isOrigoOrigin || !company.parent_company_api_id;

        return <Form action={action} onSuccess={this.onSuccess}>
            {!isOrigoOrigin ? <div className="alert alert-info mb-4">
                {I18n.t('company_details_uneditable_message')}
            </div> : null}
            <fieldset>
                <legend>{I18n.t('organization_information')}</legend>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-5">
                        <div className="form-group">
                            <label htmlFor="company_type">{I18n.t('type')} *</label>
                            {typeEditable ? null : <input type="hidden" name="company[type_str_id]" defaultValue={company.type_str_id} />}
                            <Select
                                defaultValue={company.type_str_id ? typeOptions.find(o => o.value == company.type_str_id) : null}
                                name="company[type_str_id]"
                                options={typeOptions}
                                isClearable={true}
                                isDisabled={!typeEditable}
                                placeholder={I18n.t('select_blank_text')}
                                onChange={o => this.typeChanged((o || {}).value)}
                            />
                        </div>
                        {this.simpleDetailField(isOrigoOrigin, company, 'name', true)}
                        <div className="form-group">
                            <label>{I18n.t('position_in_organization_hierarchy')}</label>
                            {parentEditable ? null : <input type="hidden" name="company[parent_company_id]" defaultValue={company.parent_company_id} />}
                            <Select
                                key="company-parent"
                                defaultValue={company.parent_company ? this.formatCompanyOptions([company.parent_company])[0] : null}
                                name="company[parent_company_id]"
                                loadOptions={this.loadCompanyOptions}
                                isClearable={true}
                                isDisabled={!parentEditable}
                                placeholder={I18n.t('select_blank_text')}
                                formatOptionLabel={(option, context) => {
                                    if (context == 'value') {
                                        return option.label;
                                    }
                                    return <div style={{ paddingLeft: ((option.hierarchy_level || 0) * 2) + 'rem' }}>{option.label}</div>;
                                }}
                            />
                            <small className="text-muted">{I18n.t('company_parent_description')}</small>
                        </div>
                        {this.simpleDetailField(isOrigoOrigin, company, 'chain')}
                        {this.simpleDetailField(isOrigoOrigin, company, 'hospital_district')}
                        {this.simpleDetailField(isOrigoOrigin, company, 'owner')}
                        {this.simpleDetailField(isOrigoOrigin, company, 'business_identifier')}
                        {!isOrigoOrigin && this.simpleDetailField(false, company, 'kela_number')}
                        <div className="form-group">
                            <label>{I18n.t('profit_centers')}</label>
                            <Select
                                defaultValue={this.formatProfitCenterOptions(company.profit_centers)}
                                name="company[profit_center_ids][]"
                                options={this.formatProfitCenterOptions(this.props.profit_centers)}
                                isClearable={true}
                                isMulti={true}
                                placeholder={I18n.t('select_blank_text')}
                            />
                        </div>
                        <div className="form-group">
                            <label>{I18n.t('logo')}</label>
                            <input type="hidden" name="company[logo][upload_id]" value={this.props.uploadId} />
                            <FileUpload
                                key={this.props.uploadId}
                                name="company[logo]"
                                multiple={false}
                                files={company.logo && [Object.assign({ previewUrl: Routes.company_logo_path({ company_api_id: company.api_id, size: 'thumb', current_company_id: null }) }, company.logo)]}
                                url={Routes.company_upload_logo_path({ upload_id: this.props.uploadId })}
                                accept={['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/tiff']}
                            />
                            <small className="text-muted">{I18n.t('company_logo_description')}</small>
                        </div>
                    </div>
                </div>
            </fieldset>
            <fieldset className="mt-4">
                <legend>{I18n.t('contact_information')}</legend>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-5">
                        {this.simpleDetailField(isOrigoOrigin, company, 'street')}
                        {this.simpleDetailField(isOrigoOrigin, company, 'house_number')}
                        {this.simpleDetailField(isOrigoOrigin, company, 'business_location')}
                        {this.simpleDetailField(isOrigoOrigin, company, 'post_code')}
                        {this.simpleDetailField(isOrigoOrigin, company, 'city')}
                        <div className="form-group">
                            <label htmlFor="company_country">{I18n.t('country')}</label>
                            {isOrigoOrigin ? null : <input type="hidden" name="company[country]" defaultValue={company.country} />}
                            <Select
                                defaultValue={company.country ? this.countryOptions.find(o => o.value == company.country) : null}
                                name="company[country]"
                                options={this.countryOptions}
                                isClearable={true}
                                isDisabled={!isOrigoOrigin}
                                placeholder={I18n.t('select_blank_text')}
                            />
                        </div>
                        {this.simpleDetailField(isOrigoOrigin, company, 'phone_number')}
                        {this.simpleDetailField(isOrigoOrigin, company, 'supply_list_email')}
                        {this.simpleDetailField(isOrigoOrigin, company, 'order_response_email')}
                        {this.simpleDetailField(true, company, 'emergency_message_additional_info')}
                    </div>
                </div>
            </fieldset>
            <fieldset className="mt-4">
                <legend>{I18n.t('user_access')}</legend>
                <Assigner
                    apps={this.props.apps}
                    roles={this.props.roles}
                    selectedRoleIds={this.state.selectedRoleIds}
                    inheritedRoleIds={this.state.inheritedRoleIds}
                    inheritedTitle={I18n.t('roles_inherited_from_company_type')}
                    roleToggled={this.roleToggled}
                    userGroupToggled={this.userGroupToggled}
                    permissions={this.props.permissions}
                />
                {this.state.selectedRoleIds.map(roleId => (
                    <input key={roleId} name="company[role_ids][]" value={roleId} type="hidden" />
                ))}
            </fieldset>
            <div className="text-left mt-4">
                <a href={this.props.cancelUrl} className="btn btn-light mr-3 px-4">{I18n.t('cancel')}</a>
                <FormSubmit />
            </div>
        </Form>;
    }

    componentDidMount() {
        this.updateInheritedRoleIds(this.state.company.type_str_id);
    }

    simpleDetailField(isOrigoOrigin, company, attribute, required) {
        return <div className="form-group">
            <label htmlFor={`company_${attribute}`}>{I18n.t(attribute)}{required ? ' *' : null}</label>
            {isOrigoOrigin ? null : <input type="hidden" name={`company[${attribute}]`} defaultValue={company[attribute]} />}
            <input disabled={!isOrigoOrigin} id={`company_${attribute}`} name={`company[${attribute}]`} type="text" className="form-control" defaultValue={company[attribute]} />
        </div>;
    }

    formatProfitCenterOptions(profitCenters) {
        return (profitCenters || []).map(c => (
            { value: c.id, label: [c.code, c.name].join(' - ') }
        ));
    }

    formatCompanyOptions(companies) {
        return companies.map(c => ({ value: c.id, label: [c.api_id, c.name].join(' - '), hierarchy_level: c.hierarchy_level }));
    }

    loadCompanyOptions = _.throttle((inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        } else {
            jQuery.get(Routes.company_search_path({query: inputValue}), (results) => {
                callback(this.formatCompanyOptions(results));
            });
        }
    }, 500)

    typeChanged = (type) => {
        this.updateInheritedRoleIds(type);
    }

    roleToggled = (appId, roleId, isAssigned) => {
        this.setState(prevState => {
            let ids = prevState.selectedRoleIds;

            if (isAssigned) {
                ids.push(roleId);
            } else {
                ids.splice(ids.indexOf(roleId), 1);
            }

            return { selectedRoleIds: ids };
        });
    }

    updateInheritedRoleIds = (type) => {
        jQuery.getJSON(Routes.company_list_role_ids_path({ type: type }), (ids) => {
            this.setState(prevState => {
                return { inheritedRoleIds: ids };
            });
        });
    }

    onSuccess = (company) => {
        if (this.props.successUrl) {
            Turbolinks.visit(this.props.successUrl);
        } else {
            Turbolinks.visit(Routes.company_show_path({ company_id: company.id }));
        }
    }
}