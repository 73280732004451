import Page from "@oriola-origo/core/lib/Page";
import React, { useState, useEffect } from "react";
import OrganizationHeader from "../../components/organization_header";
import Organization from "../organization_section/organization";
import AddOrganizationDialog from "../../components/add_organization_dialog";
import ToggleButtonGroup from "@oriola-origo/core/lib/ToggleButtonGroup/ToggleButtonGroup";
import { UserData } from "../user_page_v2";
import { UserCompany } from "../../../../services/user_management/types";
import RemoveConfirmationDialog from "../../form_v2/organisation_information/components/remove_confirmation_dialog";
import Box from "@oriola-origo/core/lib/Box";
import Typography from "@oriola-origo/core/lib/Typography";
import useTranslations from "@hooks/useTranslations";
import Button from "@oriola-origo/core/lib/Button";
import useOrganizations from "@services/user_management/hooks/useOrganizations";

export enum ContentType {
  DetailedInformation = "information",
  AccessSummary = "access_summary",
}

export interface OrganizationInformationSectionProps {
  user_data: UserData;
  handleOnUserCompaniesChanged: (userCompanies: UserCompany[]) => void;
}
export default function OrganizationInformationSection({
  user_data,
  handleOnUserCompaniesChanged,
}: Readonly<OrganizationInformationSectionProps>) {
  const {
    professional_titles,
    profit_centers,
    scc_profit_center_allowed_organization_ids,
    default_professional_title_id,
    user_group_categories,
    user_groups,
    apps,
    roles,
  } = user_data;

  const { t } = useTranslations();
  const { fetchOrganizationRoles } = useOrganizations();

  const [showAddOrganizationDialog, setShowAddOrganizationDialog] =
    useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [inheritedRolesOrgMapping, setInheritedRolesOrgMapping] = useState({});
  const [organizationToBeRemovedConfig, setOrganizationToBeRemovedConfig] =
    useState<{ organization: UserCompany; hasChildren: boolean }>({
      organization: null,
      hasChildren: false,
    });
  const [content, setContent] = useState<ContentType>(
    ContentType.DetailedInformation
  );

  useEffect(() => {
    fetchRolesForCompanies();
  }, [content]);

  const fetchRolesForCompanies = async () => {
    const fetchCompanyRolesPromises = user_data.user.companies.map((company) =>
      fetchOrganizationRoles(company.id)
    );
    const results = await Promise.allSettled(fetchCompanyRolesPromises);

    const mapping = {};
    results.forEach((result, index) => {
      const company = user_data.user.companies[index];
      if (result.status === "rejected") {
        console.error(
          `Failed to fetch roles for company ${company.id}`,
          result.reason
        );
        return;
      }
      mapping[company.id] = result.value.data;
    });
    setInheritedRolesOrgMapping(mapping);
  };

  const toggleContent = () => {
    setContent(
      content === ContentType.DetailedInformation
        ? ContentType.AccessSummary
        : ContentType.DetailedInformation
    );
  };

  const onUserCompaniesChanged = (changedCompany: UserCompany) => {
    const filteredCompanies = user_data.user.companies.filter(
      (company) => company.id !== changedCompany.id
    );
    handleOnUserCompaniesChanged([...filteredCompanies, changedCompany]);
  };

  const updateDefaultOrganization = (id, companies: UserCompany[]) => {
    return companies.map((company) => ({
      ...company,
      default: company.id === id,
    }));
  };
  const onSetDefaultOrganization = (id: number) => {
    handleOnUserCompaniesChanged(
      updateDefaultOrganization(id, user_data.user.companies)
    );
  };

  const onAddOrganizations = (
    companies: UserCompany[],
    newDefaultOrganizationId
  ) => {
    const newOrganizations = companies.map((company) => ({
      ...company,
      role_ids: [],
      user_group_ids: [],
      professional_title_id: company["professionalTitle"].id,
      profit_center_ids: company["profitCenters"].map(
        (profitCenter) => profitCenter.id
      ),
      scc_profit_center_ids: company["sccProfitCenters"].map(
        (profitCenter) => profitCenter.id
      ),
    }));

    let modifiedOrganizations = [
      ...user_data.user.companies,
      ...newOrganizations,
    ];
    if (newDefaultOrganizationId) {
      modifiedOrganizations = updateDefaultOrganization(
        newDefaultOrganizationId,
        modifiedOrganizations
      );
    }
    handleOnUserCompaniesChanged(modifiedOrganizations);
  };

  const openRemoveDialog = (id: number) => {
    const organization = user_data.user.companies.find(
      (company) => company.id === id
    );
    const hasChildren = !!user_data.user.companies.find(
      (company) =>
        company.hierarchy_parent_company_ids?.includes(organization.id) &&
        !company.default
    );

    setOrganizationToBeRemovedConfig({ organization, hasChildren });
    setOpenConfirmationDialog(true);
  };

  const onRemoveOrganization = (organizationId, removeChildrenIsChecked) => {
    let modifiedOrganizations = user_data.user.companies.filter(
      (company) => company.id !== organizationId
    );
    if (removeChildrenIsChecked) {
      modifiedOrganizations = modifiedOrganizations.filter(
        (company) =>
          !company.hierarchy_parent_company_ids?.includes(organizationId) ||
          company.default
      );
    }

    handleOnUserCompaniesChanged(modifiedOrganizations);
    setOpenConfirmationDialog(false);
  };

  const getSortedCompanies = (companies: UserCompany[]) => {
    // sort companies by default organization first with it's children
    const defaultOrganization = companies[0];
    const defaultSet = companies.filter(
      (company) =>
        company.id === defaultOrganization.id ||
        company.hierarchy_parent_company_ids?.includes(defaultOrganization.id)
    );
    const restSet = companies.filter(
      (company) =>
        company.id !== defaultOrganization.id &&
        !company.hierarchy_parent_company_ids?.includes(defaultOrganization.id)
    );

    return [...defaultSet, ...restSet];
  };

  // For self-registered users - before orangization has been linked - display the suggested organization
  // When organization has been added but removed, then display text: “No organization added to user”

  const getNoOrganizationText = () => {
    if (user_data.user.signup_company_claim) {
      return (
        t("self_registered_user_company") +
        ": " +
        user_data.user.signup_company_claim
      );
    } else {
      return t("no_organization_added_to_user");
    }
  };

  const displayNoOrganizationInfo = () => {
    return (
      <Box
        minHeight={200}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1" color="secondaryDarkGray">
          {getNoOrganizationText()}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Page
        header={
          <OrganizationHeader
            title_key="organization"
            onToggleOpen={() => setShowAddOrganizationDialog(true)}
          />
        }
        pt={1}
        pb={1}
        pr={0}
        pl={0}
      >
        {user_data.user.companies.length > 0 && (
          <Box>
            <ToggleButtonGroup
              onChange={() => toggleContent()}
              value={content}
              sx={{ ml: 4, mt: 2 }}
            >
              <Button value={ContentType.DetailedInformation}>
                {t("information")}
              </Button>
              <Button value={ContentType.AccessSummary}>
                {t("access_summary")}
              </Button>
            </ToggleButtonGroup>
          </Box>
        )}
        {getSortedCompanies(user_data.user.companies).map((company, index) => (
          <Organization
            key={company.id}
            organization={company}
            isFirst={index === 0}
            isViewUserFromUserList={true}
            hasSccProfitCenters={scc_profit_center_allowed_organization_ids.includes(
              company.id
            )}
            selectedProfitCenters={user_data.profit_centers.filter(
              (profitCenter) =>
                company.profit_center_ids?.includes(profitCenter.id)
            )}
            selectedSccProfitCenters={user_data.profit_centers.filter(
              (profitCenter) =>
                company.scc_profit_center_ids?.includes(profitCenter.id)
            )}
            professionalTitles={professional_titles}
            profitCenters={profit_centers}
            onUserCompaniesChanged={onUserCompaniesChanged}
            onSetDefaultOrganization={onSetDefaultOrganization}
            openRemoveDialog={openRemoveDialog}
            user_groups={user_groups}
            user_group_categories={user_group_categories}
            apps={apps}
            roles={roles}
            inheritedRolesFromOrganization={
              inheritedRolesOrgMapping[company.id]
            }
            viewMode={content}
          />
        ))}
        {!user_data.user.companies.length && displayNoOrganizationInfo()}
      </Page>
      <AddOrganizationDialog
        selectedOrganizationIds={user_data.user.companies.map(
          (company) => company.id
        )}
        open={showAddOrganizationDialog}
        onToggleOpen={() => setShowAddOrganizationDialog(false)}
        onAddOrganizations={(
          companies: any,
          newDefaultOrganizationId: number
        ) => onAddOrganizations(companies, newDefaultOrganizationId)}
        professionalTitles={professional_titles}
        profitCenters={profit_centers}
        defaultProfessionalTitleId={default_professional_title_id}
        sccProfitCenterAllowedOrganizationIds={
          scc_profit_center_allowed_organization_ids
        }
      />
      {openConfirmationDialog && (
        <RemoveConfirmationDialog
          organization={organizationToBeRemovedConfig.organization}
          hasChildren={organizationToBeRemovedConfig.hasChildren}
          onClose={() => setOpenConfirmationDialog(false)}
          onSave={onRemoveOrganization}
        />
      )}
    </>
  );
}
