import { sortOrganizationsByHierarchy } from "@utils/organization_sorting";
import { OrganizationFilterOption } from "./organization_filter";

export const NO_ORG_OPTION_ID = -999;
export const NO_ORG_OPTION_IDENTIFIER = "no-org";

export const isNoOrgOption = (idOrOption: number | OrganizationFilterOption) =>
  typeof idOrOption === "number"
    ? idOrOption === NO_ORG_OPTION_ID
    : idOrOption.id === NO_ORG_OPTION_ID;

export const strIdsToOrganizationIds = (ids: string[]): number[] =>
  ids
    .map((id) => {
      const numeric = Number(id);
      if (!isNaN(numeric)) {
        return numeric;
      }
      if (id === NO_ORG_OPTION_IDENTIFIER) {
        return NO_ORG_OPTION_ID;
      }

      return null;
    })
    .filter(Boolean);

export const idsToStrOrganizationIds = (ids: number[]): string[] =>
  ids.map((id) =>
    isNoOrgOption(id) ? NO_ORG_OPTION_IDENTIFIER : id.toString()
  );

export const constructFilteredOptionsWithFamilies = (
  rawFilteredOptions: OrganizationFilterOption[],
  allOptions: OrganizationFilterOption[]
) => {
  const families: Record<string, OrganizationFilterOption[]> =
    rawFilteredOptions.reduce((acc, option) => {
      const parents = (option.hierarchy_parent_company_ids ?? []).map(
        (parentId) => allOptions.find((s) => s.id === parentId)
      );
      const subs = allOptions.filter((sub) =>
        sub.hierarchy_parent_company_ids?.includes(option.id)
      );

      // Important to keep the order of the array
      const family = [...parents, option, ...subs];
      const topParentId = family[0].id;

      acc[topParentId] = acc[topParentId] ?? [];

      family.forEach((option) => {
        if (!acc[topParentId].includes(option)) {
          acc[topParentId].push(option);
        }
      });

      return acc;
    }, {});

  // Sort new options to rebuild the hierarchy trees
  return sortOrganizationsByHierarchy(Object.values(families).flat());
};
