import React, { useMemo } from "react";
import Autocomplete from "@oriola-origo/core/lib/Autocomplete/Autocomplete";
import { CompanyType } from "@services/user_management/types";

export interface OrganizationTypeAutocompleteProps {
  label: string;
  options: CompanyType[];
  language: string;
  onChange: (event: React.ChangeEvent<{}>, value: CompanyType[]) => void;
  value: CompanyType[];
}

export default function OrganizationTypeAutocomplete({
  value,
  label,
  options,
  language,
  onChange,
}: Readonly<OrganizationTypeAutocompleteProps>) {
  const sortedOptions = useMemo(() => {
    return [...options].sort((a, b) => {
      const aName = a.name[language] ?? a.name["en"];
      const bName = b.name[language] ?? b.name["en"];
      return aName.localeCompare(bName);
    });
  }, [options]);

  return (
    <Autocomplete
      label={label}
      multiple={true}
      fullWidth={true}
      freeSolo={false}
      options={sortedOptions}
      getOptionKey={(option: CompanyType) => option.id}
      limitTags={1}
      disableCloseOnSelect={true}
      size="small"
      getOptionLabel={(option: CompanyType) =>
        `${option.name[language] ?? option.name["en"]}`
      }
      getLimitTagsText={(more) => `+${more}`}
      onChange={onChange}
      value={value}
    />
  );
}
