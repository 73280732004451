import { useCallback, useMemo, useState } from "react";
import { staticReportsTranslationFactory } from "./utils";

export interface FetchFilterOptionsOutput {
  permissions: {
    maySelectCustomers: boolean;
  };
  filterOptions: {
    reportNames: Array<{
      value: string;
      label: string;
      default: string;
    }>;
  };
}

export interface UseFetchFilterOptionsOutput {
  isFetching: boolean;
  fetchFilterOptions: () => Promise<FetchFilterOptionsOutput>;
}

const prepareReportNames = (
  reportNames: Array<{ label: string; value: string }>,
  language: string
) => {
  const getText = staticReportsTranslationFactory(language);
  return reportNames.map((report) => ({
    ...report,
    label: getText(`report_name_${report.label}`, report.label),
    default: report.label,
  }));
};

const buildFilterOptionsOutput = (
  json: any,
  language: string
): FetchFilterOptionsOutput => ({
  permissions: {
    maySelectCustomers: !!json.permissions?.maySelectCustomers,
  },
  filterOptions: {
    reportNames: prepareReportNames(
      json.filterOptions.report_names || [],
      language
    ),
  },
});

const useFetchFilterOptions = (
  language: string,
  organizationId?: string
): UseFetchFilterOptionsOutput => {
  const [isFetching, setIsFetching] = useState(false);

  const fetchFilterOptions =
    useCallback(async (): Promise<FetchFilterOptionsOutput> => {
      setIsFetching(true);
      try {
        const data = await fetch(
          Routes.dashboard_widget_data_path({
            widget: "StaticReports",
            method: "filter_options",
            organization_id: organizationId,
          })
        );

        const json = await data.json();
        return buildFilterOptionsOutput(json, language);
      } finally {
        setIsFetching(false);
      }
    }, [language, organizationId]);

  return useMemo(
    () => ({
      isFetching,
      fetchFilterOptions,
    }),
    [fetchFilterOptions, isFetching]
  );
};

export default useFetchFilterOptions;
