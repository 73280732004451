import React, { useState } from "react";
import OrganizationInformationSection from "./organization_information_section";
import UserAccessSection from "./user_access_section";
import useUserAccessAndOrg from "./useUserAccessAndOrg";

import { UserPageV2Props } from "../user_page_v2";
import { UserCompany } from "../../../../services/user_management/types";
import { UserAccessCompanyMap } from "../../components/user_access_information/user_access_information";

export default function CombinedOrganizationAndUserAccessSections({
  user_data,
}: Readonly<UserPageV2Props>) {
  const { updateUserAccessMap, createUserAccessMapFromCompaniesData, user } =
    useUserAccessAndOrg();
  const [userCompanies, setUserCompanies] = useState(user_data.user.companies); // Use setUserCompanies to update latest user companies data
  const [userAccessMap, setUserAccessMap] = useState(
    createUserAccessMapFromCompaniesData(userCompanies)
  );

  const handleOnAccessMapChanged = (userAccessMap: UserAccessCompanyMap) => {
    setUserAccessMap(userAccessMap);
    updateUserAccessMap(userAccessMap, userCompanies);
  };
  const handleOnUserCompaniesChanged = (userCompanies: UserCompany[]) => {
    setUserCompanies(userCompanies);

    const companyIds = userCompanies.map((company) => company.id);
    let modifiedUserAccessMap = {};
    for (const companyId of companyIds) {
      modifiedUserAccessMap[companyId] = userAccessMap[companyId] || { roleIds: [], userGroupIds: [] };
    }
    
    setUserAccessMap(modifiedUserAccessMap);
    updateUserAccessMap(
      createUserAccessMapFromCompaniesData(userCompanies),
      userCompanies
    );
  };

  return (
    <>
      <OrganizationInformationSection
        user_data={{
          ...user_data,
          user: { ...user_data.user, companies: user.companies },
        }}
        handleOnUserCompaniesChanged={handleOnUserCompaniesChanged}
      />
      <UserAccessSection
        userCompanies={userCompanies}
        userData={user_data}
        userAccessMap={userAccessMap}
        handleOnAccessMapChanged={handleOnAccessMapChanged}
      />
    </>
  );
}
