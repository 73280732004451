import React, { useMemo } from "react";
import {
  CompanyType,
  OrigoApplicationRole,
} from "@services/user_management/types";
import DetailsIcon from "./details_icon";
import Box from "@oriola-origo/core/lib/Box";
import { buildTooltipContent } from "./details_tooltip";
import useTranslations from "@hooks/useTranslations";

export interface DetailsSummaryProps {
  roles: OrigoApplicationRole[];
  companyTypes: CompanyType[];
}

function DetailsSummary({
  roles,
  companyTypes,
}: Readonly<DetailsSummaryProps>) {
  const i18n = useTranslations();
  const rolesWithIcons = useMemo(
    () => roles.filter((role) => role.icon),
    [roles]
  );

  const rolesPerIcon: Record<string, OrigoApplicationRole[]> =
    rolesWithIcons.reduce(
      (acc, role) => ({
        ...acc,
        [role.icon]: [...(acc[role.icon] || []), role],
      }),
      {}
    );

  return (
    <Box display="flex" gap={1}>
      {Object.keys(rolesPerIcon).map((icon) => (
        <DetailsIcon
          key={icon}
          icon={icon}
          tooltipContent={buildTooltipContent(
            rolesPerIcon[icon],
            i18n,
            companyTypes
          )}
          endAdornmentText={
            rolesPerIcon[icon].length > 1
              ? `+${rolesPerIcon[icon].length - 1}`
              : ""
          }
        />
      ))}
    </Box>
  );
}

export default DetailsSummary;
