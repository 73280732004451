import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Box from "@oriola-origo/core/lib/Box";
import Button from "@oriola-origo/core/lib/Button";
import Dialog from "@oriola-origo/core/lib/Dialog";
import DialogActions from "@oriola-origo/core/lib/DialogActions";
import DialogContent from "@oriola-origo/core/lib/DialogContent";
import DialogTitle from "@oriola-origo/core/lib/DialogTitle";
import IconButton from "@oriola-origo/core/lib/IconButton";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import TextField from "@oriola-origo/core/lib/TextField";
import React, { useState } from "react";
import useTranslations from "../../../hooks/useTranslations";
import useUserGroupCategories, {
  UserGroupCategory,
  UserGroupCategoryWithDelete,
} from "../../../services/user_management/hooks/useUserGroupCategories";
import useSnackbar from "@hooks/useSnackbar";

export const editCategoryButton = (toggleOpen, t) => {
  return (
    <Button
      color="secondary"
      startIcon={<FontIcon>edit</FontIcon>}
      variant="contained"
      sx={{
        color: "#2B3E5B",
        backgroundColor: "#fff !important",
        "&:focus": {
          outline: "none",
        },
      }}
      onClick={toggleOpen}
    >
      {t("edit")}
    </Button>
  );
};

export interface EditCategoryProps {
  categories: UserGroupCategory[];
  dialogVisible?: boolean;
  fetchCategoriesAfterUpdate: () => void;
}

const mapToEditType = (
  categories: UserGroupCategory[]
): UserGroupCategoryWithDelete[] => {
  return categories.map((category) => ({ ...category, isDeleted: false }));
};

function EditCategory({
  categories: originalCategories,
  dialogVisible,
  fetchCategoriesAfterUpdate,
}: EditCategoryProps) {
  const { setSnackMessage } = useSnackbar();
  const { saveDeleteUserGroupCategories } = useUserGroupCategories();
  // Local state so changes does not effect the main display of categories
  const [categories, setCategories] = useState(
    mapToEditType(originalCategories)
  );
  const { t } = useTranslations();
  const [open, setOpen] = useState(dialogVisible ?? false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleOpen = () => setOpen(!open);

  const updateCategories = async () => {
    setIsLoading(true);
    try {
      await saveDeleteUserGroupCategories(categories);
      setSnackMessage(t("update_user_group_categories_success"), "success");
      fetchCategoriesAfterUpdate();
    } catch (e) {
      setSnackMessage(t("update_user_group_categories_failure"), "error");
      console.error("Failed to update categories", e);
    } finally {
      setIsLoading(false);
      setOpen(false);
    }
  };

  const resetStates = () => {
    setCategories(mapToEditType(originalCategories));
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = handleDragEndFactory(setCategories);

  const updateCategoryState = (
    id: UserGroupCategoryWithDelete["id"],
    updateData: Partial<UserGroupCategoryWithDelete>
  ) => {
    const updatedCategories = categories.map((category) => {
      if (category.id === id) {
        return {
          ...category,
          ...updateData,
        };
      }
      return category;
    });
    setCategories(updatedCategories);
  };

  const displayCategories = categories.filter(
    (category) => !category.isDeleted
  );

  return (
    <Box>
      {editCategoryButton(toggleOpen, t)}
      <Dialog
        open={open}
        disableEscapeKeyDown={false}
        onClose={() => {
          resetStates();
          toggleOpen();
        }}
      >
        <Box sx={{ backgroundColor: "white" }}>
          <DialogTitle
            onCloseButtonClick={() => {
              resetStates();
              toggleOpen();
            }}
          >
            {t("edit_user_group_categories")}
          </DialogTitle>
          <DialogContent>
            <Box mb={4}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={categories}
                  strategy={verticalListSortingStrategy}
                >
                  {displayCategories.map(({ id, name }) => (
                    <SortableItem key={id} id={id}>
                      <TextField
                        sx={{ width: "360px" }}
                        label={t("user_group_category")}
                        maxRows={1}
                        minRows={1}
                        value={name}
                        onChange={(event) => {
                          updateCategoryState(id, { name: event.target.value });
                        }}
                        InputProps={{
                          sx: { paddingRight: 0 },
                          endAdornment: (
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                updateCategoryState(id, { isDeleted: true })
                              }
                            >
                              <FontIcon
                                color="secondary"
                                colorTone="light"
                                fontSize="medium"
                              >
                                delete
                              </FontIcon>
                            </IconButton>
                          ),
                        }}
                      />
                    </SortableItem>
                  ))}
                </SortableContext>
              </DndContext>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                resetStates();
                toggleOpen();
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={updateCategories}
            >
              {t("save")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const style = {
    opacity: isDragging ? 0.15 : undefined,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Box sx={{ paddingTop: "10px" }} mb={4}>
        <IconButton style={{ fontSize: 20 }} {...attributes} {...listeners}>
          <FontIcon sx={{ fontSize: "inherit" }}>drag_indicator</FontIcon>
        </IconButton>
        {props.children}
      </Box>
    </div>
  );
}

type SetStateFnType<T> = React.Dispatch<React.SetStateAction<T>>;

/**
 * Extracted to make this testable
 * @returns
 */
export const handleDragEndFactory =
  (setCategories: SetStateFnType<UserGroupCategory[]>) =>
  (event: {
    active: { id: UniqueIdentifier };
    over: { id: UniqueIdentifier };
  }) => {
    const { active, over } = event;

    if (active.id === over.id) {
      return;
    }

    setCategories((items) => {
      return moveItems(items, active.id, over.id);
    });
  };

/**
 * Extracted to make this testable
 * @returns
 */
export const moveItems = <T extends { id: UniqueIdentifier }>(
  items: T[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier
): T[] => {
  const fromIndex = items.findIndex((item) => item.id === activeId);
  const toIndex = items.findIndex((item) => item.id === overId);
  const updated = arrayMove(items, fromIndex, toIndex);
  return updated;
};

export default EditCategory;
