import React from 'react';
import { Table, TableRowActions } from '../common/table';
import { ContentSection, ContentSectionHead } from '../common/content_section';

export default class List extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div>
            <ContentSection>
                <ContentSectionHead>
                    <h2>{I18n.t('services_widgets')}</h2>
                </ContentSectionHead>
                <InternalWidgetList widgets={this.props.internal_widgets} permissions={this.props.permissions} />
            </ContentSection>
            <ContentSection className="mt-5">
                <ContentSectionHead>
                    <h2>{I18n.t('external_widgets')}</h2>
                </ContentSectionHead>
                <ExternalWidgetList widgets={this.props.external_widgets} permissions={this.props.permissions} />
            </ContentSection>
        </div>;
    }
}

class InternalWidgetList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let columns = [
            {
                dataField: 'name',
                text: I18n.t('name'),
                sort: true,
                headerClasses: 'w-50',
                formatter: (cell, row) => {
                    if (row.id == 'tips') {
                        return <a href={Routes.usage_tip_list_path()}>{row.name}</a>;
                    } else if (row.id == 'contact') {
                        return <a href={Routes.usage_contact_list_path()}>{row.name}</a>;
                    } else {
                        return row.name;
                    }
                }
            },
            {
                dataField: 'context',
                text: I18n.t('dashboard_context'),
                sort: true,
                formatter: (cell, row) => {
                    return I18n.t(`dashboard_context_${row.context}`);
                }
            },
            {
                dataField: 'id',
                text: '',
                classes: 'row-actions w-25',
                headerClasses: 'row-actions w-25',
                formatter: (cell, row) => {
                    let actions = [];

                    if (row.id == 'tips') {
                        actions.push(<a key="edit" href={Routes.usage_tip_list_path()}>
                            <i className="fa fa-pencil"></i> {I18n.t('edit')}
                        </a>);
                    }
                    if (row.id == 'contact') {
                        actions.push(<a key="edit" href={Routes.usage_contact_list_path()}>
                            <i className="icon-edit"></i> {I18n.t('edit')}
                        </a>);
                    }
                    return <TableRowActions.Menu disabled={actions.length == 0}>
                        {actions}
                    </TableRowActions.Menu>;
                }
            }
        ];

        return <Table
            data={this.props.widgets}
            columns={columns}
            pagination={false}
            defaultSorted={[{
                dataField: 'name',
                order: 'asc'
            }]}
            search={false}
            filterCallback={this.filter} />;
    }

    filter(row, filter, filterRexExp) {
        return [
            row.name
        ].join(' ').match(filterRexExp);
    }
}

class ExternalWidgetList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            widgets: props.widgets
        };

        this.filter = this.filter.bind(this);
        this.deleteWidget = this.deleteWidget.bind(this);
    }

    render() {
        let columns = [
            {
                dataField: 'name',
                text: I18n.t('name'),
                sort: true,
                headerClasses: 'w-50',
                formatter: (cell, row) => {
                    if (this.props.permissions.may_edit) {
                        return <a href={Routes.dashboard_widget_edit_path({ widget_id: row.id })} data-dialog="true" data-title={I18n.t('edit_widget')}>
                            {row.name}
                        </a>;
                    } else {
                        return row.name;
                    }
                }
            },
            {
                dataField: 'context',
                text: I18n.t('dashboard_context'),
                sort: true,
                formatter: (cell, row) => {
                    return I18n.t(`dashboard_context_${row.context}`);
                }
            },
            {
                dataField: 'id',
                text: '',
                classes: 'row-actions',
                headerClasses: 'row-actions',
                formatter: (cell, row) => {
                    let actions = []

                    if (this.props.permissions.may_edit) {
                        actions.push(<a key="edit" href={Routes.dashboard_widget_edit_path({ widget_id: row.id })} data-dialog="true" data-title={I18n.t('edit_widget')}>
                            <i className="fa fa-pencil"></i> {I18n.t('edit')}
                        </a>);
                    }
                    if (this.props.permissions.may_delete) {
                        if (actions.length > 0) {
                            actions.push(<TableRowActions.Divider key="div" />)
                        }
                        actions.push(<a key="del" href={Routes.dashboard_widget_delete_path({ widget_id: row.id })} onClick={this.deleteWidget} data-id={row.id}>
                            <i className="oricon-bin"></i> {I18n.t('delete')}
                        </a>);
                    }
                    return actions.length > 0 ? <TableRowActions.Menu>{actions}</TableRowActions.Menu> : null
                }
            }
        ];

        return <Table
            key={this.state.widgets.length} // Forces update on delete
            actions={this.renderActions()}
            data={this.state.widgets}
            columns={columns}
            pagination={true}
            defaultSorted={[{
                dataField: 'name',
                order: 'asc'
            }]}
            filterCallback={this.filter} />;
    }

    renderActions() {
        let actions = [];

        if (this.props.permissions.may_add) {
            actions.push(<a key="add" href={Routes.dashboard_widget_new_path()} className="btn btn-light" data-dialog="true" data-title={I18n.t('add_widget')}>
                <i className="fa fa-plus text-highlight"></i> {I18n.t('add')}
            </a>);
        }
        return actions;
    }

    filter(row, filter, filterRexExp) {
        return [
            row.name
        ].join(' ').match(filterRexExp);
    }

    deleteWidget(event) {
        let a = jQuery(event.currentTarget);
        let widget = this.state.widgets.filter(w => w.id == a.data('id'))[0];

        if (widget && confirm(I18n.t('confirm_delete_dashboard_widget', { name: widget.name }))) {
            jQuery.ajax({
                url: a.attr('href'),
                type: 'DELETE',
                success: () => {
                    this.setState({
                        widgets: this.state.widgets.filter(w => w.id != widget.id)
                    });
                }
            });
        }
        event.preventDefault();
    }
}