import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import DropdownMenu from "../common/dropdown_menu";
import { Layouts } from "./layouts";
import _ from "underscore";
import * as DashboardWidgets from "./widgets/exports";
import NewReportPageLayout from "./new_reports_page_layout";
import { Modal, CloseModal } from "../common/modal";
import { Form, FormSubmit } from "../common/form";
import { Scrollbars } from "react-custom-scrollbars";
import AttentionBar from "../common/attention_bar";

for (let widgetName in DashboardWidgets) {
  Origo.Widget.register(widgetName, Origo.BASE_URL, (props, container) => {
    ReactDOM.render(
      React.createElement(DashboardWidgets[widgetName], props),
      container
    );
  });
}

function WidgetContent(props) {
  const contentProps = Object.assign({}, props);
  delete contentProps.fixedHeight;

  if (props.fixedHeight) {
    return (
      <Scrollbars
        {...contentProps}
        renderThumbVertical={(props) => (
          <div {...props} className="scrollbar-thumb-vertical" />
        )}
        renderTrackVertical={(props) => (
          <div {...props} className="scrollbar-track-vertical" />
        )}
        renderThumbHorizontal={(props) => (
          <div {...props} className="scrollbar-thumb-horizontal" />
        )}
        renderTrackHorizontal={(props) => (
          <div {...props} className="scrollbar-track-horizontal" />
        )}
      >
        {props.children}
      </Scrollbars>
    );
  } else {
    return <div {...contentProps}>{props.children}</div>;
  }
}

WidgetContent.propTypes = {
  fixedHeight: PropTypes.bool,
  children: PropTypes.node,
}

export default class Dashboard extends React.Component {
  static propTypes = {
    isResponsiblePerson: PropTypes.bool,
    widgets: PropTypes.array,
    attention_bar_data: PropTypes.object,
    dashboard: PropTypes.object,
    access_token: PropTypes.string,
    id_token: PropTypes.string,
    organization_id: PropTypes.string,
    editable: PropTypes.bool,
    from: PropTypes.string,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.updateWidgetHoverState = _.throttle(this.updateWidgetHoverState, 100);

    this.state = {
      widgets: props.widgets,
      attentionBarData: props.attention_bar_data?.other || []
    };



    this.scrollOnDragDuration = 200;
    this.scrollOnDragOngoing = false;

    this.rootRef = React.createRef();
  }
  render() {
    if (!this.props.dashboard) {
      return (
        <div className="card" style={{ maxWidth: "50rem", margin: "auto" }}>
          <div className="card-body">
            <h3 className="card-title text-primary">
              {I18n.t("empty_dashboard_title")}
            </h3>
            {I18n.t("empty_dashboard_text")}
          </div>
        </div>
      );
    } else if (
      ["sidebar_one_column", "sidebar_one_column_primary"].includes(
        this.props.dashboard.layout
      ) &&
      !this.props.editable
    ) {
      const widgets = this.state.widgets.map((w) => ({
        ...w,
        instanceId: `OW${w.id}`,
        mode: w.mode || Origo.Widget.Mode.VIEW,
        language: I18n.locale,
        accessToken: this.props.access_token,
        idToken: this.props.id_token,
        organizationId: this.props.organization_id,
      }));
      return (
        <NewReportPageLayout
          id="dashboard-reports"
          {...this.props}
          widgets={widgets}
          dynamicWidgetProps={{ update: this.updateSettings }}
        ></NewReportPageLayout>
      );
    } else {
      let layoutWidgets = (this.state.widgets || []).map((w, i) => {
        const useAutoHeight =
          this.state.widgetExpanded ||
          w.size.height == "auto" ||
          w.mode == Origo.Widget.Mode.SETTINGS;
        const isVisibilityRestricted =
          (w.user_group_ids || []).concat(w.role_ids || []).length > 0;

        return {
          layoutPosition: this.state.widgetExpanded ? 1 : w.layout_position,
          markup: (
            <div
              key={i}
              className={[
                "widget",
                `widget-${w.widget_identifier.toLowerCase()}`,
                useAutoHeight ? "height-auto" : "height-fixed",
                this.props.editable ? "editable" : null,
                w.title ? null : "no-header",
                this.state.widgetExpanded ? "expanded" : null,
              ]
                .filter((c) => c)
                .join(" ")}
              style={
                w.size.height == "auto"
                  ? null
                  : useAutoHeight
                  ? {}
                  : { height: w.size.height + "rem" }
              }
              data-title={w.title}
              data-id={w.id}
            >
              {this.props.editable && w.mode != Origo.Widget.Mode.SETTINGS ? (
                <div className="actions py-2 px-3 rounded">
                  <span
                    className="btn btn-link mr-3 border bg-white move"
                    onMouseDown={this.startDrag}
                  >
                    <i className="fa fa-arrows"></i>
                  </span>
                  <div className="d-inline-block">
                    <DropdownMenu.Menu
                      label={<i className="icon icon-settings"></i>}
                      align="right"
                      btnClass="btn btn-light bg-white dropdown-toggle"
                    >
                      <a
                        href="#"
                        onClick={(e) => {
                          this.openWidgetEditor("size", w);
                          e.preventDefault();
                        }}
                      >
                        <i className="fa fa-arrows-v"></i>{" "}
                        {I18n.t("set_height")}{" "}
                        <span className="text-muted">
                          (
                          {w.size.height == "auto"
                            ? I18n.t("widget_height_auto").toLowerCase()
                            : I18n.t("widget_height_fixed").toLowerCase()}
                          )
                        </span>
                      </a>
                      <a
                        href="#"
                        onClick={(e) => {
                          this.openWidgetEditor("visibility", w);
                          e.preventDefault();
                        }}
                      >
                        <i
                          className={`fa fa-${
                            isVisibilityRestricted ? "lock" : "unlock"
                          }`}
                        ></i>{" "}
                        {I18n.t("set_widget_visibility")}{" "}
                        <span className="text-muted">
                          (
                          {!isVisibilityRestricted
                            ? I18n.t("widget_visibility_default").toLowerCase()
                            : I18n.t(
                                "widget_visibility_restricted"
                              ).toLowerCase()}
                          )
                        </span>
                      </a>
                      <a
                        href="#"
                        onClick={(e) => {
                          this.enterSettingsMode(w.id);
                          e.preventDefault();
                        }}
                      >
                        <i className="icon icon-settings"></i>{" "}
                        {I18n.t("configure")}
                      </a>
                      <DropdownMenu.Divider />
                      <a
                        href={Routes.dashboard_widget_link_delete_path({
                          link_id: w.id,
                          from: this.props.from,
                        })}
                        onClick={this.deleteWidget}
                        data-title={w.title}
                      >
                        <i className="oricon-bin"></i> {I18n.t("delete")}
                      </a>
                    </DropdownMenu.Menu>
                  </div>
                </div>
              ) : null}
              <WidgetContent
                fixedHeight={!useAutoHeight}
                className="content"
                id={`dashboard-widget-content-${w.id}`}
                key={w.id}
              >
                {w.reloading ? null : (
                  <DashboardWidget
                    identifier={w.widget_identifier}
                    instanceId={`OW${w.id}`}
                    mode={w.mode || Origo.Widget.Mode.VIEW}
                    viewSize={
                      this.state.widgetExpanded
                        ? Origo.Widget.Size.EXPANDED
                        : Origo.Widget.Size.DEFAULT
                    }
                    language={I18n.locale}
                    settings={w.modifiedSettings || w.settings || {}}
                    updateSettings={(settings) =>
                      this.updateSettings(w.id, settings)
                    }
                    accessToken={this.props.access_token}
                    idToken={this.props.id_token}
                    isAutoHeight={useAutoHeight}
                    organizationId={this.props.organization_id}
                  />
                )}
                {w.mode == Origo.Widget.Mode.SETTINGS && (
                  <div className="clearfix p-3 text-right">
                    <button
                      type="button"
                      onClick={(e) => this.cancelSettingsMode(w.id)}
                      className="btn btn-light px-4 mr-3"
                    >
                      {I18n.t("cancel")}
                    </button>
                    <button
                      type="submit"
                      onClick={(e) => this.saveSettings(w.id)}
                      className="btn btn-primary px-4"
                    >
                      {I18n.t("save")}
                    </button>
                  </div>
                )}
                <div className="drag-overlay"></div>
              </WidgetContent>
            </div>
          ),
        };
      });
      let widgetsContent = null;

      if (layoutWidgets.length > 0 && !this.state.widgetExpanded) {
        widgetsContent = React.createElement(
          Layouts[this.props.dashboard.layout],
          { widgets: layoutWidgets, dragOngoing: this.state.dragOngoing }
        );
      } else if (layoutWidgets.length == 1 && this.state.widgetExpanded) {
        widgetsContent = React.createElement(Layouts["hero_two_columns"], {
          widgets: layoutWidgets,
          dragOngoing: false,
        });
      } else {
        widgetsContent = this.props.editable ? (
          <div className="text-center pt-5">
            <h1 className="text-muted-more">
              {I18n.t("no_dashboard_widgets_message")}
            </h1>
            <a
              href={Routes.dashboard_widget_link_new_path({
                dashboard_id: this.props.dashboard.id,
                from: this.props.from,
              })}
              data-dialog="true"
              data-title={I18n.t("add_widget")}
              className="btn btn-primary btn-lg mt-2"
            >
              {I18n.t("add_widget")}
            </a>
          </div>
        ) : null;
      }
      return (
        <div id="dashboard" ref={this.rootRef}>
          {this.maybeRenderAttentionBars()}
          <div
            className={
              "widgets" + (this.state.dragOngoing ? " drag-ongoing" : "")
            }
            onMouseMove={this.doDrag}
            onMouseUp={this.endDrag}
          >
            {widgetsContent}
          </div>
          {this.state.editorAttribute && (
            <EditWidgetModal
              attribute={this.state.editorAttribute}
              widget={this.state.editedWidget}
              userGroups={this.props.user_groups}
              roles={this.props.roles}
              onClose={this.closeWidgetEditor}
              onChange={this.editorWidgetChanged}
            />
          )}
        </div>
      );
    }

  }

  maybeRenderAttentionBars = () => {
    if (!this.state.attentionBarData.length) {
      return null;
    }

    return (
      <AttentionBar
        items={this.state.attentionBarData}
        isEmergencyResponsiblePerson={this.props.isResponsiblePerson}
        onDismiss={this.dismissPromotions}
      />
    );
  };

  dismissPromotions = (dismissedItems) => {
    const newAttentionBarData = this.state.attentionBarData.filter(
      a => !dismissedItems.some(d => d.id === a.id)
    );
    const removingPromotions = dismissedItems.map((a) => ({
      promotion_id: a.id,
      announcement_type: a.type,
    }));

    jQuery
      .ajax({
        url: Routes.dashboard_promotion_dismiss_path(),
        type: "POST",
        data: { promotions: removingPromotions },
      })
      .done(() => {
        this.setState((prevState) => ({
          ...prevState,
          attentionBarData: newAttentionBarData,
        }));
      });
  };

  openWidgetEditor = (attribute, widget) => {
    this.setState((prevState) => {
      return {
        editorAttribute: attribute,
        editedWidget: widget,
      };
    });
  };

  closeWidgetEditor = () => {
    const linkId = this.state.editedWidget.id;
    this.setState({ editorAttribute: null, editedWidget: null }, () =>
      this.reloadWidget(linkId)
    );
  };

  editorWidgetChanged = _.throttle((widget) => {
    this.setState(
      (prevState) => {
        let widgets = prevState.widgets;
        Object.assign(
          widgets.find((w) => w.id == widget.id),
          widget
        );
        return { widgets: widgets };
      },
      () => this.reloadWidget(widget.id)
    );
  }, 1000);

  deleteWidget = (event) => {
    let a = jQuery(event.currentTarget);

    if (
      confirm(
        I18n.t("confirm_delete_dashboard_widget_link", {
          title: a.attr("data-title"),
        })
      )
    ) {
      jQuery.ajax({
        url: a.attr("href"),
        type: "DELETE",
        success: () => {
          Turbolinks.visit(
            Routes.dashboard_path({
              dashboard_id: this.props.dashboard.id,
              from: this.props.from,
            })
          );
        },
      });
    }
    event.preventDefault();
  };

  enterSettingsMode = (linkId) => {
    this.reloadWidget(linkId, Origo.Widget.Mode.SETTINGS);
  };

  cancelSettingsMode = (linkId) => {
    this.reloadWidget(linkId, Origo.Widget.Mode.VIEW);
  };

  saveSettings = (linkId) => {
    const widget = this.state.widgets.find((w) => w.id == linkId);
    const settings = widget.modifiedSettings || widget.settings;

    jQuery.post(
      Routes.dashboard_widget_link_update_settings_path({ link_id: linkId }),
      { settings: JSON.stringify(settings) },
      () => {
        this.setState(
          (prevState) => {
            let widgets = prevState.widgets;
            widgets.find((w) => w.id == linkId).settings = settings;
          },
          () => this.reloadWidget(linkId, Origo.Widget.Mode.VIEW)
        );
      }
    );
  };

  updateSettings = (linkId, settings) => {
    this.setState((prevState) => {
      let widgets = prevState.widgets;
      let widget = widgets.find((w) => w.id == linkId);
      widget.modifiedSettings = Object.assign(
        JSON.parse(
          JSON.stringify(widget.modifiedSettings || widget.settings || {})
        ),
        settings
      );
      return { widgets: widgets };
    });
  };

  componentDidMount() {
    this.initDragContent();
    window.addEventListener("hashchange", this.selectWidget);

    if (location.hash.match("#expand-")) {
      this.selectWidget();
    }
  }

  // Select the widget for expanded view
  selectWidget = () => {
    this.setState((prevState) => {
      const hash = location.hash;

      if (hash.match("#expand-")) {
        const filteredWidgets = this.props.widgets.filter(
          (w) => `#expand-OW${w.id}` === hash
        );
        return { widgets: filteredWidgets, widgetExpanded: true };
      } else {
        return { widgets: this.props.widgets, widgetExpanded: false };
      }
    });
  };

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.selectWidget);
  }

  reloadWidget = (linkId, mode) => {
    this.setState(
      (prevState) => {
        let widget = prevState.widgets.find((w) => w.id == linkId);
        widget.reloading = true;
        return { widgets: prevState.widgets };
      },
      () => {
        this.setState((prevState) => {
          let widget = prevState.widgets.find((w) => w.id == linkId);
          Object.assign(widget, {
            mode: mode || Origo.Widget.Mode.VIEW,
            modifiedSettings: null,
            reloading: false,
          });
          return { widgets: prevState.widgets };
        });
      }
    );
  };

  initDragContent = () => {
    // Place drag content to body
    jQuery(
      '<div id="dashboard-drag-content" style="display: none;"><h3 class="title"></h3></div>'
    ).appendTo("body");
    this.dragContainer = jQuery("#dashboard-drag-content");
    this.dragContentOffset = { left: 0, top: 0 };
  };

  startDrag = (event) => {
    if (this.props.editable) {
      let widget = jQuery(event.currentTarget).closest(".widget");

      this.dragContainer.css({
        width: widget.outerWidth() + "px",
        height: widget.outerHeight() + "px",
      });
      this.dragContentOffset = {
        left: event.pageX - widget.offset().left,
        top: event.pageY - widget.offset().top,
      };
      this.dragContainer.find(".title").html(widget.attr("data-title"));

      this.setState({
        dragOngoing: true,
        dragWidgetId: parseInt(widget.attr("data-id"), 10),
      });

      this.dragCursorPos = { x: event.pageX, y: event.pageY };
      this.updateWidgetHoverState();
    }

    event.preventDefault();
  };

  doDrag = (event) => {
    if (this.state.dragOngoing) {
      this.dragContainer.css({
        left: event.pageX - this.dragContentOffset.left + "px",
        top: event.pageY - this.dragContentOffset.top + "px",
        display: "block",
      });

      this.dragCursorPos = { x: event.pageX, y: event.pageY };
      this.updateWidgetHoverState();
      this.scrollOnDrag(event.pageY);

      event.preventDefault();
    }
  };

  endDrag = (event) => {
    if (this.state.dragOngoing) {
      let dropTarget = jQuery(
        "#dashboard .widget.hover, #dashboard .widget-drop-area.hover, #dashboard .dashboard-layout [data-position].hover"
      );

      // If dropped over the layout position, do the drop within the drop area in that position
      if (dropTarget.is("[data-position]")) {
        dropTarget = dropTarget.find(".widget-drop-area");
      }

      // If dropped on a target that is not the dragged widget itself
      if (
        dropTarget.length == 1 &&
        parseInt(dropTarget.attr("data-id"), 10) != this.state.dragWidgetId
      ) {
        let newPositions = {};

        // Dropped widget position
        newPositions[this.state.dragWidgetId] = {
          layout_position: dropTarget
            .closest("[data-position]")
            .attr("data-position"),
          layout_sort_order:
            dropTarget
              .closest("[data-position]")
              .find(".widget, .widget-drop-area")
              .index(dropTarget) + 1,
        };

        // Resolve positions for others from visible DOM elements
        jQuery("#dashboard .widgets [data-position]").each((pi, p) => {
          let position = p.getAttribute("data-position");
          jQuery(p)
            .find('.widget:not([data-id="' + this.state.dragWidgetId + '"])')
            .each((wi, w) => {
              newPositions[parseInt(w.getAttribute("data-id"), 10)] = {
                layout_position: position,
                layout_sort_order:
                  position !=
                  newPositions[this.state.dragWidgetId].layout_position
                    ? wi + 1
                    : wi <
                      newPositions[this.state.dragWidgetId].layout_sort_order -
                        1
                    ? wi + 1
                    : wi + 2,
              };
            });
        });

        // Update widgets in state
        let widgets = this.state.widgets
          .map((w) => {
            return Object.assign(Object.assign({}, w), {
              layout_position: newPositions[w.id].layout_position,
              layout_sort_order: newPositions[w.id].layout_sort_order,
            });
          })
          .sort((l, r) => {
            return l.layout_sort_order - r.layout_sort_order;
          });

        this.setState(
          {
            dragOngoing: false,
            dragWidgetId: null,
            widgets: widgets,
          },
          () => {
            jQuery.post(
              Routes.dashboard_widget_link_update_order_path({
                dashboard_id: this.props.dashboard.id,
              }),
              { positions: newPositions }
            );
          }
        );
      } else {
        this.setState({
          dragOngoing: false,
          dragWidgetId: null,
        });
      }
      this.dragContainer.hide();

      event.preventDefault();
    }
  };

  // Scroll window up or down if the drag is close enough to top or bottom.
  scrollOnDrag = (cursorY) => {
    if (this.scrollOnDragOngoing || !this.state.dragOngoing) {
      return;
    }

    let scrollContainer = jQuery(this.rootRef.current).closest(
      "#content-scroll"
    );
    let scrollTop = scrollContainer.scrollTop();
    let offset = scrollContainer.offset();
    let height = scrollContainer.innerHeight();
    let scrollBottom = scrollContainer.get(0).scrollHeight - height;
    let newScrollTop = scrollTop;

    // Scroll if within top/bottom 10% of the height of container
    if (cursorY < offset.top + 0.1 * height && scrollTop > 0) {
      newScrollTop = scrollTop - 200;
    } else if (
      cursorY > offset.top + height - 0.1 * height &&
      scrollTop < scrollBottom
    ) {
      newScrollTop = scrollTop + 200;
    }

    if (newScrollTop != scrollTop) {
      this.scrollOnDragOngoing = true;
      scrollContainer.animate(
        {
          scrollTop: newScrollTop,
        },
        {
          duration: this.scrollOnDragDuration,
          easing: "linear",
          complete: () => {
            this.scrollOnDragOngoing = false;
          },
        }
      );
    } else {
      this.scrollOnDragOngoing = false;
    }
  };

  // Simple CSS :hover would do but it doesn't seem to trigger on Safari
  // while dragging an element.
  updateWidgetHoverState = () => {
    jQuery(
      ".dashboard-layout [data-position], #dashboard .widget, #dashboard .widget-drop-area"
    ).each((i, e) => {
      e = jQuery(e);
      let offset = e.offset();
      e.toggleClass(
        "hover",
        (this.dragCursorPos.x > offset.left &&
          this.dragCursorPos.x < offset.left + e.outerWidth() &&
          this.dragCursorPos.y > offset.top &&
          this.dragCursorPos.y < offset.top + e.outerHeight()) ||
          // A drop area should be highlighted if the containing layout position is hovered but not any widget within it
          (e.is(".widget-drop-area") &&
            e.closest("[data-position]").is(".hover") &&
            e.closest("[data-position]").find(".widget.hover").length == 0)
      );
    });
  };
}

class EditWidgetModal extends React.Component {
  static get MIN_HEIGHT() {
    return 8;
  }

  constructor(props) {
    super(props);

    this.state = {
      widget: this.props.widget,
      originalWidget: JSON.parse(JSON.stringify(this.props.widget)),
      htmlElement: jQuery(`.widget[data-id="${this.props.widget.id}"]`).get(0),
      saved: false,
    };

    this.modalId = "widget-edit-modal";
  }

  render() {
    return (
      <Modal
        id={this.modalId}
        title={I18n.t(`set_widget_${this.props.attribute}`)}
        onClose={this.onClose}
      >
        <Form
          action={Routes.dashboard_widget_link_update_path({
            link_id: this.state.widget.id,
            attribute: this.props.attribute,
            from: "admin",
          })}
          onSuccess={this.onSuccess}
        >
          {this[`${this.props.attribute}FormFields`]()}
          <div className="text-right">
            <CloseModal className="btn btn-light mr-3 px-4">
              {I18n.t("cancel")}
            </CloseModal>
            <FormSubmit />
          </div>
        </Form>
      </Modal>
    );
  }

  titleFormFields() {
    return (
      <div>
        <div className="form-group">
          <label htmlFor="widget_title">{I18n.t("title")}</label>
          <input
            id="widget_title"
            name="widget[title]"
            type="text"
            value={this.props.widget.title}
            onChange={(e) => this.titleChanged(e.currentTarget.value)}
            className="form-control"
          />
        </div>
      </div>
    );
  }

  sizeFormFields() {
    const size = this.state.widget.size;

    return (
      <div>
        <div className="form-group">
          <input
            id="widget_height_auto"
            name="widget[size][height]"
            type="radio"
            value="auto"
            checked={size.height == "auto"}
            onChange={(e) => this.heightOptionChanged("auto")}
          />
          <label className="pl-2" htmlFor="widget_height_auto">
            {I18n.t("widget_height_auto")}
          </label>
          <div className="pl-4">
            <small>{I18n.t("widget_height_auto_description")}</small>
          </div>
        </div>
        <div className="form-group">
          <input
            id="widget_height_fixed"
            name="widget[size][height]"
            type="radio"
            value={size.height}
            checked={size.height != "auto"}
            onChange={(e) => this.heightOptionChanged("fixed")}
          />
          <label className="pl-2" htmlFor="widget_height_fixed">
            {I18n.t("widget_height_fixed")}
            {size.height != "auto" && (
              <span className="ml-2 text-muted">= {size.height}</span>
            )}
          </label>
          <div className="pl-4">
            <button
              disabled={size.height == "auto"}
              type="button"
              className="btn btn-light mr-2"
              onClick={(e) => this.increaseHeight(-1)}
            >
              <i className="fa fa-minus text-highlight"></i>
            </button>
            <button
              disabled={size.height == "auto"}
              type="button"
              className="btn btn-light ml-2"
              onClick={(e) => this.increaseHeight(1)}
            >
              <i className="fa fa-plus text-highlight"></i>
            </button>
          </div>
          <div className="pl-4 mt-3">
            <small>{I18n.t("widget_height_fixed_description")}</small>
          </div>
        </div>
      </div>
    );
  }

  visibilityFormFields() {
    return (
      <div>
        <div className="alert alert-info">
          {I18n.t("widget_visibility_explanation")}
        </div>
        <input
          type="hidden"
          name="widget[update_visibility]"
          defaultValue="true"
        />
        <fieldset>
          <legend>{I18n.t("user_groups")}</legend>
          <div className="form-group">
            {(this.props.userGroups || []).map((g) => (
              <label key={g.id} className="font-weight-normal d-block">
                <input
                  type="checkbox"
                  name="widget[user_group_ids][]"
                  defaultChecked={
                    (this.state.widget.user_group_ids || []).indexOf(g.id) != -1
                  }
                  value={g.id}
                  onChange={this.userGroupIdsChanged}
                  className="mr-2"
                />
                {g.name}
              </label>
            ))}
          </div>
        </fieldset>
        <fieldset className="mt-3">
          <legend>{I18n.t("roles")}</legend>
          <div className="form-group">
            {(this.props.roles || []).map((r) => (
              <label key={r.id} className="font-weight-normal d-block">
                <input
                  type="checkbox"
                  name="widget[role_ids][]"
                  defaultChecked={
                    (this.state.widget.role_ids || []).indexOf(r.id) != -1
                  }
                  value={r.id}
                  onChange={this.roleIdsChanged}
                  className="mr-2"
                />
                {r.name}
              </label>
            ))}
          </div>
        </fieldset>
      </div>
    );
  }

  onClose = () => {
    if (!this.state.saved) {
      // Revert to original widget before any changes
      this.props.onChange(this.state.originalWidget);
    }

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  onSuccess = () => {
    this.setState({ saved: true }, () => Modal.close(this.modalId));
  };

  heightOptionChanged = (value) => {
    this.setState(
      (prevState) => {
        let widget = prevState.widget;

        if (value == "auto") {
          widget.size.height = "auto";
        } else {
          widget.size.height = Math.round(
            parseFloat(getComputedStyle(this.state.htmlElement).height, 10) /
              parseFloat(getComputedStyle(document.body).fontSize, 10)
          );
        }
        return { widget: widget };
      },
      () => {
        this.props.onChange(this.state.widget);
      }
    );
  };

  increaseHeight = (increment) => {
    this.setState(
      (prevState) => {
        let widget = prevState.widget;
        let newHeight = widget.size.height + increment;

        if (newHeight >= this.constructor.MIN_HEIGHT) {
          widget.size.height = newHeight;
        }
        return { widget: widget };
      },
      () => {
        this.props.onChange(this.state.widget);
      }
    );
  };

  titleChanged = (title) => {
    this.setState(
      (prevState) => {
        let widget = prevState.widget;
        widget.title = title;
        return { widget: widget };
      },
      () => {
        this.props.onChange(this.state.widget);
      }
    );
  };

  userGroupIdsChanged = () => {
    this.setState(
      (prevState) => {
        let widget = prevState.widget;
        widget.user_group_ids = jQuery(
          `#${this.modalId} [name*="[user_group_ids]"]:checked`
        )
          .map((i, e) => parseInt(e.value, 10))
          .get();
        return { widget: widget };
      },
      () => {
        this.props.onChange(this.state.widget);
      }
    );
  };

  roleIdsChanged = () => {
    this.setState(
      (prevState) => {
        let widget = prevState.widget;
        widget.role_ids = jQuery(
          `#${this.modalId} [name*="[role_ids]"]:checked`
        )
          .map((i, e) => parseInt(e.value, 10))
          .get();
        return { widget: widget };
      },
      () => {
        this.props.onChange(this.state.widget);
      }
    );
  };
}

export class DashboardWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widget: Origo.widgets[this.props.identifier],
      id: `dashboard-widget-${this.props.instanceId}`,
    };
    this.rootRef = React.createRef();
    this.parent = null;
  }

  render() {
    return this.state.widget ? (
      <div ref={this.rootRef} id={this.state.id} className="h-100"></div>
    ) : null;
  }

  componentDidMount() {
    if (this.state.widget) {
      this.state.widget.render(
        {
          instanceId: this.props.instanceId,
          mode: this.props.mode,
          viewSize: this.props.viewSize,
          language: this.props.language,
          organizationId: this.props.organizationId,
          settings: this.props.settings,
          updateSettings: (callbackOrSettings) => {
            if (typeof callbackOrSettings == "function") {
              this.props.updateSettings(
                callbackOrSettings(
                  JSON.parse(JSON.stringify(this.props.settings))
                )
              );
            } else {
              this.props.updateSettings(callbackOrSettings);
            }
          },
          origoUrl: Origo.BASE_URL,
          appBaseUrl: this.state.widget.baseUrl,
          accessToken: this.props.accessToken,
          idToken: this.props.idToken,
          scrollContainer: this.props.isAutoHeight
            ? null
            : this.rootRef.current.parentNode,
        },
        document.getElementById(this.state.id)
      );
    }

    setTimeout(this.refreshContentScroll, 500);
  }

  // With custom scroller the bars don't always show up when content height changes without triggering like this
  refreshContentScroll = () => {
    if (this.rootRef.current) {
      if (this.prevScrollHeight != this.rootRef.current.scrollHeight) {
        this.prevScrollHeight = this.rootRef.current.scrollHeight;
        this.parent = this.parent || this.rootRef.current.parentNode;
        this.rootRef.current.style.marginBottom = "1px";
        this.parent.scrollTop = this.parent.scrollTop + 1;
        this.parent.scrollTop = this.parent.scrollTop - 1;
        this.rootRef.current.style.marginBottom = "0px";
      }

      setTimeout(this.refreshContentScroll, 500);
    }
  };
}
