import React from "react";
import Loading from "../../common/loading";

export class ReportEmbed extends React.Component {
    static get AUTH_PATH() {
        return 'origoauth';
    }

    static get AUTH_STATUS() {
        return {
            pending: "pending",
            success: "success",
            failed: "failed",
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            auth: this.constructor.AUTH_STATUS.pending,
            authError: null,
            urlError: null,
            settings: props.settings,
        };

        this.titleRef = React.createRef();
        this.overlayRef = React.createRef();
        this.iframeContainerRef = React.createRef();
    }

    render() {
        if (this.props.mode === Origo.Widget.Mode.SETTINGS) {
            return this.renderSettings();
        } else {
            return this.renderView();
        }
    }

    renderView() {
        if (this.state.settings.report_src) {
            if (this.state.urlError) {
                // Display message if error in configuration
                return (
                    <h2 className="text-muted text-center p-3">
                        {I18n.t("configuration_error")}: {I18n.t("invalid_url")}
                    </h2>
                );
            }

            if (this.state.auth === this.constructor.AUTH_STATUS.failed) {
                // Display message if authentication failed
                return (
                    <h2 className="text-muted text-center p-3">
                        {I18n.t("authentication_failed")}: {this.state.authError}
                    </h2>
                );
            }

            const title = this.getTitle(this.props.language);

            let content = <Loading />;

            if (this.state.auth !== this.constructor.AUTH_STATUS.pending) {
                content = this.state.settings.report_src.match("<iframe") ? (
                    <div ref={this.iframeContainerRef} className="iframe-container h-100 w-100" dangerouslySetInnerHTML={{ __html: this.state.settings.report_src }}></div>
                ) : (
                    <div ref={this.iframeContainerRef} className="iframe-container h-100 w-100">
                        <iframe src={this.getIframeUrl()} frameBorder="0" width="100%" height="100%" />
                    </div>
                );
            }

            // If report link is present, clicking the overlay will open the report.
            const overlayProps = this.getReportLink() ? { onClick: this.openReport, style: { cursor: "pointer" } } : {};

            const reportLink = (
                <a href={this.getReportLink()} target="_blank" onClick={this.openReport}>
                    {I18n.t("show_report")}
                </a>
            );

            const showReportLink = this.getReportLink() && this.state.settings.show_link;

            return (
                <div className="d-flex flex-column justify-content-between position-relative h-100">
                    {title ? (
                        <div ref={this.titleRef} className="d-flex justify-content-between">
                            <h1 className="text-highlight font-weight-bold p-3" style={{ fontSize: "250%", overflow: "hidden" }}>
                                {title}
                            </h1>
                            {showReportLink ? <div className="text-right py-3 pr-3">{reportLink}</div> : null}
                        </div>
                    ) : null}
                    {!title && showReportLink ? (
                        <div className="text-right position-absolute w-100 p-3" style={{ zIndex: 1 }}>
                            {reportLink}
                        </div>
                    ) : null}
                    {this.state.settings.allow_interactivity ? null : <div ref={this.overlayRef} className="w-100 d-flex flex-wrap position-absolute" {...overlayProps} />}
                    {content}
                </div>
            );
        } else {
            return <h2 className="text-muted text-center p-3">{I18n.t("report_is_not_configured")}</h2>;
        }
    }

    renderSettings() {
        return (
            <form className="settings p-3">
                <div className="form-group">
                    <label>{I18n.t("title")}</label>
                    {Origo.AVAILABLE_LANGUAGES.map((lang) => (
                        <div key={lang} className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" style={{ width: "2.7rem" }}>
                                    {lang.toUpperCase()}
                                </span>
                            </div>
                            <input
                                type="text"
                                name={`title_${lang}`}
                                className="form-control"
                                value={this.getTitle(lang)}
                                onChange={(e) => this.updateSettings(`title_${lang}`, e.currentTarget.value)}
                            />
                        </div>
                    ))}
                </div>
                <div className="form-group">
                    <label>{I18n.t("report_src")} *</label>
                    <input
                        type="text"
                        name="report_src"
                        placeholder="https://..."
                        className="form-control"
                        value={this.state.settings.report_src || ""}
                        onChange={(e) => this.updateSettings("report_src", e.currentTarget.value)}
                    />
                    <div className="text-muted">
                        <small>{I18n.t("report_src_description")}</small>
                    </div>
                </div>
                <div className="form-group">
                    <label>{I18n.t("report_link_url")}</label>
                    <input
                        type="text"
                        name="report_url"
                        placeholder="https://..."
                        className="form-control"
                        value={this.state.settings.report_link || ""}
                        onChange={(e) => this.updateSettings("report_link", e.currentTarget.value)}
                    />
                    <div className="text-muted">
                        <small>{I18n.t("report_link_url_description")}</small>
                    </div>
                </div>
                <div className="form-group">
                    <label>
                        <input
                            name="allow_interactivity"
                            type="checkbox"
                            className="mr-2"
                            value={true}
                            checked={this.state.settings.allow_interactivity}
                            onChange={(e) => this.updateSettings("allow_interactivity", e.currentTarget.checked)}
                        />
                        {I18n.t("allow_interactivity")}
                        <div className="text-muted">
                            <small>{I18n.t("allow_interactivity_explanation")}</small>
                        </div>
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        <input
                            name="show_link"
                            type="checkbox"
                            className="mr-2"
                            value={true}
                            checked={this.state.settings.show_link}
                            onChange={(e) => this.updateSettings("show_link", e.currentTarget.checked)}
                        />
                        {I18n.t("show_separate_link")}
                        <div className="text-muted">
                            <small>{I18n.t("show_separate_link_explanation")}</small>
                        </div>
                    </label>
                </div>
            </form>
        );
    }

    componentDidMount() {
        if (this.props.mode !== Origo.Widget.Mode.SETTINGS) {
            this.updateAuthStatus();
        }
    }

    componentDidUpdate() {
        // Adjust iframe-overlay so it won't cover the title, if title and overlay are present.
        const overlay = this.overlayRef.current;

        if (overlay) {
            const title = this.titleRef.current;
            const iframeContainer = this.iframeContainerRef.current;
            if (title && iframeContainer) {
                overlay.style.top = `${title.scrollHeight}px`;
                overlay.style.height = `${iframeContainer.scrollHeight}px`;
            } else {
                overlay.style.height = "100%";
            }
        }
    }

    updateSettings(attr, value) {
        this.setState(
            (prevState) => {
                const settings = prevState.settings;
                settings[attr] = value;
                return { settings: settings };
            },
            () => {
                this.props.updateSettings((prevSettings) => {
                    return this.state.settings;
                });
            }
        );
    }

    updateAuthStatus = () => {
        if (this.state.settings.report_src) {
            this.setState({ auth: this.constructor.AUTH_STATUS.pending, urlError: false }, this.authenticateIframe);
        }
    };

    authenticateIframe = () => {
        const url = this.getIframeUrl();

        this.authenticate(
            url,
            () => {
                this.setState({ auth: this.constructor.AUTH_STATUS.success });
            },
            (error) => {
                this.setState({
                    auth: this.constructor.AUTH_STATUS.failed,
                    authError: error.message,
                });
            }
        );
    };

    openReport = (event) => {
        const reportWindow = window.open("", "_blank");

        const url = this.getReportLink();

        this.authenticate(
            url,
            () => {
                reportWindow.location = url;
            },
            (error) => {
                reportWindow.document.write("Authentication failed: ", error.message);
            }
        );

        event.preventDefault();
    };

    authenticate = (url, onSuccess, onError) => {
        if (url) {
            let urlObj;

            try {
                urlObj = new URL(url);
            } catch (err) {
                this.setState({ urlError: true });
                return;
            }

            fetch(this.getAuthUrl(urlObj), {
                method: "POST",
                credentials: "include",
                headers: {
                    accessToken: this.props.accessToken,
                },
            })
                .then(onSuccess)
                .catch(onError);
        }
    };

    getTitle = (lang) => {
        return typeof this.state.settings[`title_${lang}`] == "string" ? this.state.settings[`title_${lang}`] : this.state.settings[`title_${I18n.defaultLocale}`] || "";
    };

    getIframeUrl = () => {
        // Change single quotes to double
        const src = this.state.settings.report_src.replace(/\'/, '"');

        return src.match("<iframe") ? src.split('"')[src.split('"').findIndex((a) => a.match("src=")) + 1] : this.state.settings.report_src;
    };

    getReportLink = () => {
        return this.state.settings.report_link;
    };

    getAuthUrl = (url) => {
        return `${url.protocol}//${url.hostname}/${this.constructor.AUTH_PATH}`;
    };
}
