import React from "react";
import Box from "@oriola-origo/core/lib/Box";
import { styled } from "@oriola-origo/core/lib/styles";
import Typography from "@oriola-origo/core/lib/Typography";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import OriolaColors from "@oriola-origo/core/lib/Theme/OriolaColors";
import { handleLinkClick, getTitle } from "./utils";
import { IWidgetProps } from "../base_widget";

type ReportLinkWidgetSettings = {
  title_en: string;
  title_fi: string;
  title_sv: string;
  report_url: string;
};

export interface IReportLinkWidgetV2Props
  extends IWidgetProps<ReportLinkWidgetSettings> {
    icon: string;
    image: string;
    dataTrackingText: string;
  }

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: theme.spacing(22),
  minWidth: theme.spacing(25),
  borderRadius: theme.spacing(0.5),
  backgroundSize: "cover",
  backgroundPosition: "center",
  flexGrow: 1,
}));

const Icon = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(1),
  width: theme.spacing(10),
  height: theme.spacing(10),
  alignItems: "center",
  justifyContent: "center",
}));

const Title = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  textAlign: "center",
  height: theme.spacing(5),
  padding: theme.spacing(0, 1),
}));

function ReportLinkWidgetV2(props: Readonly<IReportLinkWidgetV2Props>) {
  const { accessToken, id, settings, language, icon, image, dataTrackingText } = props;
  const title = getTitle(language, settings);

  return (
    <a
      href={settings.report_url}
      target="_blank"
      onClick={(event) =>
        handleLinkClick(event, settings.report_url, accessToken)
      }
      style={{ textDecoration: "none" }}
      data-tracking-link="reportlink"
      data-tracking-text={dataTrackingText}
    >
      <Root
        key={id}
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(43, 62, 91, 0.85) 46.21%, rgba(90, 104, 127, 0.85) 98.19%), 
                    url(/reports_link_widget/${image})`,
        }}
      >
        <Icon>
          <FontIcon sx={{ color: OriolaColors.white, fontSize: "5.25rem" }}>
            {icon}
          </FontIcon>
        </Icon>
        <Title>
          {title && (
            <Typography
              variant="subtitle1"
              fontWeight={400}
              color={OriolaColors.white}
            >
              {title}
            </Typography>
          )}
        </Title>
      </Root>
    </a>
  );
}

export default ReportLinkWidgetV2;
