import React from 'react';

export default function IconLink(props) {
    const iconProps = {
        className: [props.iconClassName, 'mr-2'].filter(c => c).join(' ')
    };
    const containerProps = {
        className: [props.className, 'icon-link d-inline-block'].filter(c => c).join(' ')
    };
    const aProps = {
        href: props.href,
        onClick: props.onClick
    };

    return <div {...containerProps}>
        <div className="row no-gutters flex-nowrap">
            <div className="col-auto icon-link-icon">
                <a {...aProps}>
                    <i {...iconProps}></i>
                </a>
            </div>
            <div className="col">
                <a {...aProps}>
                    {props.children}
                </a>
            </div>
        </div>
    </div>;
}