import React, { useState, useMemo } from "react";
import countries from "i18n-iso-countries";
import useTranslations from "@hooks/useTranslations";
import Box from "@oriola-origo/core/lib/Box";
import Grid from "@oriola-origo/core/lib/Grid";
import Typography from "@oriola-origo/core/lib/Typography";
import Divider from "@oriola-origo/core/lib/Divider";
import Chip from "@oriola-origo/core/lib/Chip";
import { styled } from "@oriola-origo/core/lib/styles";
import OrganizationTitleWithActions from "../../components/organization_title_with_actions";
import {
  ProfessionalTitle,
  ProfitCenter,
  UserCompany,
  OrigoApplicationRole,
  UserGroup,
  OrigoApplication,
} from "@services/user_management/types";
import EditableField, {
  EditableFieldMode,
  EditableFieldType,
} from "@components/common/editable_field";
import IconButton from "@oriola-origo/core/lib/IconButton";
import FontIcon from "@oriola-origo/core/lib/Icons";
import ProfitCenterAutocomplete from "../../form_v2/organization_information/components/profit_center_autocomplete";
import Button from "@oriola-origo/core/lib/Button";
import { ContentType } from "../organization_and_user_access_section/organization_information_section";
import OrganizationAccessSummary from "./organization_access_summary";
import {
  buildUserAllInheritedRoles,
  buildAppRoleMapping,
  buildInheritedRolesFromUserGroups,
  buildUserGroupCategoriesMapping,
} from "@utils/user_role_group_mapping.utils";
import { UserGroupCategory } from "@services/user_management/hooks/useUserGroupCategories";

export interface OrganizationProps {
  organization: UserCompany;
  hasSccProfitCenters: boolean;
  profitCenters: ProfitCenter[];
  selectedProfitCenters: ProfitCenter[];
  selectedSccProfitCenters: ProfitCenter[];
  professionalTitles: ProfessionalTitle[];
  isFirst?: boolean;
  isViewUserFromUserList?: boolean;
  onUserCompaniesChanged: (userCompany: UserCompany) => void;
  onSetDefaultOrganization: (id: number) => void;
  openRemoveDialog: (id: number) => void;
  viewMode: ContentType;
  user_groups: UserGroup[];
  user_group_categories: UserGroupCategory[];
  apps: OrigoApplication[];
  inheritedRolesFromOrganization: Record<string, Array<number>>;
  roles: Record<string, OrigoApplicationRole[]>;
}

const PositionedChip = styled(Chip)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  marginLeft: theme.spacing(1),
}));

export const renderLabelValue = (
  label: string,
  value: React.ReactNode,
  chipValue = "",
  disabled = false
) => (
  <Box>
    <Typography
      display="block"
      color="secondaryText"
      fontSize={12}
      position="relative"
    >
      {label}
      {chipValue && (
        <PositionedChip
          label={chipValue}
          color="primary"
          variant="filled"
          size="small"
        />
      )}
    </Typography>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography
        display="block"
        py={1}
        sx={(theme) => ({
          color: disabled
            ? theme.palette.text.disabled
            : theme.palette.text.primary,
        })}
      >
        {value}
      </Typography>
    </Box>
  </Box>
);

export const renderAddress = (organization: UserCompany, language: string) => {
  const { street, house_number, business_location, post_code, city, country } =
    organization;

  const lines: React.ReactNode[] = [];
  const maybeAdd = (value: string, key: string) =>
    value && lines.push(<Box key={key}>{value}</Box>);

  maybeAdd(
    [street, house_number, business_location].filter(Boolean).join(" "),
    "street"
  );
  maybeAdd([post_code, city].filter(Boolean).join(" "), "city");
  maybeAdd(country && countries.getName(country, language), "country");
  return lines;
};

export default function Organization({
  organization,
  hasSccProfitCenters,
  profitCenters,
  selectedProfitCenters,
  selectedSccProfitCenters,
  professionalTitles,
  onUserCompaniesChanged,
  onSetDefaultOrganization,
  openRemoveDialog,
  apps,
  roles,
  user_groups,
  user_group_categories,
  isFirst = false,
  viewMode = ContentType.DetailedInformation,
  inheritedRolesFromOrganization = {},
}: Readonly<OrganizationProps>) {
  const { t, language } = useTranslations();
  const addressLines = renderAddress(organization, language);
  const [inEditMode, setInEditMode] = useState("");
  const [profitCentersIds, setProfitCentersIds] = useState<number[]>([]);
  const [sccProfitCentersIds, setSccProfitCentersIds] = useState<number[]>([]);

  const userGroupsMapping = useMemo(
    () =>
      buildUserGroupCategoriesMapping(
        organization.user_group_ids,
        user_groups,
        user_group_categories
      ),
    [organization.user_group_ids, user_groups, user_group_categories]
  );

  const allUserInheritedRoles = useMemo(
    () =>
      buildUserAllInheritedRoles(
        buildInheritedRolesFromUserGroups(
          user_groups,
          organization.user_group_ids
        ),
        inheritedRolesFromOrganization
      ),
    [user_groups, organization.user_group_ids, inheritedRolesFromOrganization]
  );

  const rolesMapping = useMemo(
    () =>
      buildAppRoleMapping(
        apps,
        roles,
        organization.role_ids,
        allUserInheritedRoles,
        language
      ),
    [apps, roles, organization.role_ids, allUserInheritedRoles, language]
  );

  return (
    <Box
      pl={10 * (organization.default ? 0 : organization.hierarchy_level ?? 0)}
    >
      {!isFirst && <Divider />}
      <Box p={4}>
        <OrganizationTitleWithActions
          api_id={organization.api_id}
          name={organization.name}
          isDefaultOrganization={organization.default}
          setDefaultOrganization={() =>
            onSetDefaultOrganization(organization.id)
          }
          removeOrganization={() => openRemoveDialog(organization.id)}
        />
        {viewMode === ContentType.DetailedInformation && (
          <Grid container mt={1} spacing={3}>
            <Grid size={{ xs: 12, md: 7, lg: 6 }}>
              {renderLabelValue(
                t("address"),
                Array.isArray(addressLines) && addressLines.length > 0
                  ? addressLines
                  : "-"
              )}
            </Grid>
            <Grid size={{ xs: 12, md: 5, lg: 6 }}>
              <Box display="flex" justifyContent="space-between">
                <Box width="100%">
                  {inEditMode !== "profit_centers" &&
                    renderLabelValue(
                      t("profit_centers"),
                      selectedProfitCenters.map((profitCenter) => (
                        <Box
                          key={profitCenter.code}
                        >{`${profitCenter.code} - ${profitCenter.name}`}</Box>
                      ))
                    )}
                  {inEditMode === "profit_centers" && (
                    <>
                      <ProfitCenterAutocomplete
                        label={t("profit_centers")}
                        options={profitCenters}
                        defaultValue={selectedProfitCenters}
                        onChange={(_, newValue) => {
                          setProfitCentersIds(newValue.map((pc) => pc.id));
                        }}
                      />
                      <Box display="flex" justifyContent="end" gap={1} mt={1}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => setInEditMode("")}
                        >
                          {t("cancel")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            onUserCompaniesChanged({
                              ...organization,
                              profit_center_ids: profitCentersIds,
                            });
                            setInEditMode("");
                          }}
                        >
                          {t("save")}
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
                {inEditMode !== "profit_centers" && (
                  <Box>
                    <IconButton onClick={() => setInEditMode("profit_centers")}>
                      <FontIcon
                        color="secondary"
                        colorTone="light"
                        fontSize="small"
                      >
                        edit
                      </FontIcon>
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 7, lg: 6 }}>
              <EditableField
                label={t("professional_title")}
                value={organization.professional_title_id?.toString() || ""}
                inactive={inEditMode && inEditMode !== "professional_title"}
                options={professionalTitles.map((title) => ({
                  label: title.name,
                  value: title.id.toString(),
                }))}
                readValueTransform={(value) =>
                  professionalTitles.find((title) => title.id === +value)?.name
                }
                type={EditableFieldType.Select}
                mode={
                  inEditMode === "professional_title"
                    ? EditableFieldMode.Edit
                    : EditableFieldMode.Read
                }
                onModeChange={(newMode) =>
                  setInEditMode(
                    newMode === EditableFieldMode.Edit
                      ? "professional_title"
                      : ""
                  )
                }
                onSave={async (newValue) => {
                  onUserCompaniesChanged({
                    ...organization,
                    professional_title_id: +newValue,
                  });
                  setInEditMode("");
                }}
                onCancel={() => {
                  setInEditMode("");
                }}
              />
            </Grid>

            {hasSccProfitCenters ? (
              <Grid size={{ xs: 12, md: 5, lg: 6 }}>
                <Box display="flex" justifyContent="space-between">
                  <Box width="100%">
                    {inEditMode !== "scc_profit_centers" &&
                      renderLabelValue(
                        t("scc_profit_centers"),
                        selectedSccProfitCenters.map((profitCenter) => (
                          <Box
                            key={profitCenter.code}
                          >{`${profitCenter.code} - ${profitCenter.name}`}</Box>
                        ))
                      )}
                    {inEditMode === "scc_profit_centers" && (
                      <>
                        <ProfitCenterAutocomplete
                          label={t("scc_profit_centers")}
                          options={profitCenters}
                          defaultValue={selectedSccProfitCenters}
                          onChange={(_, newValue) => {
                            setSccProfitCentersIds(newValue.map((pc) => pc.id));
                          }}
                        />
                        <Box display="flex" justifyContent="end" gap={1} mt={1}>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => setInEditMode("")}
                          >
                            {t("cancel")}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              onUserCompaniesChanged({
                                ...organization,
                                scc_profit_center_ids: sccProfitCentersIds,
                              });
                              setInEditMode("");
                            }}
                          >
                            {t("save")}
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                  {inEditMode !== "scc_profit_centers" && (
                    <Box>
                      <IconButton
                        onClick={() => setInEditMode("scc_profit_centers")}
                      >
                        <FontIcon
                          color="secondary"
                          colorTone="light"
                          fontSize="small"
                        >
                          edit
                        </FontIcon>
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Grid>
            ) : (
              <Grid size={{ xs: 12, md: 5, lg: 6 }}>
                {renderLabelValue(
                  t("organization_type"),
                  organization.type_name[language] ??
                    organization.type_name["en"] ??
                    "-"
                )}
              </Grid>
            )}
          </Grid>
        )}
        {viewMode === ContentType.AccessSummary && (
          <OrganizationAccessSummary
            userGroupsMapping={userGroupsMapping}
            rolesMapping={rolesMapping}
          />
        )}
      </Box>
    </Box>
  );
}
