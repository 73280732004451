import Box from "@oriola-origo/core/lib/Box";
import Chip from "@oriola-origo/core/lib/Chip";
import IconButton from "@oriola-origo/core/lib/IconButton";
import FontIcon from "@oriola-origo/core/lib/Icons";
import ListItemIcon from "@oriola-origo/core/lib/ListItemIcon";
import ListItemText from "@oriola-origo/core/lib/ListItemText";
import Menu from "@oriola-origo/core/lib/Menu";
import MenuItem from "@oriola-origo/core/lib/MenuItem";
import MenuList from "@oriola-origo/core/lib/MenuList";
import Typography from "@oriola-origo/core/lib/Typography";
import React, { useState } from "react";
import useTranslations from "@hooks/useTranslations";

interface OrganizationTitleWithActionsProps {
  api_id: string;
  name: string;
  isDefaultOrganization: boolean;
  setDefaultOrganization: () => void;
  removeOrganization: () => void;
}
const OrganizationTitleWithActions = (
  props: Readonly<OrganizationTitleWithActionsProps>
) => {
  const {
    api_id,
    name,
    isDefaultOrganization,
    setDefaultOrganization,
    removeOrganization,
  } = props;
  const { t } = useTranslations();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="space-between" gap={1}>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="h6" color="secondary">
          {api_id} - {name}
        </Typography>
        {isDefaultOrganization && (
          <Chip
            color="primary"
            label={t("default_organization")}
            size="small"
          />
        )}
      </Box>
      <IconButton
        data-testid="iconButtonMenu"
        size="large"
        onClick={handleClick}
        color="secondary"
      >
        <FontIcon>more_vert</FontIcon>
      </IconButton>
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box>
          <MenuList>
            {!isDefaultOrganization && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setDefaultOrganization();
                }}
              >
                <ListItemIcon>
                  <FontIcon>star</FontIcon>
                </ListItemIcon>
                <ListItemText>{t("set_as_default_organization")}</ListItemText>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                handleClose();
                removeOrganization();
              }}
            >
              <ListItemIcon>
                <FontIcon>delete</FontIcon>
              </ListItemIcon>
              <ListItemText>{t("remove_organization")}</ListItemText>
            </MenuItem>
          </MenuList>
        </Box>
      </Menu>
    </Box>
  );
};

export default OrganizationTitleWithActions;
