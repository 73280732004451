import React from 'react';
import IconLink from '../common/icon_link';
import Assigner from '../role/assigner';

export default class CompanyTypeRoleAssigner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roles: this.props.roles,
            selectedRoleIds: this.props.selectedRoleIds
        };
    }

    render() {
        return <div>
            {this.hasPermission('view_activity') && <div className="float-right">
                <IconLink
                    iconClassName="icon-clock text-highlight"
                    href={Routes.activity_log_list_path({target_type: 'organization-type', target_id: this.props.typeStrId})}
                >
                    {I18n.t('activity')}
                </IconLink>
            </div>}
            <Assigner
                apps={this.props.apps}
                roles={this.state.roles}
                selectedRoleIds={this.props.selectedRoleIds}
                roleToggled={this.roleToggled}
                permissions={this.props.permissions}
            />
        </div>;
    }

    roleToggled = (appId, roleId, isAssigned) => {
        this.setState(prevState => {
            const roles = JSON.parse(JSON.stringify(prevState.roles));
            roles[appId].find(r => r.id === roleId).loading = true;

            return { roles: roles };
        }, () => {
            jQuery.post(Routes.company_type_toggle_role_path({ type_str_id: this.props.typeStrId, role_id: roleId }))
                .done(result => {
                    this.setState(prevState => {
                        let ids = prevState.selectedRoleIds;
        
                        if (isAssigned) {
                            ids.push(roleId);
                        } else {
                            ids.splice(ids.indexOf(roleId), 1);
                        }
        
                        return { selectedRoleIds: ids };
                    });
                })
                .always(() => {
                    this.setState(prevState => {
                        const roles = JSON.parse(JSON.stringify(prevState.roles));
                        roles[appId].find(r => r.id === roleId).loading = false;

                        return { roles: roles };
                    });
                });
        });
    }

    hasPermission(permission) {
        return (this.props.permissions || {})[permission] ? true : false;
    }
}