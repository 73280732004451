import React, { useEffect, useState } from "react";
import Grid from "@oriola-origo/core/lib/Grid";
import Box from "@oriola-origo/core/lib/Box";
import Divider from "@oriola-origo/core/lib/Divider";
import List from "@oriola-origo/core/lib/List";
import ListSubheader from "@oriola-origo/core/lib/ListSubheader";
import ListItem from "@oriola-origo/core/lib/ListItem";
import ListItemText from "@oriola-origo/core/lib/ListItemText";
import { Tooltip } from "@material-ui/core";
import IconButton from "@oriola-origo/core/lib/IconButton";
import Menu from "@oriola-origo/core/lib/Menu";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import MenuItem from "@oriola-origo/core/lib/MenuItem/MenuItem";
import ListItemIcon from "@oriola-origo/core/lib/ListItemIcon";
import Button from "@oriola-origo/core/lib/Button";
import useUserGroupCategories, {
  UserGroups,
} from "../../../services/user_management/hooks/useUserGroupCategories";
import useTranslations, {
  TranslateFunction,
} from "../../../hooks/useTranslations";
import useSnackbar from "../../../hooks/useSnackbar";
import useCSRFToken from "./../../../hooks/useCSRFToken";
import DeleteConfirmationDialog from "./deleteConfirmationDialog";
import { handleEditClick } from "./editUserGroup";

interface GroupListProps {
  selectedCategoryId: string | number;
  shouldFetchCategories: boolean;
  setShouldFetchCategories: (value: boolean) => void;
  hasUserGroupManagePermissions?: boolean;
}

export const noGroupsListItem = (t: TranslateFunction) => {
  return (
    <Box>
      <ListItem key={t("no_groups")}>
        <Tooltip title={t("no_groups")}>
          <ListItemText
            primary={t("no_groups")}
            primaryTypographyProps={{
              variant: "subtitle1",
            }}
          />
        </Tooltip>
      </ListItem>
      <Divider />
    </Box>
  );
};

export const handleClick = (
  key: string,
  event: React.MouseEvent<HTMLElement>,
  group: Record<string, any>,
  setSelectedUserGroup: (group: Record<string, any>) => void,
  setAnchorEl: (value: HTMLElement) => void,
  setMenuId: (key: string) => void
) => {
  setAnchorEl(event.currentTarget);
  setMenuId(key);
  setSelectedUserGroup(group);
};

export const renderActionMenuButton = (
  key: string,
  anchorEl: HTMLElement,
  menuId: string,
  t: TranslateFunction,
  group: Record<string, any>,
  selectedUserGroup: Record<string, any>,
  actions: Record<string, any>
) => {
  const { setAnchorEl, setSelectedUserGroup, setOpenDialog, setMenuId } =
    actions;
  return (
    <div>
      <IconButton
        sx={{ marginRight: "30px" }}
        data-testid="iconButtonMenu"
        onClick={(e) =>
          handleClick(
            key,
            e,
            group,
            setSelectedUserGroup,
            setAnchorEl,
            setMenuId
          )
        }
        color="secondary"
      >
        <FontIcon fontSize="medium">more_vert</FontIcon>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuId === key}
        onClose={() => {
          setMenuId("");
          setAnchorEl(null);
        }}
      >
        <MenuItem
          divider
          onClick={() => {
            setMenuId("");
            setAnchorEl(null);
            handleEditClick(selectedUserGroup.id);
          }}
        >
          <ListItemIcon>
            <FontIcon>edit</FontIcon>
          </ListItemIcon>
          <ListItemText>{t("edit")}</ListItemText>
        </MenuItem>
        <MenuItem
          divider
          onClick={() => {
            setMenuId("");
            setAnchorEl(null);
            setOpenDialog(true);
          }}
        >
          <ListItemIcon>
            <FontIcon>delete</FontIcon>
          </ListItemIcon>
          <ListItemText>{t("delete")}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};
function GroupList({
  selectedCategoryId,
  shouldFetchCategories,
  setShouldFetchCategories,
  hasUserGroupManagePermissions
}: Readonly<GroupListProps>) {
  const { t } = useTranslations();
  const csrfToken = useCSRFToken();
  const { setSnackMessage } = useSnackbar();
  const { fetchUserGroupByCategories } = useUserGroupCategories();
  const [userGroups, setUserGroups] = useState<UserGroups[]>([]);
  const [deletedUserGroupIds, setDeletedUserGroupIds] = useState([]);
  const [selectedUserGroup, setSelectedUserGroup] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [menuId, setMenuId] = React.useState<string>("");

  useEffect(() => {
    if (!shouldFetchCategories) return;
    const fetchGroupByCategories = async () => {
      const res = await fetchUserGroupByCategories();
      if (res.succeed) {
        setUserGroups(res.userGroups);
      } else {
        setSnackMessage(t("error_occurred"), "error");
      }
    };
    fetchGroupByCategories();
    setShouldFetchCategories(false);
  }, [shouldFetchCategories]);

  if (userGroups.length === 0) {
    return (
      <Button color="secondary" isLoading variant="contained">
        {t("loading")}
      </Button>
    );
  }

  const filteredGroups =
    selectedCategoryId === "all" || !selectedCategoryId
      ? userGroups
      : userGroups.filter((item) => item.category.id === selectedCategoryId);

  return (
    <Grid sx={{ width: "100%" }}>
      {filteredGroups.map((item, index) => (
        <List
          key={item.category.name}
          subheader={<ListSubheader>{item.category.name}</ListSubheader>}
        >
          {item.groups.length === 0
            ? noGroupsListItem(t)
            : Object.entries(
                item.groups.filter(
                  (group) => !deletedUserGroupIds.includes(group.id)
                )
              ).map(([key, value]) => {
                return (
                  <div key={key}>
                    <ListItem>
                      <a
                        style={{ width: "100%", textDecoration: "none" }}
                        href={Routes.user_group_show_path({
                          user_group_id: value.id,
                        })}
                      >
                        <Tooltip title={value.name}>
                          <ListItemText
                            primary={value.name}
                            primaryTypographyProps={{
                              variant: "subtitle1",
                            }}
                            secondary={value.description}
                            secondaryTypographyProps={{
                              variant: "body1",
                            }}
                          />
                        </Tooltip>
                      </a>
                      {hasUserGroupManagePermissions && renderActionMenuButton(
                        key,
                        anchorEl,
                        menuId,
                        t,
                        value,
                        selectedUserGroup,
                        {
                          setAnchorEl,
                          setSelectedUserGroup,
                          setOpenDialog,
                          setMenuId,
                        }
                      )}
                    </ListItem>
                    <Divider />
                  </div>
                );
              })}
        </List>
      ))}
      {openDialog && (
        <DeleteConfirmationDialog
          openDialog={openDialog}
          selectedUserGroup={selectedUserGroup}
          csrfToken={csrfToken}
          deletedUserGroupIds={deletedUserGroupIds}
          setOpenDialog={setOpenDialog}
          setDeletedUserGroupIds={setDeletedUserGroupIds}
        />
      )}
    </Grid>
  );
}

export default GroupList;
