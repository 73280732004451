import React from "react";
import Typography from "@oriola-origo/core/lib/Typography";
import Box from "@oriola-origo/core/lib/Box";
import { EXTENDED_ROW_ITEM_HEIGHT } from "./constants";
import useTranslations from "@hooks/useTranslations";
import { UserTableCell } from "./common_cells";
import Tooltip from "@oriola-origo/core/lib/Tooltip";

export type ProfessionalTitlesCellOrganization = {
  id: number;
  professional_title_str_id: string;
};

export interface ProfessionalTitlesCellProps {
  isExpanded: boolean;
  isDisabled: boolean;
  organizations: ProfessionalTitlesCellOrganization[];
}

function ProfessionalTitlesCell({
  isExpanded,
  isDisabled,
  organizations,
}: Readonly<ProfessionalTitlesCellProps>) {
  const { t } = useTranslations();

  const getProfessionalTitleNameForCompany = (
    company: ProfessionalTitlesCellOrganization
  ) =>
    company?.professional_title_str_id
      ? t(`professional_title_${company.professional_title_str_id}`)
      : "";

  const renderContent = () => {
    if (isExpanded) {
      return organizations.map((org) => {
        const professionalTitle = getProfessionalTitleNameForCompany(org);
        return (
          <Tooltip title={professionalTitle} key={org.id}>
            <Typography
              noWrap
              variant="body2"
              color="textSecondary"
              height={EXTENDED_ROW_ITEM_HEIGHT}
            >
              {professionalTitle}
            </Typography>
          </Tooltip>
        );
      });
    }

    const moreCount = organizations.length - 1;
    const firstOrganizationProfessionalTitle =
      getProfessionalTitleNameForCompany(organizations[0]);

    return (
      <Tooltip title={firstOrganizationProfessionalTitle}>
        <Box display="flex">
          <Typography
            noWrap
            variant="body2"
            display="inline"
            color="textSecondary"
          >
            {firstOrganizationProfessionalTitle}
          </Typography>

          {moreCount > 0 && (
            <Typography
              ml={0.5}
              variant="body2"
              whiteSpace="nowrap"
              display="inline-block"
              color="textPrimary"
              textTransform="lowercase"
            >
              (+{moreCount} {t("more")})
            </Typography>
          )}
        </Box>
      </Tooltip>
    );
  };

  return (
    <UserTableCell isExpanded={isExpanded} isDisabled={isDisabled}>
      {renderContent()}
    </UserTableCell>
  );
}

export default ProfessionalTitlesCell;
