import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import useWindow from "./useWindow";

export type FooterContextType = {
  container: HTMLElement | null;
};

export const FooterContext = createContext<FooterContextType>({
  container: null,
});

export const resolveFooterContainer = () =>
  document.getElementById("content-footer") ?? null;

export function FooterProvider({ children }: Readonly<PropsWithChildren>) {
  const [container, setContainer] = React.useState<HTMLElement | null>(null);
  const windowObject = useWindow();

  useEffect(() => {
    const setFooterContainer = () => {
      setContainer(resolveFooterContainer());
    };

    setFooterContainer();

    windowObject.addEventListener("load", setFooterContainer);

    return () => {
      windowObject.removeEventListener("load", setFooterContainer);
    };
  }, []);

  const footerContextValue = useMemo(
    () => ({
      container,
    }),
    [container]
  );

  return (
    <FooterContext.Provider value={footerContextValue}>
      {children}
    </FooterContext.Provider>
  );
}

function useFooter() {
  return useContext(FooterContext);
}

export default useFooter;
