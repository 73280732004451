import React from "react";
import Box from "@oriola-origo/core/lib/Box";
import { styled } from "@oriola-origo/core/lib/styles";
import StaticReportsWidgetV2, {
  IStaticReportsWidgetV2Props,
} from "./static_reports_v2";

export interface INewStaticReportsProps {
  staticReportsWidgets: IStaticReportsWidgetV2Props[];
}

const Root = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Container = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export function NewStaticReports(props: Readonly<INewStaticReportsProps>) {
  const { staticReportsWidgets } = props;

  return (
    <Root>
      {staticReportsWidgets.map((widget) => (
        <Container key={widget.id}>
          <StaticReportsWidgetV2 {...widget} />
        </Container>
      ))}
    </Root>
  );
}

export default NewStaticReports;
