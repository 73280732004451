import React, { useState } from "react";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Grid from "@oriola-origo/core/lib/Grid";
import UserGroupCategoryList from "./categoryList";
import GroupList from "./groupList";
import Page from "@oriola-origo/core/lib/Page";
import Header, { HeaderButton } from "@oriola-origo/core/lib/Header";
import useTranslations from "@hooks/useTranslations";
import useRoutes from "@hooks/useRoutes";
import useTurbolinks from "@hooks/useTurbolinks";

export interface UserGroupListV2Props {
  permissions?: {
    mayManageUserGroups: boolean;
    mayManageUserGroupCategories: boolean;
  };
}

function UserGroupListV2({ permissions }: Readonly<UserGroupListV2Props>) {
  const { t } = useTranslations();
  const routes = useRoutes();
  const { navigate } = useTurbolinks();
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [shouldFetchCategories, setShouldFetchCategories] = useState<boolean>(true);
  const hasUserGroupManagePermissions = permissions?.mayManageUserGroups;
  const hasUserGroupCategoriesManagePermissions = permissions?.mayManageUserGroupCategories;

  const headerButtons: HeaderButton[] = hasUserGroupManagePermissions ? [
    {
      text: t("new_user_group_v2"),
      variant: "contained",
      onClick: () => navigate(routes.userGroupNewPath()),
    },
  ] : [];

  return (
    <OriolaThemeProvider>
      <Page
        header={
          <Header
            title={t("user_groups")}
            buttons={headerButtons}
          />
        }
      >
        <Grid container direction="row" mb={2}>
          <UserGroupCategoryList onCategoryClick={setSelectedCategoryId} setShouldFetchCategories={setShouldFetchCategories} hasUserGroupCategoriesManagePermissions={hasUserGroupCategoriesManagePermissions}/>
        </Grid>
        <Grid container direction="row">
          <GroupList 
            selectedCategoryId={selectedCategoryId} 
            shouldFetchCategories={shouldFetchCategories} 
            setShouldFetchCategories={setShouldFetchCategories}  
            hasUserGroupManagePermissions={hasUserGroupManagePermissions} />
        </Grid>
      </Page>
    </OriolaThemeProvider>
  );
}

export default UserGroupListV2;
