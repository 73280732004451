import React, { MouseEvent, useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@oriola-origo/core/lib/Select";
import MenuItem from "@oriola-origo/core/lib/MenuItem";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import { styled } from "@oriola-origo/core/lib/styles";
import useUserGroupCategories, {
  UserGroupCategory,
} from "../../../../services/user_management/hooks/useUserGroupCategories";
import AddNewCategoryDialog from "./add_new_category_dialog";
import useTranslations from "../../../../hooks/useTranslations";
import useSnackbar from "../../../../hooks/useSnackbar";

export interface CategorySelectionProps {
  selectedCategoryId: number;
  onSelectedCategoryIdChange: (categoryId: number) => void;
  errorText?: string;
}

const AddNewMenuItem = styled(MenuItem)({
  display: "flex",
  alignItems: "center",
});

function CategorySelection({
  errorText,
  selectedCategoryId,
  onSelectedCategoryIdChange,
}: Readonly<CategorySelectionProps>) {
  const { t } = useTranslations();
  const {
    fetchUserGroupCategories,
    isFetching,
    isInitialized,
    createNewUserGroupCategory,
    isSaving,
  } = useUserGroupCategories();
  const [categories, setCategories] = useState<UserGroupCategory[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { setSnackMessage } = useSnackbar();

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await fetchUserGroupCategories();
      if (res.succeed) {
        setCategories(res.categories);
      } else {
        setSnackMessage(t("error_occurred"), "error");
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryChange = (event: SelectChangeEvent<number>) => {
    const categoryId = Number(event.target.value);
    if (categoryId) {
      onSelectedCategoryIdChange(categoryId);
    } else {
      onSelectedCategoryIdChange(null);
    }
  };

  const handleAddNewCategoryMenuItemClick = (
    event: MouseEvent<HTMLLIElement>
  ) => {
    event.stopPropagation();
    setIsDialogOpen(true);
  };

  const handleDialogSaveRequest = async (categoryName: string) => {
    const res = await createNewUserGroupCategory(categoryName);
    if (res.succeed) {
      setCategories([...categories, res.category]);
      onSelectedCategoryIdChange(res.category.id);
      setIsDialogOpen(false);
      setSnackMessage(t("user_group_category_created"), "success");
    } else {
      setSnackMessage(t("error_occurred"), "error");
    }
  };

  const renderMenuItems = () => {
    if (!isInitialized || isFetching) {
      return <MenuItem disabled>{t("loading")}</MenuItem>;
    }

    const items = categories.map((category) => (
      <MenuItem
        key={category.id}
        value={category.id}
        selected={selectedCategoryId === category.id}
      >
        {category.name}
      </MenuItem>
    ));

    items.push(
      <AddNewMenuItem
        key="__add_new"
        value="__add_new"
        onClick={handleAddNewCategoryMenuItemClick}
      >
        <FontIcon sx={{ mr: 1 }}>add_new</FontIcon>
        {t("add_new_user_group_category")}
      </AddNewMenuItem>
    );

    return items;
  };

  return (
    <>
      <Select
        label={t("user_group_category")}
        required={true}
        onChange={handleCategoryChange}
        value={selectedCategoryId ?? ""}
        fullWidth={true}
        error={!!errorText}
        helperText={errorText}
      >
        {renderMenuItems()}
      </Select>
      <AddNewCategoryDialog
        isSaving={isSaving}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSaveRequest={handleDialogSaveRequest}
      />
    </>
  );
}

export default CategorySelection;
