import React from "react";
import Typography from "@oriola-origo/core/lib/Typography";
import Box from "@oriola-origo/core/lib/Box";
import { styled } from "@oriola-origo/core/lib/styles";
import FormHelperText from "@oriola-origo/core/lib/FormHelperText";
import Checkbox, { CheckboxProps } from "@oriola-origo/core/lib/Checkbox";
import { GetAccessCard, ReportAccessRequestStatus } from "../types";

export interface SelectReportsPageProps {
  getAccessCardsSettings: GetAccessCard[];
  selectedReportIds: string[];
  onChangeSelectedReportIds: (newSelectedReportIds: string[]) => void;
}

const PositionedHelperText = styled(FormHelperText)(({ theme }) => ({
  marginTop: `-${theme.spacing(1)}`,
  marginLeft: theme.spacing(4),
}));

const resolveHelperText = (helperTextKey: string) => {
  const defaultDummy = "__default__";
  const value = I18n.t(helperTextKey, { default: defaultDummy });
  return value === defaultDummy ? null : value;
};

function SelectReportsPage({
  getAccessCardsSettings,
  selectedReportIds,
  onChangeSelectedReportIds,
}: Readonly<SelectReportsPageProps>) {
  return (
    <>
      <Typography
        variant="body1"
        color="textSecondary"
        textAlign="center"
        gutterBottom
      >
        {I18n.t("report_access_dialog_selection_description")}
      </Typography>

      <Box display="flex" justifyContent="center">
        <Box>
          {getAccessCardsSettings.map((cardSettings) => {
            const handleCheckboxChange = (_, checked) => {
              const newSelectedReportIds = checked
                ? [...selectedReportIds, cardSettings.id]
                : selectedReportIds.filter((id) => id !== cardSettings.id);
              onChangeSelectedReportIds(newSelectedReportIds);
            };

            const helperText = resolveHelperText(cardSettings.helperText);

            const checkboxLabelProps = {
              sx: { marginBottom: 0 },
            } as unknown as CheckboxProps["labelProps"];

            return (
              <Box key={cardSettings.id}>
                <Checkbox
                  labelProps={checkboxLabelProps}
                  checked={
                    selectedReportIds.includes(cardSettings.id) ||
                    cardSettings.status === ReportAccessRequestStatus.PENDING
                  }
                  label={I18n.t(cardSettings.title)}
                  disabled={
                    cardSettings.status !== ReportAccessRequestStatus.AVAILABLE
                  }
                  onChange={handleCheckboxChange}
                />
                {!!helperText && (
                  <PositionedHelperText>{helperText}</PositionedHelperText>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
}

export default SelectReportsPage;
