import {
  AUTH_PATH,
  staticDataTrackingTextMapping,
  staticIconMapping,
  staticImageMapping,
} from "../../../../constants";

export const handleLinkClick = (
  event: React.MouseEvent,
  urlLink: string,
  accessToken: string
) => {
  const url = new URL(urlLink);
  const authUrl = `${url.protocol}//${url.hostname}/${AUTH_PATH}`;

  let reportWindow = window.open("", "_blank");

  fetch(authUrl, {
    method: "POST",
    credentials: "include",
    headers: {
      accessToken: accessToken,
    },
  })
    .then((response) => {
      reportWindow.location = urlLink;
    })
    .catch((error) => {
      reportWindow.document.write("Authentication failed: ", error.message);
    });

  event.preventDefault();
};

export const getTitle = (language: string, settings: Record<string, any>) => {
  return (
    (settings[`title_${language}`] ??
      settings[`title_${I18n.defaultLocale}`]) ||
    ""
  );
};

export const getStaticDataMappingByUrl = (url: string) => {
  switch (true) {
    case /extensions\/(OriolaSales|AstraSales)/i.test(url):
      return {
        icon: staticIconMapping.Sales,
        image: staticImageMapping.Sales,
        dataTrackingText: staticDataTrackingTextMapping.Sales,
      };
    case /extensions\/(OriolaStock|AstraStock)/i.test(url):
      return {
        icon: staticIconMapping.Stock,
        image: staticImageMapping.Stock,
        dataTrackingText: staticDataTrackingTextMapping.Stock,
      };
    case /extensions\/OriolaKPI/i.test(url):
      return {
        icon: staticIconMapping.KPI,
        image: staticImageMapping.KPI,
        dataTrackingText: staticDataTrackingTextMapping.KPI,
      };
    case /extensions\/(OriolaOpenSales|AstraOpenSales)/i.test(url):
      return {
        icon: staticIconMapping.OpenSales,
        image: staticImageMapping.OpenSales,
        dataTrackingText: staticDataTrackingTextMapping.OpenSales,
      };
    case /extensions\/OriolaFMD/i.test(url):
      return {
        icon: staticIconMapping.FMD,
        image: staticImageMapping.FMD,
        dataTrackingText: staticDataTrackingTextMapping.FMD,
      };
    case /extensions\/OriolaPrincipal/i.test(url):
      return {
        icon: staticIconMapping.StockPrincipalActions,
        image: staticImageMapping.StockPrincipalActions,
        dataTrackingText: staticDataTrackingTextMapping.StockPrincipalActions,
      };
    case /extensions\/OriolaPromotional/i.test(url):
      return {
        icon: staticIconMapping.Promotional,
        image: staticImageMapping.Promotional,
        dataTrackingText: staticDataTrackingTextMapping.Promotional,
      };
    case /extensions\/OriolaSamples/i.test(url):
      return {
        icon: staticIconMapping.Samples,
        image: staticImageMapping.Samples,
        dataTrackingText: staticDataTrackingTextMapping.Samples,
      };
    default:
      return {};
  }
};
