import React, { useEffect, useState } from "react";
import Grid from "@oriola-origo/core/lib/Grid";
import {
  isValidPhoneNumberAsMaybeErpPerson,
  trimAndRemoveSpaces,
} from "@utils/validations";
import EditableField, {
  EditableFieldMode,
  EditableFieldType,
} from "@components/common/editable_field";
import useTranslations from "@hooks/useTranslations";
import { EditableUser } from "@components/user/contexts/editable_user";
import { validateEmailAddress } from "../form_validation";

export interface UserDetailProps {
  user: EditableUser;
  isUpdating: boolean;
  languages: [string, string][];
  onSave: (property: string, value: string) => void | Promise<void>;
  profileMode?: boolean;
}

const WORD_LENGTH_LIMIT = 30;

function UserDetailFields({
  user,
  isUpdating,
  languages,
  onSave,
  profileMode = true,
}: Readonly<UserDetailProps>) {
  const [inEditMode, setInEditMode] = useState("");
  const { t } = useTranslations();
  const [editableUser, setEditableUser] = useState(user);

  // Check if string has extra long words
  const isExtraLongWord = (value: string) => {
    if (value?.length > WORD_LENGTH_LIMIT) {
      const strSplit = value.split(" ");
      for (const word of strSplit) {
        if (word.length > WORD_LENGTH_LIMIT) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    setEditableUser(user);
  }, [user]);

  const isValidEmail = (value: string) => {
    const { error } = validateEmailAddress("", value);
    if (error) {
      return t("error_invalid_email_address");
    }
  };

  // For names and titles, check if the value is an extra long word
  // Extra long words are displayed in single row - possible with ellipsis
  // Otherwise text may be displayed in multiple rows

  const inputs = [
    {
      property: "first_name",
      label: t("first_name"),
      value: editableUser.first_name,
      checkSaveDisabled: (value) => !value.trim(),
      checkLongWords: (value) => isExtraLongWord(value),
    },
    {
      property: "last_name",
      label: t("last_name"),
      value: editableUser.last_name,
      checkSaveDisabled: (value) => !value.trim(),
      checkLongWords: (value) => isExtraLongWord(value),
    },
    {
      property: "work_title",
      label: t("work_title"),
      value: editableUser.work_title,
      readValueTransform: (value) => value || "-",
      checkLongWords: (value) => isExtraLongWord(value),
    },
    {
      property: "email_address",
      label: t("email"),
      value: editableUser.email_address,
      disabled: profileMode,
      disabledInfo: t("profile_email_cannot_be_changed"),
      editIcon: profileMode ? "email" : "edit",
      validateSave: (value: string) => isValidEmail(value),
      overflowEllipsis: true,
    },
    {
      property: "mobile_phone",
      label: t("mobile_phone"),
      value: editableUser.mobile_phone,
      validateSave: (value: string) =>
        isValidPhoneNumberAsMaybeErpPerson(
          value,
          editableUser.is_emergency_responsible_person,
          t
        ),
    },
    {
      property: "language",
      label: t("preferred_language"),
      value: editableUser.language,
      type: EditableFieldType.Select,
      options: languages.map(([code, name]) => ({
        label: name,
        value: code,
      })),
      readValueTransform: (value) =>
        languages.find(([code]) => code === value)?.[1] ?? value,
    },
  ];

  return (
    <Grid container>
      <Grid size={{ lg: 10 }} width="100%">
        <Grid container rowSpacing={2} columnSpacing={8}>
          {inputs.map((input) => (
            <Grid size={{ xs: 12, md: 6, lg: 4 }} key={input.property}>
              <EditableField
                label={input.label}
                value={input.value}
                inactive={inEditMode && inEditMode !== input.property}
                disabled={input.disabled || isUpdating}
                disabledInfo={input.disabledInfo}
                editIcon={input.editIcon}
                options={input.options ?? []}
                readValueTransform={input.readValueTransform}
                validateSave={input.validateSave}
                checkSaveDisabled={input.checkSaveDisabled}
                type={input.type}
                overflowEllipsis={input.overflowEllipsis}
                checkLongWords={input.checkLongWords}
                mode={
                  inEditMode === input.property
                    ? EditableFieldMode.Edit
                    : EditableFieldMode.Read
                }
                onModeChange={(newMode) =>
                  setInEditMode(
                    newMode === EditableFieldMode.Edit ? input.property : ""
                  )
                }
                onSave={async (newValue) => {
                  if (input.property === "mobile_phone") {
                    newValue = trimAndRemoveSpaces(newValue);
                  }
                  await onSave(input.property, newValue);
                  setInEditMode("");
                }}
                onCancel={() => {
                  setInEditMode("");
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UserDetailFields;
