import React from 'react';
import { Table } from '../common/table';

export default class List extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tableKey: StringHelper.random(8)
        };
    }

    render() {
        let columns = [
            {
                dataField: 'name',
                text: I18n.t('name'),
                sort: true,
                formatter: (cell, row) => {
                    return <a href={Routes.company_type_show_path({ type_str_id: row.str_id })}>{row.name}</a>;
                }
            },
            {
                dataField: 'company_count',
                text: I18n.t('company_count'),
                sort: true
            }
        ];

        return (
            <div ref="root">
                <Table
                    key={this.state.tableKey}
                    ref="table"
                    keyField="str_id"
                    columns={columns}
                    pagination={false}
                    defaultSorted={[{
                        dataField: 'name',
                        order: 'asc'
                    }]}
                    data={this.props.types}
                    preserveState={true} />
            </div>
        );
    }
}
