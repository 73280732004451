import useTranslations from "@hooks/useTranslations";
import React, { useState } from "react";
import Dialog from "@oriola-origo/core/lib/Dialog";
import DialogTitle from "@oriola-origo/core/lib/DialogTitle";
import DialogContent from "@oriola-origo/core/lib/DialogContent";
import Box from "@oriola-origo/core/lib/Box";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Typography from "@oriola-origo/core/lib/Typography";
import Checkbox from "@oriola-origo/core/lib/Checkbox";
import DialogActions from "@oriola-origo/core/lib/DialogActions";
import Button from "@oriola-origo/core/lib/Button";
import { UserCompany } from "../../../../../services/user_management/types";

interface RemoveConfirmationDialogProps {
  organization: UserCompany;
  onClose: () => void;
  onSave: (organizationId: number, isChecked: boolean) => void;
  hasChildren: boolean;
}
const RemoveConfirmationDialog = (
  props: Readonly<RemoveConfirmationDialogProps>
) => {
  const { organization, onClose, onSave, hasChildren } = props;
  const { t } = useTranslations();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle onCloseButtonClick={onClose}>
        {t("remove_organization_confirmation_title")}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" mb={4}>
          <FontIcon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: 150 }}
          >
            work_off
          </FontIcon>
        </Box>
        <Typography color="text.secondary" sx={{ mb: 2 }}>
          {t("remove_organization_confirmation", {
            organization_name: organization.name,
          })}
        </Typography>
        {hasChildren && (
          <Checkbox
            style={{ paddingLeft: "30px" }}
            label={t("remove_organization_all_sub")}
            onChange={() => setIsChecked(!isChecked)}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ boxShadow: "none" }}>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onSave(organization.id, isChecked)}
        >
          {t("remove")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveConfirmationDialog;
