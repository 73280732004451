import React from "react";
import Box from "@oriola-origo/core/lib/Box";
import Autocomplete from "@oriola-origo/core/lib/Autocomplete/Autocomplete";
import DatePicker, {
  LocalizationProvider,
  AdapterDayjs,
} from "@oriola-origo/core/lib/DatePicker/DatePicker";
import { Filters, staticReportsTranslationFactory } from "./utils";
import { FetchFilterOptionsOutput } from "./useFetchFilterOptions";
import CustomersAutocomplete from "./customer_selection/customer_selection";
import "dayjs/locale/fi";

export interface StaticReportsFiltersProps {
  language: string;
  filterOptions: FetchFilterOptionsOutput["filterOptions"];
  permissions: FetchFilterOptionsOutput["permissions"];
  filters: Filters;
  allowedReportNames: string[];
  onChange: (filters: Filters) => void;
}

const DATAPICKER_CSS_FIX = {
  // Override globally defined legend margin
  "& fieldset legend": {
    margin: 0,
  },
  // Fix label and input to make DatePicker height to match other inputs
  "& input": {
    py: 1,
  },
  "& label:not(.MuiInputLabel-shrink)": {
    transform: "translate(14px, 8px) scale(1)",
  },
};

function StaticReportsFilters({
  language,
  filterOptions,
  permissions,
  allowedReportNames,
  filters,
  onChange,
}: Readonly<StaticReportsFiltersProps>) {
  const getText = staticReportsTranslationFactory(language);
  const updateFilters = (changed: Partial<Filters>) => {
    const newFilters = { ...filters, ...changed };
    onChange(newFilters);
  };

  const reportOptions = filterOptions.reportNames.filter((report) =>
    allowedReportNames.includes(report.value)
  );

  return (
    <Box display="flex" alignItems="flex-start" gap={2}>
      <Box flex={1} minWidth={300} maxWidth={600}>
        <Box>
          <Autocomplete
            label={getText("reports")}
            sx={{
              // Override globally defined legend margin
              "& fieldset legend": {
                margin: 0,
              },
            }}
            multiple={true}
            options={reportOptions}
            value={filters.reportNames}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.label
            }
            onChange={(_, newValue) => {
              updateFilters({
                reportNames: newValue as Filters["reportNames"],
              });
            }}
          />
        </Box>
        {permissions.maySelectCustomers && (
          <Box mt={2}>
            <CustomersAutocomplete
              language={language}
              selectedCompanies={filters.customers}
              onChange={(companies) => {
                updateFilters({ customers: companies });
              }}
            />
          </Box>
        )}
      </Box>
      <LocalizationProvider adapterLocale="fi" dateAdapter={AdapterDayjs}>
        <Box display="flex" alignItems="center">
          <DatePicker
            sx={DATAPICKER_CSS_FIX}
            label={getText("start_date")}
            value={filters.startDate}
            onChange={(date) => {
              updateFilters({ startDate: date?.isValid() ? date : null });
            }}
          />
          <Box display="inline-block" mx={1}>
            -
          </Box>
          <DatePicker
            sx={DATAPICKER_CSS_FIX}
            label={getText("end_date")}
            value={filters.endDate}
            minDate={filters.startDate}
            onChange={(date) => {
              updateFilters({ endDate: date?.isValid() ? date : null });
            }}
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
}

export default StaticReportsFilters;
