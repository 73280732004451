export enum ReportAccessRequestStatus {
  AVAILABLE = "AVAILABLE",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}

export interface GetAccessCard {
  id: string;
  status: ReportAccessRequestStatus;
  title: string;
  descriptionShort: string;
  descriptionLong: string;
  helperText: string;
  imageUrl: string;
  bannerImageUrl: string;
  report: string[];
}
