import React from "react";
import Box from "@oriola-origo/core/lib/Box";
import { AssignRoles } from "./assign_roles";
import { OrigoApplication } from "@services/user_management/types/application";
import { OrigoApplicationRole } from "@services/user_management/types/roles";
import { UserGroup } from "@services/user_management/types/user_groups";
import { UserGroupCategory } from "@services/user_management/hooks/useUserGroupCategories";
import { AssignUserGroups } from "./assign_user_groups";
import { InheritedRole } from "../types";

export enum UserAccessAssignerMode {
  Role,
  UserGroup,
}

export interface UserAccessAssignerProps {
  mode: UserAccessAssignerMode;
  apps: OrigoApplication[];
  roles: Record<string, OrigoApplicationRole[]>;
  userGroups: UserGroup[];
  userGroupCategories: UserGroupCategory[];
  selectedUserGroupIds: number[];
  selectedRoleIds: number[];
  inheritedRoles: InheritedRole[];
  onSelectedUserGroupIdsChange: (
    userGroupIds: number[],
    oldState: number[]
  ) => void;
  onSelectedRoleIdsChange: (roleIds: number[], oldState: number[]) => void;
  permissions: {
    assign_roles: boolean;
    assign_superuser_roles: boolean;
  };
  disabled?: boolean;
}

export default function UserAccessAssigner({
  mode,
  apps,
  permissions,
  roles,
  selectedRoleIds,
  selectedUserGroupIds,
  inheritedRoles,
  onSelectedRoleIdsChange,
  onSelectedUserGroupIdsChange,
  userGroupCategories,
  userGroups,
  disabled
}: Readonly<UserAccessAssignerProps>) {
  return (
    <Box>
      {mode === UserAccessAssignerMode.Role ? (
        <AssignRoles
          apps={apps}
          roles={roles}
          permissions={permissions}
          selectedRoleIds={selectedRoleIds}
          inheritedRoles={inheritedRoles}
          onSelectedRoleIdsChange={onSelectedRoleIdsChange}
          disabled={disabled}
        />
      ) : (
        <AssignUserGroups
          userGroups={userGroups}
          userGroupCategories={userGroupCategories}
          permissions={permissions}
          selectedUserGroupIds={selectedUserGroupIds}
          onSelectedUserGroupIdsChange={onSelectedUserGroupIdsChange}
          disabled={disabled}
        />
      )}
    </Box>
  );
}
