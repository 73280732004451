import React from 'react';

export default function DetailList(props) {
    return <div className={props.className}>
        <div className="detail-list d-table">
            {(props.children || []).map((row, i) => <div key={i} className="d-table-row">
                <div className="detail-column d-table-cell label text-truncate">
                    {row[0]}
                </div>
                <div className="detail-column d-table-cell value">
                    {(row[1] && row[1].__html) ? <div dangerouslySetInnerHTML={row[1]}></div> : row[1]}
                </div>
                {row.length > 2 && row.slice(2).map(c =>
                    <div className="detail-column d-table-cell">
                        {(c.__html) ? <div dangerouslySetInnerHTML={c}></div> : c}
                    </div>
                )}
            </div>)}
        </div>
    </div>;
}