import TableCell, { TableCellProps } from "@oriola-origo/core/lib/TableCell";
import { styled } from "@oriola-origo/core/lib/styles";

export const NoBottomBorderTableCell = styled(TableCell)({
  borderBottomWidth: "0 !important",
});

export const HeaderPlaceholderCell = styled(NoBottomBorderTableCell)<{
  width: number | string;
}>(({ width }) => ({
  padding: 0,
  width,
}));

export interface UserTableCellProps extends TableCellProps {
  isExpanded: boolean;
  isDisabled: boolean;
}

export const UserTableCell = styled(TableCell, {
  shouldForwardProp: (prop) =>
    !["isExpanded", "isDisabled"].includes(prop as string),
})<UserTableCellProps>(({ isExpanded, isDisabled }) => ({
  verticalAlign: isExpanded ? "top" : "middle",
  opacity: isDisabled ? 0.1 : undefined,
}));
