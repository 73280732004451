import { Organization } from "@services/user_management/types";

export type OrganizationOrigin = Pick<Organization, "origin">;

const ORGANIZATION_ORIGIN_ORIGO = "origo";
const ORGANIZATION_ORIGIN_ORIGO_SYSTEM = "origo-system";

export const isOrigoSystemOrganization = (organization: OrganizationOrigin) =>
  organization.origin === ORGANIZATION_ORIGIN_ORIGO_SYSTEM;

export const isOrigoOrganization = (organization: OrganizationOrigin) =>
  organization.origin === ORGANIZATION_ORIGIN_ORIGO ||
  isOrigoSystemOrganization(organization);

export const isSapOrganization = (organization: OrganizationOrigin) =>
  !isOrigoOrganization(organization);

export const isNonSystemOrigoOrganization = (
  organization: OrganizationOrigin
) =>
  isOrigoOrganization(organization) && !isOrigoSystemOrganization(organization);
