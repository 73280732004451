import React, { useEffect, useMemo, useState } from "react";
import useTranslations from "@hooks/useTranslations";
import Page from "@oriola-origo/core/lib/Page";
import ToggleButtonGroup from "@oriola-origo/core/lib/ToggleButtonGroup/ToggleButtonGroup";
import Button from "@oriola-origo/core/lib/Button";
import { UserFormV2Props } from "@components/user/form_v2/types";
import useOrganizations from "@services/user_management/hooks/useOrganizations";
import UserAccessAssigner, {
  UserAccessAssignerMode,
} from "./components/user_access_assigner";
import UserAccessHeader from "./components/user_access_header";
import {
  InheritanceSource,
  InheritedRole,
  InheritedRolesByCompanyMap,
} from "./types";
import {
  buildUserAccessMapForCompanyAndChilds,
  refreshInheritedRoles,
  UserAccessItemType,
} from "./utils";

export interface UserAccessInformationCompany {
  id: number;
  api_id: string;
  name: string;
  hierarchy_level?: number;
  hierarchy_parent_company_ids?: Array<number>;
}

export type UserAccessCompanyItem = {
  roleIds: number[];
  userGroupIds: number[];
};

export type UserAccessCompanyMap = Record<number, UserAccessCompanyItem>;

export interface UserAccessInformationProps {
  apps: UserFormV2Props["apps"];
  roles: UserFormV2Props["roles"];
  userGroups: UserFormV2Props["user_groups"];
  userGroupCategories: UserFormV2Props["user_group_categories"];
  selectedCompanies: UserAccessInformationCompany[];
  permissions: UserFormV2Props["permissions"];
  nonInheritingRoleIds: number[];
  prioritizeCompanyWithId?: number;
  userAccessMap: UserAccessCompanyMap;
  onUserAccessMapChange: (newUserAccessMap: UserAccessCompanyMap) => void;
  disabled?: boolean;
}

enum UserAccessTab {
  UserGroups = "user_groups",
  Roles = "roles",
}

export default function UserAccessInformation({
  apps,
  roles,
  userGroups,
  userGroupCategories,
  selectedCompanies,
  permissions,
  nonInheritingRoleIds,
  prioritizeCompanyWithId,
  onUserAccessMapChange,
  userAccessMap,
  disabled
}: Readonly<UserAccessInformationProps>) {
  const { t } = useTranslations();
  const { fetchOrganizationRoles } = useOrganizations();
  const [userAccessFilter, setUserAccessFilter] = useState(
    UserAccessTab.UserGroups
  );
  const [activeCompanyId, setActiveCompanyId] = useState<number | null>(
    selectedCompanies[0].id
  );
  const [inheritedRolesByCompany, setInheritedRolesByCompany] =
    useState<InheritedRolesByCompanyMap>({});

  const userAccessAssignerMode =
    userAccessFilter === UserAccessTab.Roles
      ? UserAccessAssignerMode.Role
      : UserAccessAssignerMode.UserGroup;

  const refreshInheritedRolesForCompany = (
    newState: { [companyId: number]: InheritedRole[] },
    companyId: number,
    companyData: { [origoApplicationId: number]: number[] }
  ): boolean => {
    const responseRoleIds = Object.values(companyData ?? {}).flat();
    if (responseRoleIds.length === 0) {
      return false;
    }

    newState[companyId] = refreshInheritedRoles(
      newState[companyId] ?? [],
      InheritanceSource.COMPANY,
      responseRoleIds
    );
    return true;
  };

  const fetchRolesForCompanies = async () => {
    const fetchCompanyRolesPromises = selectedCompanies.map((company) =>
      fetchOrganizationRoles(company.id)
    );
    const results = await Promise.allSettled(fetchCompanyRolesPromises);
    const newState = { ...inheritedRolesByCompany };

    let hasChanges = false;
    results.forEach((result, index) => {
      const company = selectedCompanies[index];
      if (result.status === "rejected") {
        console.error(
          `Failed to fetch roles for company ${company.id}`,
          result.reason
        );
        return;
      }

      const companyHasChanges = refreshInheritedRolesForCompany(
        newState,
        company.id,
        result.value.data
      );
      hasChanges = hasChanges || companyHasChanges;
    });

    if (hasChanges) {
      setInheritedRolesByCompany(newState);
    }
  };

  useEffect(() => {
    fetchRolesForCompanies();
  }, [selectedCompanies]);

  useEffect(() => {
    const selectedUserGroupIds =
      userAccessMap[activeCompanyId]?.userGroupIds ?? [];
    const newUserGroups = selectedUserGroupIds
      .map((id) => userGroups.find((ug) => ug.id === id))
      .filter(Boolean);
    const userGroupInheritedRoles = newUserGroups.flatMap((ug) =>
      ug.roles.map((r) => r.id)
    );

    if (userGroupInheritedRoles.length > 0) {
      setInheritedRolesByCompany({
        ...inheritedRolesByCompany,
        [activeCompanyId]: refreshInheritedRoles(
          inheritedRolesByCompany[activeCompanyId] ?? [],
          InheritanceSource.USER_GROUP,
          userGroupInheritedRoles
        ),
      });
    }
  }, [userAccessMap[activeCompanyId]?.userGroupIds]);

  useEffect(() => {
    const prioritizedExists = selectedCompanies.some(
      (org) => org.id === prioritizeCompanyWithId
    );

    const newActiveCompanyId =
      prioritizedExists && prioritizeCompanyWithId
        ? prioritizeCompanyWithId
        : selectedCompanies[0]?.id;
    setActiveCompanyId(newActiveCompanyId ?? null);
  }, [prioritizeCompanyWithId, selectedCompanies]);

  const handleSelectedUserGroupIdsChange = (
    newUserGroupIds: number[],
    oldState: number[]
  ) => {
    const newState = buildUserAccessMapForCompanyAndChilds({
      companies: selectedCompanies,
      activeCompanyId,
      currentAccessMap: userAccessMap,
      ids: { newState: newUserGroupIds, oldState },
      accessType: UserAccessItemType.UserGroup,
    });

    onUserAccessMapChange(newState);
  };

  const handleSelectedRoleIdsChange = (
    newRoleIds: number[],
    oldState: number[]
  ) => {
    const newState = buildUserAccessMapForCompanyAndChilds({
      companies: selectedCompanies,
      activeCompanyId,
      currentAccessMap: userAccessMap,
      ids: { newState: newRoleIds, oldState },
      skipIdsForChildren: nonInheritingRoleIds,
      accessType: UserAccessItemType.Role,
    });

    onUserAccessMapChange(newState);
  };

  const { selectedUserGroupIdsMap, selectedRoleIdsMap } = useMemo(() => {
    const selectedUserGroupIdsMap: { [companyId: number]: number[] } = {};
    const selectedRoleIdsMap: { [companyId: number]: number[] } = {};

    selectedCompanies.forEach((company) => {
      selectedUserGroupIdsMap[company.id] =
        userAccessMap[company.id]?.userGroupIds ?? [];
      selectedRoleIdsMap[company.id] = userAccessMap[company.id]?.roleIds ?? [];
    });

    return { selectedUserGroupIdsMap, selectedRoleIdsMap };
  }, [userAccessMap]);

  return (
    <Page
      data-testid="page-user-form-user-access"
      forceMode="tablet"
      mt={3}
      header={
        <UserAccessHeader
          selectedCompanyId={activeCompanyId}
          companies={selectedCompanies}
          onCompanyChange={setActiveCompanyId}
        />
      }
    >
      <ToggleButtonGroup
        sx={{ marginBottom: 5 }}
        onChange={setUserAccessFilter as (value: UserAccessTab) => void}
        value={userAccessFilter}
      >
        <Button
          data-testid="btn-user-access-tab-user-groups"
          value={UserAccessTab.UserGroups}
        >
          {t("user_groups")}
        </Button>
        <Button
          data-testid="btn-user-access-tab-roles"
          value={UserAccessTab.Roles}
        >
          {t("roles")}
        </Button>
      </ToggleButtonGroup>

      <UserAccessAssigner
        key={activeCompanyId}
        mode={userAccessAssignerMode}
        apps={apps}
        roles={roles}
        userGroups={userGroups}
        userGroupCategories={userGroupCategories}
        permissions={permissions}
        inheritedRoles={inheritedRolesByCompany[activeCompanyId] ?? []}
        selectedRoleIds={selectedRoleIdsMap[activeCompanyId] ?? []}
        onSelectedRoleIdsChange={handleSelectedRoleIdsChange}
        selectedUserGroupIds={selectedUserGroupIdsMap[activeCompanyId] ?? []}
        onSelectedUserGroupIdsChange={handleSelectedUserGroupIdsChange}
        disabled={disabled}
      />
    </Page>
  );
}
