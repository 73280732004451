import { useCallback, useMemo } from "react";
import useFetch from "@hooks/useFetch";
import useRoutes from "@hooks/useRoutes";
import useCSRFToken from "@hooks/useCSRFToken";
import { ICompanySelection } from "./inviteUser";

export interface PatchUpdateUserOpts {
  userId?: number;
  user: {
    first_name?: string;
    last_name?: string;
    work_title?: string;
    email_address?: string;
    mobile_phone?: string;
    language?: string;
    active?: boolean;
    subscribe_weekly_newsletter?: boolean;
    subscribe_important_announcement_notification?: boolean;
    subscribe_emergency_announcement_notification?: boolean;
    company_selection?: ICompanySelection[];
  };
}

export default function useUser() {
  const csrfToken = useCSRFToken();
  const { doFetch, isFetching: isUpdating } = useFetch();
  const routes = useRoutes();

  const patchUpdateUser = useCallback(
    async (
      opts: PatchUpdateUserOpts
    ): Promise<{ succeed: boolean; data?: any; error?: any }> => {
      const endpoint = routes.userUpdatePatchPath(opts.userId);

      const response = await doFetch(endpoint, {
        body: JSON.stringify({ user: opts.user }),
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
      });

      try {
        const data = await response.json();
        return {
          succeed: response.ok,
          data,
          error: !response.ok ? data : null,
        };
      } catch (error) {
        return {
          succeed: false,
        };
      }
    },
    [routes, csrfToken]
  );

  const updateLanguage = useCallback(
    async (language: string): Promise<{ succeed: boolean }> => {
      const response = await doFetch(
        routes.userProfileUpdateLanguagePath(language),
        {
          body: "{}",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
        }
      );

      return {
        succeed: response.ok,
      };
    },
    [routes, csrfToken]
  );

  const fetchUserActivityLogs = useCallback(
    async (userId: string, page, sizePerPage, sortOrder = "desc") => {
      const endpoint = routes.userActivityLogsShow(
        userId,
        page,
        sizePerPage,
        sortOrder
      );
      try {
        const response = await doFetch(endpoint, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
        });

        const data = await response.json();
        return {
          succeed: response.ok,
          data,
        };
      } catch (error) {
        return {
          succeed: false,
        };
      }
    },
    [routes, csrfToken]
  );

  return useMemo(
    () => ({
      patchUpdateUser,
      updateLanguage,
      fetchUserActivityLogs,
      isUpdating,
    }),
    [patchUpdateUser, updateLanguage, fetchUserActivityLogs, isUpdating]
  );
}
