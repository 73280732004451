import React from "react";
import Box from "@oriola-origo/core/lib/Box";
import { CompanyType } from "@services/user_management/types/company_types";
import { EXTENDED_ROW_ITEM_HEIGHT } from "./constants";
import { UserTableCell } from "./common_cells";
import { UserTableUser } from "../user_table";
import DetailsSummary from "@components/common/user_management_base_table/details/details_summary";

export interface DetailsCellProps {
  user: UserTableUser;
  isExpanded: boolean;
  isDisabled: boolean;
  companyTypes: CompanyType[];
}

export function renderDetailsSummary(companies, all_roles, companyTypes) {
  return companies.map((company) => {
    const roles = all_roles.filter((role) => role.company_id === company.id);
    return (
      <Box key={company.id} height={EXTENDED_ROW_ITEM_HEIGHT}>
        <DetailsSummary roles={roles} companyTypes={companyTypes} />
      </Box>
    );
  });
}

function DetailsCell({
  user,
  isExpanded,
  isDisabled,
  companyTypes,
}: Readonly<DetailsCellProps>) {
  const renderContent = () => {
    if (!isExpanded) {
      // Respect user companies and filter out roles that are not related to the
      // current company list.
      const companyIds = user.companies.map((company) => company.id);
      const roles = user.all_roles.filter((role) =>
        companyIds.includes(role.company_id)
      );
      return <DetailsSummary roles={roles} companyTypes={companyTypes} />;
    }
    return renderDetailsSummary(user.companies, user.all_roles, companyTypes);
  };

  return (
    <UserTableCell isExpanded={isExpanded} isDisabled={isDisabled}>
      {renderContent()}
    </UserTableCell>
  );
}

export default DetailsCell;
