import React from 'react';

class BaseLayout extends React.Component {
    getWidgetMap() {
        let map = {};

        this.props.widgets.forEach(w => {
            let pos = (this.constructor.POSITIONS.indexOf(w.layoutPosition) != -1) ? w.layoutPosition : '1';
            map[pos] = map[pos] || [];
            map[pos].push(w.markup);
        });

        return map;
    }
}

const resolveIconMarkupClass = (layout) => {
    return layout === 'sidebar_one_column_primary' ? 'bg-success' : 'bg-primary';
}

export let Layouts = {

    dropArea: <div className="widget-drop-area"></div>,

    Icon: function(props) {
        return <div className={['dashboard-layout-icon border border-primary p-1', props.className].filter(c => c).join(' ')} onClick={props.onClick}>
            {React.createElement(Layouts[props.layout], {
                asIcon: true,
                widgets: Layouts[props.layout].POSITIONS.map(p => ({
                    layoutPosition: p,
                    markup: (<div key={p} className={`h-100 ${resolveIconMarkupClass(props.layout)}`}></div>)
                }))
            })}
        </div>;
    },

    two_columns: class extends BaseLayout {
        static get POSITIONS() {
            return ['1', '2'];
        }
        render() {
            let widgetMap = this.getWidgetMap();

            return (
                <div className={'dashboard-layout row' + (this.props.asIcon ? ' no-gutters' : '')}>
                    {this.constructor.POSITIONS.map(pos => <div key={pos} className={this.props.asIcon ? 'col-6' : 'col-12 col-md-6'} data-position={pos}>
                        {widgetMap[pos]}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>)}
                </div>
            );
        }
    },

    three_columns: class extends BaseLayout {
        static get POSITIONS() {
            return ['1', '2', '3'];
        }
        render() {
            let widgetMap = this.getWidgetMap();

            return (
                <div className={'dashboard-layout row' + (this.props.asIcon ? ' no-gutters' : '')}>
                    {this.constructor.POSITIONS.map(pos => <div key={pos} className={this.props.asIcon ? 'col-4' : 'col-12 col-md-6 col-lg-4'} data-position={pos}>
                        {widgetMap[pos]}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>)}
                </div>
            );
        }
    },

    wide_narrow_columns: class extends BaseLayout {
        static get POSITIONS() {
            return ['1', '2'];
        }
        render() {
            let widgetMap = this.getWidgetMap();

            return (
                <div className={'dashboard-layout row' + (this.props.asIcon ? ' no-gutters' : '')}>
                    <div className={this.props.asIcon ? 'col-8' : 'col-12 col-md-8'} data-position="1">
                        {widgetMap['1']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                    <div className={this.props.asIcon ? 'col-4' : 'col-12 col-md-4'} data-position="2">
                        {widgetMap['2']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                </div>
            );
        }
    },

    narrow_wide_columns: class extends BaseLayout {
        static get POSITIONS() {
            return ['1', '2'];
        }
        render() {
            let widgetMap = this.getWidgetMap();

            return (
                <div className={'dashboard-layout row' + (this.props.asIcon ? ' no-gutters' : '')}>
                    <div className={this.props.asIcon ? 'col-4' : 'col-12 col-md-4'} data-position="1">
                        {widgetMap['1']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                    <div className={this.props.asIcon ? 'col-8' : 'col-12 col-md-8'} data-position="2">
                        {widgetMap['2']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                </div>
            );
        }
    },

    hero_two_columns: class extends BaseLayout {
        static get POSITIONS() {
            return ['1', '2', '3'];
        }
        render() {
            let widgetMap = this.getWidgetMap();

            return (
                <div className={'dashboard-layout row' + (this.props.asIcon ? ' no-gutters' : '')}>
                    <div className={'col-12'} data-position="1">
                        {widgetMap['1']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                    <div className={this.props.asIcon ? 'col-6' : 'col-12 col-md-6'} data-position="2">
                        {widgetMap['2']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                    <div className={this.props.asIcon ? 'col-6' : 'col-12 col-md-6'} data-position="3">
                        {widgetMap['3']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                </div>
            );
        }
    },

    hero_three_columns: class extends BaseLayout {
        static get POSITIONS() {
            return ['1', '2', '3', '4'];
        }
        render() {
            let widgetMap = this.getWidgetMap();

            return (
                <div className={'dashboard-layout row' + (this.props.asIcon ? ' no-gutters' : '')}>
                    <div className={'col-12'} data-position="1">
                        {widgetMap['1']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                    <div className={this.props.asIcon ? 'col-4' : 'col-12 col-md-4'} data-position="2">
                        {widgetMap['2']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                    <div className={this.props.asIcon ? 'col-4' : 'col-12 col-md-4'} data-position="3">
                        {widgetMap['3']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                    <div className={this.props.asIcon ? 'col-4' : 'col-12 col-md-4'} data-position="4">
                        {widgetMap['4']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                </div>
            );
        }
    },

    three_columns_joined_top_left: class extends BaseLayout {
        static get POSITIONS() {
            return ['1', '2', '3', '4'];
        }
        render() {
            let widgetMap = this.getWidgetMap();

            return (
                <div className={'dashboard-layout row' + (this.props.asIcon ? ' no-gutters' : '')}>
                    <div className={this.props.asIcon ? 'col-8' : 'col-12 col-lg-8'}>
                        <div className={'row' + (this.props.asIcon ? ' no-gutters h-100' : '')}>
                            <div className={'col-12'} data-position="1">
                                {widgetMap['1']}
                                {this.props.dragOngoing ? Layouts.dropArea : null}
                            </div>
                            <div className={this.props.asIcon ? 'col-6' : 'col-12 col-md-6'} data-position="2">
                                {widgetMap['2']}
                                {this.props.dragOngoing ? Layouts.dropArea : null}
                            </div>
                            <div className={this.props.asIcon ? 'col-6' : 'col-12 col-md-6'} data-position="3">
                                {widgetMap['3']}
                                {this.props.dragOngoing ? Layouts.dropArea : null}
                            </div>
                        </div>
                    </div>
                    <div className={this.props.asIcon ? 'col-4' : 'col-12 col-lg-4'} data-position="4">
                        {widgetMap['4']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                </div>
            );
        }
    },

    three_columns_joined_bottom_left: class extends BaseLayout {
        static get POSITIONS() {
            return ['1', '2', '3', '4'];
        }
        render() {
            let widgetMap = this.getWidgetMap();

            return (
                <div className={'dashboard-layout row' + (this.props.asIcon ? ' no-gutters' : '')}>
                    <div className={this.props.asIcon ? 'col-8' : 'col-12 col-lg-8'}>
                        <div className={'row' + (this.props.asIcon ? ' no-gutters h-100' : '')}>
                            <div className={this.props.asIcon ? 'col-6' : 'col-12 col-md-6'} data-position="1">
                                {widgetMap['1']}
                                {this.props.dragOngoing ? Layouts.dropArea : null}
                            </div>
                            <div className={this.props.asIcon ? 'col-6' : 'col-12 col-md-6'} data-position="2">
                                {widgetMap['2']}
                                {this.props.dragOngoing ? Layouts.dropArea : null}
                            </div>
                            <div className={'col-12'} data-position="4">
                                {widgetMap['4']}
                                {this.props.dragOngoing ? Layouts.dropArea : null}
                            </div>
                        </div>
                    </div>
                    <div className={this.props.asIcon ? 'col-4' : 'col-12 col-lg-4'} data-position="3">
                        {widgetMap['3']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                </div>
            );
        }
    },

    columns_two_three_one: class extends BaseLayout {
        static get POSITIONS() {
            return ['1', '2', '3', '4', '5', '6'];
        }
        render() {
            let widgetMap = this.getWidgetMap();

            return (
                <div className={'dashboard-layout row' + (this.props.asIcon ? ' no-gutters' : '')}>
                    <div className={this.props.asIcon ? 'col-6' : 'col-12 col-md-6'} data-position="1">
                        {widgetMap['1']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                    <div className={this.props.asIcon ? 'col-6' : 'col-12 col-md-6'} data-position="2">
                        {widgetMap['2']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                    <div className={this.props.asIcon ? 'col-4' : 'col-12 col-md-4'} data-position="3">
                        {widgetMap['3']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                    <div className={this.props.asIcon ? 'col-4' : 'col-12 col-md-4'} data-position="4">
                        {widgetMap['4']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                    <div className={this.props.asIcon ? 'col-4' : 'col-12 col-md-4'} data-position="5">
                        {widgetMap['5']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                    <div className={'col-12'} data-position="6">
                        {widgetMap['6']}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>
                </div>
            );
        }
    },

    sidebar_one_column: class extends BaseLayout {
        static get POSITIONS() {
            return ['1'];
        }
        render() {
            let widgetMap = this.getWidgetMap();

            return (
                <div className={'dashboard-layout row' + (this.props.asIcon ? ' no-gutters' : '')}>
                    {this.constructor.POSITIONS.map(pos => <div key={pos} className={this.props.asIcon ? 'col-12' : 'col-12 col-md-12'} data-position={pos}>
                        {widgetMap[pos]}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>)}
                </div>
            );
        }
    },

    sidebar_one_column_primary: class extends BaseLayout {
        static get POSITIONS() {
            return ['1'];
        }
        render() {
            let widgetMap = this.getWidgetMap();

            return (
                <div className={'dashboard-layout row' + (this.props.asIcon ? ' no-gutters' : '')}>
                    {this.constructor.POSITIONS.map(pos => <div key={pos} className={this.props.asIcon ? 'col-12' : 'col-12 col-md-12'} data-position={pos}>
                        {widgetMap[pos]}
                        {this.props.dragOngoing ? Layouts.dropArea : null}
                    </div>)}
                </div>
            );
        }
    },
}