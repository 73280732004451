import { useCallback, useMemo } from "react";
import useFetch from "@hooks/useFetch";
import useRoutes from "@hooks/useRoutes";
import useCSRFToken from "@hooks/useCSRFToken";
import {
  FetchUserApiOpts,
  mapFetchUserApiOptsToRouteOpts,
  buildUserListResult,
  FetchUsersResult,
} from "./utils/users";

function useUsers() {
  const csrfToken = useCSRFToken();
  const { doFetch, isFetching } = useFetch();
  const { doFetch: doDelete, isFetching: isDeleting } = useFetch();
  const { doFetch: doPassivate, isFetching: isPassivating } = useFetch();
  const { doFetch: doPost, isFetching: isPosting } = useFetch();

  const routes = useRoutes();

  const fetchUsers = useCallback(
    async (opts: FetchUserApiOpts = {}): Promise<FetchUsersResult> => {
      const response = await doFetch(
        routes.usersPath(mapFetchUserApiOptsToRouteOpts(opts))
      );

      if (!response.ok) {
        return {
          succeed: false,
          users: [],
          counts: { active: 0, inactive: 0, invited: 0, total: 0 },
        };
      }

      const { rows, status_counts } = await response.json();
      const res = {
        succeed: true,
        ...buildUserListResult(rows, status_counts),
      };
      return res;
    },
    [doFetch, routes]
  );

  const passivateUser = useCallback(
    async (id: number) => {
      const response = await doPassivate(routes.usersPassivatePath(id), {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      });
      return { succeed: response.ok };
    },
    [doPassivate, routes, csrfToken]
  );

  const deleteUser = useCallback(
    async (id: number) => {
      const response = await doDelete(routes.usersDeletePath(id), {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      });
      return { succeed: response.ok };
    },
    [doDelete, routes, csrfToken]
  );

  const sendResetLink = useCallback(
    async (path: string, id: number) => {
      const response = await doPost(path, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          person_id: id.toString(),
          authenticity_token: csrfToken,
        }),
      });
      return { succeed: response.ok };
    },
    [doPost, routes, csrfToken]
  );

  return useMemo(
    () => ({
      fetchUsers,
      isFetching,
      passivateUser,
      isPassivating,
      deleteUser,
      isDeleting,
      sendResetLink,
      isPosting,
    }),
    [
      fetchUsers,
      isFetching,
      passivateUser,
      isPassivating,
      deleteUser,
      isDeleting,
      sendResetLink,
      isPosting,
    ]
  );
}

export default useUsers;
