import React from "react";
import { Box } from "@oriola-origo/core/lib/Box";
import NotFoundPage from "@oriola-origo/core/lib/NotFound";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";

const NotFound = () => {
  document.querySelector("#content-menu").remove();

  return (
    <OriolaThemeProvider>
      <Box height={"calc(100vh - 200px)"}>
        <NotFoundPage />
      </Box>
    </OriolaThemeProvider>
  );
};

export default NotFound;
