import { UserListOpts } from "@hooks/useRoutes";
import { User, UserWithCompactCompanies } from "../../types/users";

export interface FetchOrganizationUserApiOpts {
  id: number;
  page: number;
  sizePerPage: number;
  orderBy: string;
  order: Parameters<(typeof Routes)["userGroupMembers"]>[0]["sortOrder"];
  search?: string;
  filter?: string;
  userGroupIds?: number[];
}
export interface FetchUserApiOpts {
  page?: number;
  perPage?: number;
  sortField?: string;
  sortOrder?: Parameters<(typeof Routes)["userGroupMembers"]>[0]["sortOrder"];
  statusFilter?: string;
  search?: string;
  organizationIds?: string[];
  userGroupIds?: number[];
}

export const mapFetchUserApiOptsToRouteOpts = (
  opts: FetchUserApiOpts
): UserListOpts => {
  const columnFilters = {};
  if (opts.statusFilter) {
    columnFilters["status"] = opts.statusFilter;
  }
  if (Array.isArray(opts.organizationIds) && opts.organizationIds.length > 0) {
    columnFilters["company_ids"] = opts.organizationIds;
  }

  return {
    page: opts.page,
    sizePerPage: opts.perPage,
    sortField: opts.sortField,
    sortOrder: opts.sortOrder,
    globalFilter: opts.search?.trim(),
    user_group_ids: opts.userGroupIds,
    columnFilters:
      Object.keys(columnFilters).length > 0 ? columnFilters : undefined,
  };
};

const countTotalUsers = (active = 0, inactive = 0, invited = 0) =>
  active + inactive + invited;

type StatusCounts = {
  active?: number;
  inactive?: number;
  invited?: number;
};

export type FetchUsersResult = {
  succeed: boolean;
  users: User[];
  counts: {
    total: number;
    active: number;
    inactive: number;
    invited: number;
  };
};

export const buildUserListResult = (
  users: UserWithCompactCompanies[],
  statusCounts: StatusCounts
) => {
  const totalCount = countTotalUsers(
    statusCounts?.active,
    statusCounts?.inactive,
    statusCounts?.invited
  );

  return {
    users,
    counts: {
      total: totalCount,
      active: statusCounts?.active ?? 0,
      inactive: statusCounts?.inactive ?? 0,
      invited: statusCounts?.invited ?? 0,
    },
  };
};
