import { useCallback, useMemo } from "react";
import useFetch from "@hooks/useFetch";
import useRoutes from "@hooks/useRoutes";
import useCSRFToken from "@hooks/useCSRFToken";

export interface PatchUpdateOrganizationOpts {
  organizationId: number;
  organization: Partial<{
    type_str_id: string;
    name: string;
    parent_company_id: number;
    role_ids: number[];
    profit_center_ids: number[];
    logo: {
      upload_id: string;
      file_name: string;
    };
    street: string;
    house_number: string;
    business_location: string;
    post_code: string;
    city: string;
    country: string;
    phone_number: string;
    chain: string;
    owner: string;
    hostpital_district: string;
    supply_list_email: string;
    order_response_email: string;
    business_identifier: string;
    emergency_message_additional_info: string;
  }>;
}

type PatchUpdateOrganizationBody = Partial<
  Omit<PatchUpdateOrganizationOpts["organization"], "logo"> & {
    logo: {
      upload_id: string;
      new: string;
    };
  }
>;

const mapPatchUpdateOptsToRequestBody = (
  organizationOpts: PatchUpdateOrganizationOpts["organization"]
) => {
  const { logo, ...rest } = organizationOpts;
  const body: PatchUpdateOrganizationBody = { ...rest };
  if (logo) {
    body.logo = {
      upload_id: organizationOpts.logo.upload_id,
      new: organizationOpts.logo.file_name,
    };
  }

  return body;
};

export type FetchOrganizationResult = {
  succeed: boolean;
  data?: {
    api_id: string;
    hierarchy_level: number;
    hierarchy_parent_company_ids: number[];
    id: number;
    name: string;
  };
};

export default function useOrganization() {
  const csrfToken = useCSRFToken();
  const { doFetch, isFetching } = useFetch();
  const { doFetch: doUpdate, isFetching: isUpdating } = useFetch();
  const { doFetch: doUpload, isFetching: isUploading } = useFetch();
  const routes = useRoutes();

  const fetchOrganization = useCallback(
    async (organizationId: number): Promise<FetchOrganizationResult> => {
      const response = await doFetch(
        routes.companySearchPath({ company_id: organizationId })
      );
      if (!response.ok) {
        return { succeed: false };
      }

      const data = await response.json();
      return {
        succeed: true,
        data: data[0],
      };
    },
    [routes]
  );

  const patchUpdateOrganization = useCallback(
    async (
      opts: PatchUpdateOrganizationOpts
    ): Promise<{ succeed: boolean; data?: any; error?: any }> => {
      const response = await doUpdate(
        routes.organizationUpdatePatchPath(opts.organizationId),
        {
          body: JSON.stringify({
            company: mapPatchUpdateOptsToRequestBody(opts.organization),
          }),
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
        }
      );

      try {
        const data = await response.json();
        return {
          succeed: response.ok,
          data,
          error: !response.ok ? data : null,
        };
      } catch (error) {
        return {
          succeed: false,
        };
      }
    },
    [routes, csrfToken]
  );

  const uploadLogo = useCallback(
    async (uploadId: string, file: Blob | string) => {
      const formData = new FormData();
      formData.append("file", file);

      const response = await doUpload(
        routes.organizationUploadLogoPath(uploadId),
        {
          body: formData,
          method: "POST",
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      );
      if (!response.ok) {
        return {
          succeed: false,
          error: response.json ? await response.json() : null,
        };
      }

      return {
        succeed: response.ok,
      };
    },
    [routes, csrfToken]
  );

  const fetchOrganizationActivityLogs = useCallback(
    async (userId: string, page, sizePerPage, sortOrder = "desc") => {
      const endpoint = routes.organizationActivityLogsShow(
        userId,
        page,
        sizePerPage,
        sortOrder
      );
      try {
        const response = await doFetch(endpoint, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
        });

        const data = await response.json();
        return {
          succeed: response.ok,
          data,
        };
      } catch (error) {
        return {
          succeed: false,
        };
      }
    },
    [routes, csrfToken]
  );

  return useMemo(
    () => ({
      patchUpdateOrganization,
      uploadLogo,
      fetchOrganization,
      isFetching,
      isUpdating,
      isUploading,
      fetchOrganizationActivityLogs,
    }),
    [
      patchUpdateOrganization,
      isUpdating,
      fetchOrganization,
      isFetching,
      uploadLogo,
      isUploading,
      fetchOrganizationActivityLogs,
    ]
  );
}
