import React, { useState } from "react";
import Typography from "@oriola-origo/core/lib/Typography";
import Box from "@oriola-origo/core/lib/Box";
import HintAccordion from "./hint_accordion";
import useTranslations from "../../../../hooks/useTranslations";

const HINTS = [
  {
    summaryKey: "user_group_hint_examples_naming",
    details: [
      {
        titleKey: "user_group_hint_follow_structure",
        descriptionKey: "user_group_hint_follow_structure_description",
      },
      {
        titleKey: "user_group_hint_category_prefix",
        descriptionKey: "user_group_hint_category_prefix_description",
      },
      {
        titleKey: "user_group_hint_user_group_name",
        descriptionKey: "user_group_hint_user_group_name_description",
      },
      {
        titleKey: "user_group_hint_special_access_function",
        descriptionKey: "user_group_hint_special_access_function_description",
      },
    ],
  },
  {
    summaryKey: "user_group_hint_maintain_naming",
    details: [
      {
        titleKey: "user_group_hint_simplicity",
        descriptionKey: "user_group_hint_simplicity_description",
      },
      {
        titleKey: "user_group_hint_consistency",
        descriptionKey: "user_group_hint_consistency_description",
      },
      {
        titleKey: "user_group_hint_specificity",
        descriptionKey: "user_group_hint_specificity_description",
      },
    ],
  },
];

function Hints() {
  const { t } = useTranslations();
  const [openHintIndeces, setOpenHintIndeces] = useState<number[]>([]);

  return Object.values(HINTS).map((hint, index) => {
    const handleClick = () => {
      openHintIndeces.includes(index)
        ? setOpenHintIndeces(openHintIndeces.filter((i) => i !== index))
        : setOpenHintIndeces([...openHintIndeces, index]);
    };

    return (
      <HintAccordion
        key={hint.summaryKey}
        summaryText={t(hint.summaryKey)}
        isExpanded={openHintIndeces.includes(index)}
        onClick={handleClick}
      >
        {hint.details.map((detail, detailIndex) => {
          const isLast = detailIndex === hint.details.length - 1;
          return (
            <Box key={detail.titleKey} mb={isLast ? 0 : 2}>
              <Typography
                variant="body1"
                color="textSecondary"
                fontWeight={600}
              >
                {t(detail.titleKey)}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {t(detail.descriptionKey)}
              </Typography>
            </Box>
          );
        })}
      </HintAccordion>
    );
  });
}

export default Hints;
