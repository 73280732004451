import React, { useEffect } from "react";
import useRoutes from "@hooks/useRoutes";
import { styled } from "@oriola-origo/core/lib/styles";
import Box from "@oriola-origo/core/lib/Box";

export interface OrganizationLogoProps {
  organization: {
    api_id: string;
    updated_at?: string;
  };
  size?: "thumb";
}

const Container = styled(Box)({
  position: "relative",
  height: 25,
  width: 25,
});

const LogoImage = styled("img")({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  height: 36,
  right: 0,
});

export default function OrganizationLogo({
  organization,
  size = "thumb",
}: Readonly<OrganizationLogoProps>) {
  const { companyLogoPath } = useRoutes();
  const [imageLoaded, setImageLoaded] = React.useState(false);
  let logoPath = companyLogoPath(organization.api_id, size);
  if (organization.updated_at) {
    const cacheTag = organization.updated_at.replace(/\D/g, "") ?? null;
    logoPath = `${logoPath}?${cacheTag}`;
  }

  useEffect(() => {
    setImageLoaded(false);
    const img = new Image();
    img.src = logoPath;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, [logoPath]);

  return imageLoaded ? (
    <Container>
      <LogoImage src={logoPath} alt="" />
    </Container>
  ) : null;
}
