import React from 'react';
import Switch from '../common/switch';

export default class List extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            settings: this.props.settings
        };
    }

    render() {
        return <div ref="root">
            <hr />
            <div className="row px-3">
                <div className="col-auto">
                    <i className="fa fa-wrench text-primary pt-2" style={{fontSize: '2rem'}}></i>
                </div>
                <div className="col">
                    <strong>{I18n.t('system_setting_maintenance_break')}</strong>
                    <div className="text-muted">
                        {I18n.t('system_setting_maintenance_break_description')}
                    </div>
                </div>
                <div className="col-auto pt-2">
                    <Switch
                        onChange={(checked) => this.updateSetting('maintenance-break', checked, 'boolean', I18n.t(`confirm_maintenance_break_${checked ? 'enabled' : 'disabled'}`))}
                        checked={this.state.settings['maintenance-break'] === true}
                        checkedLabel={<span className="px-3">{I18n.t('enabled')}</span>}
                        uncheckedLabel={<div className="px-3 position-absolute" style={{ right: 0, top: '4px' }}>{I18n.t('disabled')}</div>}
                        width={110}
                    />
                </div>
            </div>
            <hr />
        </div>;
    }

    updateSetting = (setting, value, datatype, confirmText) => {
        if (confirm(confirmText)) {
            jQuery.post(Routes.system_setting_update_path(), {setting: setting, value: value, datatype: datatype}, () => {
                this.setState(prevState => {
                    let settings = {...prevState.settings};
                    settings[setting] = value;
                    return { settings: settings };
                });
            });
        }
    }
}