import { useCallback, useMemo } from "react";
import useFetch from "@hooks/useFetch";
import useRoutes from "@hooks/useRoutes";
import {
  buildUserListResult,
  FetchUsersResult,
  FetchOrganizationUserApiOpts,
} from "./utils/users";

export const buildQueryParams = (opts: FetchOrganizationUserApiOpts) => {
  const { page, sizePerPage, orderBy, order, search, filter, userGroupIds } =
    opts;
  let qparam = `page=${page}&sizePerPage=${sizePerPage}&orderBy=${orderBy}&order=${order}`;
  if (search) {
    qparam += `&search=${search}`;
  }
  if (filter) {
    qparam += `&filter=${filter}`;
  }
  if (userGroupIds) {
    for (const id of userGroupIds) {
      qparam += `&user_group_ids[]=${id}`;
    }
  }
  return qparam;
};
function useOrganizationUsers() {
  const { doFetch, isFetching } = useFetch();

  const routes = useRoutes();

  const fetchUsers = useCallback(
    async (opts: FetchOrganizationUserApiOpts): Promise<FetchUsersResult> => {
      const qparam = buildQueryParams(opts);
      const response = await doFetch(
        `${routes.companyUsersPath(opts.id)}&${qparam}`
      );

      if (!response.ok) {
        return {
          succeed: false,
          users: [],
          counts: { active: 0, inactive: 0, invited: 0, total: 0 },
        };
      }

      const { rows, status_counts } = await response.json();
      const res = {
        succeed: true,
        ...buildUserListResult(rows, status_counts),
      };
      return res;
    },
    [doFetch, routes]
  );

  return useMemo(
    () => ({
      fetchUsers,
      isFetching,
    }),
    [fetchUsers, isFetching]
  );
}

export default useOrganizationUsers;
