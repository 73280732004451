import React from 'react';
import { Modal, CloseModal } from '../common/modal';
import { Form, FormSubmit } from '../common/form';
import FileUpload from '../common/file_upload';
import _ from 'underscore';

export default class UsageContactForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contact: {
        en: this.props.usageContact && this.props.usageContact.content.en || {},
        fi: this.props.usageContact && this.props.usageContact.content.fi || {},
        sv: this.props.usageContact && this.props.usageContact.content.sv || {},
        contact_persons: this.props.usageContact && this.props.usageContact.content.contact_persons || [],
      },
      thumbnail: this.props.usageContact && this.props.usageContact.thumbnail || {},
      banner: this.props.usageContact && this.props.usageContact.banner || {},
      thumbnailUploadId: StringHelper.random(8),
      bannerUploadId: StringHelper.random(8)
    };
    this.addContactPerson = this.addContactPerson.bind(this);
    this.deleteContactPerson = this.deleteContactPerson.bind(this);
    this.updateContactPerson = this.updateContactPerson.bind(this);
  }

  addContactPerson(e) {
    this.setState(prevState => {
      const newContact = { ...prevState.contact };
      newContact.contact_persons = newContact.contact_persons.concat({});
      return { contact: newContact };
    });
  }

  updateContactPerson(e, idx) {
    e.persist();
    this.setState((prevState) => {
      let newContact = { ...prevState.contact };
      newContact.contact_persons[idx] = { ...newContact.contact_persons[idx], [e.target.id]: [e.target.value] };
      return { contact: newContact };
    });
  }

  deleteContactPerson(idx) {
    if (confirm(I18n.t("confirm_delete_contact_person"))) {
      this.setState((prevState) => {
        let newContact = { ...prevState.contact };
        newContact.contact_persons.splice(idx, 1);
        return { contact: newContact };
      });
    }
  }

  render() {
    const contact = this.state.contact;

    return <Modal title={this.props.title} size="lg" onClose={this.props.onClose}>
      <Form action={this.props.action} onSuccess={this.props.onSuccess}>
        <div className="accordion mb-3" id="contact-accordion">
          {this.props.languages.map((locale, idx) => {
            return <div key={locale}>
              <button type="button" className={`btn btn-light text-left d-block w-100 ${idx > 0 ? 'mt-3' : ''}`} id={`heading-${locale}`} data-toggle="collapse" data-target={`#collapse-${locale}`}>
                {I18n.t(`language_${locale}`)} *
              </button>
              <div id={`collapse-${locale}`} className={`collapse ${idx === 0 ? 'show' : ''}`} aria-labelledby={`heading-${locale}`} data-parent="#contact-accordion">
                <div className="card">
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor={`usage_contact_title_${locale}`}>{I18n.t('usage_contact_title')} *</label>
                      <input type="text" id={`usage_contact_title_${locale}`} name={`usage_contact[content][${locale}][title]`} defaultValue={contact ? (contact || {})[locale].title : ''} className="form-control" />
                    </div>
                    <div className="form-group">
                      <label htmlFor={`usage_contact_short_description_${locale}`}>{I18n.t('usage_contact_short_description')} *</label>
                      <textarea id={`usage_contact_short_description_${locale}`} name={`usage_contact[content][${locale}][short_description]`} defaultValue={contact ? (contact || {})[locale].short_description : ''} className="form-control" rows="3" />
                    </div>
                    <div className="form-group">
                      <label htmlFor={`usage_contact_long_description_${locale}`}>{I18n.t('usage_contact_long_description')} *</label>
                      <textarea id={`usage_contact_long_description_${locale}`} name={`usage_contact[content][${locale}][long_description]`} defaultValue={contact ? (contact || {})[locale].long_description : ''} className="form-control" rows="3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          })
          }
        </div>

        <div className="form-group">
          <label>{I18n.t('thumbnail')}</label>
          <input type="hidden" name={`usage_contact[thumbnail][upload_id]`} value={this.state.thumbnailUploadId} className="form-control" />
          <FileUpload
            key={this.state.thumbnailUploadId}
            name={`usage_contact[thumbnail]`}
            multiple={false}
            files={!_.isEmpty(this.state.thumbnail) && [Object.assign({ previewUrl: Routes.usage_contact_download_image_path({ contact_id: this.props.usageContact.id, size: 'small', type: 'thumbnail' }) }, this.state.thumbnail)]}
            url={Routes.usage_contact_upload_image_path({ upload_id: this.state.thumbnailUploadId })}
            accept={['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/tiff']}
          />
          <small className="text-muted">{I18n.t('contact_image_description')}</small>
        </div>

        <div className="form-group">
          <label>{I18n.t('banner')}</label>
          <input type="hidden" name={`usage_contact[banner][upload_id]`} value={this.state.bannerUploadId} className="form-control" />
          <FileUpload
            key={this.state.bannerUploadId}
            name={`usage_contact[banner]`}
            multiple={false}
            files={!_.isEmpty(this.state.banner) && [Object.assign({ previewUrl: Routes.usage_contact_download_image_path({ contact_id: this.props.usageContact.id, size: 'small', type: 'banner' }) }, this.state.banner)]}
            url={Routes.usage_contact_upload_image_path({ upload_id: this.state.bannerUploadId })}
            accept={['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/tiff']}
          />
          <small className="text-muted">{I18n.t('contact_image_description')}</small>
        </div>
        <div className="form-group">
          <label>{I18n.t('contact_persons')}</label>
        </div>
        {contact.contact_persons.map((person, index) => <ContactPerson
          key={`contact-person-${index}`}
          keyId={index}
          name={"usage_contact[content][contact_persons][]"}
          person={person}
          onChange={this.updateContactPerson}
          onDelete={this.deleteContactPerson}
        />
        )}
        <div className="d-flex justify-content-center mt-2">
          <div
            className="btn btn-primary mr-auto ml-auto mb-3"
            onClick={this.addContactPerson}
          >
            {contact.contact_persons.length ? I18n.t("add_another_contact_person") : I18n.t("add_a_contact_person")}
          </div>
        </div>
        <div className="text-right">
          <CloseModal className="btn btn-light mr-3 px-4">{I18n.t('cancel')}</CloseModal>
          <FormSubmit />
        </div>
      </Form>
    </Modal >
  }
}

class ContactPerson extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const person = this.props.person;
    const name = this.props.name;
    return (
      <div className="bg-light p-2 mb-2">
        <div className="row justify-content-end">
          {!this.props.disabled ? (
            <button
              type="button"
              className="btn btn-danger btn-sm mr-3"
              onClick={() => this.props.onDelete(this.props.keyId)}
            >
              <i className="icon-trash"></i>
            </button>
          ) : null}
        </div>
        <div className="row form-group">
          <div className="col-sm-12">
            <label className="input-label">{I18n.t("full_name")}</label>
            <input type="text" id="name" name={`${name}[name]`} defaultValue={person && person.name} className="form-control" onChange={e => this.props.onChange(e, this.props.keyId)} />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-lg-6 col-sm-12">
            <label className="input-label">{I18n.t("designation")}</label>
            <input type="text" id="designation" name={`${name}[designation]`} defaultValue={person && person.designation} className="form-control" onChange={e => this.props.onChange(e, this.props.keyId)} />
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="input-label">{I18n.t("department")}</label>
            <input type="text" id="department" name={`${name}[department]`} defaultValue={person && person.department} className="form-control" onChange={e => this.props.onChange(e, this.props.keyId)} />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-lg-6 col-sm-12">
            <label className="input-label">{I18n.t("phone")}</label>
            <input type="phone" id="phone" name={`${name}[phone]`} defaultValue={person && person.phone} className="form-control" onChange={e => this.props.onChange(e, this.props.keyId)} />
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="input-label">{I18n.t("email")}</label>
            <input type="email" id="email" name={`${name}[email]`} defaultValue={person && person.email} className="form-control" onChange={e => this.props.onChange(e, this.props.keyId)} />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-lg-6 col-sm-12">
            <label className="input-label">WWW</label>
            <input type="text" id="website" name={`${name}[website]`} defaultValue={person && person.website} className="form-control" onChange={e => this.props.onChange(e, this.props.keyId)} />
          </div>
        </div>
      </div>
    );
  }
}
