import React, { useEffect } from "react";
import Header from "@oriola-origo/core/lib/Header";
import Box from "@oriola-origo/core/lib/Box";
import { styled } from "@oriola-origo/core/lib/styles";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import TextField from "@oriola-origo/core/lib/TextField";
import Grid from "@oriola-origo/core/lib/Grid";
import Button from "@oriola-origo/core/lib/Button";
import SnackbarProvider from "@oriola-origo/core/lib/Snackbar/SnackbarProvider";
import Page from "@oriola-origo/core/lib/Page";
import Hints from "./hints/hints";
import ToggleItemsList, {
  ALL_CATEGORY_ID,
  ItemsActiveCategoryId,
  ToggleItemsListItem,
} from "@components/common/toggle_items_list";
import useSaveUserGroup, { SaveUserGroupAction } from "./useSaveUserGroup";
import { ValidationErrorMap, validateUserGroup } from "./validation";
import { UserGroupPermissions } from "./types";
import CategorySelection from "./category_selection/category_selection";
import { OrigoApplication } from "@services/user_management/types/application";
import { OrigoApplicationRole } from "@services/user_management/types/roles";
import Footer from "../../common/footer";
import BackButton from "../../common/back_button";
import useTranslations, {
  TranslateFunction,
} from "../../../hooks/useTranslations";
import { FooterProvider } from "../../../hooks/useFooter";
import useTurbolinks from "../../../hooks/useTurbolinks";
import useScrollContainer from "../../../hooks/useScrollContainer";
import useSnackbar from "../../../hooks/useSnackbar";
import useSearchParams from "@hooks/useSearchParams";
import { APPLICATION_QUERY_PARAM } from "./constants";

export interface UserGroupFormV2Props {
  permissions: UserGroupPermissions;
  origin: string;
  cancelUrl: string;
  successUrl: string;
  apps: OrigoApplication[];
  roles: { [origoApplicationId: number]: OrigoApplicationRole[] };
  selectedRoles: number[];
  formObject?: {
    id: number;
    name: string;
    description: string;
    roles: Array<{ id: number; name: string }>;
    user_group_category_id: number;
  };
}

const FooterContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  height: "100%",
  gap: theme.spacing(4),
}));

const resolveBackButtonText = (
  isEditView: boolean,
  origin: string,
  t: TranslateFunction
): string => {
  if (!isEditView) {
    return t("back_to_user_groups");
  }
  return origin.endsWith("user_groups")
    ? t("back_to_user_groups")
    : t("back_to_user_group");
};

function UserGroupFormInternal({
  apps,
  roles,
  origin,
  successUrl,
  cancelUrl,
  selectedRoles,
  permissions,
  formObject,
}: Readonly<UserGroupFormV2Props>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userGroupName, setUserGroupName] = React.useState(
    formObject?.name ?? ""
  );
  const [description, setDescription] = React.useState(
    formObject?.description ?? ""
  );
  const [selectedRoleIds, setSelectedRoleIds] = React.useState<number[]>(
    formObject?.roles?.map((role) => role.id) ?? selectedRoles ?? []
  );
  const [selectedCategory, setSelectedCategory] = React.useState<number>(
    formObject?.user_group_category_id ?? null
  );
  const [activeAppId, setActiveAppId] = React.useState<string>(
    searchParams.get(APPLICATION_QUERY_PARAM) ?? ALL_CATEGORY_ID
  );
  const [validationErrors, setValidationErrors] =
    React.useState<ValidationErrorMap>({});
  const { t } = useTranslations();
  const { navigate } = useTurbolinks();
  const isEditView = !!formObject?.id;
  const { isSaving, saveUserGroup } = useSaveUserGroup(
    isEditView ? SaveUserGroupAction.Update : SaveUserGroupAction.Create
  );
  const { scrollToTop } = useScrollContainer();
  const { setSnackMessage } = useSnackbar();

  useEffect(() => {
    scrollToTop();
  }, [validationErrors]);

  const hasChanges = () => {
    if (!isEditView) {
      return true;
    }

    const formObjectRoleIds = formObject?.roles?.map((role) => role.id) ?? [];
    return (
      userGroupName !== formObject?.name ||
      description !== formObject?.description ||
      selectedCategory !== formObject?.user_group_category_id ||
      selectedRoleIds.length !== formObjectRoleIds.length ||
      selectedRoleIds.some((id) => formObjectRoleIds.includes(id) === false)
    );
  };

  const handleSaveClick = async () => {
    if (isSaving) {
      return;
    }

    let validationErrors: ValidationErrorMap = validateUserGroup(
      {
        userGroupName,
        description,
        userGroupCategoryId: selectedCategory,
      },
      t
    );
    if (Object.keys(validationErrors).length === 0) {
      const response = await saveUserGroup({
        id: formObject?.id,
        name: userGroupName,
        description: description,
        roleIds: selectedRoleIds,
        userGroupCategoryId: selectedCategory,
      });

      if (response.succeed) {
        return navigate(successUrl);
      }

      validationErrors = response?.errors ?? {};
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidationErrors(validationErrors);
      setSnackMessage(t("errors_title"), "error", true, 10000);
    }
  };

  const handleCancelClick = () => {
    navigate(cancelUrl);
  };

  const buildErrorHelperText = (errors: Array<{ message: string }> = []) => (
    <>
      {errors.map((error, i) => (
        <div key={error.message}>{error.message}</div>
      ))}
    </>
  );

  const renderRoleToggles = () => {
    const handleActiveApplicationChange = (
      activeCategoryId: ItemsActiveCategoryId
    ) => {
      if (
        Number.isInteger(activeCategoryId) ||
        activeCategoryId === ALL_CATEGORY_ID
      ) {
        searchParams.set(APPLICATION_QUERY_PARAM, activeCategoryId.toString());
      } else {
        searchParams.delete(APPLICATION_QUERY_PARAM);
      }

      setSearchParams(searchParams);
      setActiveAppId(activeCategoryId.toString() ?? ALL_CATEGORY_ID);
    };

    const items: { [categoryId: number]: ToggleItemsListItem[] } = {};
    Object.keys(roles).forEach((key) => {
      items[key] = roles[key].map((role) => ({
        id: role.id,
        name: role.name,
        description: role.description,
        disabled: role.is_superuser && !permissions.assign_superuser_roles,
      }));
    });

    const activeCategoryId: ItemsActiveCategoryId =
      activeAppId === ALL_CATEGORY_ID || activeAppId === ""
        ? activeAppId
        : Number(activeAppId);

    return (
      <ToggleItemsList
        categories={apps}
        items={items}
        selectedItemsIds={selectedRoleIds}
        onSelectedItemIdsChange={setSelectedRoleIds}
        activeCategoryId={activeCategoryId}
        onActiveCategoryChange={handleActiveApplicationChange}
      />
    );
  };

  return (
    <>
      <BackButton
        text={resolveBackButtonText(isEditView, origin, t)}
        navigateTo={origin}
        mb={3}
      />

      <Page
        header={
          <Header
            title={isEditView ? t("edit_user_group") : t("add_user_group_v2")}
            description={formObject?.name}
          />
        }
        mb={3}
      >
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 5 }}>
            <Box mb={5}>
              <CategorySelection
                selectedCategoryId={selectedCategory}
                onSelectedCategoryIdChange={setSelectedCategory}
                errorText={validationErrors?.userGroupCategoryId?.[0]?.message}
              />
            </Box>
            <Box mb={5}>
              <TextField
                error={!!validationErrors.name}
                helperText={buildErrorHelperText(validationErrors.name)}
                label={t("user_group_name")}
                required={true}
                fullWidth={true}
                value={userGroupName}
                onChange={(e) => setUserGroupName(e.target.value)}
              />
            </Box>
            <Box>
              <TextField
                label={t("description")}
                error={!!validationErrors.description}
                helperText={buildErrorHelperText(validationErrors.description)}
                required={true}
                fullWidth={true}
                multiline={true}
                minRows={2}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 7 }}>
            <Hints />
          </Grid>
        </Grid>
      </Page>

      <Page
        header={
          <Header
            title={t("add_roles_to_user_group")}
            description={formObject?.name}
          />
        }
        mb={5}
      >
        {renderRoleToggles()}
      </Page>

      <Footer>
        <FooterContent>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancelClick}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveClick}
            isLoading={isSaving}
            disabled={!hasChanges()}
          >
            {t("save")}
          </Button>
        </FooterContent>
      </Footer>
    </>
  );
}

function UserGroupFormV2(props: Readonly<UserGroupFormV2Props>) {
  return (
    <FooterProvider>
      <SnackbarProvider>
        <OriolaThemeProvider>
          <UserGroupFormInternal {...props} />
        </OriolaThemeProvider>
      </SnackbarProvider>
    </FooterProvider>
  );
}

export default UserGroupFormV2;
