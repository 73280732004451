import React from "react";
import useTranslations from "@hooks/useTranslations";
import Autocomplete from "@oriola-origo/core/lib/Autocomplete/Autocomplete";

export type UserGroupOption = {
  id: number;
  name: string;
  categoryName: string;
};

export type UserGroupAutocompleteProps = {
  userGroupOptions: Array<UserGroupOption>;
  defaultValue: UserGroupOption[];
  onChange: (value: UserGroupOption[]) => void;
};

export default function UserGroupAutocomplete({
  defaultValue,
  userGroupOptions,
  onChange,
}: Readonly<UserGroupAutocompleteProps>) {
  const { t } = useTranslations();
  return (
    <Autocomplete
      label={t("user_groups")}
      multiple={true}
      fullWidth={true}
      freeSolo={false}
      options={userGroupOptions}
      getOptionKey={(option: UserGroupOption) => option.id}
      limitTags={2}
      disableCloseOnSelect={true}
      size="small"
      getOptionLabel={(option: UserGroupOption) =>
        `${option.categoryName} - ${option.name}`
      }
      getLimitTagsText={(more) => `+${more}`}
      onChange={(_, value) => onChange(value)}
      defaultValue={defaultValue}
    />
  );
}
