import React from 'react';
import _ from 'underscore';
import Linkify from 'linkifyjs/react';

const styles = {
  tipIcon: {
    fontSize: '200%',
    width: '5rem',
    height: '5rem',
    lineHeight: '5rem',
    backgroundColor: '#e7ebf3'
  },
  navButton: {
    backgroundColor: '#e7ebf3',
    width: '2rem'
  },
  nav: {
    position: 'relative',
    left: '-3.5rem',
    whiteSpace: 'nowrap'
  },
  image: {
    borderRadius: '0.3rem 0.3rem 0 0'
  },
  imageThumb: {
    borderRadius: '0.5rem',
    width: '100%'
  },
  contactDetails: {
    overflowWrap: 'break-word'
  }
};

export class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      settings: props.settings,
      contactId: this.props.settings.contact_id,
      contact: {},
      contactOptions: null,
      loading: true
    }
  }

  render() {
    if (this.props.mode == Origo.Widget.Mode.SETTINGS) {
      return this.renderSettings();
    } else {
      if (this.props.viewSize == Origo.Widget.Size.EXPANDED) {
        return this.renderExpandedView();
      }
      return this.renderView();
    }
  }

  renderView() {
    let contact = null;
    if (this.state.loading) {
      contact = <h2 className="text-muted text-center p-3">
        {I18n.t('loading')}...
            </h2>;
    } else if (!_.isEmpty(this.state.contact)) {
      const content = this.state.contact.content[I18n.locale]
      const bannerlUrl = Routes.usage_contact_download_image_path({ contact_id: this.state.contactId, size: 'small', type: 'banner' })
      const expand = () => Origo.Widget.setSize(this.props.instanceId, Origo.Widget.Size.EXPANDED);
      contact = <div>
        {this.state.contact.banner && <img src={`${bannerlUrl}${bannerlUrl.match(/^blob:/) ? '' : (bannerlUrl.match(/\?/) ? '&' : '?') + Date.now()}`} className="card-img-top embed-responsive-item" alt="contact-thumbnail" style={styles.image} />}
        <a href="#" className="d-block pt-3 px-3" onClick={e => { expand(); e.preventDefault(); }}>
          <h6 className="font-weight-bold">{content.title}</h6>
        </a>
        <div className="p-3">
          <Linkify>{content.short_description}</Linkify>
          <a href="#" className="ml-3 text-nowrap text-origo" onClick={e => { expand(); e.preventDefault(); }}>{I18n.t("see_more")}</a>
        </div>
      </div>
    } else {
      contact = <h2 className="text-muted text-center p-3">
        {I18n.t('widget_no_contact_to_show_message')}
      </h2>;
    }
    return <div>
      {contact}
    </div>
  }

  renderExpandedView() {
    let contact = null;
    if (this.state.loading) {
      contact = <h2 className="text-muted text-center">
        {I18n.t('loading')}...
            </h2>;
    } else if (!_.isEmpty(this.state.contact)) {
      const content = this.state.contact.content[I18n.locale]
      const contact_persons = this.state.contact.content["contact_persons"];
      const thumbnailUrl = Routes.usage_contact_download_image_path({ contact_id: this.state.contactId, size: 'small', type: 'thumbnail' });
      contact = <div>
        <div className="row">
          <div className={this.state.contact.thumbnail ? 'col-md-4 col-lg-3 col-12' : 'col-12'}>
            <div className="mb-3">
              <a href="#" className="card-link text-nowrap" onClick={e => { Origo.Widget.setSize(this.props.instanceId, Origo.Widget.Size.DEFAULT); e.preventDefault(); }}>
                <i className="fa fa-chevron-left"></i>
                {I18n.t('go_back_to_contacts')}
              </a>
            </div>
            {this.state.contact.thumbnail && <div className="mb-3">
              <img src={`${thumbnailUrl}${thumbnailUrl.match(/^blob:/) ? '' : (thumbnailUrl.match(/\?/) ? '&' : '?') + Date.now()}`} style={styles.imageThumb} />
            </div>}
          </div>
          <div className={this.state.contact.thumbnail ? 'col-md-8 col-lg-9 col-12' : 'col-12'}>
            <h3 className="font-weight-bold">{content.title}</h3>
            {content.long_description && <p className="pt-3 pb-4"><Linkify>{content.long_description}</Linkify></p>}
            <div className="row my-3">
              {contact_persons.map((person, index) => (
                <div key={`contact-person-${person.phone}`} className="col-12 col-lg-4 my-3" style={styles.contactDetails}>
                  {person.name && <h6 style={{fontWeight: '600'}}>{person.name}</h6>}
                  <div>{[person.designation, person.department].filter(i => i).join(', ')}</div>
                  {person.phone && <div className="mt-2">
                    <a href={`tel:${person.phone}`}>{`Tel. ${person.phone}`}</a>
                  </div>}
                  {person.email && <div>
                    <a href={`mailto:${person.email}`}>{person.email}</a>
                  </div>}
                  {person.website && <div>
                    <Linkify>{person.website}</Linkify>
                  </div>}
                  {(index + 1) % 3 != 0 && index != contact_persons.length - 1 && <div className="d-none d-lg-block position-absolute h-100 w-0 border-left border-right border-origo" style={{ right: '0.5rem', top: 0 }}></div>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    } else {
      contact = <h2 className="text-muted text-center">
        {I18n.t('widget_no_contact_to_show_message')}
      </h2>;
    }
    return <div>
      {contact}
    </div>
  }

  renderSettings() {
    return <form className="settings p-3">
      <h3 className="mb-3">{I18n.t('usage_contact')}</h3>
      <div className="form-group">
        <div className="row">
          <div className="col">
            <select className="form-control custom-select"
              name="contact_id" value={this.state.contactId}
              onChange={e => this.udpateContact(e.currentTarget.options[e.currentTarget.selectedIndex].value)}>
              <option value="">{I18n.t('select_blank_text')}</option>
              {this.renderContactOptions()}
            </select>
          </div>
        </div>
      </div>
    </form >;
  }

  renderContactOptions() {
    return (this.state.contactOptions || []).map(o => <option key={o.id} value={o.id}>{o.content[I18n.locale].title}</option>);
  }

  componentDidMount() {
    this.fetchContacts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.mode == Origo.Widget.Mode.SETTINGS && this.state.contactOptions == null) {
      this.fetchContactOptions();
    }
  }

  udpateContact = (contactId) => {
    this.setState(prevState => {
      return {
        contact: prevState.contactOptions.filter(co => co.id == contactId),
        contactId: contactId
      };
    }, this.updateSettings);
  }


  updateSettings() {
    this.props.updateSettings({ contact_id: this.state.contactId });
  }

  fetchContacts() {
    if (_.isEmpty(this.state.contact)) {
      jQuery.getJSON(Routes.dashboard_widget_data_path({ widget: 'Contact', method: 'contact', contact_id: this.state.contactId }), (data) => {
        this.setState(prevState => {
          return {
            contact: data,
            loading: false,
          };
        });
      });
    } else {
      this.setState({
        contact: {},
        loading: false,
      });
    }
  }

  fetchContactOptions() {
    jQuery.getJSON(Routes.dashboard_widget_data_path({ widget: 'Contact', method: 'contact_options' }), (data) => {
      this.setState({
        contactOptions: data,
        loading: false
      });
    });
  }
}