import React, { ChangeEvent } from "react";
import Header from "@oriola-origo/core/lib/Header";
import Page from "@oriola-origo/core/lib/Page";
import Box from "@oriola-origo/core/lib/Box";
import Switch from "@oriola-origo/core/lib/Switch";
import useTranslations, { TranslateFunction } from "@hooks/useTranslations";
import useEmailNotifications from "./useEmailNotifications";
import { EditableUser } from "@components/user/contexts/editable_user";

const getSubscriptionToggles = (user: EditableUser, t: TranslateFunction) => [
  {
    property: "subscribe_weekly_newsletter",
    label: t("email_notifications_weekly_newsletter"),
    value: user.subscribe_weekly_newsletter,
  },
  {
    property: "subscribe_important_announcement_notification",
    label: t("email_notifications_important_announcement"),
    value: user.subscribe_important_announcement_notification,
  },
  {
    property: "subscribe_emergency_announcement_notification",
    label: t("email_notifications_emergency_announcement"),
    value: user.subscribe_emergency_announcement_notification,
  },
];

function EmailNotificationSubscriptions() {
  const { t } = useTranslations();
  const {
    user,
    updateUserEmailNotifications,
    setTogglingNotificationSwitch,
    togglingNotificationSwitch,
    isUpdatingUser,
  } = useEmailNotifications();

  const createToggleSwitchChangeHandler =
    (propertyName: keyof typeof user) =>
    async (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setTogglingNotificationSwitch(propertyName);
      try {
        await updateUserEmailNotifications({
          [propertyName]: checked,
        });
      } finally {
        setTogglingNotificationSwitch("");
      }
    };

  return (
    <Page header={<Header title={t("email_notifications_title")} />} mb={2}>
      {getSubscriptionToggles(user, t).map(
        ({ label, property, value }) => (
          <Box key={property}>
            <Switch
              label={label}
              disabled={isUpdatingUser}
              checked={togglingNotificationSwitch === property ? !value : value}
              onChange={createToggleSwitchChangeHandler(
                property as keyof typeof user
              )}
            />
          </Box>
        )
      )}
    </Page>
  );
}

export default EmailNotificationSubscriptions;
