import { useCallback, useMemo, useState } from "react";
import useCSRFToken from "@hooks/useCSRFToken";
import useFetch from "@hooks/useFetch";
import useRoutes from "@hooks/useRoutes";

interface UserGroupCategoryRaw {
  categoryId: number;
  categoryName: string;
  categoryPosition: number;
}

export interface UserGroupCategory {
  id: number;
  name: string;
  position: number;
}

export type UserGroupCategoryWithDelete = UserGroupCategory & {
  isDeleted: boolean;
};

export interface UserGroups {
  category: UserGroupCategory;
  groups: Array<{
    id: number;
    name: string;
    description: string;
  }>;
}

export interface FetchUserGroupCategoriesOutput {
  succeed: boolean;
  categories: UserGroupCategory[];
  errors?: Record<string, Array<{ message: string }>>;
}

export interface FetchUserGroupByCategoriesOutput {
  succeed: boolean;
  userGroups: UserGroups[];
  errors?: Record<string, Array<{ message: string }>>;
}

export interface CreateNewUserGroupCategoryOutput {
  succeed: boolean;
  category: UserGroupCategory;
  errors?: Record<string, Array<{ message: string }>>;
}

export const mapUserGroupCategory = (
  category: UserGroupCategoryRaw
): UserGroupCategory => ({
  id: category.categoryId,
  name: category.categoryName,
  position: category.categoryPosition,
});

function useUserGroupCategories() {
  const [isInitialized, setIsInitialized] = useState(false);
  const csrfToken = useCSRFToken();
  const routes = useRoutes();
  const { doFetch, isFetching } = useFetch();
  const { doFetch: doSave, isFetching: isSaving } = useFetch();

  const fetchUserGroupCategories =
    useCallback(async (): Promise<FetchUserGroupCategoriesOutput> => {
      const response = await doFetch(routes.userGroupCategoriesPath());
      const json = await response.json();

      setIsInitialized(true);

      return {
        succeed: response.ok,
        categories: json.data?.map(mapUserGroupCategory),
        errors: json.errors as Record<string, Array<{ message: string }>>,
      };
    }, [doFetch, routes]);

  const createNewUserGroupCategory = useCallback(
    async (categoryName: string): Promise<CreateNewUserGroupCategoryOutput> => {
      const response = await doSave(routes.userGroupCategoriesPath(), {
        method: "POST",
        body: JSON.stringify({ name: categoryName }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
      });
      const json = await response.json();

      return {
        succeed: response.ok,
        category: json.data ? mapUserGroupCategory(json.data) : undefined,
        errors: json.errors,
      };
    },
    [doSave, routes]
  );

  const _saveUserGroupCategories = async (categories: UserGroupCategory[]) => {
    const response = await doSave(routes.userGroupCategoriesUpdatePath(), {
      method: "PUT",
      body: JSON.stringify({
        user_group_categories: categories,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    });

    const json = await response.json();

    return {
      succeed: response.ok,
      errors: json.error,
    };
  };

  const saveUserGroupCategories = useCallback(_saveUserGroupCategories, [
    doSave,
    routes,
  ]);

  const fetchUserGroupByCategories =
    useCallback(async (): Promise<FetchUserGroupByCategoriesOutput> => {
      const response = await doFetch(routes.userGroupByCategoriesPath());
      const json = await response.json();

      setIsInitialized(true);

      return {
        succeed: response.ok,
        userGroups: json?.["rows"]?.["user_groups"],
        errors: json.errors as Record<string, Array<{ message: string }>>,
      };
    }, [doFetch, routes]);

  const _deleteUserGroupCategories = async (ids: Array<number>) => {
    const response = await doSave(routes.userGroupCategoriesDeletePath(), {
      method: "DELETE",
      body: JSON.stringify({ ids }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    });
    const json = await response.json();

    return {
      succeed: response.ok,
      errors: json.errors,
    };
  };

  const deleteUserGroupCategories = useCallback(_deleteUserGroupCategories, [
    doFetch,
    routes,
  ]);

  /**
   * Save and/or deletes passed user group categories
   * Updates position value based on order in array
   * @throws Error
   */
  const _saveDeleteUserGroupCategories = async (
    categories: UserGroupCategoryWithDelete[]
  ) => {
    let positionCounter = 0;
    const categoriesToUpdate: UserGroupCategory[] = [];
    const deleteIds: number[] = [];

    categories.forEach(({ isDeleted, ...category }) => {
      if (isDeleted) {
        deleteIds.push(category.id);
        return;
      }

      // future optimization: only update values have changed
      categoriesToUpdate.push({
        ...category,
        // Update position as it could be changed
        position: positionCounter++,
      });
    });

    if (categoriesToUpdate.length > 0) {
      const saveResponse = await _saveUserGroupCategories(categoriesToUpdate);
      if (!saveResponse.succeed) {
        throw new Error("Failed to update categories.", {
          cause: saveResponse,
        });
      }
    }

    if (deleteIds.length > 0) {
      const deleteResp = await _deleteUserGroupCategories(deleteIds);
      if (!deleteResp.succeed) {
        throw new Error("Failed to delete categories.", {
          cause: deleteResp,
        });
      }
    }

    return {
      updatedCategories: categoriesToUpdate,
      deletedIds: deleteIds,
    };
  };
  const saveDeleteUserGroupCategories = useCallback(
    _saveDeleteUserGroupCategories,
    [doSave, routes]
  );

  return useMemo(
    () => ({
      fetchUserGroupCategories,
      fetchUserGroupByCategories,
      isFetching,
      isInitialized,
      isSaving,
      createNewUserGroupCategory,
      deleteUserGroupCategories,
      saveUserGroupCategories,
      saveDeleteUserGroupCategories,
    }),
    [
      fetchUserGroupByCategories,
      isFetching,
      isSaving,
      createNewUserGroupCategory,
      deleteUserGroupCategories,
      saveUserGroupCategories,
      saveDeleteUserGroupCategories,
      isInitialized,
    ]
  );
}

export default useUserGroupCategories;
