import React, { ReactNode } from "react";
import TableRow from "@oriola-origo/core/lib/TableRow";
import TableCellSort from "@oriola-origo/core/lib/TableCellSort";
import TableCell from "@oriola-origo/core/lib/TableCell";
import useTranslations from "@hooks/useTranslations";
import Filters, { FiltersProps, FiltersType } from "./filters";
import {
  TableColumnNonSortable,
  TableColumnSortDirection,
  TableColumnSortable,
  SortType,
  getColumnWidthFactory,
} from "./columns";
import {
  HeaderPlaceholderCell,
  NoBottomBorderTableCell,
} from "./cells/common_cells";
import { UsersTableProps } from "./user_table";

export interface HeadersProps<TFilterExtras extends object = {}> {
  sort?: SortType;
  groupColumnHeaderText: string;
  showProfessionalTitleColumn: boolean;
  onSortChange: (sort: SortType) => void;
  counts?: UsersTableProps<TFilterExtras>["counts"];
  filters?: FiltersType<TFilterExtras>;
  tableWidth: number;
  groupFilterRender?: FiltersProps<TFilterExtras>["groupFilterRender"];
  onFiltersChange: (filters: FiltersType<TFilterExtras>) => void;
}

const resolveNextSortDirection = (
  isActive: boolean,
  currentDirection: TableColumnSortDirection
) => {
  if (!isActive) {
    return TableColumnSortDirection.Asc;
  }
  return currentDirection === TableColumnSortDirection.Asc
    ? TableColumnSortDirection.Desc
    : TableColumnSortDirection.Asc;
};

function Headers<TFilterExtras = {}>({
  sort,
  filters,
  counts,
  groupColumnHeaderText,
  showProfessionalTitleColumn,
  tableWidth,
  groupFilterRender,
  onSortChange,
  onFiltersChange,
}: Readonly<HeadersProps<TFilterExtras extends object ? TFilterExtras : {}>>) {
  const { t } = useTranslations();
  const { column, direction } = sort ?? {};
  const getColumnWidth = getColumnWidthFactory(showProfessionalTitleColumn);

  const renderColumnHeader = (
    sortColumn: TableColumnSortable,
    content: ReactNode
  ) => {
    return (
      <TableCellSort
        direction={direction}
        active={column === sortColumn}
        onClick={() => {
          onSortChange({
            column: sortColumn,
            direction: resolveNextSortDirection(
              column === sortColumn,
              direction
            ),
          });
        }}
      >
        {content}
      </TableCellSort>
    );
  };

  const renderWidthReservingCells = (
    columns: Array<TableColumnNonSortable | TableColumnSortable>
  ) =>
    columns.map((column) => (
      <HeaderPlaceholderCell
        key={column}
        width={getColumnWidth(column, tableWidth)}
      />
    ));

  const columns = [
    TableColumnNonSortable.Expand,
    TableColumnSortable.Name,
    TableColumnSortable.Email,
    showProfessionalTitleColumn
      ? TableColumnNonSortable.ProfessionalTitle
      : null,
    TableColumnNonSortable.Group,
    TableColumnNonSortable.Details,
    TableColumnNonSortable.Status,
    TableColumnNonSortable.Actions,
  ].filter(Boolean);

  return (
    <>
      <TableRow>{renderWidthReservingCells(columns)}</TableRow>
      <TableRow>
        <NoBottomBorderTableCell colSpan={showProfessionalTitleColumn ? 8 : 7}>
          <Filters
            counts={counts}
            groupFilterRender={groupFilterRender}
            filters={filters}
            onFiltersChange={onFiltersChange}
          />
        </NoBottomBorderTableCell>
      </TableRow>
      <TableRow>
        <TableCell />
        {renderColumnHeader(TableColumnSortable.Name, t("name"))}
        {renderColumnHeader(TableColumnSortable.Email, t("email_address"))}
        {columns.includes(TableColumnNonSortable.ProfessionalTitle) && (
          <TableCell>{t("professional_title")}</TableCell>
        )}
        <TableCell>{groupColumnHeaderText}</TableCell>
        <TableCell>{t("user_table_details")}</TableCell>
        <TableCell>{t("status")}</TableCell>
        <TableCell>{t("user_table_actions")}</TableCell>
      </TableRow>
    </>
  );
}

export default Headers;
