import React from "react";
import Autocomplete from "@oriola-origo/core/lib/Autocomplete/Autocomplete";
import { ProfitCenter } from "../../../../../services/user_management/types";

function ProfitCenterAutocomplete({
  label,
  options,
  onChange,
  defaultValue = [],
  limitTags = 2,
}) {
  return (
    <Autocomplete
      label={label}
      multiple={true}
      fullWidth={true}
      freeSolo={false}
      options={options}
      getOptionKey={(option: ProfitCenter) => option.id}
      limitTags={limitTags}
      disableCloseOnSelect={true}
      size="small"
      getOptionLabel={(option: ProfitCenter) =>
        `${option.code} - ${option.name}`
      }
      getLimitTagsText={(more) => `+${more}`}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}

export default ProfitCenterAutocomplete;
