import React, { useEffect, useRef } from "react";
import Box from "@oriola-origo/core/lib/Box";
import TextField from "@oriola-origo/core/lib/TextField";
import ToggleButtonGroup from "@oriola-origo/core/lib/ToggleButtonGroup/ToggleButtonGroup";
import Button from "@oriola-origo/core/lib/Button";
import { styled } from "@oriola-origo/core/lib/styles";
import useTranslations from "@hooks/useTranslations";
import useDebounce from "@hooks/useDebounce";
import { UsersTableProps } from "./user_table";

export type FiltersType<T extends object> = {
  status?: string;
  search?: string;
} & Partial<T>;

export interface FiltersProps<TExtraFilters extends object> {
  filters: FiltersType<TExtraFilters>;
  counts?: UsersTableProps<TExtraFilters>["counts"];
  groupFilterRender?: (
    filtersValue: FiltersType<TExtraFilters>,
    onFiltersChange: (filters: FiltersType<TExtraFilters>) => void
  ) => React.ReactNode;
  onFiltersChange: (filters: FiltersType<TExtraFilters>) => void;
}

const StyledButton = styled(Button)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  whiteSpace: "nowrap",
}));

const ALL_VALUE = "_all";

function Filters<TExtraFilters = {}>({
  filters,
  counts,
  groupFilterRender,
  onFiltersChange,
}: Readonly<FiltersProps<TExtraFilters extends object ? TExtraFilters : {}>>) {
  const { t } = useTranslations();
  const [searchValue, setSearchValue] = React.useState(filters.search);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const isInitialMount = useRef(true);

  const handleStatusChange = (status: string) => {
    onFiltersChange({
      ...filters,
      status: status === ALL_VALUE ? undefined : status,
    });
  };

  useEffect(() => {
    setSearchValue(filters.search);
  }, [filters.search]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    onFiltersChange({ ...filters, search: debouncedSearchValue });
  }, [debouncedSearchValue]);

  const renderLabel = (label: string, count: number = 0) =>
    `${label} (${count})`;

  return (
    <Box display="flex" justifyContent="space-between" px={1} gap={3}>
      <Box>
        <ToggleButtonGroup
          onChange={handleStatusChange}
          value={filters.status ?? ALL_VALUE}
        >
          <StyledButton value={ALL_VALUE}>
            {renderLabel(t("all"), counts?.total)}
          </StyledButton>
          <StyledButton value="active">
            {renderLabel(t("user_status_active"), counts?.active)}
          </StyledButton>
          <StyledButton value="inactive">
            {renderLabel(t("user_status_inactive"), counts?.inactive)}
          </StyledButton>
          <StyledButton value="invited">
            {renderLabel(t("user_status_pending"), counts?.invited)}
          </StyledButton>
        </ToggleButtonGroup>
      </Box>
      <Box width="100%">
        {typeof groupFilterRender === "function"
          ? groupFilterRender(filters, onFiltersChange)
          : null}
      </Box>
      <Box maxWidth={280} width="100%">
        <TextField
          label={t("search")}
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          fullWidth
          iconPosition="end"
          iconName="search"
          clearable={!!searchValue}
          onClear={() => setSearchValue("")}
        />
      </Box>
    </Box>
  );
}

export default Filters;
