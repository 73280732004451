import React from "react";
import NavigationPane from "@oriola-origo/core/lib/NavigationPane";
import {
  prepareReportDashboardLinks,
  redirectToPath,
  getSelectedDashboardId,
} from "./utils";

export interface Dashboard {
  label: string;
  url: string;
}
interface IReportsPageSidebarProps {
  dashboards: Dashboard[];
  isMobileScreen?: boolean;
}

export interface ReportDashboardNavItem {
  iconName: string;
  id: string;
  text: string;
  iconStyle: "outlined" | "filled" | "twotone";
  path: string;
}

export function onSelected(
  id: string,
  reportDashboards: ReportDashboardNavItem[]
) {
  const path = reportDashboards.find((link) => link.id === id)?.path;
  if (path) {
    redirectToPath(path);
  }
}

function NewReportsPageSidebar(props: Readonly<IReportsPageSidebarProps>) {
  const { dashboards } = props;
  const isMobileScreen = props.isMobileScreen;

  const reportDashboards = prepareReportDashboardLinks(dashboards).map(
    (item) => ({
      ...item,
      text: I18n.t(item.id),
    })
  );

  return (
    <NavigationPane
      active={
        getSelectedDashboardId(reportDashboards) || reportDashboards[0].id
      }
      links={reportDashboards}
      onSelected={(id) => onSelected(id, reportDashboards)}
      isHorizontal={isMobileScreen}
      customStyles={{
        height: !isMobileScreen ? "100vh" : "auto",
        "& > div": isMobileScreen
          ? {
              display: "flex",
              flexGrow: 1,
            }
          : {},
      }}
    />
  );
}

export default NewReportsPageSidebar;
