import React, { useCallback, useMemo } from "react";
import Box from "@oriola-origo/core/lib/Box";
import QuickFilter, { FilterProp } from "@oriola-origo/core/lib/QuickFilter";
import useTranslations from "@hooks/useTranslations";
import ItemList from "./item_list";

export type ToggleItemListItemCategory = {
  id: number;
  title: Record<string, string>;
};

export type ToggleItemsListItem = {
  id: number;
  name: string;
  description: string;
  disabled?: boolean;
  disabledTooltip?: string;
};

export const ALL_CATEGORY_ID = "__all";

export type ItemsActiveCategoryId = number | typeof ALL_CATEGORY_ID | "";

export interface ToggleItemsListProps {
  categories: ToggleItemListItemCategory[];
  items: { [categoryId: number]: ToggleItemsListItem[] };
  activeCategoryId?: ItemsActiveCategoryId;
  onActiveCategoryChange?: (categoryId: ItemsActiveCategoryId) => void;
  selectedItemsIds: number[];
  readonlySelectedItemIds?: number[];
  onSelectedItemIdsChange: (itemIds: number[], oldState: number[]) => void;
  noItemsText?: string;
  disabled?: boolean;
}

const countActiveItems = (
  selectedItemIds: number[],
  items: Array<{ id: number }>
) => items.filter((item) => selectedItemIds.includes(item.id)).length;

function ToggleItemsList({
  categories,
  items,
  noItemsText,
  selectedItemsIds,
  readonlySelectedItemIds,
  onSelectedItemIdsChange,
  onActiveCategoryChange,
  activeCategoryId,
  disabled
}: Readonly<ToggleItemsListProps>) {
  const { t, language } = useTranslations();

  const filters: FilterProp[] = useMemo(
    () =>
      [
        {
          id: ALL_CATEGORY_ID,
          name: t("all"),
        },
        ...categories.map((app) => ({
          id: app.id.toString(),
          name: app.title[language] ?? app.title.en,
          count: countActiveItems(
            [...selectedItemsIds, ...(readonlySelectedItemIds ?? [])],
            items[app.id] ?? []
          ),
        })),
      ].filter(Boolean),
    [categories, items, selectedItemsIds, readonlySelectedItemIds, t, language]
  );

  const visibleCategories = useMemo(
    () =>
      activeCategoryId === "" || activeCategoryId === ALL_CATEGORY_ID
        ? categories
        : [categories.find((category) => category.id === activeCategoryId)],
    [activeCategoryId, categories]
  );

  const handleItemClick = useCallback(
    (item: ToggleItemsListItem) => {
      const oldState = [...selectedItemsIds];
      const newState = selectedItemsIds.includes(item.id)
        ? selectedItemsIds.filter((id) => id !== item.id)
        : [...selectedItemsIds, item.id];

      onSelectedItemIdsChange(newState, oldState);
    },
    [selectedItemsIds, onSelectedItemIdsChange]
  );

  const handleFilterClick = (newValue: string) => {
    const isNumeric = !isNaN(Number(newValue));

    onActiveCategoryChange(
      (isNumeric ? Number(newValue) : newValue) as ItemsActiveCategoryId
    );
  };

  return (
    <Box>
      <Box mb={3}>
        <QuickFilter
          filters={filters}
          activeId={(activeCategoryId ?? "").toString()}
          onFilterClicked={handleFilterClick}
        />
      </Box>

      {visibleCategories.map((category) => (
        <Box key={category.id} mb={3}>
          <ItemList
            category={category}
            items={items[category.id] ?? []}
            noItemsText={noItemsText}
            onItemClick={handleItemClick}
            selectedItemIds={selectedItemsIds}
            readonlySelectedItemIds={readonlySelectedItemIds}
            disabled={disabled}
          />
        </Box>
      ))}
    </Box>
  );
}

export default ToggleItemsList;
