import React, { useCallback, useEffect } from "react";
import { styled } from "@oriola-origo/core/lib/styles";
import Box, { BoxProps } from "@oriola-origo/core/lib/Box";
import Button from "@oriola-origo/core/lib/Button";
import IconButton from "@oriola-origo/core/lib/IconButton";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Tooltip from "@oriola-origo/core/lib/Tooltip";
import Typography from "@oriola-origo/core/lib/Typography";
import TextField from "@oriola-origo/core/lib/TextField";
import useTranslations from "@hooks/useTranslations";
import Select from "@oriola-origo/core/lib/Select";
import MenuItem from "@oriola-origo/core/lib/MenuItem";
import FormHelperText from "@oriola-origo/core/lib/FormHelperText";
import useWindow from "@hooks/useWindow";

const ReadViewContainer = styled(Box)<
  BoxProps & { isInvisible: boolean; isInactive: boolean }
>(({ isInvisible, isInactive }) => ({
  visibility: isInvisible ? "hidden" : "visible",
  pointerEvents: isInvisible ? "none" : "auto",
  opacity: isInactive ? 0.25 : 1,
}));

const EditContainer = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: "100%",
  display: "flex",
  alignItems: "start",
  justifyContent: "space-between",
});

const ColoredFontIcon = styled(FontIcon)(({ theme }) => ({
  color: theme.palette.secondary.lighter,
}));

// Threshold for when to fix the height of the view container
// to prevent to allow it jumping the size when entering edit mode.
// This is a workaround for the issue where the height of the view container
// is not large enought for the rendered edit field.
const FIXED_EDIT_CONTAINER_HEIGHT_THRESHOLD = 100;

export enum EditableFieldType {
  Text,
  Select,
  Custom,
}

export type CustomRendererProps = {
  setInternalValue: React.Dispatch<any>;
  internalValue: any;
  label: string;
  inputError?: string;
};

export type CustomRenderer = (props: CustomRendererProps) => React.ReactElement;

export enum EditableFieldMode {
  Read,
  Edit,
}

export interface EditableFieldProps {
  mode: EditableFieldMode;
  value: any;
  label: string;
  type?: EditableFieldType;
  options?: Array<{ label: string; value: string }>;
  editIcon?: string;
  inactive?: boolean;
  disabled?: boolean;
  disabledInfo?: string;
  customFieldTypeEditRender?: CustomRenderer;
  readValueTransform?: (value: string) => React.ReactNode;
  validateSave?: (value: string) => true | string;
  checkSaveDisabled?: (value: string) => boolean;
  onSave?: (newValue: string) => void | Promise<void>;
  onCancel?: () => void;
  onModeChange?: (newMode: EditableFieldMode) => void;
  property?: string;
  notEditable?: boolean; // Edit button not shown - text color is normal (= not disabled)
  wrapText?: boolean; // For address field which each subfield goes into new line in same typography
  overflowEllipsis?: boolean; // For long text fields - text is cut off with ellipsis (one line)
  checkLongWords?: (value: string) => boolean; // For long text fields (set overflowEllipsis based on content)
}

function EditableField({
  mode,
  value,
  label,
  inactive,
  disabled,
  options = [],
  editIcon,
  type = EditableFieldType.Text,
  customFieldTypeEditRender = () => null,
  readValueTransform,
  validateSave = () => true,
  checkSaveDisabled,
  disabledInfo,
  onModeChange,
  onCancel,
  onSave,
  notEditable,
  wrapText,
  overflowEllipsis,
  checkLongWords,
}: Readonly<EditableFieldProps>) {
  const { t } = useTranslations();
  const [inputError, setInputError] = React.useState<string | null>(null);
  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
  const window = useWindow();
  const [editContainerRef, setEditContainerRef] =
    React.useState<HTMLDivElement | null>(null);
  const [viewContainerFixedHeight, setViewContainerFixedHeight] =
    React.useState<number | null>(null);
  const [internalValue, setInternalValue] = React.useState(value);
  const [isSaving, setIsSaving] = React.useState(false);
  const isEditMode = mode === EditableFieldMode.Edit;

  /**
   * Adjust the height of the view container to match the space needed for the edit container.
   * This might become handy i.e. when using a custom edit field type and the height of the
   * rendered compononent is larger than default view container height.
   */
  const adjustViewContainerHeight = useCallback(() => {
    if (!isEditMode || !editContainerRef) {
      return;
    }

    const editContainerHeight = editContainerRef?.clientHeight ?? 0;
    setViewContainerFixedHeight(
      editContainerHeight > FIXED_EDIT_CONTAINER_HEIGHT_THRESHOLD
        ? editContainerHeight
        : null
    );
  }, [isEditMode, editContainerRef]);

  useEffect(() => {
    setInternalValue(value);
  }, [isEditMode]);

  useEffect(() => {
    if (isEditMode) {
      adjustViewContainerHeight();
      window.addEventListener("resize", adjustViewContainerHeight);
    } else {
      setViewContainerFixedHeight(null);
      window.removeEventListener("resize", adjustViewContainerHeight);
    }
    return () => {
      window.removeEventListener("resize", adjustViewContainerHeight);
    };
  }, [isEditMode, window, adjustViewContainerHeight]);

  useEffect(() => {
    if (isEditMode) {
      inputRef?.focus();
    }
  }, [isEditMode, inputRef]);

  useEffect(() => {
    adjustViewContainerHeight();
  }, [internalValue]);

  const maybeRenderWithTooltip = (content: React.ReactElement) => {
    if (disabled && disabledInfo) {
      return (
        <Tooltip arrow={true} title={disabledInfo}>
          {content}
        </Tooltip>
      );
    }
    return content;
  };

  const renderIconButton = () => (
    <IconButton
      disabled={disabled || inactive}
      title={t("edit")}
      onClick={() => onModeChange(EditableFieldMode.Edit)}
    >
      <ColoredFontIcon fontSize="small">{editIcon ?? "edit"}</ColoredFontIcon>
    </IconButton>
  );

  const renderEdit = () => {
    const handleSave = async () => {
      if (isSaving) {
        return;
      }

      const validationResult = validateSave(internalValue);
      if (typeof validationResult === "string") {
        return setInputError(validationResult);
      }

      setInputError(null);
      setIsSaving(true);
      try {
        if (typeof onSave === "function") {
          await Promise.resolve(onSave(internalValue));
        }
      } finally {
        setIsSaving(false);
      }
    };

    const handleCancel = () => {
      if (typeof onCancel === "function") {
        setInputError(null);
        setInternalValue(value);
        onCancel();
      }
    };

    return (
      <EditContainer>
        <div style={{ width: "100%" }} ref={setEditContainerRef}>
          {type === EditableFieldType.Select && (
            <Select
              error={!!inputError}
              fullWidth={true}
              label={label}
              value={internalValue}
              onChange={(event) =>
                setInternalValue(event.target.value as string)
              }
            >
              {options.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
          )}
          {type === EditableFieldType.Text && (
            <TextField
              error={!!inputError}
              inputRef={setInputRef}
              fullWidth={true}
              label={label}
              value={internalValue}
              onChange={(e) => setInternalValue(e.target.value)}
            />
          )}
          {type === EditableFieldType.Custom &&
            customFieldTypeEditRender({
              internalValue,
              setInternalValue,
              label,
              inputError,
            })}
          <Box display="flex" justifyContent="space-between">
            <Box>
              {!!inputError && (
                <FormHelperText error={true}>{inputError}</FormHelperText>
              )}
            </Box>
            <Box display="flex" justifyContent="end" gap={1} mt={1}>
              <Button
                disabled={isSaving}
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleCancel}
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                disabled={
                  typeof checkSaveDisabled === "function"
                    ? checkSaveDisabled(internalValue)
                    : false
                }
                color="primary"
                size="small"
                onClick={handleSave}
                isLoading={isSaving}
              >
                {t("save")}
              </Button>
            </Box>
          </Box>
        </div>
      </EditContainer>
    );
  };

  return (
    <Box position="relative">
      {maybeRenderWithTooltip(
        <ReadViewContainer
          isInvisible={isEditMode}
          isInactive={inactive}
          sx={{ height: viewContainerFixedHeight ?? undefined }}
        >
          <Typography display="block" color="textSecondary" fontSize={12}>
            {label}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              lineHeight="40px"
              noWrap={overflowEllipsis || checkLongWords?.(value)}
              sx={{
                color: (theme) =>
                  disabled
                    ? theme.palette.text.disabled
                    : theme.palette.text.primary,
              }}
              style={
                wrapText ? { whiteSpace: "pre-wrap", lineHeight: "2" } : {}
              }
            >
              {readValueTransform ? readValueTransform(value) : value}
            </Typography>
            {!notEditable && renderIconButton()}
          </Box>
        </ReadViewContainer>
      )}
      {isEditMode && renderEdit()}
    </Box>
  );
}

export default EditableField;
