import React from 'react';
import { Modal, CloseModal } from '../common/modal';
import { Form, FormSubmit } from '../common/form';
import Info from '../common/info'
import Select from '../common/select';
import RoleIcon from './role_icon';

export default class FormModal extends React.Component {

    constructor(props) {
        super(props);

        this.rootRef = React.createRef();

        this.state = {
            role: {
                ...(props.role || {}),
                icon_title: props.role && props.role.icon_title !== null ? props.role.icon_title : {}
            }
        };
    }

    render() {
        const role = this.state.role;
        const isIconSelected = !!role.icon || false;

        return  <Modal title={this.props.title} onClose={this.props.onClose}>
            <Form ref={this.rootRef} action={this.props.action} onSuccess={() => this.props.onSuccess(role)}>
                { this.props.origoApplicationId &&
                    // New role
                    <input name="role[origo_application_id]" value={this.props.origoApplicationId} type="hidden" />
                }
                { role.id &&
                    // Edit role
                    <input name="role[id]" value={role.id} type="hidden" />
                }
                <div className="form-group">
                    <label htmlFor="role_str_id">{I18n.t('role_str_id')} *</label>
                    <Info text={I18n.t('role_str_id_info')} />
                    <input id="role_str_id" name="role[str_id]" type="text" defaultValue={role.str_id || ''}
                        readOnly={!this.props.origoApplicationId} className="form-control" onChange={e => this.roleChanged('str_id', e.currentTarget.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="role_name">{I18n.t('name')} *</label>
                    <input id="role_name" name="role[name]" type="text" defaultValue={role.name || ''}
                        className="form-control" onChange={e => this.roleChanged('name', e.currentTarget.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="role_description">{I18n.t('description')}</label>
                    <textarea id="role_description" name="role[description]" defaultValue={role.description || ''}
                        rows="5" className="form-control" onChange={e => this.roleChanged('description', e.currentTarget.value)} />
                </div>
                <div className="form-group">
                    <label>{I18n.t('icon')}</label>
                    <Select
                        name="role[icon]"
                        options={this.props.iconOptions}
                        formatOptionLabel={(option, context) => {
                            if (context == 'value' || option.value == '') {
                                return option.label;
                            }

                            return <RoleIcon icon={option.value} />;
                        }}
                        defaultValue={this.props.iconOptions.find(o => o.value == (role.id ? (role.icon || '') : ''))}
                        isSearchable={false}
                        onChange={option => this.roleChanged('icon', (option.value || null))}
                    />
                </div>
                <div className={`form-group${isIconSelected ? ' d-block' : ' d-none'}`}>
                    <label>{I18n.t('icon_title')}</label>
                    {Origo.AVAILABLE_LANGUAGES.map(lang => <div key={lang} className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={{ width: '2.7rem' }}>{lang.toUpperCase()}</span>
                        </div>
                        <input type="text" name={`role[icon_title][${lang}]`} className="form-control"
                            value={role.icon_title ? (role.icon_title[lang] || '') : ''} disabled={!isIconSelected}
                            onChange={e => this.roleChanged('icon_title', { ...role.icon_title, [lang]: e.currentTarget.value })} />
                    </div>)}
                </div>
                <div className="text-right">
                    <CloseModal className="btn btn-light mr-3 px-4">{I18n.t('cancel')}</CloseModal>
                    <FormSubmit />
                </div>
            </Form>
        </Modal>
    }

    roleChanged = (attribute, value) => {
        this.setState((prevState) => {
            let role = {...prevState.role};
            role[attribute] = value;
            return { role: role };
        });
    }
}
