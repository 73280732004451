import React, { ReactElement, memo } from "react";
import Box from "@oriola-origo/core/lib/Box";
import Switch from "@oriola-origo/core/lib/Switch";
import Typography from "@oriola-origo/core/lib/Typography";
import { styled } from "@oriola-origo/core/lib/styles";
import { ToggleItemsListItem } from "./toggle_items_list";
import Tooltip from "@oriola-origo/core/lib/Tooltip";

export interface ItemListItemProps {
  item: Omit<ToggleItemsListItem, "disabled" | "disabledTooltip">;
  selected: boolean;
  disabled?: boolean;
  tooltipText?: string;
  onClick: (item: ToggleItemsListItem) => void;
}

const Root = styled("li")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}));

const maybeRenderWithTooltip = (content: ReactElement, tooltipText?: string) =>
  tooltipText ? (
    <Tooltip title={tooltipText} placement="bottom-start">
      {content}
    </Tooltip>
  ) : (
    content
  );

const ItemListItem = memo(
  ({
    item,
    selected,
    onClick,
    disabled,
    tooltipText,
  }: Readonly<ItemListItemProps>) => {
    return maybeRenderWithTooltip(
      <Root>
        <Box mr={0.5}>
          <Switch
            checked={selected}
            disabled={disabled}
            onClick={() => onClick(item)}
          />
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            color={(theme) =>
              disabled
                ? theme.palette.text.disabled
                : theme.palette.text.primary
            }
          >
            {item.name}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {item.description || <span>&nbsp;</span>}
          </Typography>
        </Box>
      </Root>,
      tooltipText ?? null
    );
  }
);

export default ItemListItem;
