import React from "react";
import Box from "@oriola-origo/core/lib/Box";
import { styled } from "@oriola-origo/core/lib/styles";
import Typography from "@oriola-origo/core/lib/Typography";
import ReportLinkWidgetV2, { IReportLinkWidgetV2Props } from "./report_link_v2";
import { getStaticDataMappingByUrl } from "./utils";

interface IReportLinkContainerProps {
  reportLinkWidgets: IReportLinkWidgetV2Props[];
}

const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 4),
  backgroundColor: theme.palette.background.default,
  marginBottom: theme.spacing(4),
}));

const Title = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));

const ReportLinkWidgetContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(auto-fit, minmax(12.5em, 1fr))`,
  gridGap: theme.spacing(3),
}));

export function NewReportLinkWidgets(props: IReportLinkContainerProps) {
  const { reportLinkWidgets } = props;

  const enrichedReportLinkWidgets = reportLinkWidgets.map((widget) => {
    const { icon, image, dataTrackingText } = getStaticDataMappingByUrl(widget.settings.report_url);
    return {
      ...widget,
      icon,
      image,
      dataTrackingText,
    };
  });

  return (
    <Root>
      <Title id="report-link-widgets-title">
        <Typography variant="h5">
          {I18n.t("report_link_widgets_main_title")}
        </Typography>
      </Title>
      <ReportLinkWidgetContainer>
        {enrichedReportLinkWidgets.map((widget) => {
          return (
            <ReportLinkWidgetV2
              key={widget.id}
              {...widget}
            ></ReportLinkWidgetV2>
          );
        })}
      </ReportLinkWidgetContainer>
    </Root>
  );
}

export default NewReportLinkWidgets;
