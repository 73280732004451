import { useCallback, useMemo, useState } from "react";
import useCSRFToken from "../../../../hooks/useCSRFToken";

export interface UseCreateAccessRequestOutput {
  isRequesting: boolean;
  createAccessRequest: (requestAccessReportIds: string[]) => Promise<void>;
}

function useCreateAccessRequest() {
  const [isRequesting, setIsRequesting] = useState(false);
  const csrfToken = useCSRFToken();

  const createAccessRequest = useCallback(
    async (requestAccessReportIds: string[]) => {
      setIsRequesting(true);
      try {
        await fetch(Routes.v1_report_access_index_path(), {
          body: JSON.stringify({ requested_report: requestAccessReportIds }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
        });
      } finally {
        setIsRequesting(false);
      }
    },
    []
  );

  return useMemo(
    () => ({
      isRequesting,
      createAccessRequest,
    }),
    [createAccessRequest, isRequesting]
  );
}

export default useCreateAccessRequest;
