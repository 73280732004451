import React from "react";
import Box from "@oriola-origo/core/lib/Box";
import Dialog from "@oriola-origo/core/lib/Dialog";
import { DialogTitle } from "@oriola-origo/core/lib/DialogTitle";
import { DialogContent } from "@oriola-origo/core/lib/DialogContent";
import { FontIcon } from "@oriola-origo/core/lib/Icons";
import { DialogActions } from "@oriola-origo/core/lib/DialogActions";
import { Button } from "@oriola-origo/core/lib/Button";
import Typography from "@oriola-origo/core/lib/Typography";
import useTranslations from "@hooks/useTranslations";

export interface DeleteConfirmationProps {
  open: boolean;
  title: React.ReactNode;
  content: React.ReactNode;
  isDeleting: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

function DeleteConfirmation({
  open,
  title,
  content,
  isDeleting,
  onDelete,
  onCancel,
}: Readonly<DeleteConfirmationProps>) {
  const { t } = useTranslations();
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle onCloseButtonClick={onCancel}>{title}</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" mb={4}>
          <FontIcon
            sx={{
              fontSize: 150,
              color: (theme) => theme.palette.secondary.light,
            }}
          >
            delete_outline
          </FontIcon>
        </Box>
        <Typography color="text.secondary">{content}</Typography>
      </DialogContent>
      <DialogActions sx={{ boxShadow: "none" }}>
        <Button
          disabled={isDeleting}
          variant="outlined"
          color="secondary"
          onClick={onCancel}
        >
          {t("cancel")}
        </Button>
        <Button
          disabled={isDeleting}
          isLoading={isDeleting}
          variant="contained"
          color="error"
          onClick={onDelete}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmation;
