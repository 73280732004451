import React, { useState } from "react";
import Header from "@oriola-origo/core/lib/Header";
import Page from "@oriola-origo/core/lib/Page";
import Box from "@oriola-origo/core/lib/Box";
import Grid from "@oriola-origo/core/lib/Grid";
import useTranslations from "@hooks/useTranslations";
import EditableField, {
  EditableFieldMode,
} from "@components/common/editable_field";
import { Organization } from "@services/user_management/types/organizations";
import { localizeCountryName } from "@utils/country_localization";
import { createDefaultReadValueTransform } from "../utils";

interface OrganizationContactInformationProps {
  organization: Organization;
  isUpdating: boolean;
  onSave: (property: string, value: any) => void | Promise<void>;
}

function OrganizationContactInformation({
  organization,
  isUpdating,
  onSave,
}: Readonly<OrganizationContactInformationProps>) {
  const { t } = useTranslations();
  const [inEditMode, setInEditMode] = useState("");

  const {
    street,
    post_code,
    phone_number,
    city,
    country,
    supply_list_email,
    order_response_email,
    emergency_message_additional_info,
    house_number
  } = organization;

  const inputs = [
    {
      property: "address",
      label: t("address"),
      value: `${street} ${house_number},${post_code} ${city},${localizeCountryName(country, I18n.locale)}`,
      notEditable: true,
      readValueTransform: (value: string) => {
        return value.split(",").filter(Boolean).join("\n");
      },
      wrapText: true,
    },
    {
      property: "supply_list_email",
      label: t("supply_list_email"),
      value: supply_list_email,
      notEditable: true,
    },
    {
      property: "order_response_email",
      label: t("order_response_email"),
      value: order_response_email,
      notEditable: true,
    },
    {
      property: "phone_number",
      label: t("phone_number"),
      value: phone_number,
      notEditable: true,
    },
    {
      property: "emergency_message_additional_info",
      label: t("emergency_message_additional_info"),
      value: emergency_message_additional_info,
    },
  ];

  const defaultReadValueTransform = createDefaultReadValueTransform(t);

  return (
    <Page header={<Header title={t("contact_information")} />} mb={2}>
      <Box>
        <Grid container spacing={2}>
          <Grid size={{ lg: 12 }}>
            <Grid container rowSpacing={2} columnSpacing={8}>
              {inputs.map((input) => (
                <Grid size={{ xs: 12, md: 6, lg: 4 }} key={input.property}>
                  <EditableField
                    label={input.label}
                    value={input.value}
                    disabled={isUpdating}
                    mode={
                      inEditMode === input.property
                        ? EditableFieldMode.Edit
                        : EditableFieldMode.Read
                    }
                    onModeChange={(newMode) =>
                      setInEditMode(
                        newMode === EditableFieldMode.Edit ? input.property : ""
                      )
                    }
                    onCancel={() => {
                      setInEditMode("");
                    }}
                    onSave={async (newValue) => {
                      await onSave(input.property, newValue);
                      setInEditMode("");
                    }}
                    notEditable={input.notEditable}
                    readValueTransform={
                      input.readValueTransform ?? defaultReadValueTransform
                    }
                    wrapText={input.wrapText}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
}

export default OrganizationContactInformation;
