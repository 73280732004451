import React from "react";
import Chip, { ChipProps } from "@oriola-origo/core/lib/Chip";
import useTranslations from "@hooks/useTranslations";

export interface StatusChipProps {
  status: string;
  localeMap: Record<string, string>;
}

export const SUCCESS_LIGHT_COLOR = "#ECF4ED";

function StatusChip({ status, localeMap }: Readonly<StatusChipProps>) {
  const { t } = useTranslations();
  const sx: ChipProps["sx"] = (theme) => {
    if (status === "active") {
      return {
        backgroundColor: SUCCESS_LIGHT_COLOR,
        color: theme.palette.success.main,
      };
    } else if (status === "invited") {
      return {
        backgroundColor: theme.palette.primary.lightest,
        color: theme.palette.primary.main,
      };
    }

    return {
      backgroundColor: theme.palette.secondary.lightest,
      color: theme.palette.secondary.light,
    };
  };

  return <Chip sx={sx} size="small" label={t(localeMap[status])} />;
}

export default StatusChip;
