import React from 'react';
import ReactDOMServer from 'react-dom/server';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DropdownMenu from '../common/dropdown_menu';
import Loading from '../common/loading';
import _ from 'underscore';

/*
 * Table is using BootstrapTable. See its documentation for complete
 * list of initialisation options.
 * https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/getting-started.html
 * 
 * Different from docs: 
 * 
 * Pagination can be boolean or an object. With boolean
 * the pagination is initialised with defaults and if an object is given, its
 * properties will override the default pagination options.
 * 
 * Remote can be boolean or an object. With true the remote is initialised
 * with default remote options which can be overriden with an object.
 * Option remoteUrl is also required. Example:
 * remote: true,
 * remoteUrl: '/path/to/action'
 */
export class Table extends React.Component {
    constructor(props) {
        super(props);

        // Table state is preserved to sessionStorage using this key
        this.sessionStateKey = 'table-state-' + window.location.href.replace(/^https?:\/\//, '');

        // Do search only every so often
        this.search = _.throttle(
            this.search.bind(this),
            (this.props.remote ? 1500 : 500),
            { leading: false }
        );
        this.resize = this.resize.bind(this);
        this.updateColumnFilters = this.updateColumnFilters.bind(this);
        this.loadRemoteData = this.loadRemoteData.bind(this);
        this.clearSearch = this.clearSearch.bind(this);

        // Clone and merge props with some defaults
        this.tableOpts = Object.assign({
            keyField: 'id',
            bordered: false
        }, props);
        this.tableOpts.ref = 'table';

        // Merge remote options to defaults
        if (this.tableOpts.remote && this.tableOpts.remoteUrl) {
            this.tableOpts.remote = Object.assign({
                pagination: props.pagination ? true : false
            }, props.remote);
            this.tableOpts.onTableChange = (type, newState) => {
                this.loadRemoteData(null, newState);
            }
        } else {
            this.tableOpts.remote = null;
        }

        // Column filter options for state
        let columnFilterOptions = {};
        props.columns.forEach(col => columnFilterOptions[col.dataField] = Array.isArray(col.filters) ? col.filters : null);

        // Set data to component state for triggering the filtering changes
        let sessionState = this.loadStateFromSession();
        this.fullData = this.tableOpts.data || [];
        this.state = {
            data: this.fullData,
            totalSize: this.fullData.length,
            page: sessionState.page || 1,
            pageSize: (props.pagination || {}).sizePerPage || 20,
            filter: props.searchFilter || sessionState.filter,
            filterRegExp: props.searchFilter ? this.createFilterRegExp(props.searchFilter) : sessionState.filterRegExp,
            columnFilters: sessionState.columnFilters,
            sortField: sessionState.sortField,
            sortOrder: sessionState.sortOrder,
            columnFilterOptions: columnFilterOptions
        };

        if (sessionState.sortField && sessionState.sortOrder) {
            this.tableOpts.defaultSorted = [{
                dataField: sessionState.sortField,
                order: sessionState.sortOrder
            }];
        }

        this.tableOpts.pagination = null;
        this.latestRemoteDataRequestTimestamp = null;

        this.bindSortEventHandlers();
    }

    render() {
        this.tableOpts.noDataIndication = this.getSuitableNoDataIndication();
        let searchFieldPlaceholder = this.props.searchFieldPlaceholder || I18n.t('search');

        return (
            <div ref="root" className="react-table">
                {!this.props.actions && this.props.search === false ? null : <div className="row justify-content-end react-table-head mb-3">
                    {this.props.actions ? <div className="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-9 mb-3 mb-sm-0 action-container">
                        {this.props.actions}
                    </div> : null}
                    {this.props.search === false ? null : <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 search-container">
                        <div className="search-input">
                            {this.state.filter ? null : <i className="icon icon-search"></i>}
                            <input ref="search" type="text" defaultValue={this.state.filter} className="form-control"
                                placeholder={searchFieldPlaceholder} onChange={this.search} />
                            {this.state.filter ? <a href="#" className="search-clear" onClick={this.clearSearch}>
                                <i className="icon icon-x"></i>
                            </a> : null}
                        </div>
                    </div>}
                </div>}
                <div className="react-table-body">
                    <div className="react-bootstrap-table-container">
                        {React.createElement(BootstrapTable, this.tableOpts)}
                    </div>
                </div>
            </div>
        );
    }

    search() {
        let filter = this.refs.search ? this.refs.search.value.trim() : '';

        if (this.props.remote) {
            this.loadRemoteData('globalFilter', { filter: filter, page: 1 });
        } else {
            this.filterHasChanged = true;
            this.setState({
                page: 1,
                filter: filter,
                filterRegExp: this.createFilterRegExp(filter)
            });
        }
    }

    clearSearch(e) {
        jQuery(this.refs.search).val('');

        if (this.props.remote) {
            this.loadRemoteData('globalFilter', { filter: '', page: 1 });
        } else {
            this.filterHasChanged = true;
            this.setState({
                page: 1,
                filter: '',
                filterRegExp: null
            });
        }

        e.preventDefault();
    }

    // Default filter by all columns' unformatted data
    defaultFilterCallback(row, filter, filterRegExp) {
        return this.props.columns.map((c) => {
            return row[c.dataField] || '';
        }).join(' ').match(filterRegExp);
    }

    loadRemoteData(type, options) {
        let params = {
            globalFilter: type == 'globalFilter' ? options.filter : this.state.filter,
            columnFilters: type == 'columnFilters' ? options.columnFilters : this.state.columnFilters,
            page: type == 'globalFilter' ? 1 : options.page || this.state.page,
            sizePerPage: options.sizePerPage || this.state.pageSize,
            sortField: options.sortField || this.state.sortField,
            sortOrder: options.sortOrder || this.state.sortOrder
        };

        let setFilterHasChangedFlag = () => {
            if (type == 'globalFilter') {
                this.filterHasChanged = true;
            }
        };

        Indicator.set(jQuery(this.refs.root).find('.react-table-body'), true);

        const currentRemoteDataRequestTimestamp = Date.now();
        this.latestRemoteDataRequestTimestamp = currentRemoteDataRequestTimestamp;

        jQuery.getJSON(this.props.remoteUrl, params)
            .done((data) => {
                // Ignore callback if new request has already been sent before this one has finished
                if (currentRemoteDataRequestTimestamp != this.latestRemoteDataRequestTimestamp) {
                    return;
                }

                setFilterHasChangedFlag();

                this.setState({
                    filter: typeof options.filter !== 'undefined' ? options.filter : this.state.filter,
                    data: data.rows,
                    totalSize: data.total_count,
                    page: params.page,
                    pageSize: params.sizePerPage,
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                    remoteDataError: false
                });

                if (this.props.remoteDataLoaded) {
                    this.props.remoteDataLoaded(data);
                }
            })
            .fail(() => {
                // Ignore callback if new request has already been sent before this one has finished
                if (currentRemoteDataRequestTimestamp != this.latestRemoteDataRequestTimestamp) {
                    return;
                }

                setFilterHasChangedFlag();

                this.setState({
                    remoteDataError: true
                });
            })
            .always(() => {
                // Ignore callback if new request has already been sent before this one has finished
                if (currentRemoteDataRequestTimestamp != this.latestRemoteDataRequestTimestamp) {
                    return;
                }
                
                Indicator.remove(jQuery(this.refs.root).find('.react-table-body'));
            });
    }

    resize() {
        // Update no data -indication colspan to visible columns
        let noDataColumn = jQuery(this.refs.root).find('.react-bs-table-no-data');
        noDataColumn.attr('colspan', jQuery(this.refs.root).find('table thead tr:first-child th:visible').length);

        // Mark table container with no-search class when search input is empty
        let tableContainer = jQuery(this.refs.root).find('.react-bootstrap-table-container');
        if (this.props.search === false || !(jQuery(this.refs.root).find('.search-input input').val() || '').trim()) {
            tableContainer.addClass('no-search');
        } else {
            tableContainer.removeClass('no-search');
        }
    }

    togglePagination() {
        let pageItems = jQuery(this.refs.root).find('.page-item');
        pageItems.closest('.react-bootstrap-table-pagination-list').toggle(pageItems.length > 1);
    }

    componentWillUpdate(nextProps, nextState) {
        // With remote data filtering is done on server side.
        if (nextProps.remote) {
            this.tableOpts.data = nextState.data;
        } else {
            // Otherwise apply filter callback
            this.applySearch(nextProps, nextState);
            this.applyColumnFilters(nextProps, nextState);
        }

        this.updatePaginationOptions(nextProps, nextState);
    }

    componentWillMount() {
        this.componentWillUpdate(this.props, this.state);
    }

    componentDidMount() {
        jQuery(window).off('resize', this.resize).on('resize', this.resize);

        if (this.props.remote) {
            Indicator.set(jQuery(this.refs.root).find('.react-table-body'), true);
        }

        // Build column filters view
        this.renderColumnFilters();

        this.togglePagination();
        this.resize();
    }

    updatePaginationOptions(nextProps, nextState) {
        // Merge given pagination options to default pagination options and
        // pass to paginationFactory.
        this.tableOpts.pagination = nextProps.pagination ? paginationFactory(Object.assign({
            page: nextState.page || 1,
            sizePerPage: nextState.pageSize,
            totalSize: nextState.totalSize,
            hideSizePerPage: true // It doesn't work well :/
        }, nextProps.pagination)) : null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.preserveStateToSession();

        // Build column filters view
        this.renderColumnFilters();

        this.togglePagination();
        this.resize();

        if (this.props.onFilterChange && this.filterHasChanged) {
            this.filterHasChanged = false;
            this.props.onFilterChange(this.state.filter);
        }
    }

    renderColumnFilters(filters) {
        // Are there any column filters OR are they already rendered?
        if (this.props.columns.filter(c => (c.filters || []).length > 0).length == 0 || jQuery(this.refs.root).find('table thead tr.filters').length > 0) {
            return;
        }

        jQuery(this.refs.root).find('table thead tr').addClass('titles');
        jQuery(this.refs.root).find('table thead').append(ReactDOMServer.renderToStaticMarkup(
            <tr className="filters">
            </tr>
        ));
        let filterRow = jQuery(this.refs.root).find('table thead tr.filters');

        this.props.columns.filter(c => !c.hidden).forEach((col, idx) => {
            filterRow.append(ReactDOMServer.renderToStaticMarkup(<th className={col.headerClasses}></th>));

            if (col.filters) {
                let anyChecked = ((this.state.columnFilters || {})[col.dataField] || []).length > 0;

                filterRow.find('th:eq(' + idx + ')').append(ReactDOMServer.renderToStaticMarkup(
                    <DropdownMenu.Menu label={<span><i className="icon icon-filter"></i> {I18n.t('filter')}</span>} btnClass={'filter-toggle' + (anyChecked ? ' filtered' : '')}>
                        <div key="s" className="search px-2 pb-2">
                            <input type="text" className="form-control" defaultValue="" placeholder={I18n.t('search')} />
                        </div>
                        <div key="f" className="filters bg-light" data-column={col.dataField}>
                            <div className="p-3"><Loading /></div>
                        </div>
                        <div key="a" className="actions">
                            <a href="#" className="clear btn btn-sm btn-light mr-1">{I18n.t('clear')}</a>
                            <a href="#" className="done btn btn-sm btn-primary">{I18n.t('done')}</a>
                        </div>
                    </DropdownMenu.Menu>
                ));

                if (Array.isArray(col.filters)) {
                    filterRow.find('th:eq(' + idx + ') .filters').html(this.formatFilterOptions(col.dataField, col.filters));
                } else if (col.filters.optionsUrl) {
                    jQuery.getJSON(col.filters.optionsUrl, (options) => {
                        filterRow.find('th:eq(' + idx + ') .filters').html(this.formatFilterOptions(col.dataField, options));
                        this.setState(prevState => {
                            let opts = prevState.columnFilterOptions;
                            opts[col.dataField] = options;
                            return opts;
                        });
                    });
                }
            }
        });

        filterRow.on('click', 'a.done', (e) => {
            e.preventDefault();
        });
        filterRow.on('click', 'a.clear', (e) => {
            jQuery(e.currentTarget).closest('.dropdown-menu').find('.filters .item .check').removeClass('checked');
            this.updateColumnFilters();
            e.preventDefault();
            e.stopPropagation();
        });
        filterRow.on('click', '.filters .item', (e) => {
            jQuery(e.currentTarget).find('.check').toggleClass('checked');
            this.updateColumnFilters();
            e.stopPropagation();
        });
        filterRow.on('keyup', '.search input', _.throttle((e) => {
            let optionsContainer = jQuery(e.currentTarget).closest('.dropdown-menu').find('.filters');
            const dataField = optionsContainer.attr('data-column');
            const searchTerm = e.currentTarget.value.toLowerCase();

            if ((e.currentTarget.value || '').length > 0) {
                optionsContainer.html(this.formatFilterOptions(dataField, (this.state.columnFilterOptions[dataField] || []).filter(o => {
                    // Already checked or matches the criteria
                    return ((this.state.columnFilters || {})[dataField] || []).indexOf(o.value) != -1 || o.label.toLowerCase().match(searchTerm);
                })));
            } else {
                optionsContainer.html(this.formatFilterOptions(dataField, this.state.columnFilterOptions[dataField] || []));
            }
            optionsContainer.scrollTop(0);
        }, 500));
    }

    formatFilterOptions = (dataField, options) => {
        // Include always the checked ones and max 1000 unchecked options.
        let filteredOpts = options.map((o, i) => {
            const isChecked = ((this.state.columnFilters || {})[dataField] || []).indexOf(o.value) != -1;

            if (isChecked || i < 1000) {
                return (
                    <div key={o.value} className="item text-truncate" data-label={o.label.toLowerCase()}>
                        <i className={'check fa fa-checkbox' + (isChecked ? ' checked' : '')} data-value={o.value}></i>
                        {o.label}
                    </div>
                );
            }
            return null;
        }).filter(o => o);

        if (filteredOpts.length >= 1000) {
            filteredOpts.push(
                <div key="toomany" className="item text-muted text-center pt-2 pb-3">
                    <small>{I18n.t('table_too_many_filter_options_message')}</small>
                </div>
            );
        }

        return ReactDOMServer.renderToStaticMarkup(filteredOpts);
    }

    updateColumnFilters() {
        let columnFilters = {};

        jQuery(this.refs.root).find('table thead tr.filters .filters').each((i, filters) => {
            filters = jQuery(filters);
            let col = filters.data('column');
            filters.find('.item .check.checked').each((j, check) => {
                columnFilters[col] = columnFilters[col] || [];
                columnFilters[col].push(jQuery(check).data('value'));
            });
            filters.closest('th').find('.filter-toggle').toggleClass('filtered', (columnFilters[col] || []).length > 0);
        });

        this.setState({
            page: 1,
            columnFilters: columnFilters
        });

        if (this.props.remote) {
            this.loadRemoteData('columnFilters', { columnFilters: columnFilters });
        }
    }

    applyColumnFilters(nextProps, nextState) {
        if (Object.keys(nextState.columnFilters || {}).length == 0) {
            return;
        }
        this.tableOpts.data = this.tableOpts.data.filter((row) => {
            let include = true;

            for (let dataField in nextState.columnFilters) {
                if (nextProps.filterColumnCallback) {
                    include = (include && nextProps.filterColumnCallback(row, dataField, nextState.columnFilters[dataField]));
                } else {
                    include = (include && nextState.columnFilters[dataField].indexOf(row[dataField]) != -1);
                }
            }
            return include;
        });
    }

    applySearch(nextProps, nextState) {
        if (nextState.filter) {
            this.tableOpts.data = this.fullData.filter((item) => {
                if (nextProps.filterCallback) {
                    return nextProps.filterCallback(item, nextState.filter, nextState.filterRegExp);
                } else {
                    return this.defaultFilterCallback(item, nextState.filter, nextState.filterRegExp);
                }
            });
        } else {
            this.tableOpts.data = this.fullData;
        }
    }

    isAnyFiltersApplied() {
        let columnFilterCount = 0;
        Object.keys(this.state.columnFilters || {}).forEach((key, index) => {
            if (this.state.columnFilters[key].length) {
                columnFilterCount++;
            }
        });

        return (this.state.filter || columnFilterCount > 0 ? true : false);
    }

    getSuitableNoDataIndication() {
        if (this.state.remoteDataError) {
            return this.props.dataErrorIndication || I18n.t('table_data_error_notification');
        } else if (this.isAnyFiltersApplied()) {
            return this.props.noResultsIndication || I18n.t('table_no_results_notification');
        }

        return this.props.noDataIndication || I18n.t('table_empty_data_notification');
    }

    preserveStateToSession() {
        if (this.props.preserveState) {
            let sessionState = JSON.parse(sessionStorage.getItem(this.sessionStateKey) || '{}');

            ['page', 'filter', 'columnFilters', 'sortField', 'sortOrder'].forEach(k => {
                sessionState[k] = this.state[k];
            });
            sessionStorage.setItem(this.sessionStateKey, JSON.stringify(sessionState));
        }
    }

    loadStateFromSession() {
        if (this.props.preserveState) {
            let sessionState = JSON.parse(sessionStorage.getItem(this.sessionStateKey) || 'null') || {};

            if (sessionState.filter) {
                sessionState.filterRegExp = this.createFilterRegExp(sessionState.filter);
            }
            return sessionState;
        }
        return {};
    }

    bindSortEventHandlers() {
        this.tableOpts.columns.forEach(c => {
            c.onSort = (field, order) => {
                if (field && order) {
                    if (typeof this.tableOpts.columns.find(col => col.dataField == field).onSort === 'function') {
                        c.onSort();
                    }
                    this.setState({
                        sortField: field,
                        sortOrder: order
                    });
                }
            }
        });
    }

    createFilterRegExp(filter) {
        return filter.length > 0 ? new RegExp(filter, 'i') : null;
    }
}


export let TableRowActions = {
    Menu: class extends React.Component {
        render() {
            return (
                <DropdownMenu.Menu label={<span className="d-block" style={{margin: '-0.15rem 0 0.15rem 0'}}>&#x22EE;</span>} align="right" btnClass="btn btn-light table-row-actions" disabled={this.props.disabled}>
                    {this.props.children}
                </DropdownMenu.Menu>
            );
        }
    },
    Divider: DropdownMenu.Divider
};
