import React from 'react';

let DropdownMenu = {
    Menu: class extends React.Component {
        render() {
            let className = [this.props.disabled ? 'disabled' : null, this.props.btnClass].filter(c => c).join(' ');
            return (
                <div className="dropdown">
                    <a href="#" className={className} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {this.props.label}
                    </a>
                    <div ref="items" className={'dropdown-menu' + (this.props.align == 'right' ? ' dropdown-menu-right' : '')}>
                        {this.props.children}
                    </div>
                </div>
            );
        }

        componentDidMount() {
            this.addItemClasses()
        }

        componentDidUpdate() {
            this.addItemClasses()
        }

        addItemClasses() {
            jQuery(this.refs.items).find('> *:not(.dropdown-divider, .dropdown-header)').addClass('dropdown-item');
        }
    },

    Divider: class extends React.Component {
        render() {
            return <li className="dropdown-divider" role="separator"></li>
        };
    },

    Header: class extends React.Component {
        render() {
            return <h6 className="dropdown-header">{this.props.children}</h6>
        };
    }
};

export default DropdownMenu;