import React from "react";
import UserAccessInformation, {
  UserAccessCompanyMap,
} from "@components/user/components/user_access_information/user_access_information";
import { UserCompany } from "@services/user_management/types/users";
import { UserData } from "@components/user/user_view/user_page_v2";

export interface UserAccessSectionProps {
  userCompanies: UserCompany[];
  userData: UserData;
  userAccessMap: UserAccessCompanyMap;
  isUpdatingUser?: boolean;
  handleOnAccessMapChanged: (userAccessMap: UserAccessCompanyMap) => void;
}

export default function UserAccessSection({
  userCompanies,
  userData,
  userAccessMap,
  isUpdatingUser,
  handleOnAccessMapChanged,
}: Readonly<UserAccessSectionProps>) {
  const {
    apps,
    permissions,
    roles,
    user_groups,
    user_group_categories,
    noninheriting_role_ids,
  } = userData;

  return userCompanies.length > 0 ? (
    <UserAccessInformation
      apps={apps}
      roles={roles}
      userGroups={user_groups}
      permissions={permissions}
      nonInheritingRoleIds={noninheriting_role_ids}
      userGroupCategories={user_group_categories}
      selectedCompanies={userCompanies}
      onUserAccessMapChange={(updatedUserAccessMap) =>
        handleOnAccessMapChanged(updatedUserAccessMap)
      }
      userAccessMap={userAccessMap}
      disabled={isUpdatingUser}
    />
  ) : null;
}
