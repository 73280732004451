import IconButton from "@oriola-origo/core/lib/IconButton";
import { styled } from "@oriola-origo/core/lib/styles";

const HideableIconButton = styled(IconButton)<{ invisible: boolean }>(
  ({ invisible }) => ({
    padding: 0,
    visibility: invisible ? "hidden" : "visible",
    pointerEvents: invisible ? "none" : "auto",
  })
);

export default HideableIconButton;
