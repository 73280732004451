import React from "react";
import {
  OrigoApplication,
  OrigoApplicationRole,
} from "@services/user_management/types";
import ToggleItemsList, {
  ALL_CATEGORY_ID,
  ItemsActiveCategoryId,
  ToggleItemsListItem,
} from "@components/common/toggle_items_list";
import useTranslations from "@hooks/useTranslations";

export interface OrganizationRolesProps {
  apps: OrigoApplication[];
  roles: { [origoApplicationId: number]: OrigoApplicationRole[] };
  selectedCompanyTypeStrId: string;
  selectedRoleIds: number[];
  inheritedRoleIds: number[];
  isReadonly?: boolean;
  onSelectedRoleIdsChange: (selectedRoleIds: number[]) => void;
}

export default function OrganizationRoles({
  apps,
  onSelectedRoleIdsChange,
  roles,
  selectedRoleIds,
  isReadonly,
  inheritedRoleIds,
}: Readonly<OrganizationRolesProps>) {
  const { t } = useTranslations();
  const [activeCategoryId, setActiveCategoryId] =
    React.useState<ItemsActiveCategoryId>(ALL_CATEGORY_ID);
  const [localRoleIds, setLocalRoleIds] =
    React.useState<number[]>(selectedRoleIds);

  const items: { [categoryId: number]: ToggleItemsListItem[] } = {};
  Object.keys(roles).forEach((key) => {
    items[key] = roles[key].map((role) => {
      let disabledTooltip = null;
      if (inheritedRoleIds.includes(role.id)) {
        disabledTooltip = t("roles_inherited_from_company_type");
      }

      return {
        id: role.id,
        name: role.name,
        description: role.description,
        disabledTooltip,
      };
    });
  });

  const handleSelectItemIdsChange = (ids: number[]) => {
    setLocalRoleIds(ids);
    onSelectedRoleIdsChange(ids);
  };

  return (
    <ToggleItemsList
      categories={apps}
      items={items}
      onSelectedItemIdsChange={handleSelectItemIdsChange}
      selectedItemsIds={localRoleIds}
      activeCategoryId={activeCategoryId}
      readonlySelectedItemIds={inheritedRoleIds}
      onActiveCategoryChange={setActiveCategoryId}
      disabled={isReadonly}
    />
  );
}
