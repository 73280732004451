import React from 'react';
import {default as ReactSwitch} from 'react-switch';

export default function Switch(props) {
    let mergedProps = Object.assign({
        onColor: '#eaeef5',
        offColor: '#eaeef5',
        onHandleColor: '#2B3E5B',
        offHandleColor: '#fff',
        handleDiameter: 20,
        width: 66,
        boxShadow: '0 0 1px rgba(0, 0, 0, 0.7)',
        activeBoxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        uncheckedIcon: <small className="d-inline-block w-100 text-center text-primary">{props.uncheckedLabel || I18n.t('txt_no')}</small>,
        checkedIcon: <small className="d-inline-block w-100 text-center text-primary">{props.checkedLabel || I18n.t('txt_yes')}</small>
    }, props);

    delete mergedProps.uncheckedLabel;
    delete mergedProps.checkedLabel;

    return <ReactSwitch {...mergedProps} />;
}