import useWindow from "@hooks/useWindow";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { createPortal } from "react-dom";

export interface StickyProps extends PropsWithChildren {
  stickyContainerElement: HTMLElement | null;
  viewportElement: HTMLElement | null;
}

export default function Sticky({
  stickyContainerElement,
  viewportElement,
  children,
}: Readonly<StickyProps>) {
  const windowObject = useWindow();
  const [observedElement, setObservedElement] = useState<HTMLElement | null>(
    null
  );
  const [shouldRenderStickyContent, setShouldRenderStickyContent] =
    useState(false);

  useEffect(() => {
    let observer: IntersectionObserver;
    if (
      "IntersectionObserver" in windowObject &&
      observedElement &&
      stickyContainerElement
    ) {
      const observerOptions = { root: viewportElement };
      observer = new IntersectionObserver(([observedElement]) => {
        if (observedElement) {
          setShouldRenderStickyContent(!observedElement.isIntersecting);
        }
      }, observerOptions);
      observer.observe(observedElement);
    }
    return () => {
      observer?.disconnect();
    };
  }, [observedElement, viewportElement, stickyContainerElement]);

  return (
    <>
      {shouldRenderStickyContent &&
        createPortal(children, stickyContainerElement)}
      <div ref={setObservedElement} />
    </>
  );
}
