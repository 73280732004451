export enum InternalItemType {
  EmergencyAnnouncement = "EmergencyAnnouncement",
  ImportantAnnouncement = "ImportantAnnouncement",
  Other = "other",
}

export const toInternalItemType = (type: string): InternalItemType => {
  switch (type) {
    case "EmergencyAnnouncement":
    case "EmergencyMessage":
      return InternalItemType.EmergencyAnnouncement;
    case "ImportantAnnouncement":
      return InternalItemType.ImportantAnnouncement;
    default:
      return InternalItemType.Other;
  }
};
