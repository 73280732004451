import { useCallback, useMemo } from "react";
import useFetch from "../../../hooks/useFetch";
import useCSRFToken from "../../../hooks/useCSRFToken";
import useRoutes from "../../../hooks/useRoutes";

export enum SaveUserGroupAction {
  Update = "update",
  Create = "create",
}

export interface SaveUserGroupInput {
  id?: number;
  name: string;
  description: string;
  userGroupCategoryId: number;
  roleIds: number[];
}

export interface SaveUserGroupOutput {}

function useSaveUserGroup(action: SaveUserGroupAction) {
  const { doFetch, isFetching } = useFetch();
  const csrfToken = useCSRFToken();
  const routes = useRoutes();
  const saveUserGroup = useCallback(
    async (data: SaveUserGroupInput) => {
      const url =
        action === SaveUserGroupAction.Update
          ? routes.userGroupPath(data.id.toString())
          : routes.userGroupsPath();
      const response = await doFetch(url, {
        method: action === SaveUserGroupAction.Update ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          user_group: {
            id: data.id,
            name: data.name,
            description: data.description,
            role_ids: data.roleIds,
            user_group_category_id: data.userGroupCategoryId,
          },
        }),
      });

      return {
        succeed: response.ok,
        errors: response.ok ? undefined : (await response.json()).errors,
      };
    },
    [doFetch, csrfToken, action]
  );

  return useMemo(
    () => ({ saveUserGroup, isSaving: isFetching }),
    [saveUserGroup, isFetching]
  );
}

export default useSaveUserGroup;
