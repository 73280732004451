import React from "react";
import Typography from "@oriola-origo/core/lib/Typography";
import Box from "@oriola-origo/core/lib/Box";
import { EXTENDED_ROW_ITEM_HEIGHT } from "../../user_table/cells/constants";
import useTranslations from "@hooks/useTranslations";
import Tooltip from "@oriola-origo/core/lib/Tooltip";
import { UserTableUser } from "../../user_table";

export interface OrganizationsCellProps {
  isExpanded: boolean;
  organizations: UserTableUser["companies"];
}

function OrganizationCellContent({
  isExpanded,
  organizations,
}: Readonly<OrganizationsCellProps>) {
  const { t } = useTranslations();
  const renderContent = () => {
    if (isExpanded) {
      return organizations.map((company) => (
        <Tooltip title={company.name} key={company.id}>
          <Typography
            noWrap
            variant="body2"
            color="textSecondary"
            height={EXTENDED_ROW_ITEM_HEIGHT}
          >
            {company.name}
          </Typography>
        </Tooltip>
      ));
    }

    const moreCount = organizations.length - 1;
    return (
      <Tooltip title={organizations[0]?.name}>
        <Box display="flex">
          <Typography
            noWrap
            variant="body2"
            display="inline"
            color="textSecondary"
          >
            {organizations[0]?.name}
          </Typography>

          {moreCount > 0 && (
            <Typography
              ml={0.5}
              variant="body2"
              whiteSpace="nowrap"
              display="inline-block"
              color="textPrimary"
              textTransform="lowercase"
            >
              (+{moreCount} {t("more")})
            </Typography>
          )}
        </Box>
      </Tooltip>
    );
  };

  return renderContent();
}

export default OrganizationCellContent;
