import { useMemo } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { setDefaultOrganizationById } from "../../../store/states/inviteUserSlice";

export function useSetDefaultOrganization() {
  const dispatch = useAppDispatch();

  const setDefaultOrganization = (organizationId: number) => {
    dispatch(setDefaultOrganizationById(organizationId));
  };

  return useMemo(() => setDefaultOrganization, [dispatch]);
}
