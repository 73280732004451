import React from "react";
import Typography from "@oriola-origo/core/lib/Typography";
import Box from "@oriola-origo/core/lib/Box";
import useTranslations from "@hooks/useTranslations";
import Tooltip from "@oriola-origo/core/lib/Tooltip";
import { EXTENDED_ROW_ITEM_HEIGHT } from "@components/common/user_table/cells/constants";

export interface UserGroupCellContentProps {
  isExpanded: boolean;
  userGroups: Array<{ id: number; name: string; categoryName: string }>;
}

const buildUserGroupLabel = (
  userGroup: UserGroupCellContentProps["userGroups"][0]
) => [userGroup?.categoryName, userGroup?.name].filter(Boolean).join(" - ");

function UserGroupCellContent({
  isExpanded,
  userGroups,
}: Readonly<UserGroupCellContentProps>) {
  const { t } = useTranslations();
  const renderContent = () => {
    if (isExpanded) {
      return userGroups.map((userGroup) => (
        <Tooltip title={buildUserGroupLabel(userGroup)} key={userGroup.id}>
          <Typography
            noWrap
            variant="body2"
            color="textSecondary"
            height={EXTENDED_ROW_ITEM_HEIGHT}
          >
            {buildUserGroupLabel(userGroup)}
          </Typography>
        </Tooltip>
      ));
    }

    const moreCount = userGroups.length - 1;
    return (
      <Tooltip title={buildUserGroupLabel(userGroups[0])}>
        <Box display="flex">
          <Typography
            noWrap
            variant="body2"
            display="inline"
            color="textSecondary"
          >
            {buildUserGroupLabel(userGroups[0])}
          </Typography>

          {moreCount > 0 && (
            <Typography
              ml={0.5}
              variant="body2"
              whiteSpace="nowrap"
              display="inline-block"
              color="textPrimary"
              textTransform="lowercase"
            >
              (+{moreCount} {t("more")})
            </Typography>
          )}
        </Box>
      </Tooltip>
    );
  };

  return renderContent();
}

export default UserGroupCellContent;
