import {
  OrigoApplication,
  OrigoApplicationRole,
  UserGroup,
} from "@services/user_management/types";
import { UserGroupCategory } from "@services/user_management/hooks/useUserGroupCategories";

export const buildUserGroupCategoriesMapping = (
  userGroupIdsFromOrg: Array<number>,
  userGroups: UserGroup[],
  categories: UserGroupCategory[]
) => {
  return userGroupIdsFromOrg.reduce(
    (acc, id) => {
      const group = userGroups.find((group) => group.id === id);
      if (!group) return acc;
      const category = categories.find(
        (category) => category.id === group.user_group_category_id
      );
      if (!category) return acc;
      acc[category.name] = acc[category.name] ?? [];
      acc[category.name].push(group);
      return acc;
    },
    {} as Record<string, UserGroup[]>
  );
};

export const buildInheritedRolesFromUserGroups = (
  userGroups: UserGroup[],
  userGroupIdsFromOrg: Array<number>
): Array<{ id: number; name: string }> => {
  const inheritedRolesFromUserGroups = userGroupIdsFromOrg.reduce(
    (acc, id) => {
      const group = userGroups.find((group) => group.id === id);
      return group ? [...acc, ...group.roles] : acc;
    },
    [] as Array<{ id: number; name: string }>
  );

  return inheritedRolesFromUserGroups;
};

export const buildUserAllInheritedRoles = (
  inheritedRolesFromUserGroups: Array<{ id: number }>,
  inheritedRolesFromOrganization: Record<string, number[]>
) => {
  const allUserInheritedRoles = [...inheritedRolesFromUserGroups];
  Object.values(inheritedRolesFromOrganization).forEach((roles) => {
    roles.forEach((role) => {
      allUserInheritedRoles.push({ id: role });
    });
  });
  return allUserInheritedRoles;
};

export const buildAppRoleMapping = (
  apps: OrigoApplication[],
  roles: Record<string, OrigoApplicationRole[]>,
  roleIdsFromOrg: Array<number>,
  allUserInheritedRoles: Array<{ id: number }>,
  language: string
): Record<string, Array<OrigoApplicationRole & { isInherited: boolean }>> => {
  const appRoleMapping: { [key: string]: Array<any> } = apps.reduce(
    (acc, app) => {
      const rolesPerApp = roles[app.id];
      if (!rolesPerApp) return acc;

      const appName = app.title[language] ?? app.title["en"];
      acc[appName] = acc[appName] ?? [];

      rolesPerApp.forEach((role) => {
        const isInherited = allUserInheritedRoles.some((r) => r.id === role.id);
        const isNonInherited = roleIdsFromOrg.includes(role.id);

        if (isInherited || isNonInherited) {
          if (!acc[appName].some((r) => r.id === role.id)) {
            acc[appName].push({ ...role, isInherited });
          }
        }
      });
      return acc;
    },
    {}
  );

  return appRoleMapping;
};
