import React from 'react';
import Switch from '../common/switch';
import Loading from '../common/loading';

export default class Assigner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openApps: JSON.parse(sessionStorage.getItem('role-assigner-open-apps') || '[]')
        };
    }

    render() {
        const userGroups = this.props.userGroups || [];
        const permissions = this.props.permissions || {};

        return <div>
            {userGroups.length > 0 ? <div>
                <div>
                    <strong>{I18n.t('user_groups')}</strong>
                </div>
                <div className="mt-3 mb-2">
                    <div className="row">
                        {userGroups.map(group => {
                            return <div key={group.id} className="col-12 col-sm-6 col-md-4 pb-3">
                                <div className="row no-gutters">
                                    <div className="col-auto pr-3">
                                        <Switch
                                            onChange={(checked) => this.toggleUserGroup(group.id, checked)}
                                            checked={(this.props.selectedUserGroupIds || []).indexOf(group.id) != -1}
                                            disabled={group.is_superuser && !permissions.assign_superuser_roles}
                                        />
                                    </div>
                                    <div className="col">
                                        <div>{group.name}</div>
                                        <small className="text-muted">{group.description}</small>
                                    </div>
                                </div>
                            </div>;
                        })}
                    </div>
                </div>
            </div> : null}
            <div>
                <strong>{I18n.t('roles')}</strong>
            </div>
            {this.props.apps.map(app => {
                const inheritedRoleIds = ((this.props.inheritedRoleIds || {})[app.id] || []);
                const roles = ((this.props.roles || {})[app.id] || []);
                let inheritedRoles = [];
                let selectedRoleIds = [];

                roles.forEach(role => {
                    if (inheritedRoleIds.indexOf(role.id) != -1) {
                        inheritedRoles.push(role);
                    } else if ((this.props.selectedRoleIds || []).indexOf(role.id) != -1) {
                        selectedRoleIds.push(role.id);
                    }
                });

                let isOpen = this.state.openApps.indexOf(app.id) != -1;

                return <div key={app.id} className="my-2">
                    <button type="button" className="btn bg-transparent px-0 text-body" onClick={e => this.toggleApp(app.id)}>
                        <i className={`fa fa-${isOpen ? 'minus' : 'plus'} text-highlight mr-2`}></i>
                        {app.title[I18n.locale] || app.title.en} ({inheritedRoleIds.length + selectedRoleIds.length})
                    </button>
                    <div className="border-bottom mt-2">
                        {isOpen ? <div>
                            <div className="row">
                                {roles.map(role => {
                                    let isInherited = inheritedRoleIds.indexOf(role.id) != -1;

                                    return <div key={role.id} className="col-12 col-sm-6 col-md-4 pb-3">
                                        <div className="row no-gutters">
                                            <div className="col-auto pr-3">
                                                <div style={{ width: '66px' }}>
                                                    {role.loading ?
                                                        <Loading
                                                            style={{ fontSize: '160%' }}
                                                        /> :
                                                        <Switch
                                                            onChange={(checked) => this.toggleRole(app.id, role.id, checked)}
                                                            checked={selectedRoleIds.indexOf(role.id) != -1 || isInherited}
                                                            disabled={isInherited || (role.is_superuser && !permissions.assign_superuser_roles)}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div>{role.name || I18n.t('role_' + role.str_id)}</div>
                                                <small className="text-muted">{role.description}</small>
                                            </div>
                                        </div>
                                    </div>;
                                })}
                            </div>
                            {inheritedRoles.length > 0 && <small className="d-block mb-4 text-muted">
                                <div>{this.props.inheritedTitle || I18n.t('inherited_roles')}</div>
                                <strong>
                                    {inheritedRoles.map(r => r.name || I18n.t('role_' + r.str_id)).sort().join(', ')}
                                </strong>
                            </small>}
                        </div> : null}
                    </div>
                </div>;
            })}
        </div>;
    }

    toggleApp = (appId) => {
        this.setState(prevState => {
            let openApps = prevState.openApps;
            const idx = openApps.indexOf(appId);

            if (idx != -1) {
                openApps.splice(idx, 1);
            } else {
                openApps.push(appId);
            }

            sessionStorage.setItem('role-assigner-open-apps', JSON.stringify(openApps));
            return { openApps: openApps };
        });
    }

    toggleRole = (appId, roleId, checked) => {
        this.props.roleToggled && this.props.roleToggled(appId, roleId, checked);
    }

    toggleUserGroup = (groupId, checked) => {
        this.props.userGroupToggled && this.props.userGroupToggled(groupId, checked);
    }
}