import React from "react";
import {
  CompanyType,
  OrigoApplicationRole,
} from "@services/user_management/types";
import { type UseTranslationsOutput } from "@hooks/useTranslations";
import Box from "@oriola-origo/core/lib/Box";

enum RoleSourceType {
  Person = "person",
  UserGroup = "user_group",
  Company = "company",
  CompanyType = "company_type",
}

const I18N_ROLE_SOURCE = "role_source";

const createCompanyTypeNameResolver = (companyTypes: CompanyType[]) => {
  return (companyTypeId: number | string, language: string) => {
    const companyType = companyTypes.find(
      (ct) => ct.id === Number(companyTypeId)
    );
    return companyType?.name?.[language] || companyType?.name?.["en"] || "";
  };
};

const filterAndJoin = (array: string[], separator: string) =>
  array.filter(Boolean).join(separator);

const resolveRoleTooltipContent = (
  role: OrigoApplicationRole,
  i18n: UseTranslationsOutput,
  companyTypes: CompanyType[]
): string => {
  const title = role.icon_title?.[i18n.language] || "";

  let sourceLabel: string;
  let sourceValue: string = role.source_name;

  if (role.source_type === RoleSourceType.UserGroup) {
    sourceLabel = i18n.t("user_group", { source: I18N_ROLE_SOURCE });
  } else if (role.source_type === RoleSourceType.Company) {
    if (sourceValue) {
      sourceLabel = i18n.t("company", { source: I18N_ROLE_SOURCE });
    }
  } else if (role.source_type === RoleSourceType.CompanyType) {
    const companyTypeNameResolver = createCompanyTypeNameResolver(companyTypes);
    const companyTypeName = companyTypeNameResolver(
      role.source_name_extra,
      i18n.language
    );

    sourceLabel = i18n.t("company_type", { source: I18N_ROLE_SOURCE });
    sourceValue = filterAndJoin([companyTypeName, sourceValue], " - ");
  }

  if (sourceLabel) {
    sourceValue = `${sourceLabel}: ${sourceValue}`;
  }

  return filterAndJoin([title, sourceValue ? `(${sourceValue})` : null], " ");
};

export const buildTooltipContent = (
  roles: OrigoApplicationRole[],
  i18n: UseTranslationsOutput,
  companyTypes: CompanyType[]
): React.ReactNode => (
  <>
    {roles.map((role, index) => {
      const isLast = index === roles.length - 1;
      const content = resolveRoleTooltipContent(role, i18n, companyTypes);
      return content ? (
        <Box component="p" key={role.id} mb={isLast ? 0 : 1}>
          {content}
        </Box>
      ) : null;
    })}
  </>
);
