import Countries from "i18n-iso-countries";

Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
Countries.registerLocale(require("i18n-iso-countries/langs/fi.json"));
Countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));

export const localizeCountryName = (
  countryCode: string,
  locale: string = "en"
) => {
  return Countries.getName(countryCode, locale).toLocaleUpperCase(locale);
};
