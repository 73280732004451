import React, { useEffect, useState } from 'react';
import Select from './select';
import { components } from 'react-select';

const styles = {
    option: (base, state) => ({
        ...base,
        backgroundColor: '#fff',
        color: 'inherit',
        ':active': {
            backgroundColor: '#fff',
            color: 'inherit'
        }
    })
};

const checkStyles = {
    width: '1.75rem'
};

const checkIconClass = (checked) => {
    return `${checked ? 'oricon-checkbox-checked' : 'oricon-checkbox-unchecked'} d-inline-block`;
};

const formatOptionLabel = (value, ctx) => {
    if (ctx.context == 'value') {
        return value.label;
    }
    return <div style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}} title={value.label}>
        <i className={checkIconClass((ctx.selectValue || []).find(v => v.value == value.value))} style={checkStyles}></i>
        {value.label}
    </div>;
};

const MenuList = (props) => {
    const currentValue = props.getValue() || [];

    let options = [];
    (props.options || []).forEach(o => {
        options = options.concat(o.options ? o.options : [o]);
    });

    const checkedCount = currentValue.length;
    const [allChecked, setAllChecked] = useState(checkedCount == options.length);

    useEffect(() => {
        if (allChecked && currentValue.length < options.length) {
            setAllChecked(false);
        }
    }, [currentValue.length]);

    useEffect(() => {
        if (allChecked) {
            props.setValue(options);
        } else if (currentValue.length == options.length) {
            props.setValue([]);
        }
    }, [allChecked]);

    return <components.MenuList {...props}>
        <div style={{padding: '8px 12px', cursor: 'default'}} onClick={e => setAllChecked(!allChecked)}>
            <i className={checkIconClass(allChecked)} style={checkStyles}></i>
            {I18n.t('select_checkboxes_all')}
        </div>
        {props.children}
    </components.MenuList>;
};

export default function SelectCheckboxes(props) {

    const selectProps = Object.assign(Object.assign({}, props), {
        formatOptionLabel: formatOptionLabel,
        hideSelectedOptions: false,
        closeMenuOnSelect: typeof props.closeMenuOnSelect == 'undefined' ? false : props.closeMenuOnSelect,
        components: props.hideSelectAll ? {} : { MenuList }
    });

    return <Select {...selectProps} styles={styles} />;
}
