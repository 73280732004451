import React from "react";
import Accordion from "@oriola-origo/core/lib/Accordion";
import AccordionSummary from "@oriola-origo/core/lib/AccordionSummary";
import AccordionDetails from "@oriola-origo/core/lib/AccordionDetails";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Typography from "@oriola-origo/core/lib/Typography";

export interface HintAccordionProps extends React.PropsWithChildren {
  isExpanded: boolean;
  onClick: () => void;
  summaryText: string;
}

function HintAccordion({
  isExpanded,
  onClick,
  summaryText,
  children,
}: Readonly<HintAccordionProps>) {
  return (
    <Accordion disableGutters expanded={isExpanded} onClick={onClick}>
      <AccordionSummary
        expandIcon={<FontIcon fontSize="medium">expand_more</FontIcon>}
      >
        <Typography variant="h6">{summaryText}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export default HintAccordion;
