import React from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

const MENU_OPTION_HEIGHT = 40;
const MENU_PADDING_VERTICAL = 8;

const Input = ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="new-password" />;

/*
 * Wrapper for react-select https://react-select.com/
 * Adding styles to match the Bootstrap look.
 */
export default class Select extends React.Component {
    constructor(props) {
        super(props);

        this.selectOpts = {};

        for (let key in props) {
            this.selectOpts[key] = props[key];
        }

        this.selectOpts.cacheOptions = true;
        this.selectOpts.defaultOptions = props.options && props.options.length < 1000;
        this.selectOpts.noOptionsMessage = () => ( !this.selectOpts.defaultOptions ? I18n.t('select_search_message') : undefined );
        this.selectOpts.loadOptions = props.loadOptions || this.loadOptions;
        this.selectOpts.className = ['react-select-bootstrap', props.isDisabled ? 'disabled' : null, this.selectOpts.className].map(c => c).join(' ');
        this.selectOpts.onFocus = this.onFocus.bind(this);
        this.selectOpts.onBlur = this.onBlur.bind(this);
        this.selectOpts.components = Object.assign({Input}, props.components || {});
    }

    render() {
        return React.createElement(AsyncSelect, this.selectOpts);
    }

    loadOptions = (inputValue, callback) => {
        let re = new RegExp(`.*${inputValue}.*`, 'i')
        callback(this.props.options.filter(o => {
            return o.label.match(re);
        }));
    }

    onFocus(event) {
        this.props.onFocus ? this.props.onFocus(event) : null;

        let container = jQuery(event.currentTarget).closest('.react-select-bootstrap');
        container.addClass('focus');
    }

    onBlur(event) {
        this.props.onBlur ? this.props.onBlur(event) : null;

        let container = jQuery(event.currentTarget).closest('.react-select-bootstrap');
        container.removeClass('focus');
    }
}

export const getTotalMenuHeight = (options) => {
    return options.length * MENU_OPTION_HEIGHT + MENU_PADDING_VERTICAL;
}
